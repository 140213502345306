import React, { useState } from "react";
import classes from "./ToggleWrapper.module.scss";
import PropTypes from "prop-types";
import { Icon, Title } from "../../components";

const ToggleWrapper = ({ className, style, titleText, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className={className} style={style}>
      <div className={classes.top}>
        <div className={classes.clicker} onClick={() => setIsOpen(!isOpen)}>
          <Icon className={classes.icon} style={{ width:'16px',...(isOpen ? { transform: "rotate(180deg)" } : {}) }} name="arrow" />
          <Title text={titleText} />
        </div>
      </div>
      {isOpen && <>{children}</>}
    </div>
  );
};

ToggleWrapper.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  children: PropTypes.node
};

ToggleWrapper.defaultProps = {
  className: null,
  style: {},
  title: "",
  children: null
};

export default ToggleWrapper;
