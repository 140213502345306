import React, { useState } from "react";
import classes from "./LanguageDropdown.module.scss";
import PropTypes from "prop-types";
import OutsideClickHandler from "react-outside-click-handler";
import all from "../../../helpers/all";
import Icon from "../../icons/Icon.jsx";

const LanguageDropdown = ({ value, changeHandler }) => {
  const languages = [
    {
      value: "ua",
      label: "Українська"
    },
    {
      value: "en",
      label: "English"
    }
  ];

  const [isOpen, setIsOpen] = useState(false);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsOpen(false);
      }}
    >
      <div className={classes.wrapper}>
        <div className={classes.value} onClick={() => setIsOpen(!isOpen)}>
          <div>{languages.find(language => language.value === value).label}</div>
          <Icon style={{ ...(isOpen ? { transform: "rotate(180deg)" } : {}) }} name="arrow" />
        </div>
        {isOpen && (
          <div className={classes.options}>
            {languages
              .filter(language => language.value !== value)
              .map((language, i) => (
                <div
                  className={classes.option}
                  onClick={() =>
                    all(
                      () => changeHandler(language.value),
                      () => setIsOpen(false)
                    )
                  }
                  key={i}
                >
                  {language.label}
                </div>
              ))}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

LanguageDropdown.propTypes = {
  value: PropTypes.string,
  changeHandler: PropTypes.func
};

LanguageDropdown.defaultProps = {
  value: "ua",
  changeHandler: () => {}
};

export default LanguageDropdown;
