import React from "react";
import classes from "./Triangle.module.scss";
import PropTypes from "prop-types";

const Triangle = ({ style }) => <div className={classes.wrapper} style={style} />;

Triangle.propTypes = {
  style: PropTypes.object
};

Triangle.defaultProps = {
  style: {}
};

export default Triangle;