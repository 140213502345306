import React, { useContext } from "react";
import * as API from "../api";
import { ConfirmationCode, OperationResult } from "../components";
import serverErrorMessages from "../helpers/serverErrorMessages";
import { findCard } from "../store/getters/contracts";
import { languageContext, modalContext } from "../context";

export const useUpdateCardStatus = (cards, setContracts) => {
  const { setModal } = useContext(modalContext);
  const { language, text } = useContext(languageContext);
  
  return React.useCallback( async cardId => {
    const payload = {
      otpCredentials: {
        otp: undefined,
        challenge: undefined
      }
    };
    
    try {
      const { data } = await API.getAuthExtended();
      payload.otpCredentials.challenge = data;
    } catch (err) {
      setModal(
        <OperationResult
          result="failure"
          text={serverErrorMessages(err, language)}
          buttonText={text("buttons.close")}
          clickCompleteHandler={() => setModal(null)}
        />
      );
      return;
    }
    
    await new Promise(resolve => {
      setModal(
        <ConfirmationCode
          clickContinueHandler={code => {
            payload.otpCredentials.otp = code;
            setModal(null);
            resolve();
          }}
        />
      );
    });
    
    const copy = JSON.parse(JSON.stringify(cards));
    const {account, card} = findCard(copy, cardId);
    const cardIsActive = card.status === "ACTIVE";
    try {
      const response = await (cardIsActive
        ? API.postLockCard(account.id, card.cardNumberMask, payload)
        : API.postUnlockCard(account.id, card.cardNumberMask, payload));
      if (response.data.status === "SUCCESS") {
        card.status = cardIsActive ? "INACTIVE" : "ACTIVE";
        setContracts("card", copy);
        setModal(
          <OperationResult
            titleText={`${text(cardIsActive ? "cards.modal.titleText.blocked" : "cards.modal.titleText.unblocked")}!`}
            buttonText={text("buttons.complete")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      } else {
        setModal(
          <OperationResult
            result="failure"
            text={`${serverErrorMessages({response}, language) || text("cards.modal.titleText.unSuccessOperationTitle")}`}
            buttonText={text("buttons.close")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      }
    } catch (err) {
      setModal(
        <OperationResult
          result="failure"
          text={serverErrorMessages(err, language)}
          buttonText={text("buttons.close")}
          clickCompleteHandler={() => setModal(null)}
        />
      );
    }
  }, [cards, language, setContracts, setModal, text]);
};
