import React, { useContext, useState } from "react";
import classes from "./CardTile.module.scss";
import PropTypes from "prop-types";
import { languageContext } from "../../../context/index";
import cn from "../../../helpers/cn";
import cardNumber from "../../../helpers/cardNumber";
import localeAmount from "../../../helpers/localeAmount";
import all from "../../../helpers/all";
import { Icon } from "../../index";
import { notify } from "react-notify-toast";
import {getAdditionalInfo} from "../../../api/contracts";

const defaultCvv = '***';

const CardTile = ({
    cardId, contractId, providerId,
  className,
  style,
  name,
  amount,
  currency,
  number,
  fullPan,
  owner,
  month,
  year,
  paymentSystem,
  status,
  clickCopyHandler,
  clickHistoryHandler,
  clickFillHandler,
  clickInnerTransferHandler,
  clickOuterTransferHandler,
  clickSetLimitHandler,
  clickBlockHandler,
  clickCreditHandler = false,
    clickChangePinHandler,
  hasOpenButton,
  onlyOperations,
  ownFunds,
  creditLimit,
  isActive,
  showCreditInfo
}) => {
  const { text } = useContext(languageContext);
  const [show, setShow] = React.useState(false);
  const [cvv, setCvv] = React.useState(defaultCvv);

  React.useEffect(() => {
    setShow(false);
    setCvv(defaultCvv);
  }, [isActive]);

  const options = [
    ...(onlyOperations
      ? []
      : [
          {
            iconName: "historyOption",
            label: text("accountTile.options.history"),
            clickHandler: clickHistoryHandler
          },
          {
            iconName: "fillAccount",
            label: text("card.rechargeButton"),
            clickHandler: clickFillHandler
          },
          {
            iconName: "transferOption",
            label: text("card.transferButton"),
            clickHandler: clickInnerTransferHandler
          },
          {
            iconName: "withdrawAccount",
            label: text("card.transactionButton"),
            clickHandler: clickOuterTransferHandler
          }
        ]),
    {
      iconName: "changeLimitOption",
      label: creditLimit ? text("card.limitsButton") : text("card.fuckingButton"),
      clickHandler: clickSetLimitHandler
    },
    ...(showCreditInfo && clickCreditHandler
      ? [
          {
            iconName: "cardCredit",
            label: text("card.creditButton"),
            clickHandler: clickCreditHandler
          }
        ]
      : []),
    {
      iconName: status === "ACTIVE" ? "padlock" : "padlockUnlocked",
      label: text(status === "ACTIVE" ? "card.blockButton" : "card.unblockButton"),
      clickHandler: clickBlockHandler
    },
      ...(clickChangePinHandler ? [
          {
              iconName: "changePin",
              label: text("card.changePinButton"),
              clickHandler: clickChangePinHandler,
          }
      ]: [])
  ];

  const paymentSystemIconNames = {
    Visa: "visa",
    MasterCard: "masterCard"
  };

  const [isOpen, setIsOpen] = useState(false);

  return !isOpen ? (
    <div className={cn(classes.cardTile, className)} style={style}>
      <div style={{ height: "32px" }}>
        <div className={cn(classes.row, classes.rowTop)}>
          <div className={classes.amount}>
            {localeAmount(amount)}
            <span className={classes.currency}>{currency}</span>
          </div>
          &nbsp;
          <div className={classes.settingsIconWrapper}>
            <Icon
                className={classes.copyIcon}
                name="copy"
                clickHandler={clickCopyHandler}
                title={text("card.details.iban.copy")}
            />
            {hasOpenButton && (
              <div onClick={() => setIsOpen(true)}>
                <Icon name="selectArrow" />
              </div>
            )}
          </div>
        </div>
        {showCreditInfo && (
          <React.Fragment>
            <div style={{ fontSize: "13px" }}>
              {text("card.details.limit.ownFunds")}: {ownFunds}
            </div>
            <div style={{ fontSize: "13px" }}>
              {text("card.details.limit.creditLimit")}: {creditLimit}
            </div>
          </React.Fragment>
        )}
      </div>

      <div className={cn(classes.row, classes.rowMiddle)}>
        <div
            className={classes.number}
            title={text('card.details.pan.copy')}
            onClick={() => {
              setShow(true);
              navigator.clipboard.writeText(fullPan || number);
              notify.show(text("card.details.pan.success"), "custom", 1000, {
                background: "#7ABD43",
                text: "#FFFFFF"
              });
            }}
        >
          {cardNumber(show ? fullPan || number : number)}
        </div>
        <div
            className={classes.cvv}
            title={text('card.details.cvv.copy')}
            onClick={async () => {
                let secureCode = cvv || defaultCvv;
                try {
                  new window.ClipboardItem([{}]); // firefox exception
                  await window.navigator.clipboard.write([
                      new window.ClipboardItem({
                          'text/plain': new Promise(async (resolve) => {
                              if (secureCode === defaultCvv) {
                                  const response = await getAdditionalInfo({providerId, contractId, cardId, parameter: 'cvv'});
                                  secureCode = response?.data?.secureCode || defaultCvv;
                              }
                              setCvv(secureCode);
                              resolve(secureCode);
                            })
                      })
                  ]);
                } catch (e) {
                    console.log(e);
                    if (secureCode === defaultCvv) {
                        const response = await getAdditionalInfo({providerId, contractId, cardId, parameter: 'cvv'});
                        secureCode = response?.data?.secureCode || defaultCvv;
                    }
                    setCvv(secureCode);
                    await window.navigator.clipboard.writeText(secureCode);
                }
              notify.show(text("card.details.cvv.success"), "custom", 1000, {
                background: "#7ABD43",
                text: "#FFFFFF"
              });
            }}
        >
          CVV: {cvv}
        </div>
      </div>
      <div className={cn(classes.row, classes.rowBottom)}>
        <div className={classes.owner} title={owner}>
          {owner}
        </div>
        <div>
          <div className={classes.date}>{`${month}/${year.slice(2)}`}</div>
          {paymentSystem && <Icon name={paymentSystemIconNames[paymentSystem]} />}
        </div>
      </div>
    </div>
  ) : (
    <div className={cn(classes.accountTile, className)} style={style}>
      <div className={classes.top}>
        <div className={classes.description}>
          <div className={classes.name}>{name}</div>
          <div className={classes.text}>{cardNumber(number)}</div>
        </div>
        <div className={classes.openIconWrapper} onClick={() => setIsOpen(false)}>
          <Icon style={{ transform: "rotate(180deg)" }} name="selectArrow" />
        </div>
      </div>
      <div className={classes.options}>
        {options.map((option, i) => (
          <div id="option" className={classes.option} onClick={() => all(option.clickHandler, () => setIsOpen(false))} key={i}>
            {option.iconName && (
              <div className={classes.optionIconWrapper}>
                <Icon className={classes.optionIcon} name={option.iconName} />{" "}
              </div>
            )}
            {option.label && <div className={classes.label}>{option.label}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

CardTile.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  name: PropTypes.string,
  amount: PropTypes.number,
  currency: PropTypes.string,
  number: PropTypes.string,
  fullPan: PropTypes.string,
  owner: PropTypes.string,
  month: PropTypes.string,
  year: PropTypes.string,
  paymentSystem: PropTypes.oneOf(["Visa", "MasterCard"]),
  status: PropTypes.string,
  clickCopyHandler: PropTypes.func,
  clickHistoryHandler: PropTypes.func,
  clickFillHandler: PropTypes.func,
  clickInnerTransferHandler: PropTypes.func,
  clickOuterTransferHandler: PropTypes.func,
  clickSetLimitHandler: PropTypes.func,
  clickBlockHandler: PropTypes.func,
  hasOpenButton: PropTypes.bool,
  isActive: PropTypes.bool,
  showCreditInfo: PropTypes.bool,
};

CardTile.defaultProps = {
  className: "",
  style: {},
  name: "",
  amount: 0,
  currency: "UAH",
  owner: "",
  number: "0000 0000 0000 0000",
  fullPan: null,
  month: "MM",
  year: "YY",
  paymentSystem: null,
  status: "",
  clickCopyHandler: () => {},
  clickHistoryHandler: () => {},
  clickFillHandler: () => {},
  clickInnerTransferHandler: () => {},
  clickOuterTransferHandler: () => {},
  clickSetLimitHandler: () => {},
  clickBlockHandler: () => {},
  hasOpenButton: false,
  isActive: false,
  showCreditInfo: false,
};

export default CardTile;
