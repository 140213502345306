import React, { useContext, useState, useEffect } from "react";
import classes from "./CardLimit.module.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cn from "../../../../helpers/cn";
import { Title, Input, Button, Error, OperationResult } from "../../../../components/index";
import { languageContext, modalContext } from "../../../../context/index";
import isCurrency from "validator/lib/isCurrency";
import isInt from "validator/lib/isInt";
import * as API from "../../../../api/index";
import { useError } from "../../../../hooks";
import serverErrorMessages from "../../../../helpers/serverErrorMessages";
import * as actions from "../../../../store/actions/index";
import { getAttribute } from "../../../../store/getters/contracts";

const CardLimit = ({ account, card, readContract }) => {

  const { language, text } = useContext(languageContext);
  const { setModal } = useContext(modalContext);

  const [confirmationIsShown, setConfirmationIsShown] = useState(false);

  const [dailyWithdrawalAmount, setDailyWithdrawalAmount] = useState("");
  const [dailyWithdrawalQuantity, setDailyWithdrawalQuantity] = useState("");
  const [dailyPurchaseAmount, setDailyPurchaseAmount] = useState("");
  const [dailyPurchaseQuantity, setDailyPurchaseQuantity] = useState("");

  const [authExtended, setAuthExtended] = useState({});
  const [code, setCode] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    (async () => {
      const res = await API.getContractDetailed("card", account.id, card.id);
      const temp = res.data.cardsList.find(item => item.id === card.id);
      setDailyWithdrawalAmount(((temp.limits[0]?.amount || 0)/100).toString());
      setDailyWithdrawalQuantity((temp.limits[0]?.quantity || 0).toString());
      setDailyPurchaseAmount(((temp.limits[1]?.amount || 0)/100).toString());
      setDailyPurchaseQuantity((temp.limits[1]?.quantity || 0).toString());
    })();
  }, [account.id, card.id]);

  const [error, setError] = useError(null);

  const generalLimitAmount = `${(Math.round(+dailyWithdrawalAmount * 100) + Math.round(+dailyPurchaseAmount * 100)) / 100}`;
  const generalLimitQuantity = `${+dailyWithdrawalQuantity + +dailyPurchaseQuantity}`;

  const handleClickSaveStep1 = async () => {
    setIsFetching(true);
    try {
      const { data } = await API.getAuthExtended();
      setAuthExtended(data);
      setConfirmationIsShown(true);
    } catch (err) {
      setError(serverErrorMessages(err, language));
    }
    setIsFetching(false);
  };

  const handleClickSaveStep2 = async () => {
    setError(null);
    if (!code) {
      setError(text("errors.required"));
      return;
    }
    setIsFetching(true);
    try {
      const { headers } = await API.postAuthLoginOtp({
        otp: code,
        challenge: authExtended
      });
      const res = await API.postCardLimit(headers.authorization, account.id, card.cardNumberMask, {
        subject: {
          limits: [
            {
              type: "ATM",
              amount: dailyWithdrawalAmount ? `${Math.round(dailyWithdrawalAmount * 100)}` : "",
              quantity: dailyWithdrawalQuantity
            },
            {
              type: "POS",
              amount: dailyPurchaseAmount ? `${Math.round(dailyPurchaseAmount * 100)}` : "",
              quantity: dailyPurchaseQuantity
            }
          ]
        }
      });
      if (res.data.status === "FAIL") {
        let errorKey = res.data.errorMessageKey;
        if (errorKey !== undefined && errorKey === "unsupported_limit_value"){
          setModal(
            <OperationResult
              result="failure"
              text={text("errors.server.unsupported_limit_value").split ('\n').map ((item, i) => <p key={i} className={classes.messageWrapper}>{item}</p>)}
              buttonText={text("buttons.close")}
              clickCompleteHandler={() => setModal(null)}
            />
          );
        } else {
          setModal(
            <OperationResult
              result="failure"
              text={serverErrorMessages(
                {
                  response: {
                    data: res.data
                  }
                },
                language
              )}
              buttonText={text("buttons.close")}
              clickCompleteHandler={() => setModal(null)}
            />
          );
        }
        return;
      }
      const notification = getAttribute(res.data.attributes, 'notification');
      try {
        await readContract("card", account.id);
      } catch (error) {
        console.log("Unable to update the contract", error);
      }
      setModal(
        <OperationResult
          titleText={notification !== undefined && notification.startsWith("Specified") ? text("cards.modal.titleText.changeLimitsWithNotification") : `${text("cards.modal.titleText.changeLimits")}!`}
          buttonText={text("buttons.complete")}
          clickCompleteHandler={() => setModal(null)}
        />
      );
    } catch (err) {
      setIsFetching(false);
      setError(serverErrorMessages(err, language));
    }
  };

  return (
    <div className={classes.wrapper}>
      {!confirmationIsShown ? (
        <>
          <Title text={text("cardLimit.titles.changeLimits")} />
          <div className={classes.subtitle}>{text("cardLimit.subtitles.dailyWithdrawal")}</div>
          <div className={classes.row}>
            <div className={classes.column}>
              <Input
                labelText={text("cardLimit.labels.amount")}
                value={dailyWithdrawalAmount}
                changeHandler={value => setDailyWithdrawalAmount(value)}
                units="USD"
                validator={value =>
                  isCurrency(value, {
                    digits_after_decimal: [0, 1, 2]
                  })
                }
              />
            </div>
            <div className={classes.column}>
              <Input
                labelText={text("cardLimit.labels.quantity")}
                value={dailyWithdrawalQuantity}
                changeHandler={value => setDailyWithdrawalQuantity(value)}
                validator={value => isInt(value, { min: 0, max: 999 })}
              />
            </div>
          </div>
          <div className={classes.subtitle}>{text("cardLimit.subtitles.dailyPurchase")}</div>
          <div className={classes.row}>
            <div className={classes.column}>
              <Input
                labelText={text("cardLimit.labels.amount")}
                value={dailyPurchaseAmount}
                changeHandler={value => setDailyPurchaseAmount(value)}
                units="USD"
                validator={value =>
                  isCurrency(value, {
                    digits_after_decimal: [0, 1, 2]
                  })
                }
              />
            </div>
            <div className={classes.column}>
              <Input
                labelText={text("cardLimit.labels.quantity")}
                value={dailyPurchaseQuantity}
                changeHandler={value => setDailyPurchaseQuantity(value)}
                validator={value => isInt(value, { min: 0, max: 999 })}
              />
            </div>
          </div>
          <div className={classes.subtitle}>{text("cardLimit.subtitles.generalLimit")}</div>
          <div className={classes.row}>
            <div className={classes.column}>
              <Input labelText={text("cardLimit.labels.amount")} value={generalLimitAmount} units="USD" isDisabled />
            </div>
            <div className={classes.column}>
              <Input labelText={text("cardLimit.labels.quantity")} value={generalLimitQuantity} isDisabled />
            </div>
          </div>
          {error && <Error className={classes.error} message={error} />}
          <div className={cn(classes.row, classes.buttonsWrapper)}>
            <Button className={cn(classes.button, classes.buttonCancel)} clickHandler={() => setModal(null)}>
              {text("buttons.cancel")}
            </Button>
            <Button className={cn(classes.button, classes.buttonSave)} clickHandler={handleClickSaveStep1} isDisabled={isFetching}>
              {text("buttons.saveChanges")}
            </Button>
          </div>
        </>
      ) : (
          <>
            <Title text={text("cardLimit.titles.confirmation")} />
            <div className={classes.text}> {text("cardLimit.confirmation.text")}</div>
            <div className={cn(classes.text, classes.textBold)}>{text("cardLimit.confirmation.warningText")}</div>
            <Input
              labelClassName={classes.codeLabel}
              errorClassName={classes.codeError}
              labelText={text("cardLimit.labels.code")}
              value={code}
              changeHandler={value => setCode(value)}
              errorMessage={error}
            />
            <div className={classes.buttonWrapper}>
              <Button className={cn(classes.button, classes.buttonSave)} clickHandler={handleClickSaveStep2} isDisabled={isFetching}>
                {text("buttons.saveChanges")}
              </Button>
            </div>
          </>
        )}
    </div>
  );
};

CardLimit.propTypes = {
  data: PropTypes.shape({
    cardId: PropTypes.string.isRequired,
    cardNumber: PropTypes.string.isRequired,
    dailyWithdrawalAmount: PropTypes.string,
    dailyWithdrawalQuantity: PropTypes.string,
    dailyPurchaseAmount: PropTypes.string,
    dailyPurchaseQuantity: PropTypes.string
  }),
  clickCancelHandler: PropTypes.func,
  // checkExtendedAuthHandler: PropTypes.func,
  saveCallback: PropTypes.func,

  card: PropTypes.any.isRequired,
  account: PropTypes.any.isRequired,
};

CardLimit.defaultProps = {
  // data: {
    // cardId: "",
    // cardNumber: "",
  //   dailyWithdrawalAmount: "3",
  //   dailyWithdrawalQuantity: "3",
  //   dailyPurchaseAmount: "3",
  //   dailyPurchaseQuantity: "3"
  // },
  clickCancelHandler: () => { },
  // checkExtendedAuthHandler: () => {},
  saveCallback: () => { }
};

const mapDispatchToProps = dispatch => ({
  readContract: (contractType, id) => dispatch(actions.readContract(contractType, id))
});

export default connect(
  null,
  mapDispatchToProps
)(CardLimit);
