import React, { useContext } from "react";
import classes from "./Footer.module.scss";
import { languageContext } from "../../context/index";
import { Logo, Icon } from "../index";

const Footer = () => {
  const { text } = useContext(languageContext);

  return (
    <div className={classes.footer}>
      <div className={classes.leftBlock}>
        <div className={classes.flex}>
          <Logo isGreyscale={true} />
          <div>
            build: {process.env.REACT_APP_COMMIT_COUNT}
            <br />
            {process.env.REACT_APP_COMMIT_HASH ? `[${process.env.REACT_APP_COMMIT_HASH}]` : ""}
          </div>
        </div>
        <div className={classes.bottomTextWrap}>
          <div>{text("footer.licence")}</div>
          <div>
            {text("footer.depositSecurity")}{" "}
            <a href="https://www.fg.gov.ua/" target="_blank" rel="noopener noreferrer">
              {text("footer.fond")}
            </a>
          </div>
        </div>
      </div>
      <div className={classes.rightBlock}>
        <div className={classes.bottomRow}>
          <div className={classes.socialIconsWrap}>
            <div>
              <Icon className={classes.icon} name="facebook" clickHandler={() => window.open("https://www.facebook.com/agroprosperis.bank", "_blank")} />
            </div>
            <div>
              <Icon
                className={classes.icon}
                name="youtube"
                clickHandler={() => window.open("https://www.youtube.com/channel/UCWH3W7r8DDF93cGBzG3lt0w", "_blank")}
              />
            </div>
            <div>
              <Icon className={classes.icon} name="linkedin" clickHandler={() => window.open("https://www.linkedin.com/company/ap-bank", "_blank")} />
            </div>
          </div>
          <div>
            © {new Date().getFullYear()} {text("footer.bankName")}. {text("footer.securityText")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
