import React, { useState, useContext, useEffect, useRef } from "react";
import classes from "./Settings.module.scss";
import { Title, Input, Button, Error, OperationResult } from "../../../components/index";
import { languageContext, authContext, modalContext } from "../../../context/index";
import { passwordChangeSchema, validationError, fieldErrorMessage } from "../../../validation/index";
import * as API from "../../../api/index";
import { usePrevious, useError, useTitle } from "../../../hooks";
import serverErrorMessages from "../../../helpers/serverErrorMessages";
import * as icons from "../../../components/icons/icons";
import { Icon } from "../../../components/index";

const Settings = () => {
  const { language, text } = useContext(languageContext);
  const { user, setIsAuthenticated } = useContext(authContext);
  const { modal, setModal } = useContext(modalContext);

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newConfirmedPassword, setConfirmedPassword] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [isDropDown, setIsDropDown] = useState(false);
  const [isTooltip, setIsTooltip] = useState(false);
  const [isTooltipAdd, setIsTooltipAdd] = useState(false);
  const refInputFile = useRef(null);

  const [error, setError] = useError(null);

  useEffect(() => {
    API.getProfileResourceValue()
      .then(result => {
        const { data } = result;
        const { profileLargeResource } = data;
        const { resourceValue } = profileLargeResource;
        setAvatar(resourceValue);
      })
      .catch(error => {
        if (error) {
          setAvatar(null);
        }
        console.log(error, "error");
      });
  }, []);

  const globalErrorMessage = fieldErrorMessage("global", error);

  const submit = async () => {
    setError(null);
    const error = validationError(passwordChangeSchema(language, newPassword), {
      password,
      newPassword,
      newConfirmedPassword
    });
    if (error) {
      setError(error);
      return;
    }
    try {
      await API.postPasswordChange({
        oldPassword: password,
        newPassword,
        confirmPassword: newConfirmedPassword
      });
      setModal(
        <OperationResult
          titleText={`${text("settings.modal.titleText")}!`}
          text={text("settings.modal.text")}
          buttonText={text("buttons.continue")}
          clickCompleteHandler={() => setModal(null)}
        />
      );
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
    }
  };

  const previousModal = usePrevious(modal);

  useEffect(() => {
    if (previousModal && !modal) {
      setIsAuthenticated(false);
      sessionStorage.removeItem("token");
    }
  }, [previousModal, modal, setIsAuthenticated]);

  useTitle(text("settings.title"));

  const toBase64 = input =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(input);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = error => reject(error);
    });

  const inputFileHandler = async e => {
    e.persist();
    const resourceBase64 = await toBase64(e.target.files[0]);
    setAvatar(resourceBase64);
    let payload = {
      profileLargeResource: {
        resourceName: "avatar",
        resourceValue: resourceBase64
      }
    };
    API.postProfileResourceUpload(payload);
  };
  const openLocalRepositoryHandler = () => {
    refInputFile.current.click();
  };

  const deleteProfileResource = () => {
    API.deleteProfileResourceValue();
    setIsDropDown(false);
    setAvatar(null);
  };

  return (
    <div className={classes.wrapper}>
      <Title text={text("settings.title")} />

      <div className={classes.wrapperAvatarIcons}>
        <input type="file" ref={refInputFile} onChange={inputFileHandler} className={classes.inputFile} />
        <div className={classes.tooltipAndAvatarBlock}>
          {isTooltip && (
            <>
              <span className={classes.tooltip}>{text("settings.avatar.tooltip_change")}</span>
              <span className={classes.triangle}></span>
            </>
          )}
          {!isTooltip && <span style={{ height: "47.2px" }}></span>}
          {avatar && (
            <img
              className={classes.avatarImage}
              onMouseOver={() => setIsTooltip(true)}
              onMouseOut={() => setIsTooltip(false)}
              onClick={openLocalRepositoryHandler}
              src={`data:image/png;base64,${avatar}`}
              alt="avatar"
            />
          )}
        </div>

        {/* default avatar part */}
        <div className={classes.tooltipAndAvatarBlock}>
          {isTooltipAdd && !avatar && (
            <>
              {" "}
              <span className={classes.tooltip}>{text("settings.avatar.tooltip_add")}</span>
              <span className={classes.triangle}></span>
            </>
          )}
          {!isTooltipAdd && !avatar && <span style={{ height: "47.2px" }}></span>}

          {!avatar && (
            <img
              className={classes.avatarImage}
              src={icons.avatarka}
              alt=""
              onMouseOver={() => setIsTooltipAdd(true)}
              onMouseOut={() => setIsTooltipAdd(false)}
              onClick={openLocalRepositoryHandler}
            />
          )}
        </div>
        <div className={classes.pencel}>
          <img src={icons.pencel} alt="Pencel" onClick={() => setIsDropDown(!isDropDown)} />
        </div>

        {isDropDown && (
          <div className={classes.dropDownWrapper}>
            <div className={classes.dropdownOption} onClick={deleteProfileResource}>
              <Icon name="deleteNew" style={{ width: "25px", height: "25px", marginRight: "10px" }} />
              <span>{text("settings.avatar.delete")}</span>
            </div>
          </div>
        )}
        {!isDropDown && <div style={{ width: "150px" }}></div>}
      </div>

      <div className={classes.content}>
        <div className={classes.subtitle}>{text("settings.personalData.dataTitle")}</div>
        <Input className={classes.input} labelText={text("settings.personalData.labelText")} value={user.fullName} isDisabled />
        <div className={classes.subtitle}>{text("settings.contacts.dataTitle")}</div>
        <Input className={classes.input} labelText={text("settings.contacts.phoneNumber")} value={user.phone} isDisabled />
        {/*   <Input className={classes.input} labelText={text("settings.contacts.emailAddress")} value={user.email} isDisabled />*/}
        <Input className={classes.input} labelText={text("settings.contacts.loginForEntry")} value={user.name} isDisabled />
        <div className={classes.subtitle}>{text("settings.password.dataTitle")}</div>
        {globalErrorMessage && <Error className={classes.error} message={globalErrorMessage} />}
        <Input
          className={classes.input}
          errorClassName={classes.error}
          labelText={text("settings.password.currentPassword")}
          type="password"
          maxLength={21}
          value={password}
          changeHandler={value => setPassword(value)}
          errorMessage={fieldErrorMessage("password", error)}
        />
        <Input
          className={classes.input}
          errorClassName={classes.error}
          labelText={text("settings.password.newPassword")}
          type="password"
          maxLength={21}
          value={newPassword}
          changeHandler={value => setNewPassword(value)}
          errorMessage={fieldErrorMessage("newPassword", error)}
        />
        <Input
          className={classes.input}
          errorClassName={classes.error}
          labelText={text("settings.password.confirmationPassword")}
          type="password"
          maxLength={21}
          value={newConfirmedPassword}
          changeHandler={value => setConfirmedPassword(value)}
          errorMessage={fieldErrorMessage("newConfirmedPassword", error)}
        />
        <div className={classes.text}>{text("settings.password.passwordInformation")}</div>
        <div className={classes.buttonRow}>
          <Button className={classes.button} clickHandler={submit}>
            {text("buttons.changePassword")} &rarr;
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Settings;
