import localization from "../localization/index";

export const innerToInnerTemplateSchema = language => {
  const { required } = localization[language].errors;

  return {
    name: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    givingContract: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    gettingContract: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    amount: [
      {
        validator: "required",
        errorMessage: required
      }
    ]
  };
};

export const bankInnerTemplateSchema = language => {
  const { required } = localization[language].errors;

  return {
    name: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    givingContract: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    gettingAccount: [
      {
        validator: "length",
        args: {
          min: 1,
          max: 29
        },
        errorMessage: required
      }
    ],
    amount: [
      {
        validator: "required",
        errorMessage: required
      }
    ]
  };
};

export const innerToCustomTemplateSchema = language => {
  const { required, taxNumEmpty, taxNumMin, taxNumRules, purposeNeedDetails } = localization[language].errors;

  return {
    name: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    givingContract: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    gettingAccount: [
      {
        validator: "ibanSep",
        errorMessage: required
      }
    ],
    receiverName: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    receiverCode: [
      {
        validator: "length",
        args: {
          min: 6,
          max: 6
        },
        errorMessage: required
      }
    ],
    taxNumber: [
      {
        validator: "taxNumEmpty",
        errorMessage: taxNumEmpty
      },
      {
        validator: "taxNumMin",
        errorMessage: taxNumMin
      },
      {
        validator: "taxNumRules",
        errorMessage: taxNumRules
      }
    ],
    purpose: [
      {
        validator: "required",
        errorMessage: required
      },
      // {
      //   validator: "purposeEmpty",
      //   errorMessage: required
      // },
      {
        validator: "purposeNeedDetails",
        errorMessage: purposeNeedDetails
      }
    ],
    amount: [
      {
        validator: "required",
        errorMessage: required
      }
    ]
  };
};

export const updateTemplateSchema = language => {
  const { required } = localization[language].errors;

  return {
    name: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    givingContract: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    amount: [
      {
        validator: "required",
        errorMessage: required
      }
    ]
  };
};
