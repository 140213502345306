// import isEmpty from "validator/lib/isEmpty";
import isLength from "validator/lib/isLength";
// import equals from "validator/lib/equals";

// import isAlpha from "validator/lib/isAlpha";
import isEmail from "validator/lib/isEmail";
import matches from "validator/lib/matches";

export {
  signupCardSchema,
  signupIbanSchema,
  loginSchema,
  passwordRecoveryCardSchema,
  passwordRecoveryIbanSchema,
  passwordSavingSchema,
  passwordChangeSchema
} from "./auth";
export { feedbackSchema } from "./feedback";
export { regularPaymentContractToContractSchema, regularPaymentIntrabankTransferSchema, regularPaymentSepTransferSchema } from "./regular-payment";
export { cardOrderSchema } from "./contracts";
export {
  innerBankTransactionSchema,
  innerToOuterTransactionSchema,
  innerToInnerTransactionSchema,
  outerToInnerTransactionSchema,
  outerToOuterTransactionSchema,
  innerToCustomTransactionSchema,
  innerToBudgetTransactionSchema,
} from "./transactions";
export { innerToInnerTemplateSchema, bankInnerTemplateSchema, innerToCustomTemplateSchema, updateTemplateSchema } from "./templates";
export { billersFormSchema } from "./billers";

export const validationError = (schema = {}, fieldsObject = {}) => {
  try {
    for (const fieldName in fieldsObject) {
      const fieldValue = fieldsObject[fieldName];
      if (!schema[fieldName]) {
        console.warn(fieldName, schema[fieldName]);
        continue;
      }
      for (const config of schema[fieldName]) {
        let result = true;
        switch (config.validator) {
          case "required":
            result = !!fieldValue;
            break;
          case "length":
            result = isLength(fieldValue, config.args);
            break;
          case "email":
            result = isEmail(fieldValue);
            break;
          case "iban":
            result = matches(fieldValue, /^UA\d{27}$/);
            break;
          case "card":
            result = matches(fieldValue, /^[45](\d{15})$/);
            break;
          case "cardOrIban":
            result = matches(fieldValue, /^UA\d{27}$/) || matches(fieldValue, /^([45])(\d{15})$/);
            break;
          case "ibanSep":
            result = matches(fieldValue.toUpperCase(), /^UA\d{27}$/);
            break;
          case "taxNumEmpty":
            result = !!fieldValue;
            break;
          case "taxNumMin":
            result = !fieldValue || isLength(fieldValue, { min: 8 });
            break;
          case "taxNumRules":
            result = !fieldValue || (matches(fieldValue, /^([А-ЯҐЄІЇ]{2}\d{6}|\d{8,10})$/) && !matches(fieldValue, /^0{9,10}$/));
            break;
          case "innRules":
            result = !fieldValue || (matches(fieldValue, /^([А-ЯҐЄІЇ]{2}\d{6}|\d{9,10})$/) && !matches(fieldValue, /^0{10}$/));
            break;
          case "equals":
            result = Array.isArray(config.args) ? config.args.find(value => value === fieldValue) : config.args === fieldValue;
            break;
          case "password":
            result = matches(fieldValue, /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/);
            break;
          case "purposeEmpty":
            result = isLength(fieldValue, { min: 16 });
            break;
          case "purposeNeedDetails":
            result = isLength(fieldValue, { min: 5 });
            break;
          case "actualPayerName":
            result = !!fieldValue || !fieldsObject.actualPayerTaxIdOrPassport;
            if (!!fieldValue && result) {
              result =  fieldValue?.replaceAll(/[^A-ZА-ЯҐЄІЇ]/gi, '').length >= 5;
            }
            break;
          case "actualPayerTaxIdOrPassport":
            result = !!fieldValue || !fieldsObject.actualPayerName;
            break;
          default:
            break;
        }
        if (!result) {
          return {
            field: fieldName,
            message: config.errorMessage
          };
        }
      }
    }
    return;
  } catch (err) {
    console.log("Validation failed!", err);
  }
};

export const fieldErrorMessage = (field, error) => (error && error.field === field ? error.message : "");
