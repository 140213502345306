import React, { useCallback, useContext, useState } from "react";
import PropTypes from "prop-types";
import classes from "./Account.module.scss";
import {languageContext, modalContext} from "../../../../context/index";
import {Button, ContractHistory, NavigationTabs, ProductDetails, Title} from "../../../../components";
import * as API from "../../../../api/index";
import localeAmount from "../../../../helpers/localeAmount";
import { downloadPDFFile } from "../../../../helpers/downloadPDFFile";
import Requisites from "../../../modals/Requisites/Requisites";

const Account = ({ account, clickTitleHandler }) => {
  const { text } = useContext(languageContext);
    const { setModal } = useContext(modalContext);

  const [activeTab, setActiveTab] = useState("history");

  const tabs = [
    {
      value: "history",
      label: text("card.tabs.history")
    },
    {
      value: "details",
      label: text("card.tabs.details")
    }
  ];

  const fetchAccountHistoryHandler = useCallback(params => API.getAccountHistory(account.id, params), [account]);
  const fetchAccountPDFHandler = useCallback(
    params =>
      API.getFormatPDFByProvider(account.providerId, account.id, params).then(result => {
        const { data } = result;
        downloadPDFFile(data);
      }),
    [account]
  );

  return (
    <>
      <div className={classes.top}>
        <Title
          text={account.productTitle === "Поточний рахунок" ? text("account.title") : account.productTitle}
          onClick={clickTitleHandler && clickTitleHandler(account.productTitle || text("account.title"))}
        />
      </div>
      <div className={classes.bottom}>
        <NavigationTabs className={classes.navigationTabs} tabs={tabs} activeTab={activeTab} changeTabHandler={value => setActiveTab(value)} />
        {activeTab === "history" && <ContractHistory fetchHandler={fetchAccountHistoryHandler} fetchPDFHandler={fetchAccountPDFHandler} />}
        {activeTab === "details" && (
          <ProductDetails
            classname={classes.productDetails}
            rows={[
              { label: `${text("account.details.name")}:`, value: account.productTitle === "Поточний рахунок" ? text("account.title") : account.productTitle },
              { label: `${text("account.details.contractNumber")}:`, value: account.legalNumber },
              { label: `${text("account.details.accountNumber")}:`, value: account.mainAccountNumber },
              { label: `${text("account.details.interestRate")}, %:`, value: account.currentInterestRate },
              { label: `${text("account.details.openingDate")}:`, value: account.startDate },
              { label: `${text("account.details.balance")}:`, value: `${localeAmount(account.balance)} ${account.mainAccountCurrency}` },
              { label: `${text("requisites.details.params")}:`, value: (
                  <Button className={classes.details} clickHandler={() => setModal((<Requisites providerId="account" contractId={account.id} />))}>
                      {text("requisites.details.button")}
                  </Button>
              ) }
            ]}
          />
        )}
      </div>
    </>
  );
};

Account.propTypes = {
  clickTitleHandler: PropTypes.func
};

Account.defaultProps = {
  clickTitleHandler: null
};

export default React.memo(Account);
