import React, { useContext } from "react";
import classes from "./Exchange.module.scss";
import PropTypes from "prop-types";
import cn from "../../../helpers/cn";
import { languageContext, modalContext } from "../../../context/index";
import modifiedAmount from "../../../helpers/modifiedAmount";
import { Button } from "../../index";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { accountOptions, cardOptions } from "../../../store/getters";
import OperationResult from "../../modals/OperationResult/OperationResult";

const Exchange = ({ cards, accounts, className, style, currencies, type }) => {
  const history = useHistory();
  const { text } = useContext(languageContext);
  const { setModal } = React.useContext(modalContext);
  const contractAll = React.useMemo(() => [...cardOptions(cards), ...accountOptions(accounts, text)], [accounts, cards, text]);
  const curr = React.useMemo(() => [...new Set([...contractAll].map(item => item.currency))], [contractAll]);

  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.title}>{text("exchange." + type)}</div>
      <div className={cn(classes.exchange)} style={style}>
        <div>&nbsp;</div>
        <div>{text("exchange.buy")}</div>
        <div>{text("exchange.sell")}</div>
        {currencies.map((currency, i) => (
          <React.Fragment key={i}>
            <div>{currency.currency}</div>
            <div>{modifiedAmount(currency.buyRate)}</div>
            <div>{modifiedAmount(currency.sellRate)}</div>
          </React.Fragment>
        ))}
      </div>
      {type === "online" && (
        <div className={classes.footer}>
          <Button
            className={cn(classes.button, classes.buttonSubmit)}
            clickHandler={() => {
              curr.includes("UAH")
                ? history.push("/home/transactions/exchange")
                : setModal(
                    <OperationResult
                      result="failure"
                      titleText={text("exchangeTransaction.noUAH")}
                      buttonText={text("buttons.close")}
                      clickCompleteHandler={() => setModal(null)}
                    />
                  );
            }}
            // isDisabled={!(confirm && confirm2 && confirm3) || isFetching}
          >
            {text("exchange.button")}
          </Button>
        </div>
      )}
    </div>
  );
};

Exchange.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.string,
      buyRate: PropTypes.number,
      sellRate: PropTypes.number
    })
  )
};

Exchange.defaultProps = {
  type: "bank",
  className: "",
  style: {},
  currencies: []
};

export default connect(({ contracts }) => contracts, null)(Exchange);
