import React, { useCallback, useContext, useState } from "react";
import classes from "./Credit.module.scss";
import PropTypes from "prop-types";
import { languageContext } from "../../../../context/index";
import { Button, ContractHistory, Icon, NavigationTabs, ProductDetails, Title } from "../../../../components";
import * as API from "../../../../api/index";
import localeAmount from "../../../../helpers/localeAmount";
import moment from "moment";
import { downloadPDFFile } from "../../../../helpers/downloadPDFFile";

const Credit = ({ credit, clickOuterToInnerTransactionHandler, clickTitleHandler }) => {
  const { text } = useContext(languageContext);

  const [activeTab, setActiveTab] = useState("history");

  const tabs = [
    {
      value: "history",
      label: text("card.tabs.history")
    },
    {
      value: "details",
      label: text("card.tabs.details")
    }
  ];

  const fetchCreditHistoryHandler = useCallback(params => API.getCreditHistory(credit.id, params), [credit]);

  const fetchCreditPDFHandler = useCallback(
    params =>
      API.getFormatPDFByProvider(credit.providerId, credit.id, params).then(result => {
        const { data } = result;
        downloadPDFFile(data);
      }),
    [credit]
  );

  return (
    <>
      <div className={classes.top}>
        <Title text={credit.productTitle} onClick={clickTitleHandler && clickTitleHandler(credit.productTitle || text("deposit.title"))} />
        <div className={classes.buttons}>
          <Button className={classes.button} clickHandler={clickOuterToInnerTransactionHandler}>
            <Icon name="fillCard" />
            &nbsp;&nbsp;{text("credit.closeCreditButton")}
          </Button>
        </div>
      </div>
      <div className={classes.bottom}>
        <NavigationTabs className={classes.navigationTabs} tabs={tabs} activeTab={activeTab} changeTabHandler={value => setActiveTab(value)} />
        {activeTab === "history" && <ContractHistory fetchHandler={fetchCreditHistoryHandler} fetchPDFHandler={fetchCreditPDFHandler} />}
        {activeTab === "details" && (
          <ProductDetails
            classname={classes.productDetails}
            rows={[
              { label: `${text("credit.details.name")}:`, value: credit.productTitle },
              { label: `${text("credit.details.number")}:`, value: credit.legalNumber },
              { label: `${text("credit.details.rate")}, %:`, value: credit.currentInterestRate },
              {
                label: `${text("credit.details.monthlyPayment")}:`,
                value: `${localeAmount(credit.nextPaymentAmount.comissionAmount)} ${credit.mainAccountCurrency}`
              },
              { label: `${text("credit.details.debt")}:`, value: `${localeAmount(credit.totalDept)} ${credit.mainAccountCurrency}` },
              { label: `${text("credit.details.term")}:`, value: credit.endDate },
              {
                label: `${text("credit.details.minRepaymentAmount")}:`,
                value: `${localeAmount(credit.nextPaymentAmount.totalAmount)} ${credit.mainAccountCurrency}`
              },
              { label: `${text("credit.details.date")}:`, value: moment(credit.nextPaymentAmount.paymentDate).format("YYYY-MM-DD") }
            ]}
          />
        )}
      </div>
    </>
  );
};

Credit.propTypes = {
  credit: PropTypes.object,
  clickOuterToInnerTransactionHandler: PropTypes.func,
  clickTitleHandler: PropTypes.func
};

Credit.defaultProps = {
  credit: {},
  clickOuterToInnerTransactionHandler: () => {},
  clickTitleHandler: null
};

export default React.memo(Credit);
