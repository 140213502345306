import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import "./DateRange.scss";
import moment from "moment";
import Button from "../../Button/Button.jsx";
import OutsideClickHandler from "react-outside-click-handler";
import { Error, Label, Icon } from "../../index";
import cn from "../../../helpers/cn";
import DayPicker from "react-day-picker";
// import "../../inputs/DayPicker/node_modules/react-day-picker/lib/style.css";
import "react-day-picker/lib/style.css";

import MomentLocaleUtils from "react-day-picker/moment";
import localization from "../../../localization/index";
import { languageContext } from "../../../context/index";
// import {logicalExpression} from "@babel/types";

const DateRange = ({
  labelClassName,
  className,
  errorClassName,
  labelStyle,
  style,
  errorStyle,
  labelText,
  value,
  changeHandler,
  isDisabled,
  placeholder,
  errorMessage
}) => {
  const { language,text } = useContext(languageContext);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [resetStartButton, setResetStartButton] = useState([value[0]]);
  const [resetEndButton, setResetEndButton] = useState([value[1]]);
  const months = localization[language].dateRange.months;
  const currentYear = new Date().getFullYear();
  const fromMonth = new Date(currentYear-2, 0);
  const toMonth = new Date(currentYear + 10, 11);
  const [startMonth, setStartMonth] = useState(toMonth);
  const [endMonth, setEndMonth] = useState(toMonth);
  const [selectedStartDay, setSelectedStartDay] = useState(null);
  const [selectedEndDay, setSelectedEndDay] = useState(null);

  useEffect(() => {
    setSelectedStartDay(new Date(value[0]));
    setSelectedEndDay(new Date(value[1]));
    setStartMonth(new Date(value[0]));
    setEndMonth(new Date(value[1]));
  }, [value]);

  useEffect(() => {
    if (showDatePicker) {
      setSelectedStartDay(new Date(value[0]));
      setSelectedEndDay(new Date(value[1]));
      setStartMonth(new Date(value[0]));
      setEndMonth(new Date(value[1]));
      setResetStartButton(true);
      setResetEndButton(true);
    }
  }, [showDatePicker, value]);


  function YearMonthForm({ date, localeUtils, onChange }) {
    // const months = MomentLocaleUtils.getMonths();
    const years = [];
    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
      years.push(i);
    }

    const handleChange = function handleChange(e) {
      const { year, month } = e.target.form;
      onChange(new Date(year.value, month.value));
    };

    return (
      <form className="DayPicker-Caption">
        <select name="month" className="selectMonth" onChange={handleChange} value={date.getMonth()}>
          {months.map((month, i) => (
            <option key={month} value={i}>
              {month}
            </option>
          ))}
        </select>
        <select name="year" className="selectYear" onChange={handleChange} value={date.getFullYear()}>
          {years.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </form>
    );
  }

  const resetStartDate = () => {
    setSelectedStartDay(null);
    setResetStartButton(false);
  };

  const resetEndDate = () => {
    setSelectedEndDay(null);
    setResetEndButton(false);
  };

  const globalReset = () => {
    resetStartDate();
    resetEndDate();
  };

  const closeHandler = () => {
    setShowDatePicker(false);
    // globalReset();
    // const fullDate = "дд/мм/рр - дд/мм/рр";
    // setDateValue(fullDate);
  };

  const confirmHandler = () => {
    // let fullDate;
    // if (selectedStartDay !== null && selectedEndDay !== null) {
    //   fullDate = moment(selectedStartDay).format("DD/MM/YY") + " - " + moment(selectedEndDay).format("DD/MM/YY");
    // } else {
    //   fullDate = "дд/мм/рр - дд/мм/рр";
    // }
    // const dateArr = [moment(selectedStartDay).format("DD/MM/YY"),moment(selectedEndDay).format("DD/MM/YY")]
    // console.log(dateArr, 'date array')

    // setDateValue(`${moment(selectedStartDay).format("DD/MM/YY")} - ${moment(selectedEndDay).format("DD/MM/YY")}`);
    changeHandler([moment(selectedStartDay).format("YYYY-MM-DD"), moment(selectedEndDay).format("YYYY-MM-DD")]);
    setShowDatePicker(false);
  };

  const handleYearMonthChangeStart = month => {
    setStartMonth(month);
  };

  const handleYearMonthChangeEnd = month => {
    setEndMonth(month);
  };

  const handleDayStartDateClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    setSelectedStartDay(day);
    setResetStartButton(true);
  };

  const handleDayEndDateClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    setSelectedEndDay(day);
    setResetEndButton(true);
  };

  return (
    <>
      {labelText && (
        <Label className={labelClassName} style={{ ...labelStyle, ...(errorMessage ? { color: "#ff7a7c" } : {}) }}>
          {labelText}
        </Label>
      )}
      <OutsideClickHandler
        onOutsideClick={() => {
          setShowDatePicker(false);
        }} 
      > 
        <div className={cn("dateRange", className)} style={style}>
          <div className={showDatePicker ? "inputWrapActive" : "inputWrap"} onClick={() => setShowDatePicker(!showDatePicker)}>
            {value[0] && value[1] ? `${moment(value[0]).format("DD/MM/YY")} - ${moment(value[1]).format("DD/MM/YY")}` : placeholder}
            {/* <img className="calendarImg" src={CalendarIcon} alt=""/> */}

            <Icon className="calendarImg" name="calendar" />
          </div>
          {isDisabled && <div className="plug" />}
          {showDatePicker ? (
            <div className="main">
              <div className="cancelIconWrap">
                <div className="cancelInnerBlock" onClick={closeHandler}>
                  <Icon name="close" />
                </div>
              </div>
              <div className="dateWrapRow">
                <div className="dateWrap">
                  <div className="datepickerHeader"> {text("dateRange.start")}</div>
                  <div className="inputWrap">
                    <div className="input">{selectedStartDay ? moment(selectedStartDay).format("DD/MM/YY") : ""}</div>
                    {resetStartButton ? (
                      <Icon
                        style={{ marginRight: 10, cursor: "pointer" }}
                        name="clean"
                        clickHandler={() => {
                          resetStartDate();
                        }}
                      />
                    ) : null}
                  </div>
                  <DayPicker
                    month={startMonth}
                    fromMonth={fromMonth}
                    toMonth={toMonth}
                    localeUtils={MomentLocaleUtils}
                    locale={language === "ua" ? "uk" : language}
                    selectedDays={selectedStartDay}
                    onDayClick={handleDayStartDateClick}
                    disabledDays={{
                      after: selectedEndDay
                    }}
                    captionElement={({ date, localeUtils }) => (
                      <YearMonthForm date={date} locale={language === "ua" ? "uk" : language} localeUtils={localeUtils} onChange={handleYearMonthChangeStart} />
                    )}
                  />
                </div>
                <div className="dateWrap">
                  <div className="datepickerHeader"> {text("dateRange.end")}</div>
                  <div className="inputWrap">
                    <div className="input">{selectedEndDay ? moment(selectedEndDay).format("DD/MM/YY") : ""}</div>
                    {resetEndButton ? (
                      <Icon
                        style={{ marginRight: 10, cursor: "pointer" }}
                        name="clean"
                        clickHandler={() => {
                          resetEndDate();
                        }}
                      />
                    ) : null}
                  </div>
                  <DayPicker
                    month={endMonth}
                    fromMonth={startMonth}
                    toMonth={toMonth}
                    localeUtils={MomentLocaleUtils}
                    locale={language === "ua" ? "uk" : language}
                    selectedDays={selectedEndDay}
                    onDayClick={handleDayEndDateClick}
                    disabledDays={{
                      before: selectedStartDay
                    }}
                    captionElement={({ date, localeUtils }) => (
                      <YearMonthForm date={date} locale={language === "ua" ? "uk" : language} localeUtils={localeUtils} onChange={handleYearMonthChangeEnd} />
                    )}
                  />
                </div>
              </div>
              <div className="buttonsWrap">
                <div className="buttons">
                  <Button
                    style={{
                      width: 200,
                      height: 48,
                      margin: 10,
                      color: "#241F5A",
                      textDecoration: "underline",
                      fontWeight: "bold"
                    }}
                    clickHandler={globalReset}
                  >
                    {text("dateRange.buttonClean")}
                  </Button>
                  <Button
                    style={{
                      width: 200,
                      height: 48,
                      margin: 10,
                      color: "#241F5A",
                      backgroundColor: "#fff",
                      borderColor: "#241F5A",
                      fontWeight: "bold"
                    }}
                    clickHandler={closeHandler}
                  >
                    {text("dateRange.buttonCancel")}
                  </Button>
                  <Button
                    className={cn("button", selectedStartDay === null || selectedEndDay === null ? "buttonInactive" : "buttonSubmit")}
                    clickHandler={() => {
                      confirmHandler();
                    }}
                    isDisabled={selectedStartDay === null || selectedEndDay === null}
                  >
                    {text("dateRange.buttonConfirm")}
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </OutsideClickHandler>
      {errorMessage && <Error className={errorClassName} style={errorStyle} message={errorMessage} />}
    </>
  );
};

DateRange.propTypes = {
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  errorClassName: PropTypes.string,
  labelStyle: PropTypes.object,
  style: PropTypes.object,
  errorStyle: PropTypes.object,
  labelText: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  changeHandler: PropTypes.func,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  errorMessage: PropTypes.string
};

DateRange.defaultProps = {
  labelClassName: "",
  className: "",
  errorClassName: "",
  labelStyle: {},
  style: {},
  errorStyle: {},
  labelText: "",
  value: [null, null],
  changeHandler: () => {},
  placeholder: "дд/мм/рр - дд/мм/рр",
  isDisabled: false,
  errorMessage: ""
};

export default DateRange;
