import React, { useContext, useEffect, useState } from "react";
import classes from "./Home.module.scss";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import * as API from "../../api/index";
import { authContext, languageContext, modalContext, templatesContext } from "../../context/index";
import Profile from "./Profile/Profile.jsx";
import Transactions from "./Transactions/Transactions.jsx";
import Payments from "./Payments/Payments.jsx";
import Templates from "./Templates/Templates.jsx";
import History from "./History/History.jsx";
import FinancialAssistant from "./FinancialAssistant/FinancialAssistant.jsx";
import RegularPayments from "./RegularPayments/RegularPayments.jsx";
import RegularPaymentsHistory from "./RegularPayments/RegularPaymentHistory/RegularPaymentsHistory.jsx";
import Category from "./FinancialAssistant/Category/Category.jsx";
import News from "./News/News";
import Settings from "./Settings/Settings.jsx";
import CardOrder from "./Cards/CardOrder/CardOrder.jsx";
import CardCredit from "./Cards/CardCredit/CardCredit.jsx";
import DepositOrder from "./Deposits/DepositOrder";
import Cards from "./Cards/Cards.jsx";
import Accounts from "./Accounts/Accounts.jsx";
import Credits from "./Credits/Credits.jsx";
import Deposits from "./Deposits/Deposits.jsx";
import Contacts from "./Contacts/Contacts.jsx";
import Feedback from "../modals/Feedback/Feedback.jsx";
import { Button, Converter, Error, Exchange, Footer, Header, NavigationHorizontal, NavigationVertical, OperationResult, Spinner } from "../../components/index";
import { useScrollButton } from "../../hooks/index";
import serverErrorMessages from "../../helpers/serverErrorMessages";
import * as actions from "../../store/actions/index";
import AccountOrder from "../Accounts/AccountOrder";

const Home = ({ cards, accounts, credits, deposits, readContracts, removeContracts }) => {
  const history = useHistory();
  const { setModal } = useContext(modalContext);
  const { language, setLanguage, text } = useContext(languageContext);
  const { removeAuthenticationData } = useContext(authContext);
  const { readTemplates, deleteTemplates } = useContext(templatesContext);

  const [isShown, setIsShown] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [currOnline, _setCurrOnline] = useState([]);
  const [currCentral, setCurrCentral] = useState([]);

  const setCurrOnline = values => {
    const result = [];
    ["USD", "EUR"].forEach(currency => {
      result.push({
        currency,
        buyRate: (values?.rates || []).find(item => item.sellCurrency === currency)?.rate,
        sellRate: (values?.rates || []).find(item => item.buyCurrency === currency)?.rate
      });
    });
    _setCurrOnline(result);
  };
  const logOut = async () => {
    try {
      await API.postLogout();
      removeAuthenticationData();
      removeContracts();
      deleteTemplates();
    } catch (err) {
      setModal(
        <OperationResult
          result="failure"
          text={serverErrorMessages(err, language)}
          buttonText={text("buttons.close")}
          clickCompleteHandler={() => setModal(null)}
        />
      );
    }
  };

  useEffect(() => {
    let isMounted = true;

    const requestsCb = () => {
      let counter = 0;
      return () => {
        counter++;
        if (counter === 8 && isMounted) setIsShown(true);
      };
    };

    const cb = requestsCb();

    API.getCurrencies({
      currency: "USD,EUR",
      date: moment().format("YYYYMMDD")
    })
      .then(
        ({ data }) => {
          if (isMounted) setCurrencies(data);
        },
        err => {
          console.log(err);
        }
      )
      .finally(() => {
        cb();
      });

    API.getCurrNBU({
      currency: "USD,EUR",
      date: moment().format("YYYYMMDD")
    })
      .then(
        ({ data }) => {
          if (isMounted) setCurrCentral(data);
        },
        err => {
          console.log(err);
        }
      )
      .finally(() => {
        cb();
      });

    API.getCurrOnline({
      currency: "USD,EUR",
      date: moment().format("YYYYMMDD")
    })
      .then(
        ({ data }) => {
          if (isMounted) setCurrOnline(data);
        },
        err => {
          console.log(err);
        }
      )
      .finally(() => {
        cb();
      });

    readContracts("card").finally(() => {
      cb();
    });

    readContracts("account").finally(() => {
      cb();
    });

    readContracts("credit").finally(() => {
      cb();
    });

    readContracts("deposit").finally(() => {
      cb();
    });

    readTemplates().then(
      () => {
        cb();
      },
      err => {
        console.log(err);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [readContracts, readTemplates]);

  const scrollButtonIsShown = useScrollButton();

  return isShown ? (
    <div className={classes.home}>
      <Header
        clickFeedbackHandler={() => setModal(<Feedback />)}
        clickMapHandler={() => history.push("/home/contacts")}
        clickConverterHandler={() => setModal(<Converter currencies={currencies} />)}
        language={language}
        clickLanguageHandler={value => setLanguage(value)}
      />
      <NavigationHorizontal clickLogoHandler={() => history.push("/home/profile")} clickLogoutHandler={logOut} />
      <div className={classes.content}>
        <div className={classes.sidebar}>
          {/* <NavigationVertical clickLogoutHandler={logOut} /> */}
          <NavigationVertical />
          {!!currencies.length && <Exchange className={classes.exchange} currencies={currencies} type="bank" />}
          {!!currCentral.length && <Exchange className={classes.exchange} currencies={currCentral} type="central" />}
          {!!currOnline.length && <Exchange className={classes.exchange} currencies={currOnline} type="online" />}
        </div>
        <div className={classes.main}>
          <Switch>
            <Route path="/home/profile" component={Profile} />
            <Route path="/home/transactions" component={Transactions} />
            <Route path="/home/payments" component={Payments} />
            <Route path="/home/templates" component={Templates} />
            <Route path="/home/history" component={History} />
            <Route path="/home/financial-assistant/:categoryId" component={Category} />
            <Route path="/home/financial-assistant" component={FinancialAssistant} />
            <Route path="/home/regular-payments/:id" component={RegularPaymentsHistory} />
            <Route path="/home/regular-payments" component={RegularPayments} />
            <Route path="/home/news" component={News} />
            <Route path="/home/settings" component={Settings} />
            <Route exact path="/home/cards/:cardId/limits" component={CardCredit} />
            <Route exact path="/home/cards/order" component={CardOrder} />
            <Route exact path="/home/deposits/order" component={DepositOrder} />
            <Route path="/home/accounts/order/:currency" component={AccountOrder} />
            <Route path="/home/cards/:cardId" component={Cards} />
            <Route path="/home/cards" render={() => <Redirect to={"/home/cards/" + (cards[0]?.cardsList[0]?.id || "new")} />} />
            <Route
              path="/home/accounts"
              render={() => (accounts.length ? <Accounts /> : <Error className={classes.contractsError} message={text("home.placeholders.accounts")} />)}
            />
            <Route
              path="/home/credits"
              render={() => (credits.length ? <Credits /> : <Error className={classes.contractsError} message={text("home.placeholders.credits")} />)}
            />
            <Route path="/home/deposits" render={() => (deposits.length ? <Deposits /> : <Redirect to="/home/deposits/order" />)} />
            <Route path="/home/contacts" component={Contacts} />
            <Redirect to="/home/profile" />
          </Switch>
        </div>
      </div>
      <Footer />
      {scrollButtonIsShown && (
        <Button className={classes.button} clickHandler={() => window.scroll({ top: 0, behavior: "smooth" })}>
          &uarr;
        </Button>
      )}
    </div>
  ) : (
    <Spinner className={classes.spinner} />
  );
};

const mapStateToProps = ({ contracts }) => ({
  cards: contracts.cards,
  accounts: contracts.accounts,
  credits: contracts.credits,
  deposits: contracts.deposits
});

const mapDispatchToProps = dispatch => ({
  readContracts: contractType => dispatch(actions.readContracts(contractType)),
  removeContracts: () => dispatch(actions.removeContracts())
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
