import React, { useContext } from "react";
import classes from "./MonthPicker.module.scss";
import PropTypes from "prop-types";
import localization from "../../../localization/index";
import { Icon, Label } from "../../index";
import { languageContext } from "../../../context/index";

const MonthPicker = ({ labelText, changeHandler, isDisabled, minDate, maxDate, date }) => {
  const { language } = useContext(languageContext);

  const yearFrom = React.useMemo(() => {
    const temp = new Date(date);
    temp.setMonth(0);
    return temp.getTime();
  }, [date]);

  const yearTill = React.useMemo(() => {
    const temp = new Date(date);
    temp.setMonth(11);
    return temp.getTime();
  }, [date]);

  const months = React.useMemo(() => {
    const result = [];
    for (let i = new Date(Math.max(minDate, yearFrom)).getMonth(); i <= new Date(Math.min(maxDate, yearTill)).getMonth(); i += 1) {
      result.push({ value: i, label: localization[language].dateRange.months[i] });
    }
    return result;
  }, [yearFrom, yearTill, minDate, maxDate, language]);

  const month = React.useMemo(() => new Date(date).getMonth(), [date]);
  const year = React.useMemo(() => new Date(date).getFullYear(), [date]);

  const years = React.useMemo(() => {
    const result = [];
    for (let i = new Date(minDate || date).getFullYear(); i <= new Date(maxDate || date).getFullYear(); i += 1) {
      result.push(i);
    }
    return result;
  }, [date, minDate, maxDate]);

  const changeM = React.useCallback(
    event => {
      changeHandler(new Date(date).setMonth(Number.parseInt(event.target.value, 10)));
    },
    [changeHandler, date]
  );

  const changeY = React.useCallback(
    event => {
      changeHandler(new Date(date).setFullYear(Number.parseInt(event.target.value, 10)));
    },
    [changeHandler, date]
  );

  const changeMonthNext = React.useCallback(() => {
    const temp = new Date(date);
    temp.setMonth(temp.getMonth() + 1);
    changeHandler(temp.getTime());
  }, [changeHandler, date]);

  const changeMonthPrev = React.useCallback(() => {
    const temp = new Date(date);
    temp.setMonth(temp.getMonth() - 1);
    changeHandler(temp.getTime());
  }, [changeHandler, date]);

  return (
    <>
      <Label>{labelText}</Label>
      <div className={classes.monthPicker}>
        {date > minDate && (
          <div className={isDisabled ? classes.buttonPrevDisabled : classes.buttonPrev}>
            <Icon name="pickerLeftArrow" className={classes.nextArrow} clickHandler={changeMonthPrev} />
          </div>
        )}
        {date <= minDate && <div className={classes.buttonPrevTransparent}>&nbsp;</div>}
        <select
          name="month"
          className={classes.selectMonth}
          onChange={changeM}
          value={month || ""}
          disabled={isDisabled}
          style={isDisabled && { cursor: "default" }}
        >
          {months.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        <select
          name="year"
          className={classes.selectYear}
          value={year || ""}
          onChange={changeY}
          disabled={isDisabled}
          style={isDisabled && { cursor: "default" }}
        >
          {years.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
        {date < maxDate && (
          <div className={isDisabled ? classes.buttonNextDisabled : classes.buttonNext}>
            <Icon name="pickerRightArrow" className={classes.nextArrow} clickHandler={changeMonthNext} />
          </div>
        )}
        {date >= maxDate && <div className={classes.buttonNextTransparent}>&nbsp;</div>}
      </div>
    </>
  );
};

MonthPicker.propTypes = {
  date: PropTypes.number
};
export default MonthPicker;
