import React from "react";
import classes from "./Divider.module.scss";
import PropTypes from "prop-types";

const Divider = ({ style }) => <div className={classes.wrapper} style={style} />;

Divider.propTypes = {
  style: PropTypes.object
};

Divider.defaultProps = {
  style: {}
};

export default Divider;
