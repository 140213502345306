import React from "react";
import classes from "./PinCodeModal.module.scss";
import PropTypes from "prop-types";
import { Title } from "../../index";

const buttonClear = require('../../../assets/icons/btn-clear.svg').default;
const buttonDelete = require('../../../assets/icons/btn-delete.svg').default;

const defaultPinCode = '';
const PinCodeModal = ({ titleText, text, clickCompleteHandler }) => {
  const [pinCode, setPinCode] = React.useState(defaultPinCode);

  React.useEffect(() => {
    const action = (event) => {
      if (event.keyCode >= 48 && event.keyCode <= 57) {
        setPinCode((pin) => pin + String.fromCharCode(event.keyCode) )
      }
      if (event.keyCode === 27) {
        setPinCode(defaultPinCode);
      }
      if (event.keyCode === 8) {
        setPinCode((pin) => pin.slice(0, pin.length - 1));
      }
    };
    document.addEventListener('keydown', action);
    return () => document.removeEventListener('keydown', action);
  }, []);

  React.useEffect(() => {
    if (pinCode.length === 4) {
      clickCompleteHandler(pinCode);
    }
  }, [pinCode]);

  return (
    <div className={classes.operationResult}>
      {titleText && <Title className={classes.title} text={titleText} />}
      {text && <div className={classes.text}>{text}</div>}
      <div className={classes.pin}>
        {[0,1,2,3].map(key => (
            <div key={key} className={!!pinCode[key] ? classes.active : ''}/>
        ))}
      </div>
      <div className={classes.pinPad}>
        {[1,2,3,4,5,6,7,8,9].map((key) => (
            <div key={key} onClick={() => setPinCode((pin) => pin + `${key}`)}>{key}</div>
        ))}
        <div onClick={() => setPinCode(defaultPinCode)}>
          <img src={buttonClear} alt="clear" width={21} />
        </div>
        <div onClick={() => setPinCode((pin) => pin + `0`)}>0</div>
        <div onClick={() => setPinCode((pin) => pin.slice(0, pin.length - 1))}>
          <img src={buttonDelete} alt="delete" width={28} />
        </div>
      </div>
    </div>
  );
};

PinCodeModal.propTypes = {
  titleText: PropTypes.string,
  text: PropTypes.string,
  clickCompleteHandler: PropTypes.func
};

PinCodeModal.defaultProps = {
  titleText: "",
  text: "",
  clickCompleteHandler: null
};

export default PinCodeModal;
