import React, { useContext, useEffect, useMemo, useState } from "react";
import classes from "./History.module.scss";
import moment from "moment";
import { languageContext } from "../../../context/index";
import * as API from "../../../api/index";
import { Button, DailyOperations, Error, HistoryFilter, Icon, NavigationTabs, Spinner, Title } from "../../../components/index";
import { useTitle } from "../../../hooks/index";
import serverErrorMessages from "../../../helpers/serverErrorMessages";
import historyOperationTypeLabels from "../../../helpers/historyOperationTypeLabels";
import { genContractKey } from "../../../helpers/contract";

const History = () => {
  const { language, text } = useContext(languageContext);

  const [activeTab, setActiveTab] = useState("financial");
  const [activeOperationType, setActiveOperationType] = useState("financial");
  const [dates, setDates] = useState([moment().format("YYYYMMDD"), moment().format("YYYYMMDD")]);
  const [type, setType] = useState("All");
  const [isShown, setIsShown] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [operations, setOperations] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const tabs = useMemo(
    () => [
      {
        value: "financial",
        label: text("history.tabs.financial"),
        fetchHandler: API.getFinancialHistory
      },
      {
        value: "service",
        label: text("history.tabs.service"),
        fetchHandler: API.getServiceHistory
      },
      {
        value: "authorization",
        label: text("history.tabs.authorization"),
        fetchHandler: API.getAuthorizationHistory
      }
    ],
    [text]
  );

  const typeOptions = [...new Set(operations.map(operation => operation.operationType))].map(type => ({
    value: type,
    label: historyOperationTypeLabels(type, language)
  }));

  const filteredByTypeOperations = type === "All" ? operations : operations.filter(operation => operation.operationType === type);

  const modifiedOperations = () =>
    filteredByTypeOperations.reduce((acc, operation) => {
      const accCopy = JSON.parse(JSON.stringify(acc));
      let localDate = date => moment(date).locale(language === "ua" ? "uk" : language);
      const { operationType } = operation;
      let date, newOperation;
      if (activeOperationType === "financial") {
        const {
          createDate,
          executeDate,
          amount,
          currency,
          attributes,
          status,
          operationConditions,
          id,
          destContractRef,
          srcContractRef,
          cardId,
          dstCardId
        } = operation.operation;
        let sender = "-";
        try {
          sender = attributes.find(x => x.key === "srcCardNumberMask" || x.key === "srcAccountNo").value;
        } catch {}

        let senderName = "-";
        try {
          senderName = attributes.find(x => x.key === "srcName").value;
        } catch {}

        let receiver = "-";
        try {
          receiver = attributes.find(x => x.key === "dstCardNumberMask" || x.key === "dstAccountNo").value;
        } catch {}

        let receiverName = "-";
        try {
          receiverName = attributes.find(x => x.key === "dstName").value;
        } catch {}

        let tax = "-";
        try {
          tax = attributes.find(x => x.key === "dstTaxCode").value;
        } catch {}
        // depositProlongationChangeAction
        let description = historyOperationTypeLabels(operationType, language);
        try {
          description = attributes.find(x => x.key === "purpose").value;
        } catch {}

        date = localDate(createDate).format("LL");
        newOperation = {
          id,
          srcContractId: srcContractRef && srcContractRef.contractId,
          srcProviderId: srcContractRef && srcContractRef.providerId,
          destContractId: destContractRef && destContractRef.contractId,
          destProviderId: destContractRef && destContractRef.providerId,
          srcContract: srcContractRef && genContractKey(srcContractRef.providerId, srcContractRef.contractId, cardId),
          dstContract: destContractRef && genContractKey(destContractRef.providerId, destContractRef.contractId, dstCardId),
          operationType,
          time: localDate(createDate).format("LT"),
          description,
          amount,
          currency,
          creationDate: localDate(createDate).format("LLL"),
          executionDate: localDate(executeDate).format("LLL"),
          sender,
          senderName,
          receiver,
          receiverName,
          tax,
          status,
          commission: operationConditions && operationConditions.commission
        };
      }
      if (activeOperationType === "service") {
        const { createDate, depositProlongationChangeAction, status, change_card_settings_operation_type, operationSubtypeForHistory } = operation.operation;
        date = localDate(createDate).format("LL");
        newOperation = {
          operationType,
          status,
          time: localDate(createDate).format("LT"),
          description: operationSubtypeForHistory || change_card_settings_operation_type || depositProlongationChangeAction || operationType
          // historyOperationTypeLabels(operationType, language)
        };
      }
      if (activeOperationType === "authorization") {
        const { operationDate, ipAddress, operationStatus } = operation;
        date = localDate(operationDate).format("LL");
        newOperation = {
          operationType,
          time: localDate(operationDate).format("LT"),
          description: ipAddress,
          status: operationStatus
        };
      }
      const i = acc.findIndex(day => day.date === date);
      if (i > -1) {
        accCopy[i].rows = [...accCopy[i].rows, newOperation];
      } else {
        accCopy.push({
          date,
          rows: [newOperation]
        });
      }
      return accCopy;
    }, []);

  const fetchMoreOperations = async () => {
    setIsFetching(true);
    const params = {
      from: dates[0],
      to: dates[1]
    };
    try {
      const res = await Promise.all([
        tabs.find(tab => tab.value === activeTab).fetchHandler({ ...params, page: nextPage, per_page: 10 }),
        tabs.find(tab => tab.value === activeTab).fetchHandler({ ...params, page: 10 * nextPage + 1, per_page: 1 })
      ]);
      setOperations([...operations, ...res[0].data.historyItems]);
      setNextPage(res[1].data.historyItems.length ? nextPage + 1 : null);
    } catch (err) {
      setErrorMessage(serverErrorMessages(err, language));
    }
    setIsFetching(false);
  };

  useEffect(() => {
    (async () => {
      setIsShown(false);
      setIsFetching(true);
      setNextPage(null);
      setErrorMessage(null);
      const params = {
        from: dates[0],
        to: dates[1]
      };
      try {
        const res = await Promise.all([
          tabs.find(tab => tab.value === activeTab).fetchHandler({ ...params, page: 1, per_page: 10 }),
          tabs.find(tab => tab.value === activeTab).fetchHandler({ ...params, page: 11, per_page: 1 })
        ]);
        setOperations(res[0].data.historyItems);
        if (res[1].data.historyItems.length) {
          setNextPage(2);
        }
      } catch (err) {
        setOperations([]);
        setErrorMessage(serverErrorMessages(err, language));
      }
      setActiveOperationType(activeTab);
      setIsShown(true);
      setIsFetching(false);
    })();
  }, [dates, activeTab, tabs, text, language]);

  useEffect(() => {
    setType("All");
  }, [activeTab]);

  useTitle(text("history.title"));

  return (
    <div className={classes.history}>
      <div className={classes.top}>
        <Title text={text("history.title")} />
      </div>
      <div className={classes.bottom}>
        <NavigationTabs className={classes.navigationTabs} tabs={tabs} activeTab={activeTab} changeTabHandler={value => setActiveTab(value)} />
        <HistoryFilter
          dates={dates}
          types={typeOptions}
          type={type}
          changeDatesHandler={value => setDates(value)}
          changeTypeHandler={value => setType(value)}
        />
        {isShown && (
          <>
            {modifiedOperations().map((dailyOperations, i) => (
              <DailyOperations className={classes.dailyOperations} type={activeTab} date={dailyOperations.date} rows={dailyOperations.rows} key={i} />
            ))}

            {!modifiedOperations().length && !errorMessage && <div className={classes.warning}>{text("errors.noOperations")}</div>}
          </>
        )}
        {errorMessage && <Error className={classes.error} message={errorMessage} />}
        {isFetching && <Spinner className={classes.spinner} diameter={50} />}
        {!isFetching && nextPage && (
          <Button className={classes.buttonRefresh} clickHandler={fetchMoreOperations}>
            <Icon name="refresh" />
            &nbsp;{text("buttons.showMore")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default History;
