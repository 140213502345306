import React, { useContext, useCallback } from "react";
import classes from "./ProductDetails.module.scss";
import PropTypes from "prop-types";
import cn from "../../helpers/cn";
import { Checkbox, Button, TemplateSettings, OperationResult, RegularPayment } from "../index";
import { languageContext, modalContext, templatesContext } from "../../context/index";
import Receipt from "./Receipt/Receipt";
import { useHistory, useLocation } from "react-router-dom";
import serverErrorMessages from "../../helpers/serverErrorMessages";
import localeAmount from "../../helpers/localeAmount";

const ProductDetails = ({ classname, style, row, rows, status, id, operationType }) => {
  const { language, text } = useContext(languageContext);
  const { setModal } = useContext(modalContext);
  const { createTemplate } = useContext(templatesContext);

  let history = useHistory();
  let location = useLocation();
  const { pathname } = location;

  const addTemplate = useCallback(
    async payload => {
      try {
        setModal(null);
        await createTemplate(payload);
        setModal(
          <OperationResult
            titleText={`${text("templates.modals.createSuccess.titleText")}!`}
            text={text("templates.modals.createSuccess.text")}
            buttonText={text("buttons.complete")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      } catch (err) {
        setModal(
          <OperationResult
            result="failure"
            text={serverErrorMessages(err, language)}
            buttonText={text("buttons.close")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      }
    },
    [setModal, text, createTemplate, language]
  );

  const repeatOperationHandler = () => {
    if (operationType === "CONTRACT_TO_CONTRACT") {
      history.push({ pathname: "/home/transactions/outer-to-inner", state: { ...row, amount: (parseInt(row?.amount, 10) / 100).toFixed(2) } });
    } else if (operationType === "INTRABANK_TRANSFER") {
      history.push({ pathname: "/home/transactions/inner-to-inner", state: { ...row, amount: (parseInt(row?.amount, 10) / 100).toFixed(2) } });
    } else if (operationType === "SEP_TRANSFER") {
      history.push({ pathname: "/home/transactions/inner-to-custom", state: { ...row, amount: (parseInt(row?.amount, 10) / 100).toFixed(2) } });
    }
  };
  return (
    <div className={cn(classes.productDetails, classname)} style={style}>
      <div className={classes.rows}>
        {rows.map((row, i) => (
          <React.Fragment key={i}>
            <div className={classes.label}>{row.label}</div>
            <div className={classes.value} title={typeof row.value === "string" ? row.value : ""}>
              {typeof row.value === "boolean" ? <Checkbox type={2} value={row.value} isDisabled /> : row.value}
            </div>
          </React.Fragment>
        ))}
      </div>
      {pathname === "/home/history" &&
        status === "SUCCESS" &&
        (operationType === "CONTRACT_TO_CONTRACT" || operationType === "INTRABANK_TRANSFER" || operationType === "SEP_TRANSFER") && (
          <div className={classes.buttonBlock}>
            <Button
              className={classes.buttonTemplate}
              clickHandler={() =>
                setModal(
                  <TemplateSettings
                    isNew
                    initialType={operationType}
                    initialGivingContract={row.srcContract}
                    initialGettingContract={row.dstContract}
                    initialGettingAccount={row.receiver}
                    initialReceiverName={row.receiverName}
                    initialReceiverCode={row.code}
                    initialTaxNumber={row.tax}
                    initialPurpose={row.description}
                    initialAmount={Math.round(row.amount / 100).toString()}
                    clickCancelHandler={() => setModal(null)}
                    clickSaveHandler={addTemplate}
                  />
                )
              }
            >
              {text("history.buttons.saveTemplate")}
            </Button>
            <Button
              className={classes.button}
              // clickHandler={openCreateRegularPaymentHandler}
              clickHandler={() =>
                setModal(
                  <RegularPayment
                    initialType={row.operationType}
                    initialGivingContract={row.srcContract}
                    initialGettingContract={row.dstContract}
                    initialGettingAccount={row.receiver}
                    initialReceiverCode={row.code}
                    initialAmount={localeAmount(row.amount)}
                    // commission={localeAmount(row.commission)}
                    initialCurrency={row.currency}
                    // isNew
                    // givingContractOptions={row.srcContractId}
                    initialReceiverName={row.receiverName}
                    initialTaxNumber={row.tax}
                    initialPurpose={row.description}
                    clickCancelHandler={() => setModal(null)}
                  />
                )
              }
            >
              {text("history.buttons.createRegularPayment")}
            </Button>
            <Button className={classes.buttonBlue} clickHandler={repeatOperationHandler}>
              {text("history.buttons.repeatOperation")}
            </Button>
          </div>
        )}
      {pathname === "/home/history" && status === "SUCCESS" && (
        <div className={classes.recieptBlock}>
          <Receipt operationId={id} />
        </div>
      )}
    </div>
  );
};

ProductDetails.propTypes = {
  classname: PropTypes.string,
  style: PropTypes.object,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.element])
    })
  )
};

ProductDetails.defaultProps = {
  classname: "",
  style: {},
  rows: []
};

export default ProductDetails;
