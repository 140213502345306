import React, { useContext, useState, useEffect } from "react";
import classes from "./Feedback.module.scss";
import { languageContext, modalContext, authContext } from "../../../context/index";
import { feedbackSchema, validationError, fieldErrorMessage } from "../../../validation/index";
import * as API from "../../../api/index";
import { Title, Error, Input, Button } from "../../../components/index";
import serverErrorMessages from "../../../helpers/serverErrorMessages";

const Feedback = () => {
  const { language, text } = useContext(languageContext);
  const { setModal } = useContext(modalContext);
  const { user } = useContext(authContext);

  const [phone, setPhone] = useState("");
  const [isRequested, setIsRequested] = useState(false);
  const [error, setError] = useState(null);

  const globalErrorMessage = fieldErrorMessage("global", error);

  const submit = async () => {
    clearTimeout(Feedback.timeout);
    setError(null);
    const phoneModified = phone.split(/\s|_/).join("");
    const error = validationError(feedbackSchema(language), {
      phone: phoneModified
    });
    if (error) {
      setError(error);
      return;
    }
    try {
      await API.postFeedback({
        subject: "Phone call",
        message: `${user.fullName} (${user.clientId})\nPlease, call me. ${phone}`
      });
      setIsRequested(true);
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
    }
  };

  useEffect(() => {
    if (error) {
      Feedback.timeout = setTimeout(() => {
        setError(null);
      }, 6000);
    }
  }, [error]);

  useEffect(() => {
    return () => {
      clearTimeout(Feedback.timeout);
    };
  }, []);

  return (
    <div className={classes.feedback}>
      {!isRequested ? (
        <>
          <Title className={classes.title} text={text("feedback.title.initial")} />
          <div className={classes.text}>{text("feedback.text")}</div>
          {globalErrorMessage && <Error className={classes.error} message={globalErrorMessage} />}
          <Input
            className={classes.input}
            errorClassName={classes.error}
            labelText={text("labels.phone")}
            value={phone}
            changeHandler={value => setPhone(value)}
            mask={"+38 \\099 999 99 99"}
            placeholder="+38 000 000 00 00"
            errorMessage={fieldErrorMessage("phone", error)}
          />
          <Button className={classes.button} clickHandler={submit}>
            {text("buttons.confirm")}
          </Button>
        </>
      ) : (
        <>
          <Title className={classes.title} text={text("feedback.title.final")} />
          <Button className={classes.button} clickHandler={() => setModal(null)}>
            {text("buttons.close")}
          </Button>
        </>
      )}
    </div>
  );
};

export default Feedback;
