import React, { useState, useEffect, useContext } from "react";
import classes from "./Converter.module.scss";
import PropTypes from "prop-types";
import { Title, Label, Input, Select, Button, Icon } from "../../index";
import { languageContext } from "../../../context/index";
import isCurrency from "validator/lib/isCurrency";

const Converter = ({ currencies }) => {
  const { text } = useContext(languageContext);

  const [givingValue, setGivingValue] = useState("");
  const [givingCurrency, setGivingCurrency] = useState(null);
  const [gettingValue, setGettingValue] = useState("");
  const [gettingCurrency, setGettingCurrency] = useState(null);

  useEffect(() => {
    setGettingCurrency(null);
    setGettingValue("");
  }, [givingCurrency]);

  const givingOptions = () => {
    let arr = [];
    currencies.forEach(curr => {
      let currObj = {
        label: curr.currency,
        value: curr.currency,
        buyRate: curr.buyRate,
        sellRate: curr.sellRate
      };
      arr.push(currObj);
    });
    arr.push({ label: "UAH", value: "UAH" });
    return arr;
  };

  const gettingOptions = () => {
    let arr = [];
    if (givingCurrency === "USD" || givingCurrency === "EUR" || givingCurrency === "RUB") {
      arr.push({ label: "UAH", value: "UAH" });
      return arr;
    } else if (givingCurrency === "UAH") {
      currencies.forEach(curr => {
        let currObj = {
          label: curr.currency,
          value: curr.currency,
          buyRate: curr.buyRate,
          sellRate: curr.sellRate
        };
        arr.push(currObj);
      });
      return arr;
    } else {
      currencies.forEach(curr => {
        let currObj = {
          label: curr.currency,
          value: curr.currency,
          buyRate: curr.buyRate,
          sellRate: curr.sellRate
        };
        arr.push(currObj);
      });
      arr.push({ label: "UAH", value: "UAH" });
      return arr;
    }
  };

  const countHandler = () => {
    let num = Number(givingValue);
    if (isNaN(num)) {
      return false;
    } else {
      if (givingCurrency === "UAH") {
        let selectCurrency = currencies.find(item => item.currency === gettingCurrency);
        let val = (givingValue / selectCurrency.sellRate).toFixed(2);
        setGettingValue(val);
      } else {
        let selectCurrency = currencies.find(item => item.currency === givingCurrency);
        let val = (givingValue * selectCurrency.buyRate).toFixed(2);
        setGettingValue(val);
      }
    }
  };

  const reverseHandler = () => {
    setGettingCurrency(givingCurrency);
    setGivingCurrency(gettingCurrency);
    setGivingValue(gettingValue);
  };

  return (
    <div className={classes.wrapper}>
      <Title style={{ marginBottom: 30 }} text={text("converter.title")} />
      <div className={classes.content}>
        <div className={classes.left}>
          <Label>{text("converter.labels.give")}</Label>
          <Input
            value={givingValue}
            changeHandler={value => setGivingValue(value)}
            commaIsReplacedWithDot
            validator={value =>
              isCurrency(value, {
                digits_after_decimal: [0, 1, 2]
              })
            }
          />
          <Button
            style={{
              margin: "30px 0 30px 80px",
              width: 140,
              height: 44,
              borderColor: "#241F5A",
              fontWeight: "bold",
              visibility: givingCurrency != null && gettingCurrency != null ? "visible" : "hidden"
            }}
            clickHandler={() => reverseHandler()}
          >
            <Icon name="exchangeCurrency" />
          </Button>
          <Label>{text("converter.labels.get")}</Label>
          <Input value={gettingValue} isDisabled />
        </div>
        <div className={classes.right}>
          <Label>{text("converter.labels.currency")}</Label>
          <Select options={givingOptions()} value={givingCurrency} changeHandler={value => setGivingCurrency(value)} />
          <Button
            isDisabled={givingValue !== "" && givingCurrency !== null && gettingCurrency !== null ? false : true}
            style={{
              margin: "30px 0",
              height: 44,
              color: "white",
              backgroundColor: "#241F5A",
              borderColor: "#241F5A",
              fontWeight: "bold"
            }}
            clickHandler={() => countHandler()}
          >
            {text("converter.buttons.calculate")}
          </Button>
          <Label>{text("converter.labels.currency")}</Label>
          <Select
            options={gettingOptions()}
            value={gettingCurrency}
            changeHandler={value => setGettingCurrency(value)}
            isDisabled={givingCurrency ? false : true}
          />
        </div>
      </div>
    </div>
  );
};

Converter.propTypes = {
  currencies: PropTypes.array
};

Converter.defaultProps = {
  currencies: []
};

export default Converter;
