import React, { useState, useMemo, useCallback } from "react";
import TemplatesContext from "./templatesContext";
import * as API from "../../api/index";
import modifiedAmount from "../../helpers/modifiedAmount";
import { genContractKey } from "../../helpers/contract";

const TemplatesState = ({ children }) => {
  const [_templates, setTemplates] = useState([]);

  const templates = useMemo(() => {
    return _templates.map(x => {
      const { id, iconRef, color, title: name, description, definedAmounts, currency, operationSubject, defaultSourceContract } = x;

      let amount, type, givingContract, gettingContract, gettingAccount, receiverName, receiverCode, taxNumber, purpose;
      console.log(x);
      try {
        amount = definedAmounts[0].amount / 100;
        type = operationSubject.type;
        givingContract = `${genContractKey(defaultSourceContract.providerId, defaultSourceContract.contractId, x.srcCardId)}`;
        gettingContract = `${genContractKey(operationSubject.subject.providerId, operationSubject.subject.contractId, x.dstCardId)}`;
        if (type === "INTRABANK_TRANSFER") gettingAccount = operationSubject.subject.identifier;
        if (type === "SEP_TRANSFER") gettingAccount = operationSubject.subject.accountNumber;
        receiverName = operationSubject.subject.name;
        receiverCode = operationSubject.subject.bankId;
        taxNumber = operationSubject.subject.taxId;
        purpose = operationSubject.description;
      } catch {}

      const urls = {
        CONTRACT_TO_CONTRACT: `/home/transactions/outer-to-inner?sender=${givingContract}&receiver=${gettingContract}&amount=${amount}`,
        INTRABANK_TRANSFER: `/home/transactions/inner-to-inner?sender=${givingContract}&receiver=${gettingAccount}&amount=${amount}`,
        SEP_TRANSFER: `/home/transactions/inner-to-custom?sender=${givingContract}&receiver=${gettingAccount}&name=${receiverName}&code=${receiverCode}&tax=${taxNumber}&purpose=${purpose}&amount=${amount}`
      };

      return {
        id: id,
        iconName: iconRef,
        iconColor: color,
        name: name,
        description: description,
        currency: currency,
        type: type,
        givingContract: givingContract,
        gettingContract: gettingContract,
        gettingAccount: gettingAccount,
        receiverName: receiverName,
        receiverCode: receiverCode,
        taxNumber: taxNumber,
        purpose: purpose,
        amount: modifiedAmount(amount),
        url: urls[type]
      };
    });
  }, [_templates]);

  const readTemplates = useCallback(async () => {
    const { data } = await API.getTemplates();
    setTemplates(data.templates);
  }, []);

  const createTemplate = useCallback(
    async payload => {
      const { data } = await API.postCreateTemplate(payload);
      setTemplates([data, ..._templates]);
    },
    [_templates]
  );

  const updateTemplate = useCallback(
    async (id, payload) => {
      const { data } = await API.postUpdateTemplate(id, payload);
      const templatesCopy = JSON.parse(JSON.stringify(_templates));
      const index = templatesCopy.findIndex(x => x.id === id);
      templatesCopy.splice(index, 1, data);
      setTemplates(templatesCopy);
    },
    [_templates]
  );

  const deleteTemplates = useCallback(() => {
    setTemplates([]);
  }, []);

  const deleteTemplate = useCallback(
    async id => {
      await API.postDeleteTemplate(id);
      setTemplates(_templates.filter(x => x.id !== id));
    },
    [_templates]
  );

  return (
    <TemplatesContext.Provider value={{ templates, readTemplates, createTemplate, updateTemplate, deleteTemplates, deleteTemplate }}>
      {children}
    </TemplatesContext.Provider>
  );
};

export default TemplatesState;
