import React from "react";
import OtpContext from "./otpContext";

const OtpState = ({ children }) => {
  const [otp, _setOtp] = React.useState(null);

  const setOtp = React.useCallback(value => {
    _setOtp(value);
  }, []);

  return <OtpContext.Provider value={{ otp, setOtp }}>{children}</OtpContext.Provider>;
};

export default OtpState;
