import React from "react";
import { useParams } from 'react-router-dom';
import { Spinner } from "../../../components";
import Title from "../../../components/Title/Title";
import SimpleForm from "./InitForm/SimpleForm/SimpleForm";
import classes from "./PaymentsOperation.module.scss";
import PaymentsContext from "./PaymentsContext";
import PaymentsComplex from "./PaymentsComplex";

const PaymentsOperation = () => {
  const { categoryId, billerId } = useParams();
  const { title, setTitle, biller, getBiller } = React.useContext(PaymentsContext);

  React.useEffect(() => {
    getBiller({ billerId });
  }, [billerId, categoryId, getBiller]);

  React.useEffect(() => {
    setTitle(biller?.title)
  }, [biller, setTitle]);
  
  return (
    <>
      <div className={classes.form}>
        <div className={classes.row}>
          <Title text={title} />
        </div>
      </div>
      {biller === null && (
        <Spinner className={classes.spinner} diameter={50} />
      )}
      {biller?.isComplex === "false" && (
        <SimpleForm />
      )}
      {biller?.isComplex === "true" && (
        <PaymentsComplex />
      )}
    </>
  )
};

export default PaymentsOperation;
