import React, { useContext, useState } from "react";
import classes from "./TemplateSettings.module.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Icon, Input, Label, Select, TemplateIcon, Textarea, Title } from "../../index";
import iconNames from "../../../data/iconNames";
import cn from "../../../helpers/cn";
import numberWithSpaces from "../../../helpers/numberWithSpaces";
import amountForRequest from "../../../helpers/amountForRequest";
import {authContext, languageContext} from "../../../context/index";
import {
  bankInnerTemplateSchema,
  fieldErrorMessage,
  innerToCustomTemplateSchema,
  innerToInnerTemplateSchema,
  updateTemplateSchema,
  validationError
} from "../../../validation/index";
import { useError } from "../../../hooks/index";
import isCurrency from "validator/lib/isCurrency";
import isLength from "validator/lib/isLength";
import isInt from "validator/lib/isInt";
import {
  accountOptions,
  cardOptions,
  creditOptions,
  depositReceiverOptions,
  depositSenderOptions,
  uahAccountOptions,
  uahCardOptions
} from "../../../store/getters/index";
import * as API from "../../../api";
import matches from "validator/lib/matches";
import { defIban, defIbanPast, defIbanPreChange, defIbanValidator } from "../../../helpers/iban";

const iconColors = ["7ABD43", "F5A623", "F8E71C", "4A90E2", "1ACDCC", "FF7A7B", "241F5A"];

const TemplateSettings = ({
  isNew,
  initialType,
  initialName,
  initialGivingContract,
  initialGettingContract,
  initialGettingAccount,
  initialReceiverName,
  initialReceiverCode,
  initialTaxNumber,
  initialPurpose,
  initialAmount,
  initialDescription,
  initialIconColor,
  initialIconName,
  clickCancelHandler,
  clickSaveHandler,
  clickDeleteHandler,
  cards,
  accounts,
  credits,
  deposits
}) => {
  const { language, text } = useContext(languageContext);

  const [type, setType] = useState(initialType);
  const [name, setName] = useState(initialName);
  const [isExpanded, setIsExpanded] = useState(false);

  const srcContractOptions = React.useMemo(
    () =>
      type === "CONTRACT_TO_CONTRACT"
        ? [...cardOptions(cards), ...accountOptions(accounts, text), ...depositSenderOptions(deposits, text)]
        : [...uahCardOptions(cards), ...uahAccountOptions(accounts, text)],
    [accounts, cards, deposits, text, type]
  );
  const dstContractOptions = React.useMemo(
    () => [...cardOptions(cards), ...accountOptions(accounts, text), ...depositReceiverOptions(deposits, text), ...creditOptions(credits, text)],
    [accounts, cards, credits, deposits, text]
  );
  const srcContractAll = React.useMemo(
    () => [...cardOptions(cards), ...accountOptions(accounts, text), ...depositSenderOptions(deposits, text), ...creditOptions(credits, text)],
    [accounts, cards, credits, deposits, text]
  );
  const dstContractAll = React.useMemo(
    () => [...cardOptions(cards), ...accountOptions(accounts, text), ...depositReceiverOptions(deposits, text), ...creditOptions(credits, text)],
    [accounts, cards, credits, deposits, text]
  );

  const [srcContract, _setSrcContract] = React.useState({});
  const [dstContract, _setDstContract] = React.useState({});

  const setSrcContract = React.useCallback(
    value => {
      _setSrcContract(srcContractAll.find(contract => contract.value === value) || {});
    },
    [srcContractAll]
  );
  const setDstContract = React.useCallback(
    value => {
      _setDstContract(dstContractAll.find(contract => contract.value === value) || {});
    },
    [dstContractAll]
  );

  React.useEffect(() => {
    setSrcContract(initialGivingContract);
    setDstContract(initialGettingContract);
  }, [initialGettingContract, initialGivingContract, setDstContract, setSrcContract]);

  const srcContractList = React.useMemo(() => {
    if (dstContract?.value) {
      return srcContractOptions
        .filter(contract => contract?.value !== dstContract?.value)
        .filter(contract => contract?.currency === dstContract?.currency)
        .filter(contract => (dstContract?.providerId === "card" ? ["account", "deposit", "credit", "card"].includes(contract?.providerId) : true))
        .filter(contract => (dstContract?.providerId === "credit" ? ["account", "card"].includes(contract?.providerId) : true))
        .filter(contract => (dstContract?.providerId === "deposit" ? ["account", "card"].includes(contract?.providerId) : true))
        .filter(contract => (dstContract?.providerId === "account" ? ["account", "deposit", "card"].includes(contract?.providerId) : true));
    }
    return srcContractOptions;
  }, [dstContract.currency, dstContract.providerId, dstContract.value, srcContractOptions]);

  const dstContractList = React.useMemo(() => {
    if (srcContract?.value) {
      return dstContractOptions
        .filter(contract => contract?.value !== srcContract?.value)
        .filter(contract => contract?.currency === srcContract?.currency)
        .filter(contract => (srcContract?.providerId === "deposit" ? ["account", "card"].includes(contract?.providerId) : true))
        .filter(contract => (srcContract?.providerId === "credit" ? contract?.providerId === "card" : true));
    }
    return dstContractOptions;
  }, [dstContractOptions, srcContract.currency, srcContract.providerId, srcContract.value]);

  const [gettingAccount, setGettingAccount] = useState(() => (type === "SEP_TRANSFER" ? defIban(initialGettingAccount) : initialGettingAccount));
  const [receiverName, setReceiverName] = useState(initialReceiverName);
  const [taxNumber, setTaxNumber] = useState(initialTaxNumber);
  const [purpose, setPurpose] = useState(initialPurpose || 'Переказ коштів ');
  const [amount, setAmount] = useState(numberWithSpaces(initialAmount));
  const [description, setDescription] = useState(initialDescription);
  const [iconColor, setIconColor] = useState(initialIconColor);
  const [iconName, setIconName] = useState(initialIconName);

  const [error, setError] = useError(null);

  const typeOptions = [
    {
      value: "INTRABANK_TRANSFER",
      label: text("templateSettings.options.types.bankInner"),
      handler: submitBankInner
    },
    {
      value: "CONTRACT_TO_CONTRACT",
      label: text("templateSettings.options.types.innerToInner"),
      handler: submitInnerToInner
    },
    {
      value: "SEP_TRANSFER",
      label: text("templateSettings.options.types.innerToCustom"),
      handler: submitInnerToCustom
    }
  ];

  function submitBankInner() {
    setError(null);

    const modifiedGettingAccount = gettingAccount.split(/_/).join("");

    const error = validationError(bankInnerTemplateSchema(language), {
      name,
      givingContract: srcContract?.value,
      gettingAccount: modifiedGettingAccount,
      amount
    });

    if (error) {
      setError(error);
      return;
    }

    const data = {
      operationSubject: {
        type: type,
        subject: {
          challengeRequestValue: "",
          challengeResponseValue: "",
          identifier: gettingAccount,
          identifierType: gettingAccount.length === 16 ? "CARD_NUMBER" : "ACCOUNT_NUMBER"
        }
      },
      properties: {
        title: name,
        description: description,
        currency: "UAH",
        defaultSourceContract: {
          contractId: srcContract.contractId,
          providerId: srcContract.providerId
        },
        definedAmounts: [
          {
            amount: `${Math.round(amountForRequest(amount))}`,
            type: "DEFAULT"
          }
        ],
        iconRef: iconName,
        color: iconColor,
        srcCardId: srcContract.cardId
      }
    };

    clickSaveHandler(data);
  }

  function submitInnerToInner() {
    setError(null);

    const error = validationError(innerToInnerTemplateSchema(language), {
      name,
      givingContract: srcContract?.type,
      gettingContract: dstContract?.type,
      amount
    });

    if (error) {
      setError(error);
      return;
    }

    const data = {
      operationSubject: {
        type: type,
        subject: {
          contractId: dstContract?.contractId,
          providerId: dstContract?.providerId
        }
      },
      properties: {
        title: name,
        description: description,
        currency: srcContract?.currency,
        defaultSourceContract: {
          contractId: srcContract?.contractId,
          providerId: srcContract?.providerId
        },
        definedAmounts: [
          {
            amount: `${Math.round(amountForRequest(amount))}`,
            type: "DEFAULT"
          }
        ],
        iconRef: iconName,
        color: iconColor,
        srcCardId: srcContract?.cardId,
        dstCardId: dstContract?.cardId
      }
    };

    clickSaveHandler(data);
  }

  function submitInnerToCustom() {
    setError(null);

    const modifiedGettingAccount = gettingAccount.split(/_/).join("");
    const modifiedReceiverCode = gettingAccount
      .substring(4, 10)
      .split(/_/)
      .join("");

    const error = validationError(innerToCustomTemplateSchema(language), {
      name,
      givingContract: srcContract?.value,
      gettingAccount: modifiedGettingAccount,
      receiverName,
      receiverCode: modifiedReceiverCode,
      taxNumber,
      purpose: (purpose || '').trim(),
      amount
    });

    if (error) {
      setError(error);
      return;
    }

    const data = {
      operationSubject: {
        type: type,
        subject: {
          accountNumber: gettingAccount,
          bankId: gettingAccount.substring(4, 10),
          currencyId: "UAH",
          name: receiverName,
          taxId: taxNumber
        },
        description: (purpose || '').trim(),
      },
      properties: {
        title: name,
        description: description,
        currency: "UAH",
        defaultSourceContract: {
          contractId: srcContract?.contractId,
          providerId: srcContract?.providerId
        },
        srcCardId: srcContract?.cardId,
        definedAmounts: [
          {
            amount: `${Math.round(amountForRequest(amount))}`,
            type: "DEFAULT"
          }
        ],
        iconRef: iconName,
        color: iconColor
      }
    };

    clickSaveHandler(data);
  }

  function submitUpdate() {
    setError(null);

    const error = validationError(updateTemplateSchema(language), {
      name,
      givingContract: srcContract?.value,
      amount
    });

    if (error) {
      setError(error);
      return;
    }

    const data = {
      properties: {
        title: name,
        description: description,
        currency: srcContract?.currency,
        defaultSourceContract: {
          contractId: srcContract?.contractId,
          providerId: srcContract?.providerId
        },
        srcCardId: srcContract?.cardId,
        definedAmounts: [
          {
            amount: `${Math.round(amountForRequest(amount))}`,
            type: "DEFAULT"
          }
        ],
        iconRef: iconName,
        color: iconColor
      }
    };

    clickSaveHandler(data);
  }

  const [bankName, setBankName] = useState("");

  const [bankId, setBankId] = React.useState(gettingAccount.substring(4, 10));
  const {user: {taxId}} = React.useContext(authContext);
  const [taxChanged, setTaxChanged] = React.useState(false);
  React.useEffect(() => {
    if (!taxChanged && taxNumber !== initialTaxNumber) {
      setTaxChanged(true);
    }
  }, [taxNumber, taxChanged]);
  const [isMyTax, setMyTax] = React.useState(taxId === initialTaxNumber);
  React.useEffect(() => {
    setMyTax(taxId === taxNumber)
  }, [taxNumber, taxId]);
  React.useEffect(() => {
    if (taxChanged) {
      setPurpose(isMyTax ? 'Переказ між власними рахунками' : 'Переказ коштів ')
    }
  }, [isMyTax, taxChanged]);
  React.useEffect(() => {
    if (gettingAccount.length >= 10) {
      setBankId(gettingAccount.substring(4, 10));
    } else {
      setBankId("");
    }
  }, [gettingAccount]);
  React.useEffect(() => {
    if (bankId) {
      API
          .requestForCustomTransaction(bankId)
          .then(res => {
            setBankName(res.data?.bankName);
          });
    } else {
      setBankName("");
    }
  }, [bankId]);

  const BeneficiaryCardOrIbanNumberValidator = gettingAccount => {
    const CapitalLetters = gettingAccount.length === 1 ? matches(gettingAccount.substring(0, 1), /U|u/) : matches(gettingAccount.substring(0, 2), /UA|ua|Ua/);
    const DigitalAftetSecondChar = gettingAccount.length > 2 ? isInt(gettingAccount.substring(2, 28)) : CapitalLetters;
    const Digital = matches(gettingAccount, /\d/);
    return (
      (CapitalLetters && DigitalAftetSecondChar && isLength(gettingAccount, { min: 0, max: 29 })) || (Digital && isLength(gettingAccount, { min: 0, max: 16 }))
    );
  };

  const TaxNumberValidator = taxNumber => matches(taxNumber, /^([А-ЯҐЄІЇ]{0,2}\d{0,6}|\d{0,10})?$/);

  return (
    <div className={classes.wrapper}>
      <Title style={{ marginBottom: 10 }} text={text(isNew ? "templateSettings.title.createTemplate" : "templateSettings.title.editTemplate")} />
      <div className={classes.content}>
        <div className={classes.column}>
          <Select
            style={{ zIndex: 2, marginBottom: 10 }}
            labelText={text("templateSettings.labels.type")}
            options={typeOptions}
            value={type}
            changeHandler={x => setType(x)}
            isDisabled={!isNew}
          />
          <Input
            errorClassName={classes.error}
            style={{ marginBottom: 10 }}
            labelText={text("templateSettings.templateName")}
            value={name}
            changeHandler={x => setName(x)}
            errorMessage={fieldErrorMessage("name", error)}
          />
          <Select
            errorClassName={classes.error}
            style={{ zIndex: 1, marginBottom: 10 }}
            labelText={text("templateSettings.labels.fromContract")}
            options={srcContractList}
            value={srcContract?.value}
            changeHandler={setSrcContract}
            placeholder={text("templateSettings.select.placeholder")}
            errorMessage={fieldErrorMessage("givingContract", error)}
          />
          {type === "CONTRACT_TO_CONTRACT" ? (
            <Select
              errorClassName={classes.error}
              style={{ marginBottom: 10 }}
              labelText={text("templateSettings.labels.toContract")}
              options={dstContractList}
              value={dstContract?.value}
              changeHandler={setDstContract}
              placeholder={text("templateSettings.select.placeholder")}
              errorMessage={fieldErrorMessage("gettingContract", error)}
              isDisabled={!isNew}
            />
          ) : type === "INTRABANK_TRANSFER" ? (
            <Input
              errorClassName={classes.error}
              style={{ marginBottom: 10 }}
              labelText={text("templateSettings.labels.toContract")}
              value={gettingAccount.toUpperCase()}
              validator={BeneficiaryCardOrIbanNumberValidator}
              changeHandler={x => setGettingAccount(x)}
              errorMessage={fieldErrorMessage("gettingContractCardOrIban", error)}
              isDisabled={!isNew}
            />
          ) : (
            <Input
              errorClassName={classes.error}
              style={{ marginBottom: 10 }}
              labelText={text("templateSettings.labels.toContractForSep")}
              value={gettingAccount.toUpperCase()}
              validator={defIbanValidator}
              preChange={defIbanPreChange}
              changeHandler={x => setGettingAccount(defIban(x))}
              errorMessage={fieldErrorMessage("gettingAccount", error)}
              isDisabled={!isNew}
              onPaste={defIbanPast(setGettingAccount)}
            />
          )}
          <div className={classes.amountRow}>
            <div>
              <Input
                errorClassName={classes.error}
                style={{ marginBottom: 10, width: 220 }}
                labelText={text("templateSettings.transferAmount")}
                name="amount"
                value={amount}
                units={srcContract?.currency}
                commaIsReplacedWithDot
                validator={value =>
                  isCurrency(value, { digits_after_decimal: [0, 1, 2] }) &&
                  (type === "SEP_TRANSFER"
                    ? isCurrency(value, { digits_after_decimal: [0] })
                      ? isLength(value, { min: 0, max: 14 })
                      : isCurrency(value, { digits_after_decimal: [1] })
                      ? isLength(value, { min: 0, max: 16 })
                      : isLength(value, { min: 0, max: 17 })
                    : true)
                }
                changeHandler={x => setAmount(x)}
                errorMessage={fieldErrorMessage("amount", error)}
              />
            </div>
            {/* <div className={classes.text}>{text("templateSettings.noCommission")}</div> */}
          </div>
          <div className={classes.additionalSettingsRow} onClick={() => setIsExpanded(!isExpanded)}>
            <Icon style={{ marginRight: 10 }} name="templateSettings" />
            {`${isExpanded ? text("templateSettings.minimizeAdditionalSettings") : text("templateSettings.expandAdditionalSettings")}`}
            {text("templateSettings.additionalSettings")}
            <Icon style={{ marginLeft: 10, ...(isExpanded ? { transform: "rotate(180deg)" } : {}) }} name="selectArrow" />
          </div>
        </div>
        {type === "SEP_TRANSFER" && (
          <div className={classes.column}>
            <Input
              errorClassName={classes.error}
              style={{ marginBottom: 10 }}
              labelText={text("innerToCustomTransaction.recipientName")}
              value={receiverName}
              maxLength={38}
              changeHandler={x => setReceiverName(x)}
              errorMessage={fieldErrorMessage("receiverName", error)}
              isDisabled={!isNew}
            />
            <Label>{text("innerToCustomTransaction.mfo")}</Label>
            <p className={classes.bankName}>{bankName}</p>
            <Input
              errorClassName={classes.error}
              style={{ marginBottom: 10 }}
              labelText={text("templateSettings.labels.taxNumber")}
              value={taxNumber}
              validator={TaxNumberValidator}
              changeHandler={x => setTaxNumber(x)}
              errorMessage={fieldErrorMessage("taxNumber", error)}
              isDisabled={!isNew}
            />
            <Label>{text("templateSettings.labels.purpose")}</Label>
            <Textarea
              errorClassName={classes.error}
              style={{ marginBottom: 20 }}
              value={purpose}
              changeHandler={value => setPurpose(value)}
              onBlur={value => setPurpose((value || '').trim())}
              // warnHolder={text("innerToCustomTransaction.paymentPurposeWarnHolder")}
              placeholder={text("innerToCustomTransaction.paymentPurposeWarnHolder")}
              maxLength={160}
              errorMessage={fieldErrorMessage("purpose", error)}
              isDisabled={!isNew}
            />
          </div>
        )}
        {isExpanded && (
          <div className={classes.column}>
            <Label>{text("templateSettings.expanded.description")}</Label>
            <Textarea
              style={{ marginBottom: 10, height: 152 }}
              value={description}
              changeHandler={x => setDescription(x)}
              placeholder={text("templateSettings.expanded.textareaPlaceholder")}
            />
            <Label>{text("templateSettings.expanded.iconsColor")}</Label>
            <div className={classes.icons} style={{ marginBottom: 10 }}>
              {iconColors.map((color, i) => (
                <div
                  className={cn(classes.iconColor, iconColor === color ? classes.iconColorActive : "")}
                  style={{ backgroundColor: `#${color}` }}
                  onClick={() => setIconColor(color)}
                  key={i}
                />
              ))}
            </div>
            <Label>{text("templateSettings.expanded.templateIcon")}</Label>
            <div className={classes.icons}>
              {iconNames.slice(0, 7).map(name => (
                <div className={cn(classes.icon, iconName === name ? classes.iconActive : "")} onClick={() => setIconName(name)} key={name}>
                  <TemplateIcon name={name} color={`#${iconColor}`} />
                </div>
              ))}
            </div>
            <div className={classes.icons}>
              {iconNames.slice(7, 14).map(name => (
                <div className={cn(classes.icon, iconName === name ? classes.iconActive : "")} onClick={() => setIconName(name)} key={name}>
                  <TemplateIcon name={name} color={`#${iconColor}`} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className={classes.row} style={{ marginTop: 10 }}>
        <Button className={classes.buttonCancel} clickHandler={clickCancelHandler}>
          {text("templateSettings.buttonCancel")}
        </Button>
        <Button className={classes.buttonSave} clickHandler={isNew ? typeOptions.find(x => x.value === type).handler : submitUpdate}>
          {text("templateSettings.buttonSave")}
        </Button>
      </div>
      {!isNew && isExpanded && (
        <div className={classes.row} style={{ marginTop: 10 }}>
          <Button
            style={{
              width: 190,
              height: 44,
              color: "#241F5A",
              fontWeight: "bold",
              textDecoration: "underline"
            }}
            clickHandler={clickDeleteHandler}
          >
            {text("templateSettings.buttonDelete")}
          </Button>
        </div>
      )}
    </div>
  );
};

TemplateSettings.propTypes = {
  isNew: PropTypes.bool,
  initialType: PropTypes.string,
  initialName: PropTypes.string,
  initialGivingContract: PropTypes.string,
  initialGettingContract: PropTypes.string,
  initialGettingAccount: PropTypes.string,
  initialReceiverName: PropTypes.string,
  initialReceiverCode: PropTypes.string,
  initialTaxNumber: PropTypes.string,
  initialPurpose: PropTypes.string,
  initialAmount: PropTypes.string,
  initialDescription: PropTypes.string,
  initialIconColor: PropTypes.string,
  initialIconName: PropTypes.string,
  clickCancelHandler: PropTypes.func,
  clickSaveHandler: PropTypes.func,
  clickDeleteHandler: PropTypes.func
};

TemplateSettings.defaultProps = {
  isNew: false,
  initialType: "INTRABANK_TRANSFER",
  initialName: "",
  initialGivingContract: null,
  initialGettingContract: null,
  initialGettingAccount: "",
  initialReceiverName: "",
  initialReceiverCode: "",
  initialTaxNumber: "",
  initialPurpose: "",
  initialAmount: "",
  initialDescription: "",
  initialIconColor: "7ABD43",
  initialIconName: "domestic",
  clickCancelHandler: () => {},
  clickSaveHandler: () => {},
  clickDeleteHandler: () => {}
};

const mapStateToProps = ({ contracts }) => ({ cards: contracts.cards, accounts: contracts.accounts, credits: contracts.credits, deposits: contracts.deposits });

export default connect(mapStateToProps)(TemplateSettings);
