import React, { useContext, useEffect, useState } from "react";
import classes from "./ContractHistory.module.scss";
import PropTypes from "prop-types";
import moment from "moment";
import { languageContext } from "../../context/index";
import { Button, DailyOperations, Error, HistoryFilter, Icon, Spinner } from "../index";
import serverErrorMessages from "../../helpers/serverErrorMessages";

const ContractHistory = ({ fetchHandler, fetchPDFHandler }) => {
  const { language, text } = useContext(languageContext);

  const [dates, setDates] = useState([moment().format("YYYYMMDD"), moment().format("YYYYMMDD")]);
  // const [dates, setDates] = useState(["20180701", "20180901"]);
  const [isShown, setIsShown] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [operations, setOperations] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const modifiedOperations = operations.reduce((acc, operation) => {
    const accCopy = JSON.parse(JSON.stringify(acc));
    const localDate = date => moment(date).locale(language === "ua" ? "uk" : language);
    const { operationDate, finalizationDate, description, amountInCents: amount, currency, source, destination } = operation;

    const newOperation = {
      time: localDate(operationDate).format("LT"),
      description,
      amount,
      currency,
      creationDate: localDate(operationDate).format("LLL"),
      executionDate: localDate(finalizationDate).format("LLL"),
      sender: source?.name || "-",
      senderAccount: source?.accountNumber || "-",
      receiver: destination?.name || "-",
      receiverAccount: destination?.accountNumber || "-",
    };
    const i = acc.findIndex(day => day.date === localDate(operationDate).format("LL"));
    if (i > -1) {
      accCopy[i].rows = [...accCopy[i].rows, newOperation];
    } else {
      accCopy.push({
        date: localDate(operationDate).format("LL"),
        rows: [newOperation]
      });
    }
    return accCopy;
  }, []);

  const fetchMoreOperations = async () => {
    setIsFetching(true);
    const params = {
      from: dates[0],
      to: dates[1]
    };
    try {
      const res = await Promise.all([
        fetchHandler({ ...params, page: nextPage, per_page: 10 }),
        fetchHandler({ ...params, page: 10 * nextPage + 1, per_page: 1 })
      ]);
      setOperations([...operations, ...res[0].data]);
      setNextPage(res[1].data.length ? nextPage + 1 : null);
    } catch (err) {
      setErrorMessage(serverErrorMessages(err, language));
    }
    setIsFetching(false);
  };

  useEffect(() => {
    (async () => {
      setIsShown(false);
      setIsFetching(true);
      setNextPage(null);
      setErrorMessage(null);
      const params = {
        from: dates[0],
        to: dates[1]
      };
      try {
        const res = await Promise.all([fetchHandler({ ...params, page: 1, per_page: 10 }), fetchHandler({ ...params, page: 11, per_page: 1 })]);
        setOperations(res[0].data);
        if (res[1].data.length) {
          setNextPage(2);
        }
      } catch (err) {
        setOperations([]);
        setErrorMessage(serverErrorMessages(err, language));
      }
      setIsShown(true);
      setIsFetching(false);
    })();
  }, [dates, text, fetchHandler, language]);

  const fetchPDFWithParamsHandler = async () => {
    setIsShown(false);
    setIsFetching(true);
    setNextPage(null);
    setErrorMessage(null);
    const params = {
      from: dates[0],
      to: dates[1]
    };
    try {
      await Promise.all([fetchPDFHandler({ ...params, page: nextPage, per_page: 10 })]);
    } catch (err) {
      setErrorMessage(serverErrorMessages(err, language));
    }
    setIsShown(true);
    setIsFetching(false);
  };

  return (
    <div className={classes.contractHistory}>
      <HistoryFilter dates={dates} changeDatesHandler={value => setDates(value)} typeIsShown={false} handlerPDFClick={fetchPDFWithParamsHandler} />
      {isShown && (
        <>
          {modifiedOperations.map((dailyOperations, i) => (
            <DailyOperations className={classes.dailyOperations} date={dailyOperations.date} rows={dailyOperations.rows} key={i} />
          ))}
          {!modifiedOperations.length && !errorMessage && <div className={classes.warning}>{text("errors.noOperations")}</div>}
        </>
      )}
      {errorMessage && <Error className={classes.error} message={errorMessage} />}
      {isFetching && <Spinner className={classes.spinner} diameter={50} />}
      {!isFetching && nextPage && (
        <Button className={classes.button} clickHandler={fetchMoreOperations}>
          <Icon name="refresh" />
          &nbsp;{text("buttons.showMore")}
        </Button>
      )}
    </div>
  );
};

ContractHistory.propTypes = {
  fetchHandler: PropTypes.func,
  fetchPDFHandler: PropTypes.func
};

ContractHistory.defaultProps = {
  fetchHandler: () => {},
  fetchPDFHandler: () => {}
};

export default React.memo(ContractHistory);
