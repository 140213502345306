import localization from "../localization/index";

export const regularPaymentContractToContractSchema = language => {
  const { required } = localization[language].errors;

  return {
    name: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    givingContract: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    gettingContract: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    amount: [
      {
        validator: "required",
        errorMessage: required
      }
    ]
  };
};

export const regularPaymentIntrabankTransferSchema = language => {
  const { required } = localization[language].errors;

  return {
    name: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    givingContract: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    gettingAccount: [
      {
        validator: "required",
        errorMessage: required
      },
      {
        validator: "cardOrIban",
        errorMessage: required
      }
    ],
    amount: [
      {
        validator: "required",
        errorMessage: required
      }
    ]
  };
};

export const regularPaymentSepTransferSchema = language => {
  const { required, taxNumEmpty, taxNumMin, taxNumRules, purposeNeedDetails } = localization[language].errors;

  return {
    name: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    givingContract: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    gettingAccount: [
      {
        validator: "required",
        errorMessage: required
      },
      {
        validator: "iban",
        errorMessage: required
      }
    ],
    receiverName: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    taxNumber: [
      {
        validator: "taxNumEmpty",
        errorMessage: taxNumEmpty
      },
      {
        validator: "taxNumMin",
        errorMessage: taxNumMin
      },
      {
        validator: "taxNumRules",
        errorMessage: taxNumRules
      }
    ],
    purpose: [
      {
        validator: "required",
        errorMessage: required
      },
      // {
      //   validator: "purposeEmpty",
      //   errorMessage: required
      // },
      {
        validator: "purposeNeedDetails",
        errorMessage: purposeNeedDetails
      }
    ],
    amount: [
      {
        validator: "required",
        errorMessage: required
      }
    ]
  };
};
