import React from "react";
import classes from "./NavigationTabs.module.scss";
import PropTypes from "prop-types";
import cn from "../../../helpers/cn";

const NavigationTabs = ({className, style, tabs, activeTab, changeTabHandler }) => {
  return (
    <div className={cn(classes.tabs, className) } style={style}>
      {tabs.map((tab, i) => (
        <div className={cn(classes.tab, tab.value === activeTab ? classes.tabActive : null)} onClick={() => changeTabHandler(tab.value)} key={i}>
          {tab.label}
        </div>
      ))}
    </div>
  );
};

NavigationTabs.propTypes = {
  classname: PropTypes.string,
  style: PropTypes.object,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  activeTab: PropTypes.string,
  changeTabHandler: PropTypes.func
};

NavigationTabs.defaultProps = {
  classname: "",
  style: {},
  tabs: [],
  activeTab: null,
  changeTabHandler: () => {}
};

export default NavigationTabs;
