import React, { useState } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";
import classes from "./Map.module.scss";
import customCluster from "../../../assets/icons/custom-cluster.svg";
// import PropTypes from "prop-types";
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";

const MapElement = withScriptjs(
  withGoogleMap(props => {
    const [openInfoWindowKey, setOpenInfoWindowKey] = useState(null);

    const locations = props.locations;

    const styleCluster = [
      {
        textColor: "#000",
        url: customCluster,
        backgroundColor: "#fff",
        height: 50,
        width: 50
      }
    ];

    const mapOptions = {
      fullscreenControl: false,
      panControl: false,
      zoomControl: false,
      mapTypeControl: false,
      streetViewControl: false
    };

    return (
      <div className={classes.wrapper}>
        <GoogleMap defaultZoom={8} defaultCenter={{ lat: 50.46262458, lng: 30.45367992 }} defaultOptions={mapOptions}>
          <MarkerClusterer
            onClick={() => {
              console.log("cluster");
            }}
            averageCenter
            enableRetinaIcons
            styles={styleCluster}
          >
            {locations.map((marker, i) => (
              <Marker icon={props.markerIcon} key={i} position={{ lat: +marker.latitude, lng: +marker.longitude }} onClick={() => setOpenInfoWindowKey(i)}>
                {openInfoWindowKey === i && (
                  <InfoWindow onCloseClick={() => setOpenInfoWindowKey(null)}>
                    <div>{marker.address}</div>
                  </InfoWindow>
                )}
              </Marker>
            ))}
          </MarkerClusterer>
        </GoogleMap>
      </div>
    );
  })
);

MapElement.propTypes = {};

MapElement.defaultProps = {};

export default MapElement;
