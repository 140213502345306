import React from "react";
import Input from "../../../../components/inputs/Input/Input.jsx";
import Button from "../../../../components/Button/Button.jsx";
import classes from "./styles.module.scss";
import Icon from "../../../../components/icons/Icon";
import isCurrency from "validator/lib/isCurrency";
import { Divider, Error, Select, Triangle } from "../../../../components";
import { fieldErrorMessage } from "../../../../validation";
import { languageContext } from "../../../../context";

const Custom = ({ as, children, ...props }) => React.createElement(as, { ...(props || {}) }, children);

const modField = {
  counter: "newValue",
  prev_counter: "oldValue"
};

const modTitles = {
  difference: "різниця",
  totalAmount: "разом"
};

const modState = {
  originDebt: "V",
  name: "V",
  difference: "V",
  totalAmount: "V"
};

const Cell = ({ meter, column, as = "td", onChange, isDisabled, index }) => {
  let name = modField[column] || column;
  let state = meter[`${column}_state`];
  let columnTitle = meter[`${column}_name`] || modTitles[column];
  let value = meter[name];

  if ((state || modState[column] || "N") === "N") {
    return null;
  }

  if (column === "debt") {
    if (!["V", "E"].includes(meter[`debt_state`])) {
      return null;
    }
    if (meter["originDebt"] > 0) {
      columnTitle = "з нього списати";
    } else {
      columnTitle = "врахувати переплату";
    }
    if (value === undefined) {
      value = "0.00";
    }
  }

  // if (column === 'debt') {
  //   value = Math.abs(value || 0).toFixed(2);
  // }
  if (column === "originDebt") {
    columnTitle = meter[`debt_name`];
    value = Math.abs(value || 0).toFixed(2);
  }

  if (column === "difference") {
    if (meter["calculated"] !== "Y") {
      return null;
    }
    if (!(["V", "E"].includes(meter[`counter_state`]) && ["V", "E"].includes(meter[`prev_counter_state`]))) {
      return null;
    }
  }

  if (column === "totalAmount") {
    if (meter["calculated"] !== "Y") {
      return null;
    }
    if (!["V", "E"].includes(meter[`amount_state`])) {
      return null;
    }
    if (value === undefined) {
      value = "0.00";
    }
  }

  state = modState[column] || state;

  return (
    <Custom as={as} name={[state, name].join("-")}>
      {as === "td" ? (
        state === "V" ? (
          <span title={value}>{value}</span>
        ) : (
          <Input
            changeHandler={value => onChange(name, value, index)}
            commaIsReplacedWithDot
            validator={value =>
              isCurrency(value, {
                digits_after_decimal: [0, 1, 2]
              })
            }
            value={value}
            isDisabled={isDisabled}
          />
        )
      ) : (
        columnTitle
      )}
    </Custom>
  );
};

const Meter = ({ meter, onChange, isDisabled, index }) => {
  /*
'name',
'sectionId',
'code',
'sub_code',
'pay_different_amount',
'calculated',
'negative_amount',

'amount', // сплачую
'amount_name',
'amount_state',

'oldValue',
'prev_counter_name',
'prev_counter_state',

'debt', // переплата
'debt_name',
'debt_state',

'attribute1', // нараховано
'attribute1_name',
'attribute1_state',

'newValue',
'counter_name'
'counter_state',
'counter_accuracy',

'subsidy', // субсидия
'subsidy_name',
'subsidy_state',

'attribute3_state',

'tariff',
'tariff_name',
'tariff_state',

'attribute2_state',

  * */

  /*
  subsidy, overpayment, include overpayment, charged, pay, total
  tariff, current, previous, difference, serial number
  tariff, current, serial number
  
  tariff, current, previous, difference, subsidy, overpayment, include overpayment, serial number, pay, total
  */

  const columns = ["name", "tariff", "counter", "prev_counter", "difference", "subsidy", "originDebt", "debt", "attribute1", "amount", "totalAmount"];

  return (
    <table style={{ width: "100%" }}>
      <thead>
        <tr>
          {columns.map(column => (
            <Cell key={`${meter.sectionId}-${column}-${index}`} index={index} meter={meter} column={column} as="th" isDisabled={isDisabled} />
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {columns.map(column => (
            <Cell
              key={`${meter.sectionId}-${column}-${index}`}
              index={index}
              meter={meter}
              column={column}
              as="td"
              onChange={onChange}
              isDisabled={isDisabled}
            />
          ))}
        </tr>
      </tbody>
    </table>
  );
};

const Meters = ({ payload, sectionId, onChange, isDisabled }) => {
  return payload.destination.meters.map((meter, index) =>
    meter.sectionId === sectionId ? (
      <Meter meter={meter} key={`${meter.sectionId}-${index}-${meter.code}-${meter.sub_code}`} index={index} onChange={onChange} isDisabled={isDisabled} />
    ) : null
  );
};

const Title = ({ map, setOpen, open }) => {
  return (
    <div className={classes.title}>
      <Icon name={"paymentsUtilities"} />
      <span className={classes.name}>{map["payee.name"] || "title of section"}</span>
      <span className={classes.invoice}>{map["billed_amount_old"]}</span>
      <span className={classes.paid}>{map["billed_amount"]}</span>
      <span className={classes.control} onClick={() => setOpen(!open)}>
        {open ? "Згорнути" : "Розгорнути"}
      </span>
    </div>
  );
};

const Quote = ({ map }) => {
  const lines = React.useMemo(
    () =>
      [
        map["description"],
        [
          [map["contractNumber.title"], map["contractNumber.number"]].filter(item => item).join(": "),
          [map["attribute1.title"], map["attribute1.value"]].filter(item => item).join(": ")
        ]
          .filter(item => item)
          .join(", "),
        [
          [map["date"] && "дата рахунку", map["date"]].filter(item => item).join(": "),
          [map["billed_amount_old"] && "сума рахунку", map["billed_amount_old"]].filter(item => item).join(": "),
          [map["dept_amount"] && "борг", map["dept_amount"]].filter(item => item).join(": ")
        ]
          .filter(item => item)
          .join(", ")
      ].filter(item => item),
    [map]
  );

  return (
    <div className={classes.quote}>
      {lines.map(line => (
        <div key={line}>{line}</div>
      ))}
    </div>
  );
};

const Section = ({ payload, sectionId, onChange, isDisabled }) => {
  const [open, setOpen] = React.useState(true);
  const map = React.useMemo(() => {
    let result = {};
    payload.destination.billDetails
      .filter(item => item.sectionId === sectionId)
      .forEach(item => {
        result[item.name] = item.value;
        result[`${item.name}_old`] = item.oldValue;
      });
    return result;
  }, [payload, sectionId]);

  return (
    <div className={classes.section}>
      <Title map={map} setOpen={setOpen} open={open} />
      {open && (
        <>
          <Quote map={map} />
          <Meters payload={payload} sectionId={sectionId} onChange={onChange} isDisabled={isDisabled} />
        </>
      )}
    </div>
  );
};

const Sections = ({ payload, onChange, isDisabled }) => {
  return (
    <>
      <div className={classes.header}>
        <span className={classes.name}>Послуги</span>
        <span className={classes.invoice}>Нараховано</span>
        <span className={classes.paid}>Сплачую</span>
        <span className={classes.control}>&nbsp;</span>
      </div>
      {payload.destination.billSections.map(({ id }) => (
        <Section key={id} payload={payload} sectionId={id} onChange={onChange} isDisabled={isDisabled} />
      ))}
    </>
  );
};

const MetersStep = ({ payload, onChange, contracts, contract, setContract, onSubmit, step, error, commission }) => {
  const { text } = React.useContext(languageContext);
  const globalErrorMessage = fieldErrorMessage("global", error);

  return (
    <>
      {globalErrorMessage && (
        <div className={classes.wrapper}>
          <div className={classes.content}>
            <Error className={classes.globalError} message={globalErrorMessage} />
          </div>
        </div>
      )}

      <Sections payload={payload} onChange={onChange} step={step} isDisabled={step === "execute"} />

      <div className={classes.wrapper}>
        <div className={classes.content}>
          <div className={classes.total}>
            <span>Всього до сплати: </span>
            {payload.amount} UAH
            {commission && (
              <div>
                <span>Комісія: </span>
                {commission}
              </div>
            )}
          </div>

          <Divider />
          <Triangle style={{ margin: "auto" }} />

          <Select
            errorClassName={classes.error}
            style={{ marginBottom: 20 }}
            labelText={text("outerToInnerTransaction.labels.fromMyAccount")}
            options={contracts}
            value={contract}
            changeHandler={setContract}
            placeholder={text("innerToInnerTransaction.select.placeholder")}
            errorMessage={fieldErrorMessage("contract", error)}
            isDisabled={step === "execute"}
          />

          <div className={classes.next}>
            <Button
              style={{
                width: 200,
                height: 48,
                color: "white",
                backgroundColor: "#241F5A",
                borderColor: "#241F5A",
                fontWeight: "bold"
              }}
              clickHandler={() => onSubmit(payload)}
            >
              Продовжити &rarr;
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MetersStep;
