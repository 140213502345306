import React, { useContext} from "react";
import classes from "./ReceiptSend.module.scss";
import PropTypes from "prop-types";
import { Receipt, Title } from "../../index";
import { languageContext } from "../../../context/index";

const ReceiptSend = ({operationId}) => {
    const { text } = useContext(languageContext);
    return (
        <>
            <Title text={text("regularPayments.paymentHistory.sendReceipt")} />
            <div className={classes.receiptWrapper}>
                <Receipt operationId={operationId}/>
            </div>
        </>
    )
}

ReceiptSend.propTypes = {
    operationId: PropTypes.string,
}
export default ReceiptSend