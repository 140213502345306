import React from "react";
import classes from "./RegularPaymentsHistory.module.scss";
import { DetailedRegularPayment, Icon, MonthPicker, ReceiptSend, Spinner, Title } from "../../../../components/index";
import { languageContext, modalContext } from "../../../../context/index";
import * as API from "../../../../api/index";
import all from "../../../../helpers/all";
import moment from "moment";
import localeAmount from "../../../../helpers/localeAmount";
import { maxDate, minDate } from "../../../../helpers/zaebali";

const RegularPaymentsHistory = ({ location: { pathname } }) => {
  const { setModal } = React.useContext(modalContext);
  const id = pathname.split("/")[3];
  const { language, text } = React.useContext(languageContext);
  const [isOpen, setIsOpen] = React.useState(false);
  const [historyRegularPayments, setHistoryRegularPayments] = React.useState([]);
  const [operationId, setOperationId] = React.useState("");
  const [status, setStatus] = React.useState(null);
  const [date, setDate] = React.useState(new Date().getTime());
  let localDate = date => moment(date).locale(language === "ua" ? "uk" : language);

  React.useEffect(() => {
    const temp = new Date(date);
    const params = {
      from:
        temp.getFullYear().toString() +
        (temp.getMonth() + 1).toString().padStart(2, "0") +
        new Date(temp.getFullYear(), temp.getMonth(), 1)
          .getDate()
          .toString()
          .padStart(2, "0"),
      to:
        temp.getFullYear().toString() +
        (temp.getMonth() + 1).toString().padStart(2, "0") +
        new Date(temp.getFullYear(), temp.getMonth() + 1, 0)
          .getDate()
          .toString()
          .padStart(2, "0"),
      externalId: id
    };
    API.getFinancialHistory(params).then(result => {
      const { historyItems } = result.data;
      setHistoryRegularPayments(historyItems);
      setStatus(result.status);
    });
  }, [date, id]);

  const openDropDownHandler = id => {
    setOperationId(id);
    setIsOpen(!isOpen);
    if (operationId.length !== 0 && id !== operationId) {
      setIsOpen(isOpen => !isOpen);
    }
    if (isOpen === false) {
      setIsOpen(true);
    }
  };

  const changeHandler = date => {
    if (date > maxDate) {
      setDate(maxDate);
    } else if (date < minDate) {
      setDate(minDate);
    } else {
      setDate(date);
    }
  };

  return (
    <div className={classes.history}>
      <div className={classes.top}>
        <Title text={text("regularPayments.paymentHistory.title")} />
      </div>
      <div className={classes.bottom}>
        <div style={{ marginLeft: "25px" }}>
          <MonthPicker
            labelText={text("regularPayments.paymentHistory.period")}
            date={date}
            minDate={minDate}
            maxDate={maxDate}
            changeHandler={changeHandler}
          />
        </div>

        {historyRegularPayments.length === 0 && status === 200 && (
          <div className={classes.warning}>{text("regularPayments.paymentHistory.noPaymentsHistory")}</div>
        )}
        {historyRegularPayments.length === 0 && status !== 200 && <Spinner className={classes.spinner} diameter={50} />}

        <div className={classes.content}>
          {historyRegularPayments.map(payment => (
            <div key={payment.operation.id}>
              <div className={classes.date} key={payment.operation.id}>
                {localDate(payment.operation.createDate).format("LL")}
              </div>
              <div className={classes.row}>
                <div className={classes.time}>{localDate(payment.operation.executeData).format("LT")}</div>

                <Icon className={classes.icon} name="income" />
                {payment.operation.status === "SUCCESS" ? (
                  <Icon name="successSmallIcon" className={classes.smallIcon} />
                ) : (
                  <Icon name="warningSmallIcon" className={classes.smallIcon} />
                )}

                <div className={classes.purpose}>{payment.operation.purpose}</div>
                <div className={classes.amount}>
                  {localeAmount(payment.operation.amount)} {payment.operation.currency}
                </div>
                <div className={classes.iconWrapper} onClick={() => openDropDownHandler(payment.operation.id)}>
                  <Icon style={{ ...(isOpen && operationId === payment.operation.id ? { transform: "rotate(180deg)" } : {}) }} name="selectArrow" />
                </div>
              </div>
              {isOpen && operationId === payment.operation.id && (
                <div className={classes.dropDownWrapper}>
                  <div
                    className={classes.dropdownOption}
                    onClick={() =>
                      all(
                        setModal(<ReceiptSend operationId={payment.operation.id} />),
                        setIsOpen(isOpen => !isOpen)
                      )
                    }
                  >
                    {text("regularPayments.paymentHistory.sendReceipt")}
                  </div>
                  <div
                    className={classes.dropdownLastOption}
                    onClick={() =>
                      all(
                        setModal(<DetailedRegularPayment regularPayment={payment.operation} isOperation />),
                        setIsOpen(isOpen => !isOpen)
                      )
                    }
                  >
                    {text("regularPayments.paymentHistory.detailsOperation")}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RegularPaymentsHistory;
