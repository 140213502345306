import React, { useContext } from "react";
import classes from "./TransactionOptions.module.scss";
import Title from "../../../../components/Title/Title.jsx";
import Icon from "../../../../components/icons/Icon.jsx";
import { languageContext, modalContext } from "../../../../context/index";
import { connect } from "react-redux";
import { accountOptions, cardOptions } from "../../../../store/getters";
import OperationResult from "../../../../components/modals/OperationResult/OperationResult";

const TransactionOptions = ({ cards, accounts, history }) => {
  const { text } = useContext(languageContext);
  const { setModal } = React.useContext(modalContext);
  const contractAll = React.useMemo(() => [...cardOptions(cards), ...accountOptions(accounts, text)], [accounts, cards, text]);
  const currencies = React.useMemo(() => [...new Set([...contractAll].map(item => item.currency))], [contractAll]);

  const options = React.useMemo(
    () => [
      {
        iconName: "transactionInnerToInner",
        text: text("transactionOptions.innerToInner"),
        path: "/home/transactions/inner-to-inner"
      },
      {
        iconName: "transactionInnerToOuter",
        text: text("transactionOptions.innerToOuter"),
        path: "/home/transactions/inner-to-outer"
      },
      {
        iconName: "transactionOuterToInner",
        text: text("transactionOptions.outerToInner"),
        path: "/home/transactions/outer-to-inner"
      },
      {
        iconName: "transactionOuterToOuter",
        text: text("transactionOptions.outerToOuter"),
        path: "/home/transactions/outer-to-outer"
      },
      {
        iconName: "transactionInnerToCustom",
        text: text("transactionOptions.innerToCustom"),
        path: "/home/transactions/inner-to-custom"
      },
      {
        iconName: "transactionExchange",
        text: text("transactionOptions.exchange"),
        path: "/home/transactions/exchange",
        height: "49px",
        onClick: currencies.includes("UAH")
          ? null
          : () => {
              setModal(
                <OperationResult
                  result="failure"
                  titleText={text("exchangeTransaction.noUAH")}
                  buttonText={text("buttons.close")}
                  clickCompleteHandler={() => setModal(null)}
                />
              );
            }
      }
    ],
    [currencies, text, setModal]
  );

  return (
    <div className={classes.wrapper}>
      <Title text={text("transactionOptions.title")} />
      <div className={classes.options}>
        {options.map((option, i) => (
          <div
            className={classes.option}
            onClick={event => {
              event.preventDefault();
              if (option.onClick) {
                option.onClick();
              } else {
                history.push(option.path);
              }
            }}
            key={i}
          >
            <div className={classes.iconWrapper}>
              <Icon name={option.iconName} style={{ height: option.height }} />
            </div>
            <div className={classes.text}>{option.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default connect(({ contracts }) => contracts, null)(TransactionOptions);
