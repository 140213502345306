import React from "react";
import classes from "./Category.module.scss";
import * as API from "../../../../api/index";
import { Icon, MonthPicker, Title } from "../../../../components/index";
import localeAmount from "../../../../helpers/localeAmount";
import moment from "moment";
import { languageContext, modalContext } from "../../../../context/index";
import ChangeCategoryModal from "./ChangeCategoryModal/ChangeCategoryModal";
import { maxDate, minDate } from "../../../../helpers/zaebali";

const Category = ({
  history,
  location: {
    pathname,
    state: { dateFrom, dateTill }
  }
}) => {
  const { language, text } = React.useContext(languageContext);
  const { modal, setModal } = React.useContext(modalContext);
  const iconURL = "/bank/category-icon/svg/";
  const categoryId = pathname.split("/")[3];
  const [sort, _setSort] = React.useState("");

  const setSort = React.useCallback(() => {
    if (sort === "asc") {
      _setSort("dsc");
    } else if (sort === "dsc") {
      _setSort("");
    } else if (sort === "") {
      _setSort("asc");
    }
  }, [sort, _setSort]);

  const [categories, setCategories] = React.useState([]);
  const [operations, setOperations] = React.useState([]);
  const categoryParams = React.useMemo(
    () => ({
      fromYear: new Date(dateFrom).getFullYear(),
      fromMonth: new Date(dateFrom).getMonth() + 1,
      toYear: new Date(dateTill).getFullYear(),
      toMonth: new Date(dateTill).getMonth() + 1,
      sortBy: sort === "" ? undefined : "amount",
      sortOrder: sort === "" ? undefined : sort
    }),
    [sort, dateFrom, dateTill]
  );

  React.useEffect(() => {
    if (!modal) {
      API.getStatisticByCategoryId(categoryId, categoryParams) //
        .then(({ data: { categoriesStatistic } }) => {
          setCategories(categoriesStatistic);
        });

      setOperations([]);
      API.getOperationsByCategoryId(categoryId, categoryParams) //
        .then(({ data }) => {
          setOperations(data);
        });
    }
  }, [modal, categoryId, categoryParams]);

  const translation = categoryLocale => {
    const { locale, translation } = categoryLocale;
    return locale === language || (locale === "uk" && language === "ua") ? (
      <div className={classes.categoryName} key={locale}>
        <span>{translation}:</span>
      </div>
    ) : null;
  };

  return (
    <div className={classes.category}>
      <div className={classes.top}>
        <div className={classes.goBackBlock} onClick={() => history.push({ pathname: "/home/financial-assistant", state: categoryParams })}>
          <Icon className={classes.icon} name="arrowBack" />
          <Title className={classes.title} text={text("financialAssistant.category.backToStatistic")} />
        </div>
      </div>
      <div className={classes.bottom}>
        <div className={classes.inputMonthBlock}>
          <div style={{ marginLeft: "25px" }}>
            <MonthPicker labelText={text("financialAssistant.startPeriod")} date={dateFrom} minDate={minDate} maxDate={maxDate} isDisabled />
          </div>
          <div style={{ marginLeft: "25px" }}>
            <MonthPicker labelText={text("financialAssistant.finishPeriod")} date={dateTill} minDate={minDate} maxDate={maxDate} isDisabled />
          </div>
        </div>
        <div className={classes.statisticWrapper}>
          {categories.map(item => (
            <div className={classes.statisticBlock} key={item.category.categoryId}>
              <img className={classes.categoryIcon} src={`${iconURL}/${item.category.categoryIcon}.svg`} alt="icon" />
              {item.category.categoryLocalization.map(categoryLocale => translation(categoryLocale))}
              <div className={classes.localAmount}>
                <span>{localeAmount(item.agregatedLocalAmount)}</span>
                <span className={classes.currency}>UAH</span>
              </div>
            </div>
          ))}
        </div>
        <div className={classes.operationBlock}>
          <span className={classes.operationDate} />
          <span className={classes.operationIcon} />
          <span className={classes.description} />
          <span className={classes.operationAmount}>
            <Icon className={classes.pointsIcon} name={sort || "uns"} clickHandler={setSort} />
          </span>
          <span className={classes.pointsIcon} />
        </div>
        <div className={classes.operationsWrapper}>
          {operations.map(item => (
            <div className={classes.operationBlock} key={item.id}>
              <span className={classes.operationDate}>{moment(item.finalizationDate).format("DD.MM.YYYY")}</span>
              <img className={classes.operationImage} src={`${iconURL}/${item.category.categoryIcon}.svg`} alt="icon" />
              <span className={classes.description}>{item.description}</span>
              <div className={classes.operationAmount}>
                <span>{localeAmount(item.localAmountInCents)}</span>
                <span className={classes.operationCurrency}>UAH</span>
              </div>
              <Icon
                className={classes.pointsIcon}
                name="pointsIcon"
                clickHandler={() =>
                  setModal(
                    <ChangeCategoryModal operationId={item.externalId} categoryId={item.category.categoryId} categoryType={item.category.categoryType} />
                  )
                }
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Category;
