import React from 'react';
import { Route, Switch, useParams, useHistory, generatePath } from "react-router-dom";
import { languageContext } from "../../../context";
import Icon from "../../../components/icons/Icon";
import Title from "../../../components/Title/Title";
import Input from "../../../components/inputs/Input/Input";
import { Spinner } from "../../../components";
import PaymentsContext from "./PaymentsContext";
import {
  urlBiller,
  urlCategories,
  urlCategory, urlDefaultIcon,
  urlSearchInCategories,
  urlSearchInCategory
} from "./PaymentsUtils";
import classes from "./PaymentsCategories.module.scss";
import * as icons from "../../../components/icons/icons";

const Categories = () => {
  const { push } = useHistory();
  const { categories } = React.useContext(PaymentsContext);

  return (
    <div className={classes.categoriesWrapper}>
      {categories && categories.map(category => (
        <div
          key={category.id}
          className={classes.category}
          onClick={() =>
            push(generatePath(urlCategory, { categoryId: category.id }))
          }
        >
          <Icon className={classes.icon} name={category.iconName} />
          {category.title}
        </div>)
      )}
    </div>
  );
};

const BillerIcon = React.memo(({url}) => {
  const [error, setError] = React.useState(false);
  return (
      <img
          className={classes.billerIcon}
          src={error ? urlDefaultIcon : url}
          alt="icon"
          onError={(event) => {
            setError(true)
          }}
      />
  );
});

const Biller = ({biller}) => {
  const {push} = useHistory();
  return (
      <div
          className={classes.biller}
          onClick={() =>
              push(generatePath(urlBiller, {categoryId: biller.categoryId, billerId: biller.id }))
      }
    >
      <div className={classes.imageWrapper}>
        <BillerIcon url={biller.iconRef || icons.defaultBillerIcon} />
      </div>
      <span className={classes.billerTitle}>
        {biller.title}
      </span>
    </div>
  );
}

const Category = () => {
  const { categoryId } = useParams();
  const { billers, getBillers } = React.useContext(PaymentsContext);
  
  React.useEffect(() => {
    getBillers({ categoryId });
  }, [categoryId, getBillers]);

  return (
    <div className={classes.billerWrapper}>
      {billers === null && (
        <Spinner className={classes.spinner} diameter={50} />
      )}
      {billers && billers.map((biller) => (
          <Biller biller={biller} key={biller?.id} />
      ))}
    </div>
  );
};

const Search = () => {
  const { categoryId, search } = useParams();
  const { billers, getBillers } = React.useContext(PaymentsContext);
  
  React.useEffect(() => {
    getBillers({ categoryId, search });
  }, [categoryId, search, getBillers]);

  return (
    <div className={classes.billerWrapper}>
      {billers === null && (
        <Spinner className={classes.spinner} diameter={50} />
      )}
      {billers && billers.map((biller) => (
          <Biller biller={biller} key={biller?.id} />
      ))}
    </div>
  );
};

const PaymentsCategories = () => {
  const { push } = useHistory();
  const { categoryId, search } = useParams();
  const { text } = React.useContext(languageContext);
  const { title, setTitle, categories, getCategories } = React.useContext(PaymentsContext);
  const [searchValue, setSearchValue] = React.useState(search);
  
  React.useEffect(() => {
    if (!categories) {
      getCategories();
    }
    setTitle(
      categoryId
        ? categories?.find(category => category.id === categoryId)?.title
        : text("payments.title")
    );
  }, [categories, categoryId, getCategories, setTitle, text]);
  
  const handleChangeSearchBillers = e => {
    if (e.keyCode === 13) {
      // setSearchedBillers(
      //   allBillers.filter(biller => biller.title.toLowerCase().includes(searchValue.toLowerCase().trim()))
      // );
      if (searchValue) {
        if (categoryId) {
          push(generatePath(urlSearchInCategory, { categoryId, search: searchValue }));
        } else {
          push(generatePath(urlSearchInCategories, { search: searchValue }));
        }
      } else {
        if (categoryId) {
          push(generatePath(urlCategory, { categoryId }));
        } else {
          push(urlCategories);
        }
      }
    }
  };
  
  return (
    <>
      <div className={classes.form}>
        <div className={classes.row}>
          <Title text={title} />
        </div>
        <div className={classes.text}>
          {text("payments.inputLable")}
        </div>
        <div className={classes.searches}>
          <Input
            style={{ width: '100%', border: "none" }}
            value={searchValue}
            changeHandler={setSearchValue}
            keyEventHandler={handleChangeSearchBillers}
            placeholder={text("payments.placeholder")}
            isSearch
          />
        </div>
      </div>
      {categories === null && (
        <Spinner className={classes.spinner} diameter={50} />
      )}
      {categories !== null && (
        <Switch>
          <Route path={urlSearchInCategory} component={Search} />
          <Route path={urlSearchInCategories} component={Search} />
          <Route path={urlCategory} component={Category} />
          <Route path={urlCategories} component={Categories} />
        </Switch>
      )}
    </>
  );
};

export default PaymentsCategories;
