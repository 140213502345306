import React, { useContext } from "react";
import classes from "./NavigationHorizontal.module.scss";
import PropTypes from "prop-types";
import * as icons from "../../icons/icons";
import NavigationLink from "../NavigationLink/NavigationLink.jsx";
import Logo from "../../Logo/Logo.jsx";
import { languageContext } from "../../../context/index";

const NavigationHorizontal = ({ clickLogoHandler, clickLogoutHandler }) => {
  const { text } = useContext(languageContext);

  const links = [
    {
      path: "/home/cards",
      icon: icons.cards,
      iconActive: icons.cardsActive,
      text: text("navigationHorizontal.cards")
    },
    {
      path: "/home/accounts",
      icon: icons.accounts,
      iconActive: icons.accountsActive,
      text: text("navigationHorizontal.accounts")
    },
    {
      path: "/home/credits",
      icon: icons.credits,
      iconActive: icons.creditsActive,
      text: text("navigationHorizontal.credits")
    },
    {
      path: "/home/deposits",
      icon: icons.deposits,
      iconActive: icons.depositsActive,
      text: text("navigationHorizontal.deposits")
    }
  ];

  return (
    <div className={classes.wrapper}>
      <div className={classes.logo}>
        <Logo clickHandler={clickLogoHandler} />
        {/*<div>*/}
        {/*  build: {process.env.REACT_APP_COMMIT_COUNT}*/}
        {/*  <br />*/}
        {/*  {process.env.REACT_APP_COMMIT_HASH ? `[${process.env.REACT_APP_COMMIT_HASH}]` : ""}*/}
        {/*</div>*/}
      </div>
      <div>
        <div className={classes.links}>
          {links.map((link, i) => (
            <NavigationLink path={link.path} icon={link.icon} iconActive={link.iconActive} text={link.text} key={i} />
          ))}
        </div>
        <div className={classes.logout} onClick={clickLogoutHandler}>
          <img src={icons.logout} alt="" />
        </div>
      </div>
    </div>
  );
};

NavigationHorizontal.propTypes = {
  clickLogoutHandler: PropTypes.func
};

NavigationHorizontal.defaultProps = {
  clickLogoutHandler: () => {}
};

export default NavigationHorizontal;
