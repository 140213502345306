import React, { useState, useEffect, useContext, useMemo } from "react";
import classes from "./RegularPayments.module.scss";
import * as API from "../../../api/index";
import { languageContext, modalContext } from "../../../context/index";
import {
  Title,
  Input,
  Spinner,
  RegularPaymentTile,
  RegularPayment,
  DeleteRegularPayment,
  DetailedRegularPayment,
  DeactivateRegularPayment,
  ActivateRegularPayment
} from "../../../components/index";
import localeAmount from "../../../helpers/localeAmount";
import { genContractKey } from "../../../helpers/contract";
import { defPurposeValidator } from "../../../helpers/purpose";

const RegularPayments = ({ history }) => {
  const { setModal } = useContext(modalContext);
  const { text } = useContext(languageContext);
  const [regularPayments, setRegularPayments] = useState([]);
  const [filter, setFilter] = useState("");
  const [isEdit] = useState(true);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    API.getAutopayments().then(result => {
      setRegularPayments(result.data.items);
      setStatus(result.status);
    });
  }, []);

  const filteredPayments = useMemo(() => {
    return filter
      ? regularPayments.filter(regularPayment => {
          const modifiedFilter = filter.toLowerCase();
          const { title = "" } = regularPayment.autopayment;
          const modifiedName = title.toLowerCase();
          //   const modifiedDescription = description.toLowerCase();
          return modifiedName.includes(modifiedFilter);
        })
      : regularPayments;
  }, [filter, regularPayments]);

  const getStatusAfterRegulatPaymentOperation = value => {
    if (value === "SUCCESS") {
      API.getAutopayments().then(result => {
        const { items } = result.data;
        // console.log(items, 'items-autopayments');
        setRegularPayments(items);
        setStatus(result.status);
      });
    }
  };

  const noRegularPayments = () => {
    if (filter === "" && regularPayments.length === 0) {
      return status === 200 && regularPayments.length === 0 ? (
        <div className={classes.warning}>{text("regularPayments.noPayments")}</div>
      ) : (
        <Spinner diameter={50} />
      );
    } else if (filter !== "" && regularPayments.length !== 0) {
      setTimeout(() => {
        setFilter("");
      }, 6000);
      return <div className={classes.warning}>{text("regularPayments.noPaymentsBySearch")}</div>;
    }
  };
  //AGSUPP-247(Urii)
  return (
    <div className={classes.wrapper}>
      <div className={classes.row}>
        <Input style={{ width: "100%" }} value={filter} changeHandler={value => setFilter(value)} placeholder={text("regularPayments.placeholder")} isSearch />
        {/*      <Button className={classes.addPaymentButton}*/}
        {/*          clickHandler={() =>*/}
        {/*              setModal(<RegularPayment*/}
        {/*                  initialType="INTRABANK_TRANSFER"*/}
        {/*                  isNew*/}
        {/*                  statuRegularPaymentRequest={getStatusAfterRegulatPaymentOperation}*/}
        {/*                  clickCancelHandler={() => setModal(null)}*/}
        {/*              />)}*/}
        {/*      >*/}
        {/*          <Icon name="plus" />*/}
        {/*&nbsp;&nbsp;{text("regularPayments.addPaymentButton")}*/}
        {/*      </Button>*/}
      </div>
      <div className={classes.content}>
        <div className={classes.categories}>
          <div className={classes.category}>
            <Title className={classes.title} text={text("regularPayments.title")} />
            {!!filteredPayments.length ? (
              <div className={classes.payments}>
                {filteredPayments.map(payment => (
                  <div className={classes.templateTileWrapper} key={payment.autopayment.extId}>
                    <RegularPaymentTile
                      // regularPayment = {payment.autopayment}
                      title={payment.autopayment.title}
                      amount={payment.autopayment.amountOfPayment.toString()}
                      currency={payment.autopayment.operation.currency}
                      status={payment.autopayment.autopaymentStatus}
                      isValid={defPurposeValidator(payment.autopayment.operation && payment.autopayment.operation.purpose)}
                      clickDeleteHandler={() =>
                        setModal(<DeleteRegularPayment regularPayment={payment} statuRegularPaymentRequest={getStatusAfterRegulatPaymentOperation} />)
                      }
                      clickDetailedPaymentHandler={() => setModal(<DetailedRegularPayment regularPayment={payment} />)}
                      clickDeactivatePaymentHandler={() =>
                        setModal(<DeactivateRegularPayment regularPayment={payment} statuRegularPaymentRequest={getStatusAfterRegulatPaymentOperation} />)
                      }
                      clickActivatePaymentHandler={() =>
                        setModal(<ActivateRegularPayment regularPayment={payment} statuRegularPaymentRequest={getStatusAfterRegulatPaymentOperation} />)
                      }
                      clickGoToHistoryHandler={() => history.push(`/home/regular-payments/${payment.autopayment.extId}`)}
                      clickSettingsHandler={() =>
                        setModal(
                          <RegularPayment
                            isEdit={isEdit}
                            regularPayment={payment}
                            initialType={payment.type}
                            initialName={payment.autopayment.title}
                            initialGivingContract={genContractKey(
                              payment.autopayment.operation.srcContractRef.providerId,
                              payment.autopayment.operation.srcContractRef.contractId,
                              payment.autopayment.cardId || payment.autopayment.srcCardId
                            )}
                            initialGettingContract={
                              payment.autopayment.operation.destContractRef &&
                              genContractKey(
                                payment.autopayment.operation.destContractRef.providerId,
                                payment.autopayment.operation.destContractRef.contractId,
                                payment.autopayment.dstCardId || payment.autopayment.destCardId
                              )
                            }
                            initialGettingAccount={
                              payment.autopayment.operation.destination &&
                              (payment.autopayment.operation.destination.identifier || payment.autopayment.operation.destination.accountNumber)
                            }
                            initialReceiverName={payment.autopayment.operation.destination && payment.autopayment.operation.destination.name}
                            initialCurrency={payment.autopayment.operation && payment.autopayment.operation.currency}
                            initialTaxNumber={payment.autopayment.operation.destination && payment.autopayment.operation.destination.taxId}
                            initialPurpose={payment.autopayment.operation && payment.autopayment.operation.purpose}
                            initialAmount={localeAmount(payment.autopayment.amountOfPayment)}
                            initialIntervalValue={payment.autopayment.intervalValue}
                            initialIntervalOfPayment={payment.autopayment.intervalOfPayment}
                            initialStartData={payment.autopayment.startDateOfPayment}
                            initialFinishData={payment.autopayment.finalDateOfPayment}
                            extId={payment.autopayment.extId}
                            externalOperationId={payment.autopayment.externalOperationId}
                            clickCancelHandler={() => setModal(null)}
                            statuRegularPaymentRequest={getStatusAfterRegulatPaymentOperation}
                          />
                        )
                      }
                    />
                  </div>
                ))}
              </div>
            ) : (
              noRegularPayments()
              // <div className={classes.warning}>{text("regularPayments.noPayments")}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegularPayments;
