import React, { useEffect, useState } from "react";
import classes from "./Input.module.scss";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";
import { Label, Icon, Error } from "../../index";
import cn from "../../../helpers/cn";
import all from "../../../helpers/all";

const Input = ({
  name,
  labelClassName,
  className,
  errorClassName,
  labelStyle,
  style,
  errorStyle,
  labelText,
  type,
  value,
  changeHandler,
  keyEventHandler,
  placeholder,
  units,
  mask,
  commaIsReplacedWithDot,
  validator,
  isSearch,
  isDisabled,
  errorMessage,
  maxLength,
  preChange,
  up,
  down,
  upDown,
  readOnly,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [passwordIsShown, setPasswordIsShown] = useState(false);
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = initialValue => {
    let value = preChange(initialValue);

    if (commaIsReplacedWithDot) {
      value = initialValue.replace(",", ".");
    }

    if (value && !validator(value)) return;
    setInputValue(value);
    changeHandler(value);
  };
  return (
    <>
      {labelText && (
        <Label className={labelClassName} style={{ ...labelStyle, ...(errorMessage ? { color: "#ff7a7c" } : {}) }}>
          {labelText}
        </Label>
      )}
      <div className={cn(classes.input, isFocused ? classes.inputFocused : "", errorMessage ? classes.error : "", className)} style={style}>
        {isSearch && <Icon style={{ paddingLeft: 10 }} name="magnifyingGlass" />}
        <InputMask
          type={!passwordIsShown ? type : "text"}
          mask={mask ? mask : null}
          value={inputValue}
          onChange={e => handleChange(e.target.value)}
          onFocus={() =>
            all(
              () => setIsFocused(true),
              () => setInputValue(name === "amount" && /\s/.test(inputValue) ? inputValue.split(" ").join("") : inputValue)
            )
          }
          onBlur={() =>
            all(
              () => setIsFocused(false),
              () => setInputValue(name === "amount" ? inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ") : inputValue)
            )
          }
          onKeyDown={keyEventHandler}
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={isDisabled}
          readOnly={readOnly}
          {...props}
        />
        {type === "password" && placeholder !== "***" && (
          <div className={classes.eyeIcon} onClick={() => setPasswordIsShown(!passwordIsShown)}>
            <Icon name={passwordIsShown ? "eyeOpened" : "eyeClosed"} />
          </div>
        )}
        {units && <div className={classes.units}>{units}</div>}
        {upDown && (
            <div className={classes.upDown}>
              <div onClick={up}>
                <Icon style={{ width:'16px', transform: "rotate(180deg)" }} name="selectArrow" />
              </div>
              <div onClick={down}>
                <Icon style={{ width:'16px' }} name="selectArrow" />
              </div>
            </div>
        )}
        {isDisabled && <div className={classes.plug} />}
      </div>
      {errorMessage && <Error className={errorClassName} style={errorStyle} message={errorMessage} />}
    </>
  );
};

Input.propTypes = {
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  errorClassName: PropTypes.string,
  labelStyle: PropTypes.object,
  style: PropTypes.object,
  errorStyle: PropTypes.object,
  labelText: PropTypes.node,
  type: PropTypes.string,
  value: PropTypes.string,
  changeHandler: PropTypes.func,
  placeholder: PropTypes.string,
  units: PropTypes.string,
  mask: PropTypes.string,
  commaIsReplacedWithDot: PropTypes.bool,
  validator: PropTypes.func,
  isSearch: PropTypes.bool,
  isDisabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  preChange: PropTypes.func,
  up: PropTypes.func,
  down: PropTypes.func,
  upDown: PropTypes.bool,
  readOnly: PropTypes.bool,
};

Input.defaultProps = {
  labelClassName: "",
  className: "",
  errorClassName: "",
  labelStyle: {},
  style: {},
  errorStyle: {},
  labelText: "",
  type: "text",
  value: "",
  changeHandler: () => {},
  placeholder: null,
  units: null,
  mask: null,
  commaIsReplacedWithDot: false,
  validator: () => true,
  isSearch: false,
  isDisabled: false,
  errorMessage: "",
  preChange: value => value,
  up: () => console.error('up is not implemented'),
  down: () => console.error("down is not implemented"),
  upDown: false,
  readOnly: false,
};

export default Input;
