import React from "react";
import classes from "./Logo.module.scss";
import PropTypes from "prop-types";
import { logoColored, logoGrayscale } from "../icons/icons";

const Logo = ({ style, isGreyscale, clickHandler }) => (
  <div
    className={classes.wrapper}
    style={{
      ...style,
      backgroundImage: `url('${isGreyscale ? logoGrayscale : logoColored}')`,
      ...(clickHandler ? { cursor: "pointer" } : {})
    }}
    onClick={clickHandler}
  />
);

Logo.propTypes = {
  style: PropTypes.object,
  isGreyscale: PropTypes.bool,
  clickHandler: PropTypes.func
};

Logo.defaultProps = {
  style: {},
  isGreyscale: false,
  clickHandler: null
};

export default Logo;
