import React from "react";
import classes from "./LocationDescription.module.scss";
import PropTypes from "prop-types";
import cn from "../../../helpers/cn";
import { Icon } from "../../index";

const LocationDescription = ({ className, style, locations }) => {
  const { city } = locations[0];

  return (
    <div className={cn(classes.locationDescription, className)} style={style}>
      {city && <div className={classes.title}>{city}</div>}
      {locations.map((location, i) => (
        <div className={classes.content} key={i}>
          <div className={classes.left}>
            <div className={classes.address}>{location.address}</div>
            <div className={classes.description}> {location.description}</div>
          </div>
          <Icon
            className={classes.icon}
            name="locationDescription"
            clickHandler={() => window.open(encodeURI(`https://www.google.com/maps/dir/?api=1&origin=My Location&destination=${location.address}`), "_blank")}
          />
        </div>
      ))}
    </div>
  );
};

LocationDescription.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  locations: PropTypes.array
};

LocationDescription.defaultProps = {
  className: "",
  style: {},
  locations: []
};

export default LocationDescription;
