import React, { useContext, useEffect, useRef, useState } from "react";
import classes from "./OuterToOuterTransaction.module.scss";
import { Button, ConfirmationCode, Divider, Error, Input, Label, OperationResult, Title, Triangle } from "../../../../components/index";
import { languageContext, modalContext } from "../../../../context";
import isCurrency from "validator/lib/isCurrency";
import { useError } from "../../../../hooks/index";
import { fieldErrorMessage, outerToOuterTransactionSchema, validationError } from "../../../../validation/index";
import * as API from "../../../../api/index";
import serverErrorMessages from "../../../../helpers/serverErrorMessages";
import localeAmount from "../../../../helpers/localeAmount";
import Tooltip from "react-tooltip-lite";

const OuterToOuterTransaction = ({ history }) => {
  const { language, text } = useContext(languageContext);
  const { setModal } = useContext(modalContext);

  const [givingAccount, setGivingAccount] = useState("");
  const [date, setDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [gettingAccount, setGettingAccount] = useState("");
  const [amount, setAmount] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [isPrefaced, setIsPrefaced] = useState(false);
  const [commission, setCommission] = useState("");
  const [enrolledOperationId, setEnrolledOperationId] = useState(null);
  const [error, setError] = useError(null);
  const [step, setStep] = React.useState(null);

  // --------------------useState for requests - 3d-secure and function for 3D-secure-------------------
  const [id, setId] = useState(false);
  const [ascUrl, setAscUrl] = useState(null);
  const [monUrl, setMonUrl] = useState(null);
  const [termUrl, setTermUrl] = useState(null);
  const refIframe = useRef(null);
  const timer = useRef(null);

  const checkFrame = React.useCallback(
    async value => {
      if (refIframe.current?.contentDocument?.location?.href === value && refIframe.current.contentDocument.getElementById("param")) {
        clearInterval(timer.current);
        const paRes = refIframe.current.contentDocument.getElementById("param");
        const payload = { enrolledOperationId: id, paRes: paRes.textContent };
        try {
          let { data } = await API.postOuterToOuterTransactionCommit(payload);

          if (data.status === "CREATED") {
            setAscUrl(data.challengeUrl);
            setStep(data.nextStep);
          }

          if (data.status === "CREATED" && data.nextStep === "lookup") {
            const payload2 = {
              enrolledOperationId: data.id,
              otp: undefined
            };

            await new Promise(resolve => {
              setModal(
                <ConfirmationCode
                  clickContinueHandler={code => {
                    payload2.otp = code;
                    setModal(null);
                    resolve();
                  }}
                />
              );
            });

            data = (await API.postOuterToOuterTransactionCommit(payload2)).data;
          }

          if (data.status === "FAIL") {
            setError({ field: "global", message: serverErrorMessages({ response: { data } }, language) });
            setAscUrl(null);
            setStep(null);
          }

          if (data.status === "SUCCESS") {
            setModal(
              <OperationResult
                titleText={text("transactionSuccess.success")}
                buttonText={text("buttons.complete")}
                clickCompleteHandler={() => setModal(null)}
              />
            );
            history.push("/home/transactions");
          }
        } catch (err) {
          setError({
            field: "global",
            message: serverErrorMessages(err, language)
          });
        }
      }
    },
    [history, id, language, setError, setModal, text]
  );

  useEffect(() => () => clearInterval(timer.current), []);
  useEffect(() => {
    if (termUrl) {
      clearInterval(timer.current);
      timer.current = setInterval(() => checkFrame(termUrl), 200);
    }
  }, [checkFrame, termUrl]);
  // -----------------------------------------------------------------------------------
  const globalErrorMessage = fieldErrorMessage("global", error);

  const initialSubmit = async () => {
    setError(null);
    const modifiedGivingAccount = givingAccount.split(/\s|_/).join("");
    const modifiedDate = date.split(/\/|_/).join("");
    const modifiedCvv = cvv.split(/_/).join("");
    const modifiedGettingAccount = gettingAccount.split(/\s|_/).join("");
    const error = validationError(outerToOuterTransactionSchema(language), {
      givingAccount: modifiedGivingAccount,
      date: modifiedDate,
      cvv: modifiedCvv,
      gettingAccount: modifiedGettingAccount,
      amount
    });
    if (error) {
      setError(error);
      return;
    }
    const payload = {
      srcCard: {
        cardNumber: modifiedGivingAccount,
        secureCode: cvv,
        expMonth: date.split("/")[0],
        expYear: date.split("/")[1]
      },
      destCard: {
        cardNumber: modifiedGettingAccount
      },
      amount: Math.round(amount * 100),
      customCallbackUrl: `${window.location.origin}/bank/secure3d/callback-url.jsp`
    };
    setIsFetching(true);
    try {
      const { data } = await API.postOuterToOuterTransactionEnroll(payload);
      // setIsFetching(true);
      setId(data.id);
      setTermUrl(`${window.location.origin}/bank/secure3d/callback-url.jsp`);
      setMonUrl(data.monitorUrl);
      setAscUrl(data.secure3DRequest.acsUrl);
      setStep(data.nextStep);

      setEnrolledOperationId(data.id);
      setCommission(`${localeAmount(data.operationConditions.commission)} ${data.currency}`);
      setIsPrefaced(true);
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
      setIsFetching(false);
      cancel();
    }
    // setIsFetching(false);
  };

  const submit = async () => {
    const payload = {
      enrolledOperationId,
      otp: undefined,
      challenge: undefined
    };
    // try {
    //   setIsFetching(true);
    //   const { data } = await API.getAuthExtended();
    //   payload.challenge = data;
    //   setIsFetching(false);
    // } catch (err) {
    //   setError({
    //     field: "global",
    //     message: serverErrorMessages(err, language)
    //   });
    //   setIsFetching(false);
    //   return;
    // }
    await new Promise(resolve => {
      setModal(
        <ConfirmationCode
          clickContinueHandler={code => {
            payload.otp = code;
            setModal(null);
            resolve();
          }}
        />
      );
    });
    try {
      setIsFetching(true);
      const { data } = await API.postOuterToOuterTransactionCommit(payload);
      if (data.status === "FAIL") {
        setError({
          field: "global",
          message: serverErrorMessages(
            {
              response: {
                data
              }
            },
            language
          )
        });
        setIsFetching(false);
        cancel();
        return;
      }
      setIsFetching(false);
      setModal(
        <OperationResult titleText={text("transactionSuccess.success")} buttonText={text("buttons.complete")} clickCompleteHandler={() => setModal(null)} />
      );
      history.push("/home/transactions");
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
      setIsFetching(false);
    }
  };

  const cancel = () => {
    setGivingAccount("");
    setDate("");
    setCvv("");
    setGettingAccount("");
    setAmount("");
    setCommission("");
    setEnrolledOperationId(null);
    setIsPrefaced(false);
  };

  return (
    <div className={classes.wrapper}>
      <Title text={text("outerToOuterTransaction.title")} />
      <div className={classes.content}>
        <div className={classes.subtitle}>{text("outerToOuterTransaction.sender")}:</div>
        <Label>{text("outerToOuterTransaction.cardNumber")}</Label>
        <Input
          style={{ marginBottom: 20 }}
          errorStyle={{ marginBottom: 20 }}
          value={givingAccount}
          changeHandler={value => setGivingAccount(value)}
          placeholder="**** **** **** ****"
          mask="9999 9999 9999 9999"
          errorMessage={fieldErrorMessage("givingAccount", error)}
          isDisabled={isPrefaced}
        />
        <div className={classes.row} style={{ marginBottom: 20 }}>
          <div style={{ width: 158 }}>
            <Label>{text("outerToOuterTransaction.date")}</Label>
            <Input
              errorStyle={{ marginTop: 20 }}
              value={date}
              changeHandler={value => setDate(value)}
              placeholder="MM/YY"
              mask="99/99"
              errorMessage={fieldErrorMessage("date", error)}
              isDisabled={isPrefaced}
            />
          </div>
          <div style={{ width: 158 }}>
            <Input
              type="password"
              errorStyle={{ marginTop: 20 }}
              labelText={
                <>
                  CVV2/CVC2&nbsp;
                  <Tooltip
                    className={classes.target}
                    color="black"
                    background="lightgrey"
                    content={
                      <span>
                        {text("innerToOuterTransaction.tooltipCVV_1")}
                        <br />
                        {text("innerToOuterTransaction.tooltipCVV_2")}
                        <br />
                        {text("innerToOuterTransaction.tooltipCVV_3")}
                      </span>
                    }
                  >
                    <div className={classes.icon}>?</div>
                  </Tooltip>
                </>
              }
              value={cvv}
              changeHandler={value => setCvv(value)}
              placeholder="***"
              maxLength={3}
              errorMessage={fieldErrorMessage("cvv", error)}
              isDisabled={isPrefaced}
            />
          </div>
        </div>
        <Divider />
        <Triangle style={{ margin: "auto" }} />
        <div className={classes.subtitle}>{text("outerToOuterTransaction.recipient")}:</div>
        <Label>{text("outerToOuterTransaction.cardNumber")}</Label>
        <Input
          errorClassName={classes.error}
          style={{ marginBottom: 20 }}
          value={gettingAccount}
          changeHandler={value => setGettingAccount(value)}
          placeholder="**** **** **** ****"
          mask="9999 9999 9999 9999"
          errorMessage={fieldErrorMessage("gettingAccount", error)}
          isDisabled={isPrefaced}
        />
        <Divider style={{ marginBottom: 10 }} />
        <Label>{text("outerToOuterTransaction.transferAmount")}</Label>
        <div className={classes.row} style={{ marginBottom: 20 }}>
          <div>
            <Input
              style={{ width: 250 }}
              errorStyle={{ marginTop: 20 }}
              value={amount}
              changeHandler={value => setAmount(value)}
              units="UAH"
              commaIsReplacedWithDot
              validator={value =>
                isCurrency(value, {
                  digits_after_decimal: [0, 1, 2]
                })
              }
              errorMessage={fieldErrorMessage("amount", error)}
              isDisabled={isPrefaced}
            />
          </div>

          {commission && (
            <div className={classes.commisionText}>
              {text("outerToOuterTransaction.commission")}: {commission}
            </div>
          )}
        </div>

        {step === "3d2" && (
          <div className={classes.row} style={{ marginBottom: 20, display: "none" }}>
            <iframe title="monitor" src={monUrl} />
            <iframe title="callback" src={ascUrl} ref={refIframe} />
          </div>
        )}
        {step === "challenge" && (
          <div className={classes.row} style={{ marginBottom: 20 }}>
            <iframe title="challenge" src={ascUrl} ref={refIframe} height="500" width="100%" style={{ border: "none" }} />
          </div>
        )}

        <div className={classes.text}>
          {text("outerToOuterTransaction.pressTransfer")}{" "}
          <a className={classes.link} href="https://pay4-api-v2.payforce.net.ua/public/public_offer.pdf" target="_blank" rel="noopener noreferrer">
            {text("outerToOuterTransaction.conditions")}
          </a>
        </div>

        {globalErrorMessage && <Error className={classes.globalError} message={globalErrorMessage} />}

        <div className={classes.row}>
          <Button
            style={{
              width: 200,
              height: 48,
              color: "#241F5A",
              textDecoration: "underline",
              fontWeight: "bold"
            }}
            clickHandler={!isPrefaced ? history.goBack : cancel}
          >
            {text(!isPrefaced ? "buttons.back" : "buttons.cancel")}
          </Button>
          <Button
            style={{
              width: 200,
              height: 48,
              color: "white",
              backgroundColor: "#241F5A",
              borderColor: "#241F5A",
              fontWeight: "bold"
            }}
            clickHandler={!isPrefaced ? initialSubmit : submit}
            isDisabled={isFetching}
          >
            {text(!isPrefaced ? "buttons.continue" : "outerToOuterTransaction.buttonConfirm")} &rarr;
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OuterToOuterTransaction;
