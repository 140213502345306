import React from "react";
import classes from "./Button.module.scss";
import PropTypes from "prop-types";
import cn from "../../helpers/cn";

const Button = ({ className, style, children, clickHandler, isDisabled }) => {
  return (
    <div
      className={cn(classes.wrapper, className)}
      style={{ ...style, ...(isDisabled ? { cursor: "not-allowed" } : {}) }}
      onClick={!isDisabled ? clickHandler : null}
    >
      {children}
    </div>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  clickHandler: PropTypes.func,
  isDisabled: PropTypes.bool
};

Button.defaultProps = {
  className: "",
  style: {},
  children: "",
  clickHandler: null,
  isDisabled: false
};

export default Button;
