import React, { useCallback, useContext, useMemo, useState } from "react";
import classes from "./InnerToInnerTransaction.module.scss";
import { connect } from "react-redux";
import { languageContext, modalContext, templatesContext } from "../../../../context/index";
import {
  Button,
  ConfirmationCode,
  Divider,
  Error,
  Input,
  Label,
  OperationResult,
  RegularPayment,
  Select,
  TemplateSettings,
  Title,
  TransactionSuccess,
  Triangle
} from "../../../../components/index";
import isCurrency from "validator/lib/isCurrency";
import isLength from "validator/lib/isLength";
import getParamFromUrl from "../../../../helpers/getParamFromUrl";
import { useError } from "../../../../hooks/index";
import { fieldErrorMessage, innerBankTransactionSchema, validationError } from "../../../../validation/index";
import * as API from "../../../../api/index";
import serverErrorMessages from "../../../../helpers/serverErrorMessages";
import localeAmount from "../../../../helpers/localeAmount";
import amountForRequest from "../../../../helpers/amountForRequest";
import { uahAccountOptions, uahCardOptions } from "../../../../store/getters/index";
import * as actions from "../../../../store/actions/index";
import { findContractByKey } from "../../../../helpers/contract";

const InnerToInnerTransaction = ({ history, cards, accounts, readContract }) => {
  const { language, text } = useContext(languageContext);
  const { setModal } = useContext(modalContext);
  const { createTemplate } = useContext(templatesContext);

  const contractOptions = useMemo(() => [...uahCardOptions(cards), ...uahAccountOptions(accounts, text)], [cards, accounts, text]);

  const [isPrefaced, setIsPrefaced] = useState(false);
  const [givingAccount, setGivingAccount] = useState(() => (findContractByKey(contractOptions, getParamFromUrl("sender")) ? getParamFromUrl("sender") : null));
  const [gettingAccount, _setGettingAccount] = useState(() => getParamFromUrl("receiver"));
  const [amount, setAmount] = useState(() => getParamFromUrl("amount"));
  const [isFetching, setIsFetching] = useState(false);
  const [commission, setCommission] = useState("");
  const [challengeValue, setChallengeValue] = useState("");
  const setGettingAccount = React.useCallback((value) => {
    const temp = (value || '').replaceAll(/[^UA\d]+/g, '');
    _setGettingAccount(temp);
  }, []);
  const [error, setError] = useError(null);

  const globalErrorMessage = fieldErrorMessage("global", error);

  const addTemplate = useCallback(
    async payload => {
      try {
        setModal(null);
        await createTemplate(payload);
        setModal(
          <OperationResult
            titleText={`${text("templates.modals.createSuccess.titleText")}!`}
            text={text("templates.modals.createSuccess.text")}
            buttonText={text("buttons.complete")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      } catch (err) {
        setModal(
          <OperationResult
            result="failure"
            text={serverErrorMessages(err, language)}
            buttonText={text("buttons.close")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      }
    },
    [setModal, text, createTemplate, language]
  );

  const initialSubmit = async () => {
    setError(null);
    const error = validationError(innerBankTransactionSchema(language), {
      givingAccount,
      gettingAccount,
      amount
    });
    if (error) {
      setError(error);
      return;
    }
    setIsFetching(true);
    try {
      const contract = findContractByKey(contractOptions, givingAccount);
      const { data } = await API.postInnerBankTransactionPreface({
        srcContractRef: {
          contractId: contract.contractId,
          providerId: contract.providerId
        },
        cardId: contract.cardId,
        destination: {
          identifierType: gettingAccount.length === 16 ? "CARD_NUMBER" : "ACCOUNT_NUMBER",
          identifier: gettingAccount
        },
        amount: Math.round(amountForRequest(amount)),
        currency: "UAH"
      });
      setCommission(`${localeAmount(data.operationConditions.commission)} ${data.currency}`);
      setChallengeValue(data.destination.challengeRequestValue);
      setIsPrefaced(true);
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
    }
    setIsFetching(false);
  };

  const cancel = () => {
    setGivingAccount(null);
    setGettingAccount("");
    setAmount("");
    setCommission("");
    setChallengeValue("");
    setIsPrefaced(false);
  };

  const submit = async () => {
    const contract = findContractByKey(contractOptions, givingAccount);

    const otpCredentials = {
      otp: undefined,
      challenge: undefined
    };

    const payload = {
      srcContractRef: {
        contractId: contract.contractId,
        providerId: contract.providerId
      },
      cardId: contract.cardId,
      destination: {
        identifierType: gettingAccount.length === 16 ? "CARD_NUMBER" : "ACCOUNT_NUMBER",
        identifier: gettingAccount,
        challengeResponseValue: challengeValue
      },
      amount: Math.round(amountForRequest(amount)),
      currency: "UAH"
    };

    try {
      setIsFetching(true);
      const { data } = await API.getAuthExtended();
      otpCredentials.challenge = data;
      setIsFetching(false);
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
      setIsFetching(false);
      return;
    }

    await new Promise(resolve => {
      setModal(
        <ConfirmationCode
          clickContinueHandler={code => {
            otpCredentials.otp = code;
            setModal(null);
            resolve();
          }}
        />
      );
    });

    try {
      setIsFetching(true);
      const { headers } = await API.postAuthLoginOtp(otpCredentials);
      const { data } = await API.postInnerBankTransactionExecute(headers.authorization, payload);
      if (data.status === "FAIL") {
        setError({
          field: "global",
          message: serverErrorMessages(
            {
              response: {
                data
              }
            },
            language
          )
        });
        setIsFetching(false);
        cancel();
        return;
      }
      try {
        await readContract(contract.providerId, contract.contractId);
      } catch (error) {
        console.log("Unable to update the contract", error);
      }
      //setIsFetching(false);
      setModal(
        <TransactionSuccess
          clickCancelHandler={() => setModal(null)}
          clickAddHandler={() =>
            setModal(
              <TemplateSettings
                isNew
                initialType="INTRABANK_TRANSFER"
                initialGivingContract={givingAccount}
                initialGettingAccount={gettingAccount}
                initialAmount={amount}
                clickCancelHandler={() => setModal(null)}
                clickSaveHandler={addTemplate}
              />
            )
          }
          clickRegularHandler={() =>
            setModal(
              <RegularPayment
                initialType="INTRABANK_TRANSFER"
                initialGivingContract={givingAccount}
                initialGettingAccount={gettingAccount}
                initialAmount={amount}
                clickCancelHandler={() => setModal(null)}
              />
            )
          }
        />
      );
      history.push("/home/transactions");
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Title text={text("innerToInnerTransaction.title")} />
      <div className={classes.content}>
        <Label>{text("innerToInnerTransaction.fromMyAccount")}</Label>
        <Select
          errorClassName={classes.error}
          style={{ marginBottom: 20 }}
          options={contractOptions}
          value={givingAccount}
          changeHandler={value => setGivingAccount(value)}
          placeholder={text("outerToInnerTransaction.placeholder")}
          errorMessage={fieldErrorMessage("givingAccount", error)}
          isDisabled={isPrefaced}
        />
        <Divider />
        <Triangle style={{ margin: "auto" }} />
        <Label>{text("innerToInnerTransaction.toMyAccount")}</Label>
        <Input
            preChange={(value) => {
              const temp = (value || '').replaceAll(/[^UA\d]+/g, '');
              return temp.slice(0, /UA?\d*/.test(temp) ? 29 : 16);
            }}
          errorClassName={classes.error}
          style={{ marginBottom: 20 }}
          value={gettingAccount}
          changeHandler={value => setGettingAccount(value)}
          validator={value =>
            isLength(value, {
              min: 0,
              //max: 29
            })
          }
          errorMessage={fieldErrorMessage("gettingAccount", error)}
          isDisabled={isPrefaced}
        />
        <Label>{text("innerToInnerTransaction.transferAmount")}</Label>
        <div className={classes.row}>
          <div>
            <Input
              errorClassName={classes.error}
              style={{ width: 250 }}
              name="amount"
              value={amount}
              changeHandler={value => setAmount(value)}
              units="UAH"
              commaIsReplacedWithDot
              validator={value =>
                isCurrency(value, {
                  digits_after_decimal: [0, 1, 2]
                })
              }
              errorMessage={fieldErrorMessage("amount", error)}
              isDisabled={isPrefaced}
            />
          </div>
          {commission && (
            <div className={classes.text}>
              {text("innerToInnerTransaction.commission")}: {commission}
            </div>
          )}
        </div>
        <div className={classes.conditionText}>
          {text("innerToInnerTransaction.pressTransfer")}{" "}
          <a className={classes.link} href="https://ap-bank.com/documents/download/1084" target="_blank" rel="noopener noreferrer">
            {text("innerToInnerTransaction.conditions")}
          </a>
        </div>
        {globalErrorMessage && <Error className={classes.globalError} message={globalErrorMessage} />}
        <div className={classes.buttons}>
          <Button
            style={{
              width: 200,
              height: 48,
              color: "#241F5A",
              textDecoration: "underline",
              fontWeight: "bold"
            }}
            clickHandler={!isPrefaced ? history.goBack : cancel}
          >
            {text(!isPrefaced ? "buttons.back" : "buttons.cancel")}
          </Button>
          <Button
            style={{
              width: 200,
              height: 48,
              color: "white",
              backgroundColor: "#241F5A",
              borderColor: "#241F5A",
              fontWeight: "bold"
            }}
            clickHandler={!isPrefaced ? initialSubmit : submit}
            isDisabled={isFetching}
          >
            {text(!isPrefaced ? "buttons.continue" : "innerToInnerTransaction.buttonConfirm")} &rarr;
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ contracts }) => ({ cards: contracts.cards, accounts: contracts.accounts });
const mapDispatchToProps = dispatch => ({
  readContract: (providerId, contractId) => dispatch(actions.readContract(providerId, contractId))
});

export default connect(mapStateToProps, mapDispatchToProps)(InnerToInnerTransaction);
