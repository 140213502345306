import React, { useContext, useState } from "react";
import classes from "./CreditTile.module.scss";
import PropTypes from "prop-types";
import moment from "moment";
import { languageContext } from "../../../context/index";
import cn from "../../../helpers/cn";
import all from "../../../helpers/all";
import localeAmount from "../../../helpers/localeAmount";
import { Icon } from "../../index";

const CreditTile = ({ className, style, height, name, totalAmount, currency, amountToPay, date, clickHistoryHandler, clickPayHandler, hasOpenButton }) => {
  const { text } = useContext(languageContext);

  const options = [
    {
      iconName: "historyOption",
      label: text("creditTile.options.history"),
      clickHandler: clickHistoryHandler
    },
    {
      iconName: "payOption",
      label: text("creditTile.options.pay"),
      clickHandler: clickPayHandler
    }
  ];

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={cn(classes.creditTile, className)} style={style}>
      <div className={classes.top} style={{ height: height / 2 }}>
        <div className={classes.iconWrapper}>
          <Icon name="creditTile" />
        </div>
        <div className={classes.description}>
          <div className={classes.name}>{name}</div>
          <div className={cn(classes.text, classes.textTop)}>
            {text("creditTile.loanAmount")}: {localeAmount(totalAmount)} {currency}
          </div>
        </div>
        {hasOpenButton && (
          <div className={classes.openIconWrapper} onClick={() => setIsOpen(!isOpen)}>
            <Icon style={{ ...(isOpen ? { transform: "rotate(180deg)" } : {}) }} name="selectArrow" />
          </div>
        )}
      </div>
      {!isOpen ? (
        <div className={classes.bottom} style={{ height: height / 2 }}>
          <div className={classes.row}>
            <div className={classes.text}>{text("creditTile.remains")}:</div>
            <div className={classes.text}>
              {text("creditTile.before")} {moment(new Date(date)).format("DD.MM.YYYY")}
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.amount}>
              {localeAmount(amountToPay)} <span className={classes.currency}>{currency}</span>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.options}>
          {options.map((option, i) => (
            <div id="option" className={classes.option} onClick={() => all(option.clickHandler, () => setIsOpen(false))} key={i}>
              {option.iconName && <Icon name={option.iconName} />}
              {option.label && <div className={classes.label}>{option.label}</div>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

CreditTile.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  height: PropTypes.number,
  name: PropTypes.string,
  totalAmount: PropTypes.number,
  currency: PropTypes.string,
  amountToPay: PropTypes.number,
  date: PropTypes.string,
  clickHistoryHandler: PropTypes.func,
  clickPayHandler: PropTypes.func,
  hasOpenButton: PropTypes.bool
};

CreditTile.defaultProps = {
  className: "",
  style: {},
  height: 130,
  name: "Кредит",
  totalAmount: null,
  currency: "UAH",
  amountToPay: null,
  date: "DD.MM.YY",
  clickHistoryHandler: null,
  clickPayHandler: null,
  hasOpenButton: true
};

export default CreditTile;
