import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { matchPath, useLocation, generatePath } from 'react-router-dom';
import PaymentsState from "./PaymentsState";
import PaymentsCategories from "./PaymentsCategories";
import {
  urlBiller,
  urlCategories,
  urlCategory,
  urlSearchInCategories,
  urlSearchInCategoriesWOV,
  urlSearchInCategory,
  urlSearchInCategoryWOV
} from "./PaymentsUtils";
import PaymentsOperation from "./PaymentsOperation";
import classes from "./Payments.module.scss";

const RedirectToCategory = () => {
  const { pathname } = useLocation();
  const { params: { categoryId }} = matchPath(pathname, {pathname: urlSearchInCategoryWOV});
  return <Redirect to={generatePath(urlCategory, { categoryId })}/>
};

const Payments = () => (
  <PaymentsState>
    <div className={classes.wrapper}>
      <Switch>
        <Route path={urlSearchInCategory} component={PaymentsCategories} />
        <Route path={urlSearchInCategories} component={PaymentsCategories} />
        <Route path={urlSearchInCategoryWOV} component={RedirectToCategory} />
        <Route path={urlSearchInCategoriesWOV} render={() => <Redirect to={urlCategories}/>} />
        <Route path={urlBiller} component={PaymentsOperation}/>
        <Route path={urlCategory} component={PaymentsCategories} />
        <Route path={urlCategories} component={PaymentsCategories} />
        <Redirect to={urlCategories} />
      </Switch>
    </div>
  </PaymentsState>
);

export default Payments;
