import React, { useContext, useState, useEffect } from "react";
import classes from "./Login.module.scss";
import { Link } from "react-router-dom";
import { languageContext, authContext, modalContext } from "../../../context/index";
import * as API from "../../../api/index";
import { loginSchema, validationError, fieldErrorMessage } from "../../../validation/index";
import { LanguageSwitch, Title, Error, Input, Button, ConfirmationCode } from "../../../components/index";
import { useTitle } from "../../../hooks/index";
import serverErrorMessages from "../../../helpers/serverErrorMessages";
import { useDispatch } from "react-redux";
import { removeContracts } from "../../../store/actions";

const Login = () => {
  const dispatch = useDispatch();
  const { language, setLanguage, text } = useContext(languageContext);
  const { setAuthenticationData } = useContext(authContext);
  const { setModal } = useContext(modalContext);

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const globalErrorMessage = fieldErrorMessage("global", error);

  const submit = async () => {
    clearTimeout(Login.timeout);
    setError(null);
    dispatch(removeContracts());
    const phoneModified = phone.split(/\s|_/).join("");
    const error = validationError(loginSchema(language), {
      phone: phoneModified,
      password
    });
    if (error) {
      setError(error);
      return;
    }
    setIsFetching(true);
    try {
      const res = await API.postLoginPassword({
        login: phoneModified,
        password
      });
      const { roles } = res.data;
      if (roles[0].code === "guest" && roles[2].code === "bound_to_contragent") {
        const loginConfirmAuthHeader = res.headers.authorization;
        await API.postSuretyRecordsFindForActivation(loginConfirmAuthHeader);
        await API.postSuretyRecordsRequestActivation(loginConfirmAuthHeader);
        let activationCode;
        await new Promise(resolve => {
          setModal(
            <ConfirmationCode
              clickContinueHandler={code => {
                activationCode = code;
                setModal(null);
                resolve();
              }}
            />
          );
        });
        await API.postSuretyRecordsActivate(loginConfirmAuthHeader, {
          activationCode
        });
        const resAfterSession = await API.getAuthSession(loginConfirmAuthHeader);
        let { roles } = resAfterSession.data;
        if (roles[0].code === "full" && roles[2].code === "bound_to_contragent") {
          setAuthenticationData(res);
        }
      }
      setAuthenticationData(res);
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
    }
    setIsFetching(false);
  };

  useTitle(text("login.title"));

  useEffect(() => {
    if (error) {
      Login.timeout = setTimeout(() => {
        setError(null);
      }, 6000);
    }
  }, [error]);

  useEffect(() => {
    return () => {
      clearTimeout(Login.timeout);
    };
  }, []);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        submit();
      }}
    >
      <div className={classes.wrapper}>
        <LanguageSwitch className={classes.languageSwitch} currentLanguage={language} changeHandler={value => setLanguage(value)} />
        <Title className={classes.title} text={text("login.title")} />
        {globalErrorMessage && <Error className={classes.error} message={globalErrorMessage} />}
        <Input
          className={classes.input}
          errorClassName={classes.error}
          labelText={text("login.loginInput")}
          value={phone}
          changeHandler={value => setPhone(value)}
          mask={"+38 \\099 999 99 99"}
          placeholder="+38 000 000 00 00"
          errorMessage={fieldErrorMessage("phone", error)}
        />
        <Input
          className={classes.input}
          errorClassName={classes.error}
          labelText={text("login.passwordInput")}
          type="password"
          maxLength={21}
          value={password}
          changeHandler={value => setPassword(value)}
          isPassword
          errorMessage={fieldErrorMessage("password", error)}
        />
        <div className={classes.row}>
          <Link className={classes.link} to="/auth/password-recovery">
            {text("login.passwordRecovery")}
          </Link>
        </div>
        <button type="submit" style={{ display: "none" }} />
        <Button
          className={classes.button}
          clickHandler={e => {
            e.preventDefault();
            submit();
          }}
          isDisabled={isFetching}
        >
          {text("login.buttonConfirm")}
        </Button>
        <Link className={classes.link} to="/auth/signup">
          {text("login.buttonRegistration")}
        </Link>
      </div>
    </form>
  );
};

export default Login;
