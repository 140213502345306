const months = ["січня", "лютого", "березня", "квітня", "травня", "червня", "липня", "серпня", "вересня", "жовтня", "листопада", "грудня"];

export const convertHTMLEntity = text => {
  const span = document.createElement("span");
  return text.replace(/&[#A-Za-z0-9]+;/gi, entity => {
    span.innerHTML = entity;
    return span.innerText;
  });
};

export const convertHtmlDate = date => {
  const result = new Date(date);
  return `${result.getDate()} ${months[result.getMonth()]} ${result.getFullYear()}р.`;
};
