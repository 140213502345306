import React, { useContext } from "react";
import classes from "./NavigationVertical.module.scss";
// import PropTypes from "prop-types";
import * as icons from "../../icons/icons";
import NavigationLink from "../NavigationLink/NavigationLink.jsx";
import { languageContext } from "../../../context/index";

const NavigationVertical = () => {
  const { text } = useContext(languageContext);

  const links = [
    {
      path: "/home/profile",
      icon: icons.profile,
      iconActive: icons.profileActive,
      text: text("navigationVertical.myCabinet")
    },
    {
      path: "/home/transactions",
      icon: icons.transactions,
      iconActive: icons.transactionsActive,
      text: text("navigationVertical.transactions")
    },
    {
      path: "/home/payments",
      icon: icons.payments,
      iconActive: icons.paymentsActive,
      text: text("navigationVertical.payments")
    },
    {
      path: "/home/templates",
      icon: icons.templates,
      iconActive: icons.templatesActive,
      text: text("navigationVertical.templates")
    },
    {
      path: "/home/history",
      icon: icons.history,
      iconActive: icons.historyActive,
      text: text("navigationVertical.history")
    },
    {
      path: "/home/financial-assistant",
      icon: icons.finance,
      iconActive: icons.financeActive,
      text: text("navigationVertical.financialAssistant")
    },
    {
      path: "/home/regular-payments",
      icon: icons.regularPayment,
      iconActive: icons.regularPaymentActive,
      text: text("navigationVertical.regularPayment")
    },
    {
      path: "/home/news",
      icon: icons.news,
      iconActive: icons.newsActive,
      text: text("navigationVertical.news")
    },
    {
      path: "/home/settings",
      icon: icons.settings,
      iconActive: icons.settingsActive,
      text: text("navigationVertical.settings")
    }
    // {
    //   icon: icons.exit,
    //   text: text("navigationVertical.exit"),
    //   clickHandler: clickLogoutHandler
    // }
  ];

  return (
    <div className={classes.wrapper}>
      {links.map((link, i) => (
        <NavigationLink
          style={{ margin: "4px 0", padding: "0 30px" }}
          path={link.path}
          icon={link.icon}
          iconActive={link.iconActive}
          text={link.text}
          textColorIsChanging
          clickHandler={link.clickHandler}
          key={i}
        />
        // <div key={i}>
        //   <NavigationLink
        //     style={{ margin: "4px 0", padding: "0 30px" }}
        //     path={link.path}
        //     icon={link.icon}
        //     iconActive={link.iconActive}
        //     text={link.text}
        //     textColorIsChanging
        //     clickHandler={link.clickHandler}
        //   />
        //   {i === 4 && <div className={classes.divider} />}
        // </div>
      ))}
    </div>
  );
};

// NavigationVertical.propTypes = {
//   clickLogoutHandler: PropTypes.func
// };

// NavigationVertical.defaultProps = {
//   clickLogoutHandler: () => {}
// };

export default NavigationVertical;
