// export default (amount = 0, language = "ua") => {
//   const locales = {
//     ua: "uk-UA",
//     ru: "ru-RU",
//     en: "en-US"
//   };
//   return (amount / 100).toLocaleString(locales[language], { minimumFractionDigits: 2, maximumFractionDigits: 2 });
// };

import currencyFormatter from "currency-formatter";

const localeAmount = (amount = 0) => {
  return currencyFormatter.format(amount / 100, {
    decimal: ".",
    thousand: " ",
    precision: 2,
    format: "%v"
  });
};

export default localeAmount;
