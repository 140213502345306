import * as actionTypes from "./actionTypes";
import * as API from "../../api/index";

// MUTATIONS
export const setContracts = (contractType, data) => {
  return {
    type: actionTypes.SET_CONTRACTS,
    contractType,
    data
  };
};

export const removeContracts = () => {
  return {
    type: actionTypes.REMOVE_CONTRACTS
  };
};

// ACTIONS
export const readContract = (contractType, id) => {
  return async (dispatch, getState) => {
    const { data } = await API.getContract(contractType, id);
    const { contracts } = getState();
    const dataCopy = JSON.parse(JSON.stringify(contracts[contractType + "s"]));
    const index = dataCopy.findIndex(x => x.id === id);
    dataCopy.splice(index, 1, data);
    dispatch(setContracts(contractType, dataCopy));
  };
};

export const readContracts = contractType => {
  return async (dispatch, getState) => {
    try {
      let { data } = await API.getContracts(contractType);
      if (contractType === "card") {
        data = data.filter(x => x.cardsList.length);
      }
      const { contracts } = getState();
      if (JSON.stringify(data) !== JSON.stringify(contracts[contractType + "s"])) {
        dispatch(setContracts(contractType, data));
      }
    } catch (error) {
      console.log(error);
    }
  };
};
