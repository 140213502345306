import React, { useContext, useState } from "react";
import classes from "./ActivateRegularPayment.module.scss";
import PropTypes from "prop-types";
import * as API from "../../../api/index";
import { Button, ConfirmationCode, OperationResult, Title } from "../../index";
import { languageContext, modalContext } from "../../../context/index";

const ActivateRegularPayment = ({ regularPayment, statuRegularPaymentRequest }) => {
  const { text } = useContext(languageContext);
  const { setModal } = useContext(modalContext);

  const [isFetching, setIsFetching] = useState(false);

  const types = {
    CONTRACT_TO_CONTRACT: "contractToContract",
    INTRABANK_TRANSFER: "intrabankTransfer",
    SEP_TRANSFER: "sepTransfer"
  };

  const submitActivateRegularPayment = async () => {
    setIsFetching(true);
    API.postAutopaymentsActivatePreface(types[regularPayment.type], regularPayment.autopayment).then(result => {
      if (result) {
        const { extAuthRequired } = result.data.operationConditions;
        const { status } = result.data;
        if (status === "SUCCESS") {
          postExecute(extAuthRequired);
        }
      }
    });
  };

  const postExecute = async extAuthRequired => {
    setIsFetching(true);

    const otpCredentials = {
      otp: undefined,
      challenge: undefined
    };
    if (extAuthRequired === true) {
      try {
        setIsFetching(true);
        const { data } = await API.getAuthExtended();
        otpCredentials.challenge = data;
        setIsFetching(false);
      } catch (err) {
        setIsFetching(false);
        return;
      }
      await new Promise(resolve => {
        setModal(
          <ConfirmationCode
            clickContinueHandler={code => {
              otpCredentials.otp = code;
              setModal(null);
              resolve();
            }}
          />
        );
      });
    }

    regularPayment.autopayment.otpCredentials = otpCredentials;
    const payload = regularPayment.autopayment;

    await API.postAutopaymentsActivateExecute(types[regularPayment.type], payload)
      .then(response => {
        if (response.data.status === "SUCCESS") {
          setModal(
            <OperationResult
              titleText={text("regularPayments.activate.textSuccess")}
              buttonText={text("buttonCancel")}
              clickCompleteHandler={() => setModal(null)}
            />
          );
          statuRegularPaymentRequest(response.data.status);
        } else {
          setModal(
            <OperationResult
              result="failure"
              titleText={text("regularPayments.activate.textFail")}
              buttonText={text("buttonCancel")}
              clickCompleteHandler={() => setModal(null)}
            />
          );
        }
      })
      .catch(error => {
        if (error.response) {
          console.log(error.response.status, "error");
        }
      });
  };

  return (
    <>
      <Title text={text("regularPayments.activate.title")} />
      <div className={classes.wrapper}>
        <div className={classes.text}>{text("regularPayments.activate.text")}</div>
        <div className={classes.buttons}>
          <Button className={classes.buttonCancel} clickHandler={() => setModal(null)}>
            {text("templateSettings.buttonCancel")}
          </Button>
          <Button className={classes.buttonDelete} clickHandler={submitActivateRegularPayment} isDisabled={isFetching}>
            {text("regularPayments.activate.buttonActivate")}
          </Button>
        </div>
      </div>
    </>
  );
};
ActivateRegularPayment.propTypes = {
  regularPayment: PropTypes.object,
  statuRegularPaymentRequest: PropTypes.func
};
export default ActivateRegularPayment;
