import React from "react";
import classes from "./FinancialAssistant.module.scss";
import { Error, MonthPicker, Title } from "../../../components/index";
import { languageContext } from "../../../context/index";
import * as API from "../../../api/index";
import localeAmount from "../../../helpers/localeAmount";
import { PieChart } from "react-minimal-pie-chart";
import { maxDate, minDate } from "../../../helpers/zaebali";

const FinancialAssistant = ({ history, location: { state } }) => {
  const { language, text } = React.useContext(languageContext);
  const [error, setError] = React.useState(null);
  const [dateFrom, setDateFrom] = React.useState(
    new Date(
      state?.params ? state.params.fromYear : new Date().getFullYear() - 1,
      state?.params ? state.params.fromMonth - 1 : new Date().getMonth(),
      1
    ).getTime()
  );
  const [dateTill, setDateTill] = React.useState(
    new Date(state?.params ? state.params.toYear : new Date().getFullYear(), state?.params ? state.params.toMonth - 1 : new Date().getMonth(), 1).getTime()
  );

  const [incomeAmount, setIncomeAmount] = React.useState(null);
  const [outlayAmount, setOutlayAmount] = React.useState(null);
  const [incomeCategories, setIncomeCategories] = React.useState([]);
  const [outlayCategories, setOutlayCategories] = React.useState([]);
  const [dataOutcomes, setDataOutcomes] = React.useState([]);
  const [dataIncomes, setDataIncomes] = React.useState([]);
  const iconURL = "/bank/category-icon/svg";

  React.useEffect(() => {
    setError(null);

    const params = {
      fromMonth: new Date(dateFrom).getMonth() + 1,
      toMonth: new Date(dateTill).getMonth() + 1,
      fromYear: new Date(dateFrom).getFullYear(),
      toYear: new Date(dateTill).getFullYear()
    };
    API.getStatisticCategoriesForPeriod(params).then(result => {
      const { categoriesStatistic, localIncomeAmount, localOutlayAmount } = result.data;

      setIncomeAmount(localIncomeAmount);
      setOutlayAmount(localOutlayAmount);
      setOutlayCategories(categoriesStatistic.filter(item => item.category.categoryType === "OUTCOMES"));
      setIncomeCategories(categoriesStatistic.filter(item => item.category.categoryType === "INCOMES"));

      const outcomes = [];
      const incomes = [];
      categoriesStatistic.forEach(item => {
        if (item.category.categoryType === "OUTCOMES") {
          outcomes.push({ name: item.category.categoryName, value: +item.localAmountInPercents, color: item.category.colour });
        } else if (item.category.categoryType === "INCOMES") {
          incomes.push({ title: item.category.categoryName, value: +item.localAmountInPercents, color: item.category.colour });
        }
      });
      setDataOutcomes(outcomes);
      setDataIncomes(incomes);
    });
  }, [dateFrom, dateTill]);

  const translation = categoryLocale => {
    const { locale, translation } = categoryLocale;
    return locale === language || (locale === "uk" && language === "ua") ? (
      <div className={classes.categoryName} key={locale}>
        <span>{translation}</span>
      </div>
    ) : null;
  };

  const goToCategoriesHandler = React.useCallback(
    categoryId => {
      const state = { dateFrom, dateTill };
      const queryParams = {
        fromMonth: new Date(dateFrom).getMonth(),
        toMonth: new Date(dateTill).getMonth(),
        fromYear: new Date(dateFrom).getFullYear(),
        toYear: new Date(dateTill).getFullYear()
      };
      API.getOperationsByCategoryId(categoryId, queryParams)
        .then(() => {
          history.push({
            pathname: `/home/financial-assistant/${categoryId}`,
            state
          });
          setError(null);
        })
        .catch(error => {
          if (error.response) {
            const { errorDescription } = error.response.data;
            if (errorDescription.split(":")[0] === "Detailed_info_available_for_period_from") {
              setError(text("financialAssistant.detailedInfoAvailableError") + errorDescription.split(":")[1]);
              window.scrollTo(0, 0);
            } else if (errorDescription === "No_available_detailed_info") {
              setError(text("financialAssistant.noDetailedInfoError"));
              window.scrollTo(0, 0);
            } else {
              history.push({
                pathname: `/home/financial-assistant/${categoryId}`,
                state
              });
            }
          }
        });
    },
    [dateFrom, dateTill, history, text]
  );

  const changeHandlerFrom = date => {
    if (date > maxDate) {
      setDateFrom(maxDate);
    } else if (date < minDate) {
      setDateFrom(minDate);
    } else {
      setDateFrom(date);
    }
  };

  const changeHandlerTill = date => {
    if (date > maxDate) {
      setDateTill(maxDate);
    } else if (date < minDate) {
      setDateTill(minDate);
    } else {
      setDateTill(date);
    }
  };

  return (
    <div className={classes.financialAssistant}>
      <div className={classes.top}>
        <Title text={text("navigationVertical.financialAssistant")} />
      </div>
      <div className={classes.bottom}>
        <div className={classes.inputMonthBlock}>
          <div style={{ marginLeft: "25px" }}>
            <MonthPicker
              labelText={text("financialAssistant.startPeriod")}
              changeHandler={changeHandlerFrom}
              date={dateFrom}
              minDate={minDate}
              maxDate={maxDate}
            />
          </div>
          <div style={{ marginLeft: "25px" }}>
            <MonthPicker
              labelText={text("financialAssistant.finishPeriod")}
              changeHandler={changeHandlerTill}
              date={dateTill}
              minDate={minDate}
              maxDate={maxDate}
            />
          </div>
        </div>

        {error && (
          <div className={classes.errorWrapper}>
            <Error message={error} />
          </div>
        )}

        <div className={classes.infoBlock}>
          <div className={classes.pieChart}>
            {dataOutcomes.length !== 0 ? (
              <PieChart
                data={dataOutcomes}
                viewBoxSize={[300, 300]}
                center={[150, 150]}
                radius={100}
                animate={true}
                animationDuration={900}
                animationEasing={"ease-out"}
              />
            ) : (
              <div className={classes.defaultPieChart}>
                <span className={classes.text}>{text("financialAssistant.defaultText")}</span>
              </div>
            )}
          </div>
          <div className={classes.outcomes}>
            <div className={classes.title}>
              <span>{text("financialAssistant.totalCostsPeriod")}</span>
              <span className={classes.amount}>
                {localeAmount(outlayAmount)} <span>UAH</span>
              </span>
            </div>
            <span className={classes.subtitle}>{text("financialAssistant.costsByCategory")}</span>
            <div className={classes.outlayCategories}>
              {outlayCategories.map(item => (
                <div className={classes.category} key={item.category.categoryId} onClick={() => goToCategoriesHandler(item.category.categoryId)}>
                  <img className={classes.categoryIcon} src={`${iconURL}/${item.category.categoryIcon}.svg`} alt="icon" />
                  <span style={{ color: `${item.category.colour}` }}>{item.localAmountInPercents} %</span>
                  {item.category.categoryLocalization.map(categoryLocale => translation(categoryLocale))}
                  <div className={classes.localAmount}>
                    <span>{localeAmount(item.agregatedLocalAmount)}</span>
                    <span className={classes.currency}>UAH</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={classes.infoBlock}>
          <div className={classes.pieChart}>
            {dataIncomes.length !== 0 ? (
              <PieChart
                data={dataIncomes}
                viewBoxSize={[300, 300]}
                center={[150, 150]}
                radius={100}
                animate={true}
                animationDuration={1200}
                animationEasing={"ease-out"}
              />
            ) : (
              <div className={classes.defaultPieChart}>
                <span className={classes.text}>{text("financialAssistant.defaultText")}</span>
              </div>
            )}
          </div>

          <div className={classes.incomes}>
            <div className={classes.title}>
              <span>{text("financialAssistant.totalIncomePeriod")}</span>
              <span className={classes.amount}>
                +{localeAmount(incomeAmount)} <span>UAH</span>
              </span>
            </div>
            <span className={classes.subtitle}>{text("financialAssistant.incomeByCategory")}</span>
            <div className={classes.incomeCategories}>
              {incomeCategories.map(item => (
                <div className={classes.category} key={item.category.categoryId} onClick={() => goToCategoriesHandler(item.category.categoryId)}>
                  <img className={classes.categoryIcon} src={`${iconURL}/${item.category.categoryIcon}.svg`} alt="icon" />
                  <span style={{ color: item.category.colour }}>{item.localAmountInPercents} %</span>
                  {item.category.categoryLocalization.map(categoryLocale => translation(categoryLocale))}
                  <div className={classes.localAmount}>
                    <span>+{localeAmount(item.agregatedLocalAmount)}</span>
                    <span className={classes.currency}>UAH</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialAssistant;
