import axios from "../axios";

export const getAuthSession = authHeader =>
  axios({
    method: "get",
    url: "auth/session",
    headers: {
      Authorization: authHeader
    }
  });
 
export const getAuthExtended = () => axios.get("auth/extended");

export const postAuthLoginOtp = payload => axios.post("auth/login/otp", payload);

export const signupCheck = payload => axios.post("auth/register/user/check", payload);

export const signupConfirm = (authHeader, payload) =>
  axios.post("auth/register/user/confirm", payload, {
    headers: {
      Authorization: authHeader
    }
  });

// export const getEmailChallenge = email =>
//   axios.get("auth/confirmation/email/challenge", {
//     params: {
//       confirmationEntityValue: email
//     }
//   });

// export const postEmailResponse = (params, data) =>
//   axios.post("auth/confirmation/email/response", {
//     params,
//     data
//   });

export const postSuretyRecordsFindForActivation = authHeader =>
  axios.post(
    "auth/surety/records/find-for-activation",
    {},
    {
      headers: {
        Authorization: authHeader
      }
    }
  );

export const postSuretyRecordsRequestActivation = authHeader =>
  axios.post(
    "auth/surety/records/request-activation",
    {},
    {
      headers: {
        Authorization: authHeader
      }
    }
  );

export const postSuretyRecordsActivate = (authHeader, payload) =>
  axios.post("auth/surety/records/activate", payload, {
    headers: {
      Authorization: authHeader
    }
  });

export const postLoginPassword = payload => axios.post("auth/login/login-password", payload);

export const postPasswordReset = payload => axios.post("auth/requestPasswordReset", payload);

export const postPasswordSave = (authHeader, payload) =>
  axios.post("auth/resetPassword", payload, {
    headers: {
      Authorization: authHeader
    }
  });

export const postPasswordChange = payload => axios.post("auth/changePassword", payload);

export const postLogout = () => axios.post("auth/logout");
