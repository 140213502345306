import React, { useContext, useEffect, useState } from "react";
import classes from "./PasswordRecovery.module.scss";
import { authContext, languageContext } from "../../../context/index";
import { Button, Error, Input, LanguageSwitch, Title } from "../../../components/index";
import * as API from "../../../api/index";
import { fieldErrorMessage, passwordRecoveryCardSchema, passwordRecoveryIbanSchema, validationError } from "../../../validation/index";
import { useTitle } from "../../../hooks/index";
import serverErrorMessages from "../../../helpers/serverErrorMessages";
import cn from "../../../helpers/cn";

const PasswordRecovery = ({ history }) => {
  const { language, setLanguage, text } = useContext(languageContext);
  const { setTemporaryPasswordRecoveryAuthHeader } = useContext(authContext);

  const [phone, setPhone] = useState("");
  const [card, setCard] = useState("");
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const globalErrorMessage = fieldErrorMessage("global", error);

  const submit = async () => {
    clearTimeout(PasswordRecovery.timeout);
    setError(null);
    const phoneModified = phone.split(/\s|_/).join("");
    const modifiedCard = card.split(/\s|_/).join("");
    const error = validationError(/^U/.test(modifiedCard) ? passwordRecoveryIbanSchema(language) : passwordRecoveryCardSchema(language), {
      phone: phoneModified,
      account: modifiedCard
    });
    if (error) {
      setError(error);
      return;
    }
    setIsFetching(true);
    try {
      const { headers } = await API.postPasswordReset({
        phone: phoneModified,
        login: phoneModified,
        contractIdentifier: modifiedCard,
        identificationType: /^UA/.test(modifiedCard) ? "ACCOUNT_NUMBER" : "CARD_NUMBER"
      });
      setTemporaryPasswordRecoveryAuthHeader(headers.authorization);
      history.push("/auth/password-saving");
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
    }
    setIsFetching(false);
  };

  useTitle(text("passwordRecovery.title"));

  useEffect(() => {
    if (error) {
      PasswordRecovery.timeout = setTimeout(() => {
        setError(null);
      }, 6000);
    }
  }, [error]);

  useEffect(() => {
    return () => {
      clearTimeout(PasswordRecovery.timeout);
    };
  }, []);

  const [type, setType] = React.useState("NONE");

  return (
    <div className={classes.wrapper}>
      <LanguageSwitch className={classes.languageSwitch} currentLanguage={language} changeHandler={value => setLanguage(value)} />
      <Title className={classes.title} text={text("passwordRecovery.title")} />
      {globalErrorMessage && <Error className={classes.error} message={globalErrorMessage} />}
      <Input
        className={classes.input}
        errorClassName={classes.error}
        labelText={text("passwordRecovery.phoneNumber")}
        mask={"+38 \\099 999 99 99"}
        value={phone}
        changeHandler={value => setPhone(value)}
        errorMessage={fieldErrorMessage("phone", error)}
      />
      <Input
        className={classes.input}
        errorClassName={classes.error}
        labelText={text("passwordRecovery.bankAccount")}
        value={card}
        changeHandler={setCard}
        mask={type === "NONE" ? "ua99 9999 9999 99999999999999999" : type === "IBAN" ? "UA99 999999 9999999999999999999" : "9999 9999 9999 9999"}
        beforeMaskedValueChange={newState => {
          let { value, selection } = newState;
          let newType = "NONE";
          let newValue = (value || "")
            .toUpperCase()
            .replace(/Г/i, "U")
            .replace(/Ф/i, "A");
          if (/^(U|UA|UA[\d\s]*)$/i.test(newValue)) {
            newType = "IBAN";
          } else if (/^[\d\s]+$/i.test(newValue)) {
            newType = "CARD";
          }
          if (type !== newType) {
            setType(newType);
          }
          return { value: newValue, selection };
        }}
        maskChar={null}
        formatChars={{
          u: "[0-9UuГг]",
          a: "[0-9AaФф]",
          "9": "[0-9]",
          U: "[UuГг]",
          A: "[AaФф]"
        }}
        errorMessage={fieldErrorMessage("account", error)}
      />
      <div className={classes.buttons}>
        <Button className={cn(classes.button, classes.buttonBack)} clickHandler={() => history.push("/auth/login")}>
          {text("buttons.back")}
        </Button>
        <Button className={cn(classes.button, classes.buttonSubmit)} clickHandler={submit} isDisabled={isFetching}>
          {text("passwordRecovery.button")}
        </Button>
      </div>
    </div>
  );
};

export default PasswordRecovery;
