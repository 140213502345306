import React, { useContext, useState, useCallback } from "react";
import AuthContext from "./authContext";
import axios from "../../axios";
import { modalContext } from "../index";

const AuthState = ({ children }) => {
  const { setModal } = useContext(modalContext);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState({});
  const [temporaryPasswordRecoveryAuthHeader, _setTemporaryPasswordRecoveryAuthHeader] = useState(null);

  const removeAuthenticationData = useCallback(() => {
    delete axios.defaults.headers.common["Authorization"];
    sessionStorage.removeItem("token");
    setIsAuthenticated(false);
    setUser({});
    clearTimeout(AuthState.timeout);
    setModal(null);
  }, [setModal]);

  const setAuthenticationData = useCallback(
    res => {
      axios.defaults.headers.common["Authorization"] = res.headers.authorization;
      sessionStorage.setItem("token", res.headers.authorization);
      setIsAuthenticated(true);
      setUser({
          ...res.data.userInfo,
          clientId: res.data.profileAttributes.find(item => item.key === "clientId")?.value,
          taxId: res.data.profileAttributes.find(item => item.key === "taxId")?.value,
      });
      AuthState.timeout = setTimeout(() => {
        removeAuthenticationData();
      }, res.data.expireDate - res.data.creationDate - 300000);
    },
    [removeAuthenticationData]
  );

  const setTemporaryPasswordRecoveryAuthHeader = useCallback(authHeader => {
    _setTemporaryPasswordRecoveryAuthHeader(authHeader);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        user,
        setUser,
        setAuthenticationData,
        removeAuthenticationData,
        temporaryPasswordRecoveryAuthHeader,
        setTemporaryPasswordRecoveryAuthHeader
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;
