let timeout;
function debounce(callback, wait) {
  // let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => callback.apply(this, args), wait);
  };
}

export default debounce;
