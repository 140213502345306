import React, { useContext, useState, useEffect } from "react";
import classes from "./App.module.scss";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { languageContext, authContext, modalContext, otpContext } from "./context/index";
import Home from "./containers/Home/Home.jsx";
import Auth from "./containers/Auth/Auth.jsx";
import { Spinner } from "./components/index";
import ModalWrapper from "./hocs/ModalWrapper/ModalWrapper.jsx";
import * as API from "./api/index";
import "moment/locale/uk";
import "moment/locale/ru";
import Notifications from "react-notify-toast";
import OtpWrapper from "./hocs/OtpWrapper/OtpWrapper";

const App = () => {
  const { setLanguage } = useContext(languageContext);
  const { isAuthenticated, setAuthenticationData } = useContext(authContext);
  const { otp } = useContext(otpContext);
  const { modal } = useContext(modalContext);

  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    (async () => {
      const updateLanguage = () => {
        const { language } = localStorage;
        if (language) setLanguage(language);
      };
      updateLanguage();

      // const watchServerErrors = () => {
      //   axios.interceptors.response.use(null, err => {
      //     const { status } = err.response;
      //     if (status === 401) {
      //       setIsAuthenticated(false);
      //       sessionStorage.removeItem("token");
      //     }
      //     return Promise.reject(err);
      //   });
      // };
      // watchServerErrors();

      const updateAuth = async () => {
        const { token } = sessionStorage;
        if (token) {
          try {
            const resSession = await API.getAuthSession(token);
            setAuthenticationData(resSession);
          } catch (err) {
            console.log(err);
          }
        }
      };
      await updateAuth();

      setIsShown(true);
    })();
  }, [setLanguage, setAuthenticationData]);

  return isShown ? (
    <div className={classes.wrapper}>
      <Switch>
        <Redirect exact from="/" to="/home" />
        <Route path="/home" render={() => (isAuthenticated ? <Home /> : <Redirect to="/auth/login" />)} />
        <Route path="/auth" render={() => (!isAuthenticated ? <Auth /> : <Redirect to="/home" />)} />
        <Redirect to="/home" />
        <Route render={() => <div>Not found</div>} />
      </Switch>
      {modal && <ModalWrapper>{modal}</ModalWrapper>}
      {otp && <OtpWrapper>{otp}</OtpWrapper>}
      <Notifications />
    </div>
  ) : (
    <Spinner className={classes.spinner} />
  );
};

export default withRouter(App);
