import React, { useContext, useState, useRef, useEffect } from "react";
import classes from "./Deposits.module.scss";
import { languageContext, modalContext } from "../../../context/index";
import { withRouter, Route } from "react-router-dom";
import { connect } from "react-redux";
import { Carousel, DepositTile, OperationResult } from "../../../components";
import Deposit from "./Deposit/Deposit.jsx";
import { useTitle } from "../../../hooks/index";
import * as actions from "../../../store/actions/index";
import ChangeTitle from "../../../components/modals/ChangeTitle/ChangeTitle";
import * as API from "../../../api";
import serverErrorMessages from "../../../helpers/serverErrorMessages";
import { genContractKey } from "../../../helpers/contract";

const Deposits = ({ history, location, deposits, readContracts }) => {
  const { language, text } = useContext(languageContext);
  const { setModal } = useContext(modalContext);

  const depositId = location.pathname.split("/")[3];

  const initialDepositIndex = useRef(0);

  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    readContracts("deposit");
  }, [readContracts]);

  useEffect(() => {
    if (depositId) {
      const depositIndex = deposits.findIndex(deposit => deposit.id === depositId);
      if (depositIndex > 0) {
        initialDepositIndex.current = depositIndex;
      } else {
        history.push(`/home/deposits/${deposits[0].id}`);
      }
      setIsShown(true);
    } else {
      setIsShown(false);
      history.push(`/home/deposits/${deposits[0].id}`);
      initialDepositIndex.current = 0;
      setTimeout(() => {
        setIsShown(true);
      }, 10);
    }
  }, [deposits, depositId, history]);

  useTitle(text("deposits.title"));

  const updateDepositTitle = i => current => async () => {
    let title = current;
    await new Promise(resolve => {
      setModal(
        <ChangeTitle
          value={title}
          clickContinueHandler={value => {
            title = value;
            setModal(null);
            resolve();
          }}
        />
      );
    });
    try {
      const deposit = deposits[i];
      await API.setProfileAttributes([
        {
          key: "own_name_" + genContractKey(deposit.providerId, deposit.id),
          value: title
        }
      ]);
      readContracts(deposit.providerId);
      setModal(
        <OperationResult
          titleText={text("accounts.modal.titleText.success")}
          buttonText={text("buttons.complete")}
          clickCompleteHandler={() => setModal(null)}
        />
      );
    } catch (err) {
      setModal(
        <OperationResult
          result="failure"
          text={serverErrorMessages(err, language)}
          buttonText={text("buttons.close")}
          clickCompleteHandler={() => setModal(null)}
        />
      );
    }
  };

  return (
    <div className={classes.deposits}>
      {isShown && (
        <>
          <Carousel
            className={classes.carousel}
            slideHeight={210}
            mode="single"
            initialSlide={initialDepositIndex.current}
            changeHandler={i => history.push(`/home/deposits/${deposits[i].id}`)}
          >
            {deposits.map((deposit, i) => (
              <DepositTile
                className={classes.depositTile}
                height={208}
                name={deposit.productTitle}
                totalAmount={deposit.balance}
                currency={deposit.mainAccountCurrency}
                interestAmount={deposit.accruedInterest}
                interestRate={deposit.currentInterestRate}
                hasOpenButton={false}
                key={i}
              />
            ))}
          </Carousel>
          {deposits.map((deposit, i) => (
            <Route
              exact
              path={`/home/deposits/${deposit.id}`}
              render={() => (
                <Deposit
                  deposit={deposit}
                  clickFillHandler={() => history.push(`/home/transactions/outer-to-inner?receiver=${genContractKey(deposit.providerId, deposit.id)}`)}
                  clickWithdrawHandler={() => history.push(`/home/transactions/outer-to-inner?sender=${genContractKey(deposit.providerId, deposit.id)}`)}
                  clickOrderHandler={() => history.push("/home/deposits/order")}
                  clickTitleHandler={updateDepositTitle(i)}
                  readContracts={readContracts}
                />
              )}
              key={i}
            />
          ))}
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ contracts }) => ({ deposits: contracts.deposits });

const mapDispatchToProps = dispatch => ({
  readContracts: contractType => dispatch(actions.readContracts(contractType))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Deposits));
