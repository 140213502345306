const en = {
  buttonCancel: "Close",
  // CONTAINERS
  home: {
    placeholders: {
      accounts: "You have no accounts",
      credits: "You have no credits",
      deposits: "You have no deposits"
    }
  },
  financialAssistant: {
    startPeriod: "The beginning of the period:",
    finishPeriod: "End of period:",
    defaultText: "There is no data to display on the specified dates",
    totalCostsPeriod: "Total costs in the period:",
    costsByCategory: "Costs by category:",
    totalIncomePeriod: "Total income in the period:",
    incomeByCategory: "Income by categories:",
    category: {
      backToStatistic: "Back to statistics",
      changeTitle: "Change category",
      assignCategory: "Assign a category manually:"
    },
    detailedInfoAvailableError: "Detailed information on transactions is available from: ",
    noDetailedInfoError: "Detailed information on operations is not available"
  },
  regularPayments: {
    placeholder: "Search for regular payments",
    addPaymentButton: "Add a new payment",
    title: "My regular payments",
    noPayments:
      "There are no regular payments. This can be created in “The transaction history“ menu on the basis of transactions that have already been carried out",
    noPaymentsBySearch: "There are no regular payments for this search",
    edit: "Edit payment",
    history: "History",
    detailed: "Payment details",
    active: "Activate",
    deactive: "Deactivate",
    createRegularPaymentTitle: "Create a regular payment",
    editRegularPaymentTitle: "Edit regular payment",
    paymentHistory: {
      title: "History of regular payments",
      period: "Period",
      sendReceipt: "Send a receipt",
      detailsOperation: "Details of the operation",
      success: "Successfully",
      fail: "Unsuccessful",
      noPaymentsHistory: "There is no history of regular payments"
    },
    deactivate: {
      title: "Deactivation of regular payment",
      text: "Are you sure you want to deactivate regular payment?",
      buttonDeactivate: "Deactivate",
      textSuccess: "Your regular payment has been deactivated!",
      textFail: "Regular payment is not deactivated."
    },
    activate: {
      title: "Activation of regular payment",
      text: "Are you sure you want to activate your regular payment?",
      buttonActivate: "Activate",
      textSuccess: "Your regular payment is activated!",
      textFail: "Regular payment is not activated."
    },
    deleteModals: {
      title: "Delete regular payment",
      text: "Warning! Regular payment will be deleted without recovery.",
      buttonDelete: "Delete",
      textDeletedSuccess: "Your regular payment has been removed!",
      textDeletedFail: "Regular payment not deleted."
    },
    detailedModals: {
      title: "Details of regular payment",
      titleOperation: "Operation details",
      active: "Active",
      deactive: "Deactivated",
      status: "Status"
    },
    update: {
      textSuccess: "Your regular payment has been updated and saved!",
      textFail: "Regular payment not updated."
    }
  },
  feedback: {
    title: {
      initial: "Feedback",
      final: "Request sent!"
    },
    text: "Order a call"
  },
  contacts: {
    title: "Branches and ATMs",
    select: {
      placeholder: "Department type",
      options: {
        atms: "ATMs",
        departments: "Branches"
      }
    }
  },
  cards: {
    title: "Cards",
    createNewCardText: "Create new card",
    modal: {
      pin: {
        question: 'Are you sure you want to change your PIN code?',
        success: 'Your PIN has been changed',
        failure: 'An error occurred when changing the PIN code. Please try again later',
        action: 'Enter a new PIN code',
      },
      titleText: {
        success: "Name changed",
        blocked: "Card blocked",
        unblocked: "Card unblocked",
        changeLimits: "The limits have been changed",
        changeLimitsWithNotification: "The limits you specify exceed the maximum possible. The maximum allowed values will be set",
        unSuccessOperationTitle: "Failed. Please try again later or contact us by phone. 0 800 300 170"
      }
    }
  },
  card: {
    title: "Card",
    rechargeButton: "Recharge card",
    transferButton: "Between your accounts",
    transactionButton: "Transfer to other accounts",
    fuckingButton: "Change transaction limits",
    limitsButton: "Change transaction limits",
    creditButton: "Application to set/change your credit limit",
    blockButton: "Block card",
    unblockButton: "Unblock card",
    changePinButton: "Change PIN code",
    tabs: {
      history: "Operations history",
      details: "Details"
    },
    details: {
      number: "Contract number",
      validTo: "Valid to",
      status: "Status",
      active: "Active",
      inactive: "Inactive",
      currentBalance: "Current balance",
      creditLimit: "Credit limit",
      debt: "Debt",
      availableFunds: "Available credit funds",
      interestRate: "Interest on balance",
      accountNumber: "Account number",
      cvvCode: "CVV - code",
      cardType: "Card type",
      iban: {
        copy: 'Copy the account number',
        success: 'The account number has been copied',
      },
      pan: {
        copy: 'Copy the card number',
        success: 'The card number has been copied',
      },
      cvv: {
        copy: 'Copy the CVV code',
        success: 'The CVV code has been copied',
      },
      limit: {
        balance: "Available balance",
        ownFunds: "Own funds",
        currentInterestRateFormatted: "Interest on balance, %",
        notUsedCreditAmountFormatted: "Available Credit limit",
        creditLimitFormatted: "Credit limit set",
        usedCreditAmountFormatted: "Used credit funds",
        loanDealInterestRate: "Effective interest rate for the loan",
        endGracePeriodDate: "Grace period end date",
        nextPaymentAmount: "Mandatory minimum payment (MMP)",
        mandatoryMonthlyPaymentDeadlineDate: "Boundary date for payment MMP",
        legalNumber: "Contract number",
        expiry: "Valid to",
        status: "Card status",
        iban: "Account",
        cardTypeFullName: "Card type",
        perMonth: "in month",
        creditLimit: "Credit limit"
      }
    }
  },
  cardCredit: {
    // title: "Заявка на встановлення/зміну кредитного ліміту",
    // limit: "Встановлений Кредитний ліміт",
    // options: {
    //   type1: "Встановити/підвищити кредитний ліміт на картку",
    //   type2: "Знизити кредитний ліміт"
    // },
    // amount: "Бажаний Кредитний ліміт",
    // type: "Тип змін",
    // min: "Мінімальна сума",
    // max: "Максимальна сума",
    zero: "To cancel the credit limit",
    // submit: "Замовити",
    // modal: {
    //   titleText: "Дякуємо",
    //   text: "Вашу заявку відправлено"
    // }
  },
  cardOrder: {
    title: "Ordering a new card",
    labels: {
      cardType: "Card type",
      paymentSystem: "Payment system",
      card: "Card",
      currency: "Currency"
    },
    options: {
      cardType1: "Mriya Card",
      currency1: "UAH"
    },
    modal: {
      titleText: "Thank you",
      text: "Your application has been sent"
    }
  },
  accounts: {
    title: "Accounts",
    modal: {
      titleText: {
        success: "Name changed",
        unSuccessOperationTitle: "Unsuccessful. Please try again later or contact us at tel. 0 800 300 170"
      }
    }
  },
  account: {
    title: "Current account",
    createNewAccountText: "Create new account",
    tabs: {
      history: "Operations history",
      details: "Details"
    },
    details: {
      name: "Account name",
      contractNumber: "Contract number",
      accountNumber: "Account number",
      interestRate: " Interest on balance",
      openingDate: "Opening date",
      time: "Time without prolongation",
      rechargeable: "Refill allowed",
      balance: "Balance",
      replenishmentAmount: "Max. amount of replenishment",
      withdrawals: "Early withdrawal of funds",
      endDate: "End date"
    }
  },
  requisites: {
    details: {
      params: "Copy details for replenishment",
      button: "Details",
    },
    modal: {
      title: "Account replenishment details",
      confirm: "Copy",
      cancel: "Cancel",
      success: "The details have been copied",
    },
  },
  credits: {
    title: "Credits",
    modal: {
      titleText: {
        success: "Name changed",
        unSuccessOperationTitle: "Unsuccessful. Please try again later or contact us at tel. 0 800 300 170"
      }
    }
  },
  credit: {
    closeCreditButton: "Close credit",
    tabs: {
      history: "Operations history",
      details: "Details"
    },
    details: {
      name: "Credit name",
      number: "Number",
      rate: "Rate",
      monthlyPayment: "Monthly payment",
      debt: "Total loan debt",
      term: "Loan term",
      minRepaymentAmount: "Min repayment amount",
      date: "Monthly scheduled repayment date"
    }
  },
  deposits: {
    title: "Deposits",
    modal: {
      changeProlongation: {
        success: "Операція виконана успішно",
        unSuccessOperationTitle: "Неуспішно. Будь ласка, спробуйте пізніше або зв'яжіться з нами за тел. 0 800 300 170"
      },
      titleText: {
        success: "Name changed",
        unSuccessOperationTitle: "Unsuccessful. Please try again later or contact us at tel. 0 800 300 170"
      }
    }
  },
  deposit: {
    rechargeButton: "Replenish deposit",
    transactionButton: "Withdraw deposit",
    orderButton: "Open a new deposit",
    tabs: {
      history: "Operations history",
      details: "Details"
    },
    details: {
      name: "Deposit name",
      number: "Number",
      openingDate: "Opening date",
      time: "Time without prolongation",
      termTypeDay: "day",
      termTypeDays: "days",
      rate: "Interest rate",
      monthlyPayment: "Monthly payment",
      capitalization: "Capitalization",
      rechargeable: "Refill allowed",
      minReplenishmentAmount: "Min. amount of replenishment",
      maxReplenishmentAmount: "Max. amount of replenishment",
      withdrawals: "Early withdrawal of funds",
      endDate: "End date",
      automaticProlongation: "Automatic prolongation",
      yes: "Yes",
      no: "No"
    }
  },
  depositOrderConditions: {
    title: "Deposit selection",
    labels: {
      currency: "Currency",
      amount: "Deposit amount",
      term: "Deposit term"
    },
    term: "m.",
    termTypeM: "m.",
    termTypeD: "d.",
    termTypeD95: "3 months (95 days)",
    noDepositByThisParams: "Unable to pick up deposit on specified parameters",
    tableLable: {
      annualRate: "Annual rate",
      replenishment: "Replenishment",
      autoProlongation: "Autoprolongation",
      partialWithdrawal: "Partial withdrawal",
      payment: "Payout",
      INSTANT: "At end of term",
      MONTH: "Monthly",
      amountIncome: "Amount of income"
    },
    buttons: {
      approve: "Select"
    }
  },
  depositOrderNew: {
    title: "Deposit",
    labels: {
      currency: "Currency",
      amount: "Deposit amount",
      term: "Term",
      interestRate: "Amount of % rate",
      prolongation: "Autoprolongation",
      refill: "Replenishment",
      capitalization: "Capitalization %",
      interestPayment: "Payment %",
      account: "Account for replenishment/payments",
      confirm1: "Я ознайомлений з",
      confirm1_1: "з Публічною пропозицією,",
      confirm2: "довідкою ФГВФО",
      confirm2_1: "та умовами договору",
      licence: "Certificate of the FGVFO participant №106 dated November 6, 2012",
      commission: "Commission",
      email: "Email to which the deposit agreement and FGVFO certificate will be sent",
      isProlongation: "Extend the deposit ",
      confirm3: "I confirm that the passport data provided to the Bank have not changed.",
      confirm4: "I confirm that the data provided to the Bank regarding my status of a private entrepreneur/absence of such a status have not changed."
    },
    table: {
      title: "Basic terms and conditions of the bank deposit agreement to be opened",
      subTitle: "Please check the data:",
      row1: "Вид вкладу",
      row2: "Розмір вкладу, валюта",
      row3: "Строк зберігання коштів",
      row4: "Нарахування Банком процентів на Вклад здійснюється з розрахунку відсотків річних",
      row5: "Капіталізація відсотків",
      row6: "Дострокове припинення вкладу",
      row7: "Штрафна процентна ставка при достроковому поверненні",
      row8: "Можливість автоматичного продовження строку зберігання коштів",
      row9: "Часткове знаття коштів з вкладного (депозитного) рахунку",
      row10: "Можливість поповнення вкладу",
      row11: "Виплата процентів за Вкладом здійснюється",
      row12: "Мінімальна сума поповнення",
      row12_1: "Сума максимального поповнення",
      row13: "Номер рахунку для зарахування процентів та повернення суми Вкладу",
      row14: "Імейл, на який буде надіслано депозитний договір та довідку ФГВФО"
    },
    placeholder: "Select an account",
    buttons: {
      approve: "Apply",
      open: "Відкрити депозит"
    },
    modal: {
      titleText: "Thank you!",
      text: "Your deposit has been successfully processed"
    }
  },
  profile: {
    title: "My cabinet",
    templates: "My templates",
    card: "Cards",
    accounts: "Accounts",
    credits: "Credits",
    deposits: "Deposits"
  },
  transactions: {
    title: "Transactions"
  },
  transactionOptions: {
    innerToInner: "Inside the AP Bank",
    innerToOuter: "To another bank card",
    outerToInner: "At your own account",
    outerToOuter: "From card to card of other banks",
    innerToCustom: "According to the details",
    exchange: "Currency exchange",
    payments: "Payments",
    title: "Transactions"
  },
  exchangeTransaction: {
    transaction_with_commission: 'Including commission',
    transaction_without_commission: 'Not including commission',
    noUAH: "There is no account in the required currency to perform the transaction",
    alreadyCreated: "The account is already open",
    title: "Currency exchange",
    sender: "Withdraw from account / card",
    recipient: "Credit to account / card",
    select: {
      placeholder: "Select an account"
    },
    debitAmount: "Amount of withdrawal",
    creditAmount: "Credit amount",
    buttonUpdate: "Update Course",
    buttonCancel: "Cancel",
    buttonConfirm: "Exchange",
    labels: {
      fromMyAccount: "From account or card",
      toAccount: "To account or card"
    },
    rate: "Exchange rate",
    ttl: "Course duration",
    placeholder: "Select an account",
    openAccount: "Open account in",
    failureModal: "Sorry, the purchase of currency online is limited by the requirements of the NBU, use the bank's cash desks"
  },
  accountOrder: {
    title: "Open account in",
    labels: {
      email: "E-mail address to which the account opening agreement will be sent",
      confirm1: "I confirm that I have read and agree with",
      confirm1_1: "the Public Offer",
      confirm1_2: "Tariffs and rates",
      confirm1_3: "the Fund Certificate",
      confirm1_4: "and",
      confirm1_5: "the Terms of the Agreement",
      confirm2: "the Fund's Certificate",
      confirm2_1: "and the terms and conditions of the Agreement",
      confirm3: "I confirm that the passport data provided to the Bank have not changed.",
      confirm4: "I confirm that the data provided to the Bank regarding my status of a private entrepreneur/absence of such a status have not changed."
    },
    buttons: {
      open: "Open account"
    },
    modal: {
      titleText: "Thank you!",
      text: "Your account has been successfully ordered"
    }
  },
  innerToInnerTransaction: {
    title: "Transfer within the AP Bank",
    fromMyAccount: "From account or card",
    toMyAccount: "To account or card",
    transferAmount: "Transfer amount",
    commission: "Сommission",
    buttonCancel: "Cancel",
    buttonConfirm: "Transfer",
    select: {
      placeholder: "Select an account"
    },
    pressTransfer: 'By clicking "Submit", I agree to',
    conditions: "the terms of the public offering"
  },
  innerToOuterTransaction: {
    title: "Transfer to a card of other banks",
    sender: "Sender",
    tooltipCVV_1: "Type in CVV/CVC code of the sender card.",
    tooltipCVV_2: "The code is located on the reverse side and",
    tooltipCVV_3: "consists of 3 digits on the paper strip.",
    cardNumber: "Card Number",
    recipient: "Recipient",
    transferAmount: "Transfer amount",
    commission: "Commission",
    pressTransfer: 'By clicking "Submit", I agree to',
    conditions: "the terms of the public offering",
    buttonCancel: "Cancel",
    buttonConfirm: "Transfer",
    select: {
      placeholder: "Select a card"
    }
  },
  outerToInnerTransaction: {
    title: "Transfer to my account",
    sender: "Sender",
    cardNumber: "Card number",
    date: "Date",
    recipient: "Recipient",
    select: {
      placeholder: "Select an account"
    },
    transferAmount: "Transfer amount",
    commission: "Commission",
    pressTransfer: 'By clicking "Submit", I agree to',
    conditions: "the terms of the public offering",
    buttonCancel: "Cancel",
    buttonConfirm: "Transfer",
    options: {
      innerToInner: "From your own account",
      outerToInner: "From another bank card",
      credit: "Credit",
      deposit: "Deposit"
    },
    labels: {
      type: "Type",
      fromMyAccount: "From account or card",
      toAccount: "To account or card"
    },
    placeholder: "Select an account"
  },
  outerToOuterTransaction: {
    title: "Transfer from card to card of other banks",
    sender: "Sender",
    cardNumber: "Card Number",
    date: "Date",
    recipient: "Recipient",
    select: {
      placeholder: "Select an account"
    },
    transferAmount: "Transfer amount",
    commission: "Commission",
    pressTransfer: 'By clicking "Submit", I agree to',
    conditions: "the terms of the public offering",
    buttonCancel: "Cancel",
    buttonConfirm: "Transfer"
  },
  innerToCustomTransaction: {
    title: "Transfer by requisites",
    fromAccount: "From account or card",
    recipientName: "Name of recipient",
    receiverPlaceholder: "Full name or name of recipient",
    mfo: "Bank name",
    mfoPlaceholder: "Enter the number",
    taxNumber: "IIN / EDRPOU",
    beneficiaryNumber: "Beneficiary account",
    beneficiaryNumberTip: "For Ukraine, IBAN consists of 29 alphanumeric characters: ",
    beneficiaryNumberTip2: "UA code - 2 letters; check digit - 2 digits;",
    beneficiaryNumberTip3: "bank code - 6 digits; account number - 5 to 19",
    taxNumberTip: "Valid field values: EDRPOU / ITN / passport number ID-card /",
    taxNumberTip2: "series (capital letters in Cyrillic) and passport number,",
    taxNumberTip3: "spaces are not allowed",
    paymentPurpose: "Payment purpose",
    paymentPurposePlaceholder: "Enter text",
    paymentPurposeWarnHolder: "Please specify the purpose of the operation in detail",
    transferAmount: "Transfer amount",
    select: {
      placeholder: "Select an account"
    },
    commission: "Commission",
    buttonCancel: "Cancel",
    buttonConfirm: "Transfer",
    pressTransfer: 'By clicking "Submit", I agree to',
    conditions: "the terms of the public offering",
    paymentTypeCode: "Payment type code",
    additionalPaymentInfo: "Additional payment information",
    actualPayerName: "Full name of the actual payer",
    actualPayerTaxIdOrPassport: "Tax ID / passport field",
    actualPayerPlaceholder: "Fill in if paying for a third party",
  },
  templates: {
    placeholder: "Search for templates",
    addTemplateButton: "Add a new template",
    title: "My templates",
    noTemplates: "No templates",
    modals: {
      createSuccess: {
        titleText: "Thank you",
        text: "Your template has been successfully created"
      },
      updateSuccess: {
        titleText: "Thank you",
        text: "Your template has been successfully updated"
      },
      deleteSuccess: {
        titleText: "Thank you",
        text: "Your template has been successfully deleted"
      }
    }
    // communityServicesTitle: "Utilities",
    // other: "Others",
  },
  history: {
    title: "History of operations",
    tabs: {
      financial: "Financial",
      service: "Service",
      authorization: "Authorization"
    },
    receipt: {
      sendReceipt: "Send the receipt",
      downloadReceipt: "Download the receipt",
      sendReceiptSuccess: "Your receipt has been sent to the specified E-mail",
      sendReceiptFail: "Your receipt was not sent to the specified E-mail"
    },
    buttons: {
      saveTemplate: "Save template",
      createRegularPayment: "Create a regular payment",
      repeatOperation: "Repeat the operation"
    }
  },
  payments: {
    title: "Services and payments",
    placeholder: "Enter the company name",
    inputLable: "I want to pay:",
    success: "Your operation was successful!",
    failure: "Your operation has not been completed",
    processing: "Your operation is being processed"
  },
  settings: {
    title: "Settings",
    avatar: {
      tooltip_add: "Add",
      tooltip_change: "Change",
      save: "Save",
      delete: "Delete"
    },
    personalData: {
      dataTitle: "Personal data",
      labelText: "Full name"
    },
    contacts: {
      dataTitle: "Contact details",
      phoneNumber: "Phone number",
      emailAddress: "E-mail",
      loginForEntry: "Login for entry"
    },
    password: {
      dataTitle: "Password",
      currentPassword: "Current password",
      newPassword: "New password",
      confirmationPassword: "Confirmation of new password",
      passwordInformation: "At least 6 characters (Latin, at least one upper and lower case letter and one digit"
    },
    modal: {
      titleText: "Password changed",
      text: "You will be redirected to the login page"
    }
  },
  signup: {
    title: "New user registration",
    phoneNumber: "Phone number",
    typeOfId: "Type of ID",
    select: {
      cardNumber: "Card number",
      dealNumber: "Contract number"
    },
    bankAccount: "Card number or IBAN",
    inputIdentificator: "ID",
    name: "Name",
    lastName: "Last name",
    email: "E-mail address",
    password: "Your password",
    confirmPassword: "Please repeat your password",
    passwordInformation: "At least 6 characters (Latin, at least one upper and lower case letter and one digit",
    familiar: "I confirm that I am acquainted with and agree with",
    familiarNext: "the terms of the Agreement with the bank",
    familiar2: "and",
    familiarNext2: "the Deposit Guarantee Fund’s information",
    buttonBack: "Back",
    buttonRegistration: "Sign up"
  },
  login: {
    title: "Login",
    loginInput: "Login",
    passwordInput: "Password",
    passwordRecovery: "I forgot my password",
    buttonConfirm: "Sign in",
    buttonRegistration: "Registration",
    error: {
      loginError: "Login does not exist",
      errorPassword: "Invalid login or password"
    }
  },
  passwordRecovery: {
    title: "Password recovery",
    phoneNumber: "Enter your phone number",
    cardNumber: "Card number",
    bankAccount: "Card number or IBAN",
    email: "Email",
    button: "Recover password"
  },
  passwordSaving: {
    title: "Password recovery",
    codeSMS: "Enter the SMS code",
    newPassword: "New password",
    confirmPassword: "Password confirmation",
    passwordInformation: "At least 6 characters (Latin, at least one upper and lower case letter and one number",
    buttonSave: "Save password"
  },
  //COMPONENTS
  header: {
    phoneDescription: "toll-free in Ukraine",
    feedback: "Request a call back",
    department: "Branches and ATMs",
    exchange: "Currency converter"
  },
  navigationHorizontal: {
    cards: "Cards",
    accounts: "Accounts",
    credits: "Credits",
    deposits: "Deposits"
  },
  navigationVertical: {
    myCabinet: "My cabinet",
    transactions: "Transactions",
    payments: "Payments",
    templates: "My templates",
    history: "Operations history",
    financialAssistant: "Financial assistant",
    settings: "Settings",
    exit: "Exit",
    regularPayment: "Regular payment",
    news: "News"
  },
  news: {
    title: "News"
  },
  footer: {
    licence: "NBU banking license No 241 of 16.02.2016",
    depositSecurity: "Your deposit is secured by",
    fond: "Deposit Guarantee Fund",
    securityText: "All rights reserved.",
    bankName: "JSC «AP BANK»"
  },
  exchange: {
    buy: "Buy",
    sell: "Sell",
    bank: "Bank's exchange rates",
    central: "NBU exchange rates",
    online: "Online exchange rates",
    button: "Currency exchange"
  },
  converter: {
    title: "Currency converter",
    labels: {
      give: "Give",
      get: "Get",
      currency: "Сurrency"
    },
    buttons: {
      calculate: "Calculate"
    }
  },
  cardLimit: {
    titles: {
      changeLimits: "Change card limits",
      confirmation: "Confirm your changes to the card limits"
    },
    subtitles: {
      dailyWithdrawal: "Cash withdrawal per day",
      dailyPurchase: "Shopping per day",
      generalLimit: "General limit"
    },
    labels: {
      amount: "Amount",
      quantity: "Number of transactions",
      code: "Confirmation code"
    },
    confirmation: {
      text: "Your changes will take effect in a few minutes. Please confirm the changes you made to apply them.",
      warningText:
        "Pay attention! The amount of the limit and the number of transactions should be set taking into account the unsuccessful transactions performed during the current day."
    }
  },
  accountTile: {
    name: "Current account",
    options: {
      history: "History",
      fillAccount: "Add funds",
      withdrawAccount: "Transfer from account",
      pay: "Pay"
    }
  },
  creditTile: {
    loanAmount: "Loan amount",
    remains: "Remains to pay",
    before: "before",
    options: {
      history: "History",
      pay: "Pay off"
    }
  },
  depositTile: {
    depositAmount: "Deposit amount",
    interestAmount: "Interest amount",
    interestRate: "Interest rate",
    options: {
      history: "History",
      fill: "Refill",
      withdraw: "Withdraw an amount"
    }
  },
  templateTile: {
    options: {
      edit: "Edit template",
      delete: "Delete"
    }
  },
  templateSettings: {
    title: {
      createTemplate: "Create a template",
      editTemplate: "Edit the template"
    },
    text: "By clicking «Continue», I accept",
    linkText: "conditions of the public offer",
    textCreatedSuccess: "Regular payment successfully created",
    textCreatedFail: "No regular payment was created",
    labels: {
      type: "Template type",
      typePayment: "Type of regular payment",
      namePayment: "Name of regular payment",
      fromContract: "From account / card",
      toContract: "To account / card",
      toContractForSep: "To account",
      purpose: "Purpose of payment",
      taxNumber: "IIN / EDRPOU",
      regularPaymentSchedule: "Regular payment schedule",
      namePaymentApprove: "Payment name",
      regularPaymentScheduleApprove: "Payment schedule",
      startDate: "Date of first payment",
      finishDate: "Period of validity before",
      everyDay: "Every day",
      everyWeek: "Weekly",
      everyMonth: "Monthly",
      freePeriod: "Arbitrary period",
      every: "every",
      day: "День",
      week: "Week",
      month: "Month",
      commission: "Commission"
    },
    options: {
      types: {
        innerToInner: "Between own accounts / cards",
        bankInner: "Within the Bank",
        innerToCustom: "According to the details",
        mobile: "Mobile communication and telephony"
      }
    },
    select: {
      placeholder: "Select an account"
    },
    templateName: "Template Name",
    transferAmount: "Transfer amount",
    noCommission: "No commission",
    minimizeAdditionalSettings: "Minimize",
    expandAdditionalSettings: "Expand",
    additionalSettings: " advanced settings",
    regularPayment: "Regular payment",
    expanded: {
      description: "Description",
      textareaPlaceholder: "Enter text",
      iconsColor: "Icons color",
      templateIcon: "Template icon"
    },
    buttonCancel: "Cancel",
    buttonSave: "Save template",
    buttonDelete: "Delete template",
    buttonCreateRegularPayment: "Create a payment",
    buttonEditRegularPayment: "Save payment",
    buttonContinue: "Continue"
  },
  historyFilter: {
    showBy: "Show by",
    placeholder: "Custom period",
    selectDate: "Select dates",
    byType: "By operation and category type",
    typeOptions: {
      all: "All",
      server: {
        EXCHANGE_CURRENCY: "Currency exchange",
        SEP_TRANSFER: "According to the details",
        BILLER_PAYMENT: "Services and payments",
        CONTRACT_TO_CONTRACT: "Between own accounts",
        INTRABANK_TRANSFER: "Within the bank",
        OUTER_CARD_TO_OUTER_CARD: "From card to card",
        CARD_TO_CONTRACT: "From another bank's card",
        UNLOCK_CARD: "Card unblocking",
        LOCK_CARD: "Card blocking",
        CHANGE_LIMITS: "Limits change",
        LOGIN: "Authorization",
        MAKE_CONTRACT: "Opening a deposit",
        CARD_TO_CARD: "To another bank's card",
        CUSTOM: "Other",
      }
    }
  },
  dateRange: {
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    start: "Beginning of the period",
    end: "End of period",
    buttonClean: "Clear",
    buttonCancel: "Cancel",
    buttonConfirm: "Apply"
  },
  dailyOperations: {
    success: "Success",
    failure: "Failure",
    WAIT_FOR_PROCESSING: "In the process of processing",
    noDescription: "No description",
    authorization: "Authorization",
    labels: {
      creationDate: "Creation date",
      executionDate: "Execution date",
      sender: "Sender",
      senderAccount: "Sender account",
      fullName: "Full name",
      receiver: "Receiver",
      receiverAccount: "Receiver account",
      name: "Name",
      status: "Payment / transaction status",
      amount: "Amount of payment / transaction (no commission)",
      commission: "Commission",
      finalAmount: "Total amount of payment / transaction"
    },
    serviceAutoprolongationOptions: {
      deactivate: "Prolongation deactivation",
      activate: "Prolongation activation"
    },
    autopayment: {
      autopaymentActivate: "Autopayment activation",
      autopaymentClose: "Autopayment deletion",
      autopaymentCreate: "Creating autopayment",
      autopaymentDeactivate: "Autopayment deactivation",
      autopaymentEdit: "Edit autopayment"
    },
    changeLimits: "Limits change",
    card: {
      changePin: "Change PIN code",
      lockCard: "Card lock",
      unLockCard: "Unlocking the card"
    },
    changeCreditLimitSetRequestOperation: "Application for setting the credit limit",
    changeCreditLimitUpRequestOperation: "Application for increasing the credit limit",
    changeCreditLimitDownRequestOperation: "Application for decreasing the credit limit",
    changeCreditLimitCancelRequestOperation: "Application for canceling the credit limit"
  },
  confirmationCode: {
    title: "Enter the verification code"
  },
  changeTitle: {
    title: "Enter a product name"
  },
  select: {
    noOptions: "No options"
  },
  transactionSuccess: {
    gratitude: "Thank you",
    success: "Transaction completed successfully",
    question: "Would you like to add transaction details to templates"
  },
  suggestionTile: {
    card: {
      title: "Mriya Card",
      description: "get up to 7% per annum on the balance and pay for free in the retail network and the Internet"
    },
    app: {
      title: "AP Bank Online-banking",
      description: "perform banking operations 24/7 – fast, convenient, reliable!"
    }
  },
  // MICS
  labels: {
    phone: "Phone number (without +380)",
    yes: "Так",
    no: "Ні",
    allowed: "Передбачено",
    notAllowed: "Не передбачено"
  },
  paymentTermTypes: {
    INSTANT: "В кінці строку",
    MONTH: "Щомісяця"
  },
  buttons: {
    confirm: "Сonfirm",
    close: "Close",
    showMore: "Show more",
    back: "Back",
    continue: "Continue",
    complete: "Complete",
    changePassword: "Change password",
    cancel: "Cancel",
    saveChanges: "Save changes",
    pay: "Pay",
    addToTemplates: "Add to templates",
    orderNewCard: "Order a card",
    download: "Download this app",
    submitApplication: "Submit application",
    createRegular: "Create a regular payment"
  },
  errors: {
    purposeNeedDetails: "Please specify the purpose of the payment",
    required: "Required field",
    wrong_iban: "Incorrect IBAN",
    wrong_card: "Incorrect card number",
    taxNumMin: "Wrong length State Registry code (EDRPOU)",
    taxNumRules:
      "Valid field values: EDRPOU / ITN / passport number ID-card / series (capital letters in Cyrillic) and passport number, spaces are not allowed",
    innRules:
      "Valid field values: ITN / passport number ID-card / series (capital letters in Cyrillic) and passport number, spaces are not allowed",
    taxNumEmpty: "If there is no ITN, enter the passport series (if any) and number of the individual who has given up their ITN, no spaces allowed",
    emailErrorMessage: "Please enter the correct email address",
    passwordErrorMessage: "The password does not match the specified format",
    confirmedPasswordErrorMessage: "Passwords must be the same",
    noOperations: "No operations",
    server: {
      default: "Unsuccessful. Please try again later or contact us by phone 0 800 300 170",
      period_is_large: "The selected period is too long. Please select a shorter period",
      biller_not_found: "The recipient's company was not found",
      invalid_change_password_parameters:
        "Error setting the password. Please make sure the password is at least 6 characters long, has a minimum of 1 digit and one uppercase letter",
      insufficient_funds: "There is not enough money on the account to perform this operation",
      client_already_exists: "Login is not unique. Please enter a different value",
      client_not_found: "User not found",
      client_profile_not_found: "User not found",
      contragent_not_found: "The recipient's contragent was not found",
      contract_not_found: "Contract/agreement not found",
      deny_operation: "Operation is prohibited by bank rules",
      extended_authentication_required: "You need to authenticate using a one-time password",
      password_attempts_exceeded:
        "Number of input attempts exhausted. To complete the registration, log in by entering the Login and Password you created during the registration",
      session_expired: "The current session was outdated or closed",
      user_already_binded_to_identifier: "Your account is already linked to a bank account",
      wrong_authentication_level:
        "There is insufficient access level for the current session for this operation. Please try logging in and entering your password",
      wrong_credentials: "Wrong authentication data",
      wrong_login_and_password_credentials: "Invalid data specified",
      wrong_otp_credentials: "Invalid one-time password",
      wrong_otp_credentials_attempt_count_exceed: "Invalid one-time password",
      wrong_parameters: "Incorrectly filled data. Please check input parameters",
      max_refill_amount_violated: "Exceeded replenishment amount for this deposit",
      max_withdrawal_amount_violated: "Exceeded allowed debit amount for this deposit",
      min_refill_amount_violated: "The deposit amount is too small for this",
      refill_not_allowed: "Replenishment of this deposit is forbidden",
      withdrawal_not_allowed: "Premature debit from this deposit is forbidden",
      invalid_data: "Invalid transfer parameters",
      invalid_password: "The password is incorrect",
      invalid_user_registration_parameters: "Invalid registration information: check the policy for password and login",
      code_attempts_exceeded: "Exceeded the number of code attempts",
      limit_violation_min: "The amount of the transaction cannot be less ",
      limit_violation_max: "The amount of the transaction cannot be more ",
      limit_violation: "The operation is prohibited by the Bank's rules.",
      unsupported_limit_value: "Invalid value. \nThe allowed value of the limit amount is 0.01, \nthe minimum number of operations is 1",
      io_integration_error: "Unsuccessful. Please try again later or contact us by phone 0 800 300 170",
      unknown_integration_error: "Unsuccessful. Please try again later or contact us by phone 0 800 300 170",
      wrong_bankid_or_account_number: "Invalid recipient details",
      invalid_user_registration_parameters_login_does_not_match_security_rules:
        "Login must contain min. 6 characters (uppercase/lowercase latin letters, numbers)",
      invalid_user_registration_parameters_password_does_not_match_security_rules:
        "Password must contain min. 6 characters (uppercase/lowercase latin letters, numbers)",
      suretyrecords_error: "Invalid one-time password",
      account_temporary_locked: 'Access is temporarily blocked, to unlock - use the "Forgot password" function',
      wrong_biller_account: "It is not possible to make payment using these details",
      wrong_credentials_registration_are_denied: "Error during registration. Check the data or contact the Bank by phone 0 800 300 170",
      wrong_credentials_reset_password_are_denied: "Authentication failed. Check the data or contact the Bank by phone 0 800 300 170",
      wrong_credentials_credentials_already_in_use: "Error during registration. Check the data or contact the Bank by phone 0 800 300 170",
      invalid_email: "Incorrect email format",
      wrong_iban: "Incorrect IBAN",
      wrong_card: "Incorrect card number",
      iban_is_invalid: "Incorrect IBAN",
      specific_integration_error_operation_cannot_be_performed: "Unfortunately, the execution of this operation is not possible. Please contact the bank at 0800 300 170",
    }
  },
  dateRanges: {
    today: "Today",
    yesterday: "Yesterday",
    week: "Week",
    month: "Month",
    year: "Year",
    all: "All"
  },
  notifications: {
    copiedToBuffer: "Copied to clipboard"
  },
  support: {
    efse_eu4b: "Implemented with the assistance and financing of the European Union"
  }
};

export default en;
