import React, { useContext, useState, useCallback } from "react";
import classes from "./Card.module.scss";
import PropTypes from "prop-types";
import {languageContext, modalContext} from "../../../../context/index";
import { Title, Button, Icon, ContractHistory, NavigationTabs, ProductDetails } from "../../../../components";
import * as API from "../../../../api/index";
import localeAmount from "../../../../helpers/localeAmount";
import { getAttribute } from "../../../../store/getters/contracts";
import { downloadPDFFile } from "../../../../helpers/downloadPDFFile";
import Requisites from "../../../modals/Requisites/Requisites";

const Card = ({
  card,
  account,
  clickOuterToInnerTransactionHandler,
  clickInnerToInnerTransactionHandler,
  clickInnerToOuterTransactionHandler,
  clickChangeLimitHandler,
  clickStatusHandler,
  clickTitleHandler,
  clickCreditHandler
}) => {
  const { text } = useContext(languageContext);
  const { setModal } = useContext(modalContext);

  const [activeTab, setActiveTab] = useState("history");

  const tabs = [
    {
      value: "history",
      label: text("card.tabs.history")
    },
    {
      value: "details",
      label: text("card.tabs.details")
    }
  ];

  const fetchCardHistoryHandler = useCallback(params => API.getCardHistory(account.id, { ...params, cardId: card.id, withCard: true }), [account.id, card.id]);
  const fetchCardPDFHandler = useCallback(
    params =>
      API.getFormatPDFByProvider(account.providerId, account.id, params).then(result => {
        const { data } = result;
        downloadPDFFile(data);
      }),
    [account.id, account.providerId]
  );

  return (
    <>
      <div className={classes.top}>
        <Title
          text={getAttribute(card.attributes, "productTitle")}
          onClick={clickTitleHandler && (() => clickTitleHandler(getAttribute(card.attributes, "productTitle") || card.productTitle || text("card.title")))}
        />
        <div className={classes.buttons}>
          <Button className={classes.button} clickHandler={clickOuterToInnerTransactionHandler}>
            <Icon name="fillCard" />
            &nbsp;&nbsp;{text("card.rechargeButton")}
          </Button>
          <Button className={classes.button} clickHandler={clickInnerToInnerTransactionHandler}>
            <Icon name="transferCard" />
            &nbsp;&nbsp;{text("card.transferButton")}
          </Button>
          <Button className={classes.button} clickHandler={clickInnerToOuterTransactionHandler}>
            <Icon name="transactionCard" />
            &nbsp;&nbsp;{text("card.transactionButton")}
          </Button>
          {/*<Button className={classes.button} clickHandler={clickChangeLimitHandler}>*/}
          {/*  <Icon name="limitsCard" />*/}
          {/*  &nbsp;&nbsp;{text("card.limitsButton")}*/}
          {/*</Button>*/}
          {/*{account.creditLimitFormatted && (*/}
          {/*  <Button className={classes.button} clickHandler={clickCreditHandler}>*/}
          {/*    <Icon name="cardCredit" />*/}
          {/*    &nbsp;&nbsp;{text("card.creditButton")}*/}
          {/*  </Button>*/}
          {/*)}*/}
          {/*<Button className={classes.button} clickHandler={clickStatusHandler}>*/}
          {/*  <Icon name={card.status === "ACTIVE" ? "padlock" : "padlockUnlocked"} />*/}
          {/*  &nbsp;&nbsp;{text(card.status === "ACTIVE" ? "card.blockButton" : "card.unblockButton")}*/}
          {/*</Button>*/}
        </div>
      </div>
      <div className={classes.bottom}>
        <NavigationTabs className={classes.navigationTabs} tabs={tabs} activeTab={activeTab} changeTabHandler={value => setActiveTab(value)} />
        {activeTab === "history" && <ContractHistory fetchHandler={fetchCardHistoryHandler} fetchPDFHandler={fetchCardPDFHandler} />}
        {activeTab === "details" && (
          <ProductDetails
            classname={classes.productDetails}
            rows={
              account.creditLimitFormatted
                ? [
                    { label: `${text("card.details.limit.balance")}`, value: `${localeAmount(account.balance)} ${account.mainAccountCurrency}` },
                    { label: `${text("card.details.limit.ownFunds")}`, value: `${account.ownFunds} ${account.mainAccountCurrency}` },
                    { label: `${text("card.details.limit.currentInterestRateFormatted")}`, value: `${account.currentInterestRateFormatted}%` },
                    { label: ``, value: `` },
                    {
                      label: `${text("card.details.limit.notUsedCreditAmountFormatted")}`,
                      value: `${account.notUsedCreditAmountFormatted} ${account.mainAccountCurrency}`
                    },
                    {
                      label: `${text("card.details.limit.creditLimitFormatted")}`,
                      value: `${account.creditLimitFormatted} ${account.mainAccountCurrency}`
                    },
                    {
                      label: `${text("card.details.limit.usedCreditAmountFormatted")}`,
                      value: `${account.usedCreditAmountFormatted} ${account.mainAccountCurrency}`
                    },
                    {
                      label: `${text("card.details.limit.loanDealInterestRate")}`,
                      value: `${account.loanDealInterestRate}% ${text("card.details.limit.perMonth")}`
                    },
                    {
                      label: `${text("card.details.limit.endGracePeriodDate")}`,
                      value: `${account.endGracePeriodDate}`
                    },
                    {
                      label: `${text("card.details.limit.nextPaymentAmount")}`,
                      value: `${account.nextPaymentAmount} ${account.mainAccountCurrency}`
                    },
                    {
                      label: `${text("card.details.limit.mandatoryMonthlyPaymentDeadlineDate")}`,
                      value: `${account.mandatoryMonthlyPaymentDeadlineDate}`
                    },
                    { label: ``, value: `` },
                    {
                      label: `${text("card.details.limit.legalNumber")}`,
                      value: `${account.legalNumber}`
                    },
                    {
                      label: `${text("card.details.limit.expiry")}`,
                      value: `${getAttribute(card.attributes, "expiryDayOfMonth")}/${("0" + card.expiryMonth).slice(-2)}/${card.expiryYear}`
                    },
                    {
                      label: `${text("card.details.limit.status")}`,
                      value: text(`card.details.${card.status === "ACTIVE" ? "active" : "inactive"}`)
                    },
                    {
                      label: `${text("card.details.limit.iban")}`,
                      value: `${account.iban}`
                    },
                    {
                      label: `${text("card.details.limit.cardTypeFullName")}`,
                      value: getAttribute(card.attributes, "cardTypeFullName")
                    },
                    { label: `${text("requisites.details.params")}:`, value: (
                          <Button className={classes.details} clickHandler={() => setModal((<Requisites providerId="card" contractId={account.id} cardId={card.id} />))}>
                            {text("requisites.details.button")}
                          </Button>
                      ) }
                  ]
                : [
                    { label: `${text("card.details.number")}:`, value: account.legalNumber },
                    {
                      label: `${text("card.details.validTo")}:`,
                      value: `${getAttribute(card.attributes, "expiryDayOfMonth")}/${("0" + card.expiryMonth).slice(-2)}/${card.expiryYear}`
                    },
                    { label: `${text("card.details.status")}:`, value: text(`card.details.${card.status === "ACTIVE" ? "active" : "inactive"}`) },
                    { label: `${text("card.details.currentBalance")}:`, value: `${localeAmount(account.balance)} ${account.mainAccountCurrency}` },
                    { label: `${text("card.details.creditLimit")}:`, value: `${localeAmount(account.creditLimit)} ${account.mainAccountCurrency}` },
                    { label: `${text("card.details.debt")}:`, value: `${localeAmount(account.usedCreditLimit)} ${account.mainAccountCurrency}` },
                    {
                      label: `${text("card.details.availableFunds")}:`,
                      value: `${localeAmount(account.creditLimit - account.usedCreditLimit)} ${account.mainAccountCurrency}`
                    },
                    { label: `${text("card.details.accountNumber")}:`, value: account.mainAccountNumber },
                    { label: `${text("card.details.cardType")}:`, value: getAttribute(card.attributes, "cardTypeFullName") },
                    { label: `${text("card.details.interestRate")}, %:`, value: account.currentInterestRate },
                    { label: `${text("requisites.details.params")}:`, value: (
                          <Button className={classes.details} clickHandler={() => setModal((<Requisites providerId="card" contractId={account.id} cardId={card.id} />))}>
                            {text("requisites.details.button")}
                          </Button>
                      ) }
                  ]
            }
          />
        )}
      </div>
    </>
  );
};

Card.propTypes = {
  card: PropTypes.object,
  clickOuterToInnerTransactionHandler: PropTypes.func,
  clickInnerToInnerTransactionHandler: PropTypes.func,
  clickInnerToOuterTransactionHandler: PropTypes.func,
  clickChangeLimitHandler: PropTypes.func,
  clickStatusHandler: PropTypes.func,
  clickTitleHandler: PropTypes.func
};

Card.defaultProps = {
  card: {},
  clickOuterToInnerTransactionHandler: () => {},
  clickInnerToInnerTransactionHandler: () => {},
  clickInnerToOuterTransactionHandler: () => {},
  clickChangeLimitHandler: () => {},
  clickStatusHandler: () => {},
  clickTitleHandler: null
};

export default React.memo(Card);
