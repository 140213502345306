import React from "react";
import classes from "./Spinner.module.scss";
import PropTypes from "prop-types";
import cn from "../../helpers/cn";

const Spinner = ({ className, style, diameter, thickness, color }) => {
  return (
    <div className={cn(classes.spinner, className)} style={style}>
      <div
        className={classes.circle}
        style={{
          width: diameter,
          height: diameter,
          borderWidth: thickness,
          borderColor: color
        }}
      />
    </div>
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  diameter: PropTypes.number,
  thickness: PropTypes.number,
  color: PropTypes.string
};

Spinner.defaultProps = {
  className: "",
  style: {},
  diameter: 100,
  thickness: 5
};

export default Spinner;
