import React from "react";
import classes from "./LanguageSwitch.module.scss";
import PropTypes from "prop-types";
import { Button } from "../../index";
import cn from "../../../helpers/cn";

const LanguageSwitch = ({ className, style, currentLanguage, changeHandler }) => {
  const languages = ["ua", "en"];

  return (
    <div className={cn(classes.languageSwitch, className)} style={style}>
      {languages.map((language, i) => (
        <Button
          style={{
            width: 50,
            height: 24,
            color: "#7ABD43",
            ...(language !== currentLanguage ? { borderColor: "#7ABD43" } : { backgroundColor: "#E8F4DA" }),
            borderRadius: 12
          }}
          clickHandler={() => changeHandler(language)}
          key={i}
        >
          {language.toUpperCase()}
        </Button>
      ))}
    </div>
  );
};

LanguageSwitch.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  currentLanguage: PropTypes.string,
  changeHandler: PropTypes.func
};

LanguageSwitch.defaultProps = {
  className: "",
  style: {},
  currentLanguage: "ua",
  changeHandler: () => {}
};

export default LanguageSwitch;
