import React, { useState, useCallback } from "react";
import ModalContext from "./modalContext";

const ModalState = ({ children }) => {
  const [modal, _setModal] = useState(null);

  const setModal = useCallback(value => {
    _setModal(value);
  }, []);

  return <ModalContext.Provider value={{ modal, setModal }}>{children}</ModalContext.Provider>;
};

export default ModalState;
