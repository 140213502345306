import React, { useContext } from "react";
import classes from "./Header.module.scss";
import PropTypes from "prop-types";
import Icon from "../icons/Icon.jsx";
import LanguageDropdown from "../dropdowns/LanguageDropdown/LanguageDropdown.jsx";
import { languageContext } from "../../context/index";

const Header = ({ clickFeedbackHandler, clickMapHandler, clickConverterHandler, language, clickLanguageHandler }) => {
  const { text } = useContext(languageContext);

  return (
    <div className={classes.wrapper}>
      <div>
        <div className={classes.item}>
          <Icon style={{ marginRight: 5 }} name="phone" />
          <div>0 800 300 170 ({text("header.phoneDescription")})</div>
        </div>
        <div className={classes.item} onClick={clickFeedbackHandler}>
          {text("header.feedback")}
        </div>
        <div className={classes.item} onClick={clickMapHandler}>
          <Icon style={{ marginRight: 5 }} name="pointer" />
          <div>{text("header.department")}</div>
        </div>
        <div className={classes.item} onClick={clickConverterHandler}>
          <Icon style={{ marginRight: 5 }} name="exchange" />
          <div>{text("header.exchange")}</div>
        </div>
      </div>
      <div>
        <LanguageDropdown value={language} changeHandler={value => clickLanguageHandler(value)} />
      </div>
    </div>
  );
};
Header.propTypes = {
  clickFeedbackHandler: PropTypes.func,
  clickMapHandler: PropTypes.func,
  clickConverterHandler: PropTypes.func,
  language: PropTypes.string,
  clickLanguageHandler: PropTypes.func
};

Header.defaultProps = {
  clickFeedbackHandler: () => {},
  clickMapHandler: () => {},
  clickConverterHandler: () => {},
  language: "ua",
  clickLanguageHandler: () => {}
};

export default Header;
