export const icons = {
  "portmone:1002": "paymentsUtilities",
  "portmone:1170": "paymentsCredits",
  "portmone:1003": "paymentsInternet",
  "portmone:1006": "paymentsTelephony",
  "portmone:2000": "paymentsGoods",
  "portmone:1004": "paymentsTelecommunications",
  "portmone:1005": "paymentsInsurance",
  "portmone:1080": "paymentsPeriodicals",
  "portmone:1191": "paymentsTransport",
  "portmone:1050": "paymentsSecurity",
  "portmone:1070": "paymentsTickets",
  "portmone:1090": "paymentsCharity",
  "portmone:1100": "paymentsBudget",
  "portmone:1001": "paymentsMobile",
  "portmone:1131": "paymentsFinancialServices",
  "portmone:1041": "paymentsFinancialServices",
  "portmone:1040": "paymentsEducation",
  "portmone:1030": "paymentsDistribution"
};

const ZERRRRRRO = 10000;

export const urlBiller = "/home/payments/categories/:categoryId/biller/:billerId";
export const urlCategory = "/home/payments/categories/:categoryId";
export const urlCategories = "/home/payments/categories";
export const urlSearchInCategory = "/home/payments/categories/:categoryId/search/:search";
export const urlSearchInCategories = "/home/payments/categories/search/:search";
export const urlSearchInCategoryWOV = "/home/payments/categories/:categoryId/search";
export const urlSearchInCategoriesWOV = "/home/payments/categories/search";
export const urlPortmoneIcon = "https://www.portmone.com.ua/r3/resources/payees/i/128x128/";
export const urlDefaultIcon = "/bank/biller/icon/default.png";

export const deepCopy = payload => JSON.parse(JSON.stringify(payload));

export const firstLoad = payload => {
  payload.destination.billDetails.filter(details => details.name === "billed_amount").forEach(details => (details.oldValue = details.value));

  payload.destination.meters
    .filter(meter => meter["amount"] !== undefined)
    .forEach(meter => (meter["amount"] = (parseFloat(meter["amount"]) / 100).toFixed(2)));

  return recalc(payload);
};

export const recalc = payload => {
  payload.destination.meters
    .filter(meter => meter["calculated"] === "Y")
    .forEach(meter => {
      meter["difference"] = (parseFloat(meter["newValue"] || "0") * ZERRRRRRO - parseFloat(meter["oldValue"] || "0") * ZERRRRRRO) / ZERRRRRRO;

      if (meter["originDebt"] === undefined && meter["debt"] !== undefined) {
        meter["originDebt"] = meter["debt"];
        meter["debt"] = String(Math.abs(meter["debt"])) || "0.00";
      }

      if (meter["originDebt"] !== undefined) {
        let totalAmount = parseFloat(meter["amount"] || "0") * ZERRRRRRO - parseFloat(meter["subsidy"] || "0") * ZERRRRRRO;

        if (meter["originDebt"] < 0) {
          totalAmount -= parseFloat(meter["debt"] || "0") * ZERRRRRRO;
        }

        if (meter["originDebt"] > 0) {
          totalAmount += parseFloat(meter["debt"] || "0") * ZERRRRRRO;
        }

        if (totalAmount < 0) {
          totalAmount = 0;
        }

        meter["totalAmount"] = (totalAmount / ZERRRRRRO).toFixed(2);
      }
    });

  payload.destination.billDetails
    .filter(details => details.name === "billed_amount")
    .forEach(details => {
      details.value = (
        payload.destination.meters
          .filter(meter => meter.sectionId === details.sectionId)
          .reduce((acc, meter) => acc + parseFloat(meter["totalAmount"] || "0") * ZERRRRRRO, 0) / ZERRRRRRO
      ).toFixed(2);
    });

  payload.amount = (
    payload.destination.billDetails
      .filter(details => details.name === "billed_amount" && details.value)
      .reduce((acc, details) => acc + parseFloat(details.value) * ZERRRRRRO, 0) / ZERRRRRRO
  ).toFixed(2);

  return payload;
};

export const prepareToSend = (payload, contract) => {
  const request = deepCopy(payload);

  request.srcContractRef = {
    providerId: contract?.providerId,
    contractId: contract?.contractId
  };
  request.cardId = contract?.cardId;

  request.amount = request.amount && (request.amount * 100).toFixed(0);
  request.destination.meters.forEach(meter => {
    meter.amount = meter.amount && (meter.amount * 100).toFixed(0);
    if (meter.originDebt !== undefined) {
      meter.debt = Math.abs(meter.debt) * (meter.originDebt < 0 ? -1 : 1);
    }
  });
  request.currency = "UAH";

  return request;
};
