import React, { useContext } from "react";
import classes from "./ModalWrapper.module.scss";
import PropTypes from "prop-types";
import { modalContext } from "../../context/index";
import Icon from "../../components/icons/Icon";

const ModalWrapper = ({ children }) => {
  const { setModal } = useContext(modalContext);

  return (
    <div className={classes.wrapper}>
      <div className={classes.window}>
        {children}
        <Icon
          style={{
            position: "absolute",
            right: 15,
            top: 15,
            width: 25,
            height: 25,
            cursor: "pointer"
          }}
          name="close"
          clickHandler={() => setModal(null)}
        />
      </div>
    </div>
  );
};

ModalWrapper.propTypes = {
  children: PropTypes.element
};

ModalWrapper.defaultProps = {
  children: null
};

export default ModalWrapper;
