import React, { useContext, useState, useEffect } from "react";
import classes from "./CardOrder.module.scss";
import { languageContext, modalContext, authContext } from "../../../../context/index";
import { Title, Error, Select, Button, OperationResult } from "../../../../components";
import { cardOrderSchema, validationError, fieldErrorMessage } from "../../../../validation/index";
import cn from "../../../../helpers/cn";
import * as API from "../../../../api/index";
import { usePrevious } from "../../../../hooks";
import serverErrorMessages from "../../../../helpers/serverErrorMessages";

const CardOrder = ({ history }) => {
  const { modal, setModal } = useContext(modalContext);
  const { language, text } = useContext(languageContext);
  const { user } = useContext(authContext);

  const [cardType, setCardType] = useState(null);
  const [paymentSystem, setPaymentSystem] = useState("Visa");
  const [card, setCard] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [error, setError] = useState(null);

  const cardTypeOptions = [
    {
      value: "Картка Мрія",
      label: text("cardOrder.options.cardType1")
    }
  ];

  const paymentSystemOptions = [
    {
      value: "Visa",
      label: "Visa",
      iconName: "visa"
    }
  ];

  const cardOptions = [
    {
      value: "Visa Classic Instant",
      label: "Visa Classic Instant"
    },
    {
      value: "Visa Classic",
      label: "Visa Classic"
    },
    {
      value: "Visa Gold",
      label: "Visa Gold"
    }
  ];

  const currencyOptions = [
    {
      value: "UAH",
      label: text("cardOrder.options.currency1")
    }
  ];

  const globalErrorMessage = fieldErrorMessage("global", error);

  const submit = async () => {
    clearTimeout(CardOrder.timeout);
    setError(null);
    const error = validationError(cardOrderSchema(language), {
      cardType,
      paymentSystem,
      card,
      currency
    });
    if (error) {
      setError(error);
      return;
    }
    try {
      await API.postFeedback({
        subject: "Замовлення картки",
        message: `${user.fullName} (${user.clientId})\nТип картки: ${cardType}\nПлатіжна система: ${paymentSystem}\nВид картки: ${card}\nВалюта: ${currency}`
      });
      setModal(
        <OperationResult
          titleText={`${text("cardOrder.modal.titleText")}!`}
          text={text("cardOrder.modal.text")}
          buttonText={text("buttons.complete")}
          clickCompleteHandler={() => setModal(null)}
        />
      );
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
    }
  };

  const previousModal = usePrevious(modal);

  useEffect(() => {
    if (previousModal && !modal) {
      history.push("/home/cards");
    }
  }, [previousModal, modal, history]);

  useEffect(() => {
    if (error) {
      CardOrder.timeout = setTimeout(() => {
        setError(null);
      }, 6000);
    }
  }, [error]);

  useEffect(() => {
    return () => {
      clearTimeout(CardOrder.timeout);
    };
  }, []);

  return (
    <div className={classes.cardOrder}>
      <Title className={classes.title} text={text("cardOrder.title")} />
      <div className={classes.content}>
        {globalErrorMessage && <Error className={classes.error} message={globalErrorMessage} />}
        <Select
          className={classes.select}
          errorClassName={classes.error}
          labelText={text("cardOrder.labels.cardType")}
          options={cardTypeOptions}
          value={cardType}
          changeHandler={value => setCardType(value)}
          errorMessage={fieldErrorMessage("cardType", error)}
        />
        <Select
          className={classes.select}
          errorClassName={classes.error}
          labelText={text("cardOrder.labels.paymentSystem")}
          options={paymentSystemOptions}
          value={paymentSystem}
          changeHandler={value => setPaymentSystem(value)}
          errorMessage={fieldErrorMessage("paymentSystem", error)}
        />
        <Select
          className={classes.select}
          errorClassName={classes.error}
          labelText={text("cardOrder.labels.card")}
          options={cardOptions}
          value={card}
          changeHandler={value => setCard(value)}
          errorMessage={fieldErrorMessage("card", error)}
        />
        <Select
          className={classes.select}
          errorClassName={classes.error}
          labelText={text("cardOrder.labels.currency")}
          options={currencyOptions}
          value={currency}
          changeHandler={value => setCurrency(value)}
          errorMessage={fieldErrorMessage("currency", error)}
        />
        <div className={classes.buttons}>
          <Button className={cn(classes.button, classes.buttonBack)} clickHandler={() => history.goBack()}>
            {text("buttons.back")}
          </Button>
          <Button className={cn(classes.button, classes.buttonSubmit)} clickHandler={submit}>
            {text("buttons.submitApplication")} &rarr;
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CardOrder;
