import React, { useState, useEffect, useContext } from "react";
import "./DayPickerCalendar.scss";
import PropTypes from "prop-types";
import moment from "moment";
import Button from "../../Button/Button.jsx";
import OutsideClickHandler from "react-outside-click-handler";
import { Error, Label, Icon } from "../../index";
import cn from "../../../helpers/cn";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils from "react-day-picker/moment";
import localization from "../../../localization/index";
import { languageContext } from "../../../context/index";

const DayPickerCalendar = ({
  labelClassName,
  className,
  errorClassName,
  labelStyle,
  style,
  errorStyle,
  labelText,
  value,
  changeHandler,
  isDisabled,
  placeholder,
  errorMessage,
  disabled
}) => {
  // console.log(value, 'value')
  const { language, text } = useContext(languageContext);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [resetStartButton, setResetStartButton] = useState(value);
  const months = localization[language].dateRange.months;
  const currentYear = new Date().getFullYear();
  const fromMonth = new Date(currentYear, 0);
  const toMonth = new Date(currentYear + 10, 11);
  const [startMonth, setStartMonth] = useState(toMonth);
  const [selectedStartDay, setSelectedStartDay] = useState(null);


  useEffect(() => {
    setSelectedStartDay(new Date(value));
    setStartMonth(new Date(value));
  }, [value]);

  useEffect(() => {
    if (showDatePicker) {
      setSelectedStartDay(new Date(value));
      setStartMonth(new Date(value));
      setResetStartButton(true);
    }
  }, [showDatePicker, value]);


  function YearMonthForm({ date, localeUtils, onChange }) {
    const years = [];
    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
      years.push(i);
    }

    const handleChange = function handleChange(e) {
      const { year, month } = e.target.form;
      onChange(new Date(year.value, month.value));
    };
    return (
      <form className="DayPicker-Caption">
        <select name="month" className="selectMonth" onChange={handleChange} value={date.getMonth()}>

          {months.map((month, i) => (
            <option key={month} value={i}>
              {month}
            </option>
          ))}
        </select>
        <select name="year" className="selectYear" onChange={handleChange} value={date.getFullYear()}>
          {years.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </form>
    );
  }

  const resetStartDate = () => {
    setSelectedStartDay(null);
    setResetStartButton(false);
  };

  // const globalReset = () => {
  //   resetStartDate();
  // };

  const closeHandler = () => {
    setShowDatePicker(false);
    // globalReset();
  };

  const confirmHandler = () => {
    changeHandler(moment(selectedStartDay).format("YYYY-MM-DD"));
    setShowDatePicker(false);
  };

  const handleYearMonthChangeStart = (month, year, day) => {
    setStartMonth(month);
  };

  const handleDayStartDateClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    setSelectedStartDay(day);
    setResetStartButton(true);
  };

  return (
    <>
      {labelText && (
        <Label className={labelClassName} style={{ ...labelStyle, ...(errorMessage ? { color: "#ff7a7c" } : {}) }}>
          {labelText}
        </Label>
      )}
      <OutsideClickHandler
        onOutsideClick={() => {
          setShowDatePicker(false);
        }}
      >
        <div className="wrapper" style={style}>
          <div className={showDatePicker ? "inputWrapActive" : "inputWrap"} onClick={() => setShowDatePicker(!showDatePicker)}>
            {moment(value).format("DD/MM/YY")}
            <Icon className="calendarImg" name="calendar" />
          </div>
          {isDisabled && <div className="plug" />}
          {showDatePicker ? (
            <div className="main">
              <div className="cancelIconWrap">
                <div className="cancelInnerBlock" onClick={closeHandler}>
                  <Icon name="close" />
                </div>
              </div>
              <div className="dateWrapRow">
                <div className="dateWrap">
                  {/* <div className="datepickerHeader"> {text("dateRange.start")}</div> */}
                  <div className="inputWrap">
                    {/* <div className="input">{moment(value).format("DD/MM/YY")}</div> */}
                    <div className="input">{selectedStartDay ? moment(selectedStartDay).format("DD/MM/YY") : ""}</div>

                    {resetStartButton ? (
                      <Icon
                        style={{ marginRight: 10, cursor: "pointer" }}
                        name="clean"
                        clickHandler={() => {
                          resetStartDate();
                        }}
                      />
                    ) : null} 
                  </div>
                  <DayPicker
                    month={startMonth}
                    fromMonth={fromMonth}
                    toMonth={toMonth}
                    localeUtils={MomentLocaleUtils}
                    locale={language === "ua" ? "uk" : language}
                    selectedDays={selectedStartDay}
                    onDayClick={handleDayStartDateClick}
                    disabledDays={
                      disabled ? {before:new Date(disabled)} : {before: new Date(moment().format("YYYY-MM-DD"))
                    }}
                    captionElement={({ date, localeUtils }) => (
                      <YearMonthForm date={date} locale={language === "ua" ? "uk" : language}
                        localeUtils={localeUtils} onChange={handleYearMonthChangeStart} />
                    )}
                  />
                </div>
              </div>
              <div className="buttonsWrap">
                <div className="buttons">
                  {/* <Button
                    style={{
                      width: 200,
                      height: 48,
                      margin: 10,
                      color: "#241F5A",
                      textDecoration: "underline",
                      fontWeight: "bold"
                    }}
                    clickHandler={globalReset}
                  >
                    {text("dateRange.buttonClean")}
                  </Button> */}
                  <Button
                    style={{
                      width: 200,
                      height: 48,
                      margin: 10,
                      color: "#241F5A",
                      backgroundColor: "#fff",
                      borderColor: "#241F5A",
                      fontWeight: "bold"
                    }}
                    clickHandler={closeHandler}
                  >
                    {text("dateRange.buttonCancel")}
                  </Button>
                  <Button
                    className={cn("button", selectedStartDay === null ? "buttonInactive" : "buttonSubmit")}
                    clickHandler={() => {
                      confirmHandler();
                    }}
                    isDisabled={selectedStartDay === null}
                  >
                    {text("dateRange.buttonConfirm")}
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </OutsideClickHandler>
      {errorMessage && <Error className={errorClassName} style={errorStyle} message={errorMessage} />}
    </>
  );
};

DayPickerCalendar.propTypes = {
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  errorClassName: PropTypes.string,
  labelStyle: PropTypes.object,
  style: PropTypes.object,
  errorStyle: PropTypes.object,
  labelText: PropTypes.string,
  value: PropTypes.string,
  changeHandler: PropTypes.func,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  errorMessage: PropTypes.string
};

DayPickerCalendar.defaultProps = {
  labelClassName: "",
  className: "",
  errorClassName: "",
  labelStyle: {},
  style: {},
  errorStyle: {},
  labelText: "",
  value: "",
  changeHandler: () => { },
  placeholder: "дд/мм/рр",
  isDisabled: false,
  errorMessage: ""
};

export default DayPickerCalendar;
