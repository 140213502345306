import React, { useContext, useEffect, useState } from "react";
import classes from "./CardCredit.module.scss";
import { languageContext, modalContext } from "../../../../context/index";
import { Button, ConfirmationCode, Error, Input, Label, OperationResult, Select, Title } from "../../../../components";
import { fieldErrorMessage } from "../../../../validation/index";
import cn from "../../../../helpers/cn";
import * as API from "../../../../api/index";
import serverErrorMessages from "../../../../helpers/serverErrorMessages";
import isCurrency from "validator/lib/isCurrency";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { findCard } from "../../../../store/getters/contracts";
import localeAmount from "../../../../helpers/localeAmount";

const type1 = "UP";
const type2 = "DOWN";

const toCents = (value) => parseInt((value || "").replace(/[\s,.]+/, "")) * 100;
const toFCents = (value) => parseInt((value || "").replace(/[\s,.]+/, ""));

const CardCredit = ({ cards, history }) => {
  const currency = "UAH";
  const { cardId } = useParams();
  const { setModal } = useContext(modalContext);
  const { language, text } = useContext(languageContext);

  const [amount, setAmount] = useState("");
  const [processing, setProcessing] = useState(false);
  const [type, setType] = useState(null);
  const [error, setError] = useState(null);
  const { account } = React.useMemo(() => findCard(cards, cardId), [cards, cardId]);
  const [enroll, setEnroll] = React.useState(null);

  const typeOptions = [
    { value: type1, label: text("cardCredit.options.type1") }
  ];
  if (![null, undefined].includes(account.creditLimitFormatted)) {
    typeOptions.push({ value: type2, label: text("cardCredit.options.type2") });
  }

  const globalErrorMessage = fieldErrorMessage("global", error);

  const handleChange = async (value) => {
    clearTimeout(CardCredit.timeout);
    setError(null);

    try {
      setProcessing(true);
      const response = await API.postCustomEnroll({
        "operationSubType": "changeCreditLimitRequestOperation",
        "changeCreditLimitType": value,
        "amount": 0,
        "contractId": account.id,
        "cardId": cardId
      });
      setEnroll(response.data);
      setType(value);
      setAmount(localeAmount(response.data.creditLimitDefaultAmount));
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
    }
    setProcessing(false);
  }

  const handleUp = () => {
    const calc = toCents(amount) + parseInt(enroll.creditLimitStep);
    if (calc <= parseInt(enroll.maxCreditLimitAmount)) {
      setAmount(localeAmount(calc));
    }
  }

  const handleDown = () => {
    const calc = toCents(amount) - parseInt(enroll.creditLimitStep);
    if (calc >= parseInt(enroll.minCreditLimitAmount)) {
      setAmount(localeAmount(calc));
    } else {
      if (type === 'DOWN') {
        setAmount(localeAmount(0));
      }
    }
  }

  const submit = async () => {
    clearTimeout(CardCredit.timeout);
    setError(null);
    try {
      setProcessing(true);
      const { data } = await API.getAuthExtended();
      const otpCredentials = {
        otp: undefined,
        challenge: data
      };
      await new Promise(resolve => {
        setModal(
          <ConfirmationCode
            clickContinueHandler={code => {
              otpCredentials.otp = code;
              setModal(null);
              resolve();
            }}
          />
        );
      });
      await API.postAuthLoginOtp(otpCredentials);
      await API.postCustomCommit({
        "enrolledOperationId": enroll.id,
        "expectedCreditLimitSumInCents": toCents(amount),
      });
      setModal(
        <OperationResult
          titleText={`${text("cardCredit.modal.titleText")}!`}
          text={text("cardCredit.modal.text")}
          buttonText={text("buttons.complete")}
          clickCompleteHandler={() => setModal(null)}
        />
      );
      history.push(`/home/cards/${cardId}`);
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
    }
    setProcessing(false);
  };

  useEffect(() => {
    if (error) {
      CardCredit.timeout = setTimeout(() => {
        setError(null);
      }, 6000);
    }
  }, [error]);

  useEffect(() => {
    return () => {
      clearTimeout(CardCredit.timeout);
    };
  }, []);

  return (
    <div className={classes.cardOrder}>
      <Title className={classes.title} text={text("cardCredit.title")} />
      <div className={classes.content}>
        {globalErrorMessage && <Error className={classes.error} message={globalErrorMessage} />}

        <div>
          <Label>{text("cardCredit.limit")}</Label>
          <Input
              style={{ width: 250 }}
              value={localeAmount(toFCents(account.creditLimitFormatted || "0.00"))}
              units={account.mainAccountCurrency}
              isDisabled
          />
        </div>
        <Select
          className={classes.select}
          errorClassName={classes.error}
          labelText={text("cardCredit.type")}
          options={typeOptions}
          value={type}
          changeHandler={handleChange}
          errorMessage={fieldErrorMessage("type", error)}
          isDisabled={processing}
        />
        {type && (
            <div>
              <Label>{text("cardCredit.amount")}</Label>
              <Input
                  style={{width: 250}}
                  name="amount"
                  value={amount}
                  changeHandler={value => setAmount(value)}
                  units={currency}
                  commaIsReplacedWithDot
                  validator={value =>
                      isCurrency(value, {
                        digits_after_decimal: [0, 1, 2]
                      })
                  }
                  isDisabled={processing}
                  readOnly
                  errorMessage={fieldErrorMessage("amount", error)}
                  upDown
                  up={handleUp}
                  down={handleDown}
              />
              <div>
                {text("cardCredit.min")} - {localeAmount(enroll.minCreditLimitAmount)} {currency}
              </div>
              {type === "UP" && (
                  <div>
                    {text("cardCredit.max")} - {localeAmount(enroll.maxCreditLimitAmount)} {currency}
                  </div>
              )}
              {type === "DOWN" && (
                  <div>
                    {text("cardCredit.zero")} - 0.00 {currency}
                  </div>
              )}
            </div>
        )}
        <div className={classes.buttons}>
          <Button className={cn(classes.button, classes.buttonBack)} clickHandler={() => history.goBack()}>
            {text("buttons.back")}
          </Button>
          <Button
            className={cn(classes.button, classes.buttonSubmit)}
            clickHandler={submit}
            isDisabled={!type || processing}
          >
            {text("cardCredit.submit")} &rarr;
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ contracts }) => ({ cards: contracts.cards });
const mapDispatchToProps = () => ({});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(CardCredit));
