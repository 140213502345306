import React, { useState } from "react";
import classes from "./FinancialOperation.module.scss";
import PropTypes from "prop-types";
import { Icon, ProductDetails } from "../../index";
import cn from "../../../helpers/cn";

const FinancialOperation = ({ isContractOperation, id, time, description, amount, status, 
  localeAmount, currency, detailsRows, operationType,row }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={classes.financialOperation}>
      <div className={classes.row}>
        <div className={classes.time}>{time}</div>
        {isContractOperation ? <Icon className={classes.icon} name={amount > 0 ? "income" : "outcome"} /> : <Icon className={classes.icon} name="income" />}
        {status === "SUCCESS" ? <Icon name="successSmallIcon" className={classes.smallIcon} /> : <Icon name="warningSmallIcon" className={classes.smallIcon} />}
        <div className={classes.description} title={description}>
          {description}
        </div>
        {isContractOperation ? (
          <div className={cn(classes.amount, amount > 0 ? classes.amountPositive : classes.amountNegative)} title={`${localeAmount} ${currency}`}>
            {`${amount > 0 ? "+" : ""}${localeAmount} ${currency}`}
          </div>
        ) : (
            <div className={classes.amount} title={`${localeAmount} ${currency}`}>
              {localeAmount} {currency}
            </div>
          )}
        {!!detailsRows.length && (
          <div className={classes.iconWrapper} onClick={() => setIsOpen(isOpen => !isOpen)}>
            <Icon style={{ ...(isOpen ? { transform: "rotate(180deg)" } : {}) }} name="selectArrow" />
          </div>
        )}
      </div>
      {isOpen && (
        <div className={classes.dropdown}>
          <ProductDetails classname={classes.details} rows={detailsRows} status={status} 
          id={id} operationType={operationType} row={row}/>
        </div>
      )}
    </div>
  );
};

FinancialOperation.propTypes = {
  isContractOperation: PropTypes.bool,
  time: PropTypes.string,
  description: PropTypes.string,
  amount: PropTypes.number,
  localeAmount: PropTypes.string,
  currency: PropTypes.string,
  detailsRows: PropTypes.array
};

FinancialOperation.defaultProps = {
  isContractOperation: false,
  time: "",
  description: "",
  amount: null,
  localeAmount: "",
  currency: "",
  detailsRows: []
};

export default FinancialOperation;
