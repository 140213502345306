import React, { useContext, useState } from "react";
import classes from "./ChangeTitle.module.scss";
import PropTypes from "prop-types";
import { Title, Input, Button } from "../../index";
import { useError } from "../../../hooks/index";
import { languageContext } from "../../../context/index";

const ChangeTitle = ({ value, clickContinueHandler }) => {
  const { text } = useContext(languageContext);

  const [title, setTitle] = useState(value);

  const [error, setError] = useError(null);

  const submit = () => {
    setError(null);
    if (!title) {
      setError(text("errors.required"));
      return;
    }
    clickContinueHandler(title);
  };

  return (
    <div className={classes.changeTitle}>
      <Title className={classes.title} text={text("changeTitle.title")} />
      <Input className={classes.input} errorClassName={classes.error} value={title} changeHandler={value => setTitle(value)} errorMessage={error} />
      <Button className={classes.button} clickHandler={submit}>
        {text("buttons.continue")}
      </Button>
    </div>
  );
};

ChangeTitle.propTypes = {
  clickContinueHandler: PropTypes.func
};

ChangeTitle.defaultProps = {
  clickContinueHandler: () => {}
};

export default React.memo(ChangeTitle);
