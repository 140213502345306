import axios from "../axios";

export const getFinancialCategories = () => axios.get("/pfm/categories");

export const getStatisticCategoriesForPeriod = params => axios.get("/pfm/statistic-categories-for-period", { params });

export const getStatisticByCategoryId = (categoryId, params) =>
    axios.get(`/pfm/statistic-category-for-period/${categoryId}`, { params });

export const getOperationsByCategoryId = (categoryId, params) =>
    axios.get(`/pfm/operations/${categoryId}`, { params });

export const postChangeCategoryForOperation = (operationId, categoryId) =>
    axios.post(`/pfm/operation/switch-category/${operationId}/${categoryId}`);