import React from "react";
import { useHistory } from "react-router-dom";
import * as API from "../../../../api/index";
import Init from "./Init";
import Meters from "./Meters";
import serverErrorMessages from "../../../../helpers/serverErrorMessages";
import { languageContext, modalContext } from "../../../../context";
import { deepCopy, firstLoad, prepareToSend, recalc, urlCategories } from "../PaymentsUtils";
import { validationError } from "../../../../validation";
import localeAmount from "../../../../helpers/localeAmount";
import * as actions from "../../../../store/actions";
import { connect } from "react-redux";
import { uahAccountOptions, uahCardOptions } from "../../../../store/getters";
import { complexFormSchema } from "../../../../validation/billers";
import classes from "../PaymentsOperation.module.scss";
import { ConfirmationCode, OperationResult, Spinner } from "../../../../components";
import PaymentsContext from "../PaymentsContext";

const initialValues = {
  parameter01: undefined,
  parameter02: undefined,
  parameter03: undefined,
  parameter04: undefined,
  parameter05: undefined
};

const PaymentsComplex = ({ cards, accounts }) => {
  const { push } = useHistory();
  const { biller } = React.useContext(PaymentsContext);
  const { setModal } = React.useContext(modalContext);
  const { text, language } = React.useContext(languageContext);
  const [step, setStep] = React.useState("init");
  const [error, setError] = React.useState(null);
  const [payload, setPayload] = React.useState(null);
  const [isFetching, setFetching] = React.useState(false);
  const [commission, setCommission] = React.useState(false);
  const [billerParams, setBillerParams] = React.useState(initialValues);
  const [operationConditions, setOperationConditions] = React.useState(null);

  const srcContractAll = React.useMemo(() => [...uahCardOptions(cards), ...uahAccountOptions(accounts, text)], [cards, accounts, text]);

  const [srcContract, _setSrcContract] = React.useState();
  const setSrcContract = React.useCallback(value => _setSrcContract(srcContractAll.find(contract => contract.value === value) || {}), [srcContractAll]);

  const onChange = (name, value, index) => {
    const copyPayload = deepCopy(payload);
    copyPayload.destination.meters[index][name] = value;
    setPayload(recalc(copyPayload));
  };

  const preface = async payload => {
    setError(null);
    setFetching(true);

    const error = validationError(complexFormSchema(language), {
      contract: srcContract?.value,
      amount: payload.amount
    });

    if (error) {
      setError(error);
    } else {
      try {
        const { data } = await API.postBillPaymentPreface(prepareToSend(payload, srcContract));
        setOperationConditions(data.operationConditions);
        if (data.operationConditions) {
          setCommission(`${localeAmount(data.operationConditions.commission)} ${data.currency}`);
        }
        setStep("execute");
      } catch (err) {
        setError({
          field: "global",
          message: serverErrorMessages(err, language)
        });
      }
    }

    setFetching(false);
  };

  const execute = async payload => {
    setFetching(true);
    const request = prepareToSend(payload, srcContract);

    if (operationConditions?.extAuthRequired === true) {
      request.otpCredentials = {};
      try {
        const { data } = await API.getAuthExtended();
        request.otpCredentials.challenge = data;

        setFetching(false);
        await new Promise(resolve => {
          setModal(
            <ConfirmationCode
              clickContinueHandler={code => {
                request.otpCredentials.otp = code;
                setModal(null);
                resolve();
              }}
            />
          );
        });
      } catch (err) {
        setError({
          field: "global",
          message: serverErrorMessages(err, language)
        });
        setFetching(false);
        return;
      }
    }

    setFetching(true);
    try {
      const { data } = await API.postBillPaymentExecute(request);
      if (data.status !== "FAIL") {
        setModal(
          <OperationResult
            result={data.status !== "SUCCESS" && "failure"}
            titleText={text(data.status === "SUCCESS" ? "payments.success" : "payments.failure")}
            buttonText="Закрити"
            clickCompleteHandler={() => setModal(null)}
          />
        );

        push(urlCategories);
      } else {
        setError({
          field: "global",
          message: serverErrorMessages({ response: { data } }, language)
        });
      }
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
    }
    setFetching(false);
  };

  const init = async () => {
    setError(null);
    setPayload(null);
    setFetching(true);
    try {
      const payload = {
        destination: {
          billDetails: null,
          billId: null,
          billSections: null,
          billerAccount: billerParams.parameter01,
          billerId: biller.id,
          categoryId: null,
          lang: null,
          meters: null,
          providerId: "portmone"
        },
        ...billerParams
      };
      const { data } = await API.postBillPayment(payload);
      if (data?.destination?.billSections) {
        setPayload(firstLoad(deepCopy(data)));
        setStep("preface");
      } else {
        setError({
          field: "global",
          message: "Відсутні рахунки для оплати"
        });
      }
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
    }

    setFetching(false);
  };

  if (isFetching) {
    return <Spinner className={classes.spinner} diameter={50} />;
  }

  const actions = {
    init: init,
    preface: preface,
    execute: execute
  };

  if (step === "init") {
    return (
      <Init
        onSubmit={actions[step]}
        error={error}
        billerParams={billerParams}
        setBillerParams={(name, value) => setBillerParams({ ...billerParams, [name]: value })}
        parametersConf={biller.parametersConf}
      />
    );
  } else {
    if (!payload) return null;

    return (
      <Meters
        step={step}
        onSubmit={actions[step]}
        error={error}
        payload={payload}
        onChange={onChange}
        contracts={srcContractAll}
        contract={srcContract?.value}
        setContract={setSrcContract}
        commission={commission}
      />
    );
  }
};

const mapDispatchToProps = dispatch => ({
  readContract: (contractType, id) => dispatch(actions.readContract(contractType, id))
});

export default connect(({ contracts }) => ({ ...contracts }), mapDispatchToProps)(PaymentsComplex);
