import React, { useContext, useState, useRef, useEffect } from "react";
import classes from "./Accounts.module.scss";
import { languageContext, modalContext } from "../../../context/index";
import { withRouter, Route } from "react-router-dom";
import { connect } from "react-redux";
import { Carousel, AccountTile, OperationResult } from "../../../components";
import Account from "./Account/Account.jsx";
import { useTitle } from "../../../hooks/index";
import * as actions from "../../../store/actions/index";
import ChangeTitle from "../../../components/modals/ChangeTitle/ChangeTitle";
import * as API from "../../../api";
import serverErrorMessages from "../../../helpers/serverErrorMessages";

const Accounts = ({ history, location, accounts, readContracts }) => {
  const { language, text } = useContext(languageContext);
  const { setModal } = useContext(modalContext);

  const accountId = location.pathname.split("/")[3];

  const initialAccountIndex = useRef(0);

  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    readContracts("account");
  }, [readContracts]);

  useEffect(() => {
    if (accountId) {
      const accountIndex = accounts.findIndex(account => account.id === accountId);
      if (accountIndex > 0) {
        initialAccountIndex.current = accountIndex;
      } else {
        history.push(`/home/accounts/${accounts[0].id}`);
      }
      setIsShown(true);
    } else {
      setIsShown(false);
      history.push(`/home/accounts/${accounts[0].id}`);
      initialAccountIndex.current = 0;
      setTimeout(() => {
        setIsShown(true);
      }, 10);
    }
  }, [accounts, accountId, history]);

  useTitle(text("accounts.title"));
  
  const updateAccountTitle = i => current => async () =>  {
    let title = current;
    await new Promise(resolve => {
      setModal(
        <ChangeTitle
          value={title}
          clickContinueHandler={value => {
            title = value;
            setModal(null);
            resolve();
          }}
        />
      );
    });
    try {
      const account = accounts[i];
      await API.setProfileAttributes([{
        key: 'own_name_' + account.providerId + '-' + account.id,
        value: title,
      }]);
      readContracts(account.providerId);
      setModal(
        <OperationResult
          titleText={text("accounts.modal.titleText.success")}
          buttonText={text("buttons.complete")}
          clickCompleteHandler={() => setModal(null)}
        />
      );
    } catch (err) {
      setModal(
        <OperationResult
          result="failure"
          text={serverErrorMessages(err, language)}
          buttonText={text("buttons.close")}
          clickCompleteHandler={() => setModal(null)}
        />
      );
    }
  };

  return (
    <div className={classes.accounts}>
      {isShown && (
        <>
          <Carousel
            className={classes.carousel}
            slideHeight={210}
            mode="single"
            initialSlide={initialAccountIndex.current}
            changeHandler={i => history.push(`/home/accounts/${accounts[i].id}`)}
          >
            {accounts.map((account, i) => (
              <AccountTile
                className={classes.accountTile}
                height={208}
                number={account.mainAccountNumber}
                amount={account.balance}
                currency={account.mainAccountCurrency}
                hasOpenButton={false}
                key={i}
              />
            ))}
          </Carousel>
          {accounts.map((account, i) => (
            <Route
              exact
              path={`/home/accounts/${account.id}`}
              render={() => (
                <Account
                  account={account}
                  clickTitleHandler={updateAccountTitle(i)}
                />
              )}
              key={i}
            />
          ))}
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ contracts }) => ({ accounts: contracts.accounts });

const mapDispatchToProps = dispatch => ({
  readContracts: contractType => dispatch(actions.readContracts(contractType))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Accounts)
);
