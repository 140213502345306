import React from "react";

export default React.createContext({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  user: {},
  setUser: () => {},
  setAuthenticationData: () => {},
  removeAuthenticationData: () => {},
  temporaryPasswordRecoveryAuthHeader: null,
  setTemporaryPasswordRecoveryAuthHeader: () => {}
});
