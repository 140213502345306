import axios from "../axios";

// CARDS
export const getCards = () => axios.get("contract/card/all");

export const postCardLimit = (authHeader, cardId, cardNumber, payload) =>
  axios.post(`contract/card/${cardId}/card/${cardNumber}/limits/change`, payload);

export const postLockCard = (cardId, cardNumber, payload) =>
  axios.post(`contract/card/${cardId}/card/${cardNumber}/lock`, payload);

export const postUnlockCard = (cardId, cardNumber, payload) =>
  axios.post(`contract/card/${cardId}/card/${cardNumber}/unlock`, payload);

// ACCOUNTS
export const getAccounts = () => axios.get("contract/account/all");

// CREDITS
export const getCredits = () => axios.get("contract/credit/all");

// DEPOSITS
export const getDeposits = () => axios.get("contract/deposit/all");

//global
export const getContract = (type, contractId) => axios.get(`contract/${type}/${contractId}`);
export const getContractRequisites = (type, contractId, cardId) => {
  let path = `contract/${type}/${contractId}/additional/info/read_requisites`;
  if (cardId) {
    path = path + `?cardId=${cardId}`;
  }
  return axios.get(path);
};

export const getContractDetailed = (type, contractId, cardId) =>
  axios.get(`contract/${type}/${contractId}/detailed${cardId ? '?cardId='.concat(cardId) : undefined}`);

export const getContracts = type => axios.get(`contract/${type}/all`);

export const getContractConditions = (type, params = null) => axios({
  url: `contract/${type}/conditions/all`,
  params,
  baseURL: (process.env.REACT_APP_API2_URL || ''),
});

export const makeContractPreface = payload => axios.post(`contract/make/preface`, payload);
export const makeContractExecute = payload => axios.post(`contract/make/execute`, payload);

export const setProfileAttributes = payload => axios.post(`auth/profile/attributes`, payload);

export const setContractSettings = (providerId, contractId, action, payload, cardId) => axios.post(`contract/${providerId}/${contractId}/settings/change/${action}${cardId ? `?cardId=${cardId}` : ``}`, payload);

export const changeCardSettings = (providerId, contractId, action, payload, cardId) => axios.post(`contract/${providerId}/${contractId}/${providerId}/${cardId}/settings/change/${action}`, payload);

export const setDepositSettingsPreface = (contractId, payload) => setContractSettings('deposit', contractId, 'preface', payload);
export const setDepositSettingsExecute = (contractId, payload) => setContractSettings('deposit', contractId, 'execute', payload);
export const setCardSettingsPreface = (contractId, cardId, payload) =>  changeCardSettings('card', contractId, 'preface', payload, cardId);
export const setCardSettingsExecute = (contractId, cardId, payload) => changeCardSettings('card', contractId, 'execute', payload, cardId);

export const getAdditionalInfo = ({providerId = 'card', contractId, cardId, parameter}) => axios.get(`contract/${providerId}/${contractId}/additional/info/read_${parameter}?cardId=${cardId}`);

