import React, { useContext, useEffect, useState } from "react";
import classes from "./Signup.module.scss";
import { authContext, languageContext, modalContext } from "../../../context/index";
import { Button, Checkbox, ConfirmationCode, Error, Input, LanguageSwitch, Title } from "../../../components/index";
import * as API from "../../../api/index";
import { fieldErrorMessage, signupIbanSchema, signupCardSchema, validationError } from "../../../validation/index";
import cn from "../../../helpers/cn";
import { useTitle } from "../../../hooks/index";
import serverErrorMessages from "../../../helpers/serverErrorMessages";
import { useDispatch } from "react-redux";
import { removeContracts } from "../../../store/actions";
import matches from "validator/lib/matches";
import isLength from "validator/lib/isLength";

const Signup = ({ history }) => {
  const dispatch = useDispatch();
  const { language, setLanguage, text } = useContext(languageContext);
  const { setModal } = useContext(modalContext);
  const { setAuthenticationData } = useContext(authContext);

  const [phone, setPhone] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const globalErrorMessage = fieldErrorMessage("global", error);

  const submit = async () => {
    clearTimeout(Signup.timeout);
    setError(null);
    dispatch(removeContracts());
    const modifiedPhone = phone.split(/\s|_/).join("");
    const modifiedIdentifier = identifier.split(/\s|_/).join("");
    const error = validationError(/^U/.test(modifiedIdentifier) ? signupIbanSchema(language, password) : signupCardSchema(language, password), {
      phone: modifiedPhone,
      identifier: modifiedIdentifier,
      password,
      confirmedPassword
    });
    if (error) {
      setError(error);
      return;
    }
    setIsFetching(true);
    try {
      const signupCheckRes = await API.signupCheck({
        phone: modifiedPhone,
        identifierType: /^UA/.test(modifiedIdentifier) ? "ACCOUNT_NUMBER" : "CARD_NUMBER",
        identifier: modifiedIdentifier
      });
      const signupCheckAuthHeader = signupCheckRes.headers.authorization;
      const signupConfirmRes = await API.signupConfirm(signupCheckAuthHeader, {
        login: modifiedPhone,
        password,
        firstName: '',
        lastName: '',
        email: '',
        phone: modifiedPhone,
        identifierType: /^UA/.test(modifiedIdentifier) ? "ACCOUNT_NUMBER" : "CARD_NUMBER",
        identifier: modifiedIdentifier
      });
      const signupConfirmAuthHeader = signupConfirmRes.headers.authorization;
      await API.postSuretyRecordsFindForActivation(signupConfirmAuthHeader);
      await API.postSuretyRecordsRequestActivation(signupConfirmAuthHeader);
      let activationCode;
      await new Promise(resolve => {
        setModal(
          <ConfirmationCode
            clickContinueHandler={code => {
              activationCode = code;
              setModal(null);
              resolve();
            }}
          />
        );
      });
      await API.postSuretyRecordsActivate(signupConfirmAuthHeader, {
        activationCode
      });
      const res = await API.postLoginPassword({
        login: modifiedPhone,
        password
      });
      setAuthenticationData(res);
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (error) {
      Signup.timeout = setTimeout(() => {
        setError(null);
      }, 6000);
    }
  }, [error]);

  useEffect(() => {
    return () => {
      clearTimeout(Signup.timeout);
    };
  }, []);

  useTitle(text("signup.title"));

  const [type, setType] = React.useState("NONE");

  return (
    <div className={classes.wrapper}>
      <LanguageSwitch className={classes.languageSwitch} currentLanguage={language} changeHandler={value => setLanguage(value)} />
      <Title className={classes.title} text={text("signup.title")} />
      {globalErrorMessage && <Error className={classes.error} message={globalErrorMessage} />}
      <div className={classes.columns}>
        <div className={classes.column}>
          <Input
            className={classes.input}
            errorClassName={classes.error}
            mask={"+38 \\099 999 99 99"}
            labelText={text("signup.phoneNumber")}
            value={phone}
            changeHandler={value => setPhone(value)}
            errorMessage={fieldErrorMessage("phone", error)}
          />
          <Input
            className={classes.input}
            errorClassName={classes.error}
            labelText={text("signup.bankAccount")}
            value={identifier}
            changeHandler={setIdentifier}
            mask={type === "NONE" ? "ua99 9999 9999 99999999999999999" : type === "IBAN" ? "UA99 999999 9999999999999999999" : "9999 9999 9999 9999"}
            beforeMaskedValueChange={newState => {
              let { value, selection } = newState;
              let newType = "NONE";
              let newValue = (value || "")
                .toUpperCase()
                .replace(/Г/i, "U")
                .replace(/Ф/i, "A");
              if (/^(U|UA|UA[\d\s]*)$/i.test(newValue)) {
                newType = "IBAN";
              } else if (/^[\d\s]+$/i.test(newValue)) {
                newType = "CARD";
              }
              if (type !== newType) {
                setType(newType);
              }
              return { value: newValue, selection };
            }}
            maskChar={null}
            formatChars={{
              u: "[0-9UuГг]",
              a: "[0-9AaФф]",
              "9": "[0-9]",
              U: "[UuГг]",
              A: "[AaФф]"
            }}
            errorMessage={fieldErrorMessage("identifier", error)}
          />
          {/*<Input*/}
          {/*  className={classes.input}*/}
          {/*  errorClassName={classes.error}*/}
          {/*  labelText={text("signup.name")}*/}
          {/*  value={name.substr(0, 1).toUpperCase() + name.substr(1, name.length - 1)}*/}
          {/*  validator={NameValidator}*/}
          {/*  changeHandler={value => setName(value)}*/}
          {/*  errorMessage={fieldErrorMessage("name", error)}*/}
          {/*/>*/}
          {/*<Input*/}
          {/*  className={classes.input}*/}
          {/*  errorClassName={classes.error}*/}
          {/*  labelText={text("signup.lastName")}*/}
          {/*  value={surname.substr(0, 1).toUpperCase() + surname.substr(1, surname.length - 1)}*/}
          {/*  validator={SurnameValidator}*/}
          {/*  changeHandler={value => setSurname(value)}*/}
          {/*  errorMessage={fieldErrorMessage("surname", error)}*/}
          {/*/>*/}
        {/*</div>*/}
        {/*<div className={classes.column}>*/}
          {/*<Input*/}
          {/*  className={classes.input}*/}
          {/*  errorClassName={classes.error}*/}
          {/*  labelText={text("signup.email")}*/}
          {/*  value={email}*/}
          {/*  changeHandler={value => setEmail(value)}*/}
          {/*  errorMessage={fieldErrorMessage("email", error)}*/}
          {/*/>*/}
          <Input
            className={classes.input}
            errorClassName={classes.error}
            labelText={text("signup.password")}
            type="password"
            maxLength={21}
            value={password}
            changeHandler={value => setPassword(value)}
            errorMessage={fieldErrorMessage("password", error)}
          />
          <Input
            className={classes.input}
            errorClassName={classes.error}
            labelText={text("signup.confirmPassword")}
            type="password"
            maxLength={21}
            value={confirmedPassword}
            changeHandler={value => setConfirmedPassword(value)}
            errorMessage={fieldErrorMessage("confirmedPassword", error)}
          />
          <div className={classes.text}>{text("signup.passwordInformation")}</div>
        </div>
      </div>
      <div className={classes.row}>
        <Checkbox style={{ marginRight: 10 }} value={isAgreed} changeHandler={value => setIsAgreed(value)} />
        <div className={classes.text}>
          {text("signup.familiar")}{" "}
          <a className={classes.link} href="https://ap-bank.com/documents/download/1084" target="_blank" rel="noopener noreferrer">
            {text("signup.familiarNext")}{" "}
          </a>
          {text("signup.familiar2")}{" "}
          <a className={classes.link} href="https://ap-bank.com/documents/download/1033" target="_blank" rel="noopener noreferrer">
            {text("signup.familiarNext2")}{" "}
          </a>
        </div>
      </div>
      <div className={classes.buttons}>
        <Button className={cn(classes.button, classes.buttonBack)} clickHandler={() => history.goBack()}>
          {text("signup.buttonBack")}
        </Button>
        <Button
          className={cn(classes.button, isAgreed ? classes.buttonSubmit : classes.buttonInactive)}
          clickHandler={submit}
          isDisabled={!isAgreed || isFetching}
        >
          {text("signup.buttonRegistration")} &rarr;
        </Button>
      </div>
    </div>
  );
};
const NameValidator = name => {
  const FirstLetter = matches(
    name.substring(0, 1),
    /[а-яА-Яa-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðЄєЇїЁёҐґІі]/
  );
  const AcceptedSymbols = matches(
    name,
    /^([а-яА-Яa-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðЄєЇїЁёҐґІі'-])+$/
  );
  return FirstLetter && AcceptedSymbols && isLength(name, { min: 0, max: 33 });
};

const SurnameValidator = surname => {
  const FirstLetter = matches(
    surname.substring(0, 1),
    /[а-яА-Яa-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðЄєЇїЁёҐґІі]/
  );
  const AcceptedSymbols = matches(
    surname,
    /^([а-яА-Яa-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðЄєЇїЁёҐґІі'-])+$/
  );
  return FirstLetter && AcceptedSymbols && isLength(surname, { min: 0, max: 33 });
};

export default Signup;
