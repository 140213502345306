import localization from "../localization/index";

export const billersFormSchema = language => {
    const { required } = localization[language].errors;

    return {
        givingAccount: [
            {
                validator: "required",
                errorMessage: required
            }
        ],
        personalAccount: [
            {
                validator: "required",
                errorMessage: required
            }
        ],
        amount: [
            {
                validator: "required",
                // args: {
                //     min: 1,
                //     max: 5
                // },
                errorMessage: required
            }
        ]
    };
};

export const complexFormSchema = language => {
    const { required } = localization[language].errors;

    return {
        contract: [
            {
                validator: "required",
                errorMessage: required
            }
        ],
        amount: [
            {
                validator: "required",
                errorMessage: required
            }
        ]
    };
};
