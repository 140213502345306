import React from "react";
import classes from "./RegularPayment.module.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, DayPickerCalendar, Input, Label, Select, Textarea, Title } from "../../index";
import isCurrency from "validator/lib/isCurrency";
import isLength from "validator/lib/isLength";
import isInt from "validator/lib/isInt";
import matches from "validator/lib/matches";
import * as API from "../../../api/index";
import moment from "moment";
import {
  fieldErrorMessage,
  regularPaymentContractToContractSchema,
  regularPaymentIntrabankTransferSchema,
  regularPaymentSepTransferSchema,
  validationError
} from "../../../validation/index";
import { useError } from "../../../hooks/index";
import ApproveStep from "./ApproveStep/ApproveStep.jsx";
import { languageContext, modalContext } from "../../../context/index";
import {
  accountOptions,
  cardOptions,
  creditOptions,
  depositReceiverOptions,
  depositSenderOptions,
  uahAccountOptions,
  uahCardOptions
} from "../../../store/getters/index";
import numberWithSpaces from "../../../helpers/numberWithSpaces";
import amountForRequest from "../../../helpers/amountForRequest";
import { defIban, defIbanPast, defIbanPreChange, defIbanValidator } from "../../../helpers/iban";

const types = {
  CONTRACT_TO_CONTRACT: "contractToContract",
  INTRABANK_TRANSFER: "intrabankTransfer",
  SEP_TRANSFER: "sepTransfer"
};

const RegularPayment = ({
  regularPayment,
  statuRegularPaymentRequest,
  isNew,
  isEdit,
  initialType,
  initialName,
  initialGettingContract,
  initialGivingContract,
  initialGettingAccount,
  initialAmount,
  initialIntervalValue,
  initialIntervalOfPayment,
  initialStartData,
  initialFinishData,
  clickCancelHandler,
  initialReceiverName,
  // commission,
  initialCurrency,
  initialTaxNumber,
  initialPurpose,
  extId,
  externalOperationId,
  cards,
  accounts,
  credits,
  deposits
}) => {
  console.log(initialStartData, initialFinishData, "dates-finishDates");
  const { language, text } = React.useContext(languageContext);
  const { setModal } = React.useContext(modalContext);
  const [type, setType] = React.useState(initialType);

  const [error, setError] = useError(null);
  const [isFetching, setIsFetching] = React.useState(false);
  const [bankName, setBankName] = React.useState("");

  const refWrapper = React.useRef(null);
  const typeOptions = React.useMemo(
    () => [
      {
        value: "INTRABANK_TRANSFER",
        label: text("templateSettings.options.types.bankInner")
      },
      {
        value: "CONTRACT_TO_CONTRACT",
        label: text("templateSettings.options.types.innerToInner")
      },
      {
        value: "SEP_TRANSFER",
        label: text("templateSettings.options.types.innerToCustom")
      }
    ],
    [text]
  );

  const periodOptions = React.useMemo(
    () => [
      {
        value: "DAY",
        label: text("templateSettings.labels.everyDay")
      },
      {
        value: "WEEK",
        label: text("templateSettings.labels.everyWeek")
      },
      {
        value: "MONTH",
        label: text("templateSettings.labels.everyMonth")
      },
      {
        value: "INSTANT",
        label: text("templateSettings.labels.freePeriod")
      }
    ],
    [text]
  );
  const instantPeriodOptions = React.useMemo(
    () => [
      {
        value: "DAY",
        label: text("templateSettings.labels.day")
      },
      {
        value: "WEEK",
        label: text("templateSettings.labels.week")
      },
      {
        value: "MONTH",
        label: text("templateSettings.labels.month")
      }
    ],
    [text]
  );

  const srcContractAll = React.useMemo(
    () =>
      type === "CONTRACT_TO_CONTRACT"
        ? [...cardOptions(cards, language), ...accountOptions(accounts, text), ...depositSenderOptions(deposits, text)]
        : [...uahCardOptions(cards, language), ...uahAccountOptions(accounts, text)],
    [type, cards, language, accounts, text, deposits]
  );

  const dstContractAll = React.useMemo(
    () => [...cardOptions(cards, language), ...accountOptions(accounts, text), ...creditOptions(credits, text), ...depositReceiverOptions(deposits, text)],
    [cards, language, accounts, text, credits, deposits]
  );

  const [srcContract, _setSrcContract] = React.useState({});
  const [dstContract, _setDstContract] = React.useState({});

  const setSrcContract = React.useCallback(
    value => {
      const contract = srcContractAll.find(contract => contract.value === value) || {};
      _setSrcContract(contract);
      setCurrency(contract.currency);
    },
    [srcContractAll]
  );
  const setDstContract = React.useCallback(
    value => {
      _setDstContract(dstContractAll.find(contract => contract.value === value) || {});
    },
    [dstContractAll]
  );

  React.useEffect(() => {
    setSrcContract(initialGivingContract);
    setDstContract(initialGettingContract);
  }, [dstContractAll, srcContractAll, initialGettingContract, initialGivingContract, setSrcContract, setDstContract]);

  const srcContractList = React.useMemo(() => {
    if (dstContract?.value) {
      return srcContractAll
        .filter(contract => contract?.value !== dstContract?.value)
        .filter(contract => contract?.currency === (initialCurrency || dstContract?.currency || "UAH"))
        .filter(contract => (dstContract?.providerId === "card" ? ["account", "deposit", "credit", "card"].includes(contract?.providerId) : true))
        .filter(contract => (dstContract?.providerId === "credit" ? ["account", "card"].includes(contract?.providerId) : true))
        .filter(contract => (dstContract?.providerId === "deposit" ? ["account", "card"].includes(contract?.providerId) : true))
        .filter(contract => (dstContract?.providerId === "account" ? ["account", "deposit", "card"].includes(contract?.providerId) : true));
    }
    return srcContractAll;
  }, [dstContract.currency, dstContract.providerId, dstContract.value, initialCurrency, srcContractAll]);

  const dstContractList = React.useMemo(() => {
    if (srcContract?.value) {
      return dstContractAll
        .filter(contract => contract?.value !== srcContract?.value)
        .filter(contract => contract?.currency === srcContract?.currency)
        .filter(contract => (srcContract?.providerId === "deposit" ? ["account", "card"].includes(contract?.providerId) : true))
        .filter(contract => (srcContract?.providerId === "credit" ? contract?.providerId === "card" : true));
    }
    return dstContractAll;
  }, [dstContractAll, srcContract.currency, srcContract.providerId, srcContract.value]);

  const [gettingAccount, setGettingAccount] = React.useState(() => (type === "SEP_TRANSFER" ? defIban(initialGettingAccount) : initialGettingAccount));
  const [name, setName] = React.useState(initialName);
  const [amount, setAmount] = React.useState(numberWithSpaces(initialAmount));
  const [currency, setCurrency] = React.useState(initialCurrency);
  const [receiverName, setReceiverName] = React.useState(initialReceiverName);
  const [taxNumber, setTaxNumber] = React.useState(initialTaxNumber);
  const [purpose, setPurpose] = React.useState(initialPurpose);
  const [dates, setDates] = React.useState(
    initialStartData
      ? initialStartData
      : moment()
          .add(1, "day")
          .format("YYYY-MM-DD")
  );
  const [finishDates, setFinishDates] = React.useState(
    initialFinishData
      ? initialFinishData
      : moment()
          .add(2, "day")
          .format("YYYY-MM-DD")
  );
  const [paymentPeriod, setPaymentPeriod] = React.useState(
    initialIntervalValue && initialIntervalValue === 1 ? periodOptions.find(option => option.value === initialIntervalOfPayment).value : periodOptions[0].value
  );
  const [instantPeriod, setInstantPeriod] = React.useState(initialIntervalOfPayment ? initialIntervalOfPayment : periodOptions[0].value);
  const [intInstantPeriod, setIntInstantPeriod] = React.useState(initialIntervalValue && initialIntervalValue > 1 ? initialIntervalValue : "1");

  React.useEffect(() => {
    if (initialIntervalValue && initialIntervalValue > 1) setPaymentPeriod("INSTANT");
  }, [initialIntervalValue]);

  React.useEffect(() => {
    let requestDataToBankName;
    if (gettingAccount && gettingAccount.length >= 10) {
      requestDataToBankName = gettingAccount.substring(4, 10);
      API.requestForCustomTransaction(requestDataToBankName).then(res => {
        const { bankName } = res.data;
        setBankName(bankName);
      });
    } else {
      setBankName("");
    }
  }, [gettingAccount]);

  const submitCreateRegularPayment = async () => {
    setError(null);

    let error;
    if (type === "CONTRACT_TO_CONTRACT") {
      error = validationError(regularPaymentContractToContractSchema(language), {
        name,
        givingContract: srcContract?.value,
        gettingContract: dstContract?.value,
        amount
      });
    } else if (type === "INTRABANK_TRANSFER") {
      error = validationError(regularPaymentIntrabankTransferSchema(language), {
        name,
        givingContract: srcContract?.value,
        gettingAccount,
        amount
      });
    } else if (type === "SEP_TRANSFER") {
      error = validationError(regularPaymentSepTransferSchema(language), {
        name,
        givingContract: srcContract?.value,
        gettingAccount,
        receiverName,
        taxNumber,
        purpose: (purpose || '').trim(),
        amount
      });
    }
    if (error) {
      refWrapper.current.scrollTo(0, 0);
      setError(error);
      return;
    }
    setIsFetching(true);

    let intervalOfPayment;
    let intervalValue;
    // eslint-disable-next-line default-case
    switch (paymentPeriod) {
      case "DAY":
        intervalOfPayment = "DAY";
        intervalValue = "1";
        break;
      case "MONTH":
        intervalOfPayment = "MONTH";
        intervalValue = "1";
        break;
      case "WEEK":
        intervalOfPayment = "WEEK";
        intervalValue = "1";
        break;
      case "INSTANT":
        intervalOfPayment = instantPeriod;
        intervalValue = intInstantPeriod;
        break;
    }
    let payload;
    if (type === "CONTRACT_TO_CONTRACT") {
      payload = {
        typeOfAutopayment: type,
        title: name,
        amountOfPayment: `${Math.round(amountForRequest(amount))}`,
        amountOfPaymentCalculationRule: "FIXED_AMOUNT_IN_CENTS",
        startDateOfPayment: dates,
        finalDateOfPayment: finishDates,
        extId,
        externalOperationId,
        intervalOfPayment: !isEdit && initialIntervalOfPayment ? initialIntervalOfPayment : intervalOfPayment,
        intervalValue: !isEdit && initialIntervalValue ? initialIntervalValue : intervalValue,
        cardId: srcContract?.cardId,
        dstCardId: dstContract?.cardId,
        operation: {
          currency: currency || srcContract?.currency,
          srcContractRef: {
            providerId: srcContract?.providerId,
            contractId: srcContract?.contractId
          },
          destContractRef: {
            providerId: dstContract?.providerId,
            contractId: dstContract?.contractId
          }
        }
      };
    } else if (type === "INTRABANK_TRANSFER") {
      let identifierType;
      if (gettingAccount && gettingAccount.length === 29) {
        identifierType = "ACCOUNT_NUMBER";
      } else {
        identifierType = "CARD_NUMBER";
      }

      payload = {
        typeOfAutopayment: type,
        title: name,
        amountOfPayment: `${Math.round(amountForRequest(amount))}`,
        amountOfPaymentCalculationRule: "FIXED_AMOUNT_IN_CENTS",
        startDateOfPayment: dates,
        finalDateOfPayment: finishDates,
        extId,
        externalOperationId,
        intervalOfPayment: !isEdit && initialIntervalOfPayment ? initialIntervalOfPayment : intervalOfPayment,
        intervalValue: !isEdit && initialIntervalValue ? initialIntervalValue : intervalValue,
        cardId: srcContract?.cardId,
        operation: {
          currency: "UAH",
          destination: {
            identifier: gettingAccount && gettingAccount.toUpperCase(),
            identifierType
          },
          srcContractRef: {
            providerId: srcContract?.providerId,
            contractId: srcContract?.contractId
          }
        }
      };
    } else {
      // if initialType === 'SEP_TRANSFER
      payload = {
        typeOfAutopayment: type,
        title: name,
        amountOfPayment: `${Math.round(amountForRequest(amount))}`,
        amountOfPaymentCalculationRule: "FIXED_AMOUNT_IN_CENTS",
        startDateOfPayment: dates,
        finalDateOfPayment: finishDates,
        extId,
        externalOperationId,
        intervalOfPayment: !isEdit && initialIntervalOfPayment ? initialIntervalOfPayment : intervalOfPayment,
        intervalValue: !isEdit && initialIntervalValue ? initialIntervalValue : intervalValue,
        cardId: srcContract?.cardId,
        operation: {
          currency: "UAH",
          destination: {
            accountNumber: gettingAccount && gettingAccount.toUpperCase(),
            bankId: gettingAccount && gettingAccount.toUpperCase().substring(4, 10),
            name: receiverName,
            bankName,
            taxId: taxNumber
          },
          purpose: (purpose || '').trim(),
          srcContractRef: {
            providerId: srcContract?.providerId,
            contractId: srcContract?.contractId
          }
        }
      };
    }
    if (isEdit) {
      await API.postAutopaymentsUpdatePreface(types[type], payload)
        .then(result => {
          if (result) {
            console.log(result.data, "result");
            setModal(
              <ApproveStep
                isNew
                isEdit
                prefaceData={result.data}
                clickCancelHandler={clickCancelHandler}
                srcProvider={srcContract}
                destProvider={dstContract}
                bankName={bankName}
                statusCreatedRegularPayment={statuRegularPaymentRequest}
              />
            );
          }
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.status, "error");
          }
        });
    } else {
      await API.postAutopaymentsCreatePreface(types[type], payload)
        .then(result => {
          if (result) {
            console.log(result.data, "result");
            setModal(
              <ApproveStep
                isNew
                prefaceData={result.data}
                clickCancelHandler={clickCancelHandler}
                srcProvider={srcContract}
                destProvider={dstContract}
                bankName={bankName}
                statusCreatedRegularPayment={statuRegularPaymentRequest}
              />
            );
          }
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.status, "error");
          }
        });
    }
    // setIsFetching(false);
  };

  const cardOrIbanNumberValidator = gettingAccount => {
    const CapitalLetters = gettingAccount.length === 1 ? matches(gettingAccount.substring(0, 1), /U|u/) : matches(gettingAccount.substring(0, 2), /UA|ua|Ua/);
    const DigitalAftetSecondChar = gettingAccount.length > 2 ? isInt(gettingAccount.substring(2, 28)) : CapitalLetters;

    const Digital = matches(gettingAccount.substring(0, 1), /4|5/) && matches(gettingAccount, /\d/);
    return (
      (CapitalLetters && DigitalAftetSecondChar && isLength(gettingAccount, { min: 0, max: 29 })) || (Digital && isLength(gettingAccount, { min: 0, max: 16 }))
    );
  };

  // const ibanNumberValidator = gettingAccount => {
  //   const CapitalLetters = gettingAccount.length === 1 ? matches(gettingAccount.substring(0, 1), /U|u/) : matches(gettingAccount.substring(0, 2), /UA|ua|Ua/);
  //   const DigitalAftetSecondChar = gettingAccount.length > 2 ? isInt(gettingAccount.substring(2, 28)) : CapitalLetters;
  //   return CapitalLetters && DigitalAftetSecondChar && isLength(gettingAccount, { min: 0, max: 29 });
  // };

  const TaxNumberValidator = taxNumber => matches(taxNumber, /^([А-ЯҐЄІЇ]{0,2}\d{0,6}|\d{0,10})?$/);

  return (
    <>
      <Title text={text(isEdit ? "regularPayments.editRegularPaymentTitle" : "regularPayments.createRegularPaymentTitle")} />

      <div className={classes.wrapper} ref={refWrapper}>
        <div className={classes.row}>
          <div className={classes.column}>
            <Select
              style={{ zIndex: 2, marginBottom: 10 }}
              labelText={text("templateSettings.labels.typePayment")}
              options={typeOptions}
              value={type}
              changeHandler={value => setType(value)}
              isDisabled={!isNew}
            />
            <Input
              errorClassName={classes.error}
              style={{ marginBottom: 10 }}
              labelText={text("templateSettings.labels.namePayment")}
              value={name}
              changeHandler={value => setName(value)}
              errorMessage={fieldErrorMessage("name", error)}
            />
            <Select
              errorClassName={classes.error}
              style={{ zIndex: 1, marginBottom: 10 }}
              labelText={text("templateSettings.labels.fromContract")}
              options={srcContractList}
              value={srcContract?.value}
              changeHandler={setSrcContract}
              errorMessage={fieldErrorMessage("givingContract", error)}
              placeholder={text("templateSettings.select.placeholder")}
            />

            {type === "CONTRACT_TO_CONTRACT" ? (
              <Select
                errorClassName={classes.error}
                style={{ marginBottom: 10 }}
                labelText={text("templateSettings.labels.toContract")}
                options={dstContractList}
                value={dstContract?.value}
                changeHandler={setDstContract}
                errorMessage={fieldErrorMessage("gettingContract", error)}
                placeholder={text("templateSettings.select.placeholder")}
                isDisabled={!isNew}
              />
            ) : type === "INTRABANK_TRANSFER" ? (
              <Input
                errorClassName={classes.error}
                style={{ marginBottom: 10 }}
                labelText={text("templateSettings.labels.toContract")}
                value={gettingAccount && gettingAccount.toUpperCase()}
                validator={cardOrIbanNumberValidator}
                changeHandler={value => setGettingAccount(value)}
                errorMessage={fieldErrorMessage("gettingAccount", error)}
                // errorMessage={fieldErrorMessage("gettingContractCardOrIban", error)}
                isDisabled={!isNew}
              />
            ) : (
              <Input
                errorClassName={classes.error}
                style={{ marginBottom: 10 }}
                labelText={text("templateSettings.labels.toContractForSep")}
                value={gettingAccount && gettingAccount.toUpperCase()}
                changeHandler={value => setGettingAccount(defIban(value))}
                validator={defIbanValidator}
                preChange={defIbanPreChange}
                errorMessage={fieldErrorMessage("gettingAccount", error)}
                isDisabled={!isNew}
                onPaste={defIbanPast(setGettingAccount)}
              />
            )}
            <div className={classes.amountBlock}>
              <div>
                <Label>{text("templateSettings.transferAmount")}</Label>
                <Input
                  errorClassName={classes.errorAmount}
                  style={{ marginBottom: 10, width: 220 }}
                  name="amount"
                  value={amount}
                  units={currency}
                  commaIsReplacedWithDot
                  validator={value =>
                    isCurrency(value, { digits_after_decimal: [0, 1, 2] }) &&
                    (initialType === "SEP_TRANSFER"
                      ? isCurrency(value, { digits_after_decimal: [0] })
                        ? isLength(value, { min: 0, max: 14 })
                        : isCurrency(value, { digits_after_decimal: [1] })
                        ? isLength(value, { min: 0, max: 16 })
                        : isLength(value, { min: 0, max: 17 })
                      : true)
                  }
                  changeHandler={value => setAmount(value)}
                  errorMessage={fieldErrorMessage("amount", error)}
                />
              </div>
            </div>
          </div>

          {type === "SEP_TRANSFER" && (
            <div className={classes.column}>
              <Input
                errorClassName={classes.error}
                style={{ marginBottom: 10 }}
                labelText={text("innerToCustomTransaction.recipientName")}
                value={receiverName}
                maxLength={38}
                changeHandler={value => setReceiverName(value)}
                errorMessage={fieldErrorMessage("receiverName", error)}
                isDisabled={!isNew}
              />
              <Label>{text("innerToCustomTransaction.mfo")}</Label>
              <p className={classes.bankName}>{bankName}</p>
              <Input
                errorClassName={classes.error}
                style={{ marginBottom: 10 }}
                labelText={text("templateSettings.labels.taxNumber")}
                value={taxNumber}
                validator={TaxNumberValidator}
                changeHandler={x => setTaxNumber(x)}
                errorMessage={fieldErrorMessage("taxNumber", error)}
                isDisabled={!isNew}
              />
              <Label>{text("templateSettings.labels.purpose")}</Label>
              <Textarea
                errorClassName={classes.error}
                style={{ marginBottom: 20 }}
                value={purpose}
                changeHandler={value => setPurpose(value)}
                onBlur={value => setPurpose((value || '').trim())}
                // warnHolder={text("innerToCustomTransaction.paymentPurposeWarnHolder")}
                placeholder={text("innerToCustomTransaction.paymentPurposeWarnHolder")}
                maxLength={160}
                errorMessage={fieldErrorMessage("purpose", error)}
                // isDisabled={!isNew}
              />
            </div>
          )}
        </div>
        <div className={classes.periodBlock}>
          <Select
            style={{ marginBottom: 10 }}
            labelText={text("templateSettings.labels.regularPaymentSchedule")}
            options={periodOptions}
            value={paymentPeriod}
            changeHandler={value => setPaymentPeriod(value)}
          />
          {paymentPeriod === "INSTANT" && (
            <div className={classes.instantInputs}>
              <span>{text("templateSettings.labels.every")}</span>
              <Input style={{ marginBottom: 10, width: "55px" }} value={intInstantPeriod} changeHandler={value => setIntInstantPeriod(value)} />
              <Select
                style={{ marginBottom: 10, marginLeft: "15px", width: "240px" }}
                value={instantPeriod}
                options={instantPeriodOptions}
                changeHandler={value => setInstantPeriod(value)}
              />
            </div>
          )}
          <DayPickerCalendar
            labelText={text("templateSettings.labels.startDate")}
            value={dates}
            changeHandler={value => setDates(moment(value).format("YYYY-MM-DD"))}
            disabled={moment()
              .add(1, "day")
              .format("YYYY-MM-DD")}
          />
          <DayPickerCalendar
            labelText={text("templateSettings.labels.finishDate")}
            value={finishDates}
            changeHandler={value => setFinishDates(moment(value).format("YYYY-MM-DD"))}
            disabled={moment()
              .add(2, "day")
              .format("YYYY-MM-DD")}
          />
        </div>
        <div className={classes.buttonBlock}>
          <Button className={classes.buttonCancel} clickHandler={clickCancelHandler}>
            {text("templateSettings.buttonCancel")}
          </Button>
          <Button className={classes.buttonCreate} clickHandler={submitCreateRegularPayment} isDisabled={isFetching}>
            {isEdit ? text("templateSettings.buttonEditRegularPayment") : text("templateSettings.buttonCreateRegularPayment")}
          </Button>
        </div>
      </div>
    </>
  );
};

RegularPayment.propTypes = {
  isNew: PropTypes.bool,
  initialType: PropTypes.string,
  initialName: PropTypes.string,
  gettingContract: PropTypes.string,
  givingContract: PropTypes.string,
  initialAmount: PropTypes.string,
  clickCancelHandler: PropTypes.func,
  gettingAccount: PropTypes.string,
  receiverName: PropTypes.string,
  bankName: PropTypes.string,
  taxNumber: PropTypes.string,
  purpose: PropTypes.string
};

const mapStateToProps = ({ contracts }) => ({
  cards: contracts.cards,
  accounts: contracts.accounts,
  credits: contracts.credits,
  deposits: contracts.deposits
});

export default connect(mapStateToProps)(RegularPayment);
