import React from "react";
import classes from "./Cards.module.scss";
import { connect } from "react-redux";
import { languageContext, modalContext } from "../../../context/index";
import * as API from "../../../api/index";
import { useHistory, useParams } from "react-router-dom";
import { Button, CardTile, Carousel, ConfirmationCode, Icon, OperationResult, SuggestionTile } from "../../../components";
import Card from "./Card/Card.jsx";
import CardLimit from "./CardLimit/CardLimit";
import { useTitle } from "../../../hooks";
import serverErrorMessages from "../../../helpers/serverErrorMessages";
import * as actions from "../../../store/actions/index";
import { findCard, getAttribute } from "../../../store/getters/contracts";
import { notify } from "react-notify-toast";
import ChangeTitle from "../../../components/modals/ChangeTitle/ChangeTitle";
import { genContractKey } from "../../../helpers/contract";
import {useChangePin} from "../../../hooks/useChangePin";

const Cards = ({ cards, setContracts, readContracts }) => {
  const history = useHistory();
  const { cardId } = useParams();
  const { text, language } = React.useContext(languageContext);
  const { setModal } = React.useContext(modalContext);
  const changePin = useChangePin();
  // const updateCardStatus = useUpdateCardStatus(cards, setContracts);

  const { account, card, index, count } = React.useMemo(() => findCard(cards, cardId), [cards, cardId]);

  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current) {
      ref.current.slickGoTo(card ? index : count);
    }
  }, [card, count, index]);

  const findCardByIndex = (accounts, index) => {
    let count = 0;
    let result = {};
    accounts.forEach(account =>
      account.cardsList.forEach(card => {
        if (count === index) {
          result.account = account;
          result.card = card;
        }
        count++;
      })
    );
    return result;
  };

  useTitle(text("cards.title"));

  const updateCardStatus = async i => {
    const payload = {
      otpCredentials: {
        otp: undefined,
        challenge: undefined
      }
    };

    try {
      const { data } = await API.getAuthExtended();
      payload.otpCredentials.challenge = data;
    } catch (err) {
      setModal(
        <OperationResult
          result="failure"
          text={serverErrorMessages(err, language)}
          buttonText={text("buttons.close")}
          clickCompleteHandler={() => setModal(null)}
        />
      );
      return;
    }

    await new Promise(resolve => {
      setModal(
        <ConfirmationCode
          clickContinueHandler={code => {
            payload.otpCredentials.otp = code;
            setModal(null);
            resolve();
          }}
        />
      );
    });

    const { account, card } = findCard(cards, i);
    const cardIsActive = card.status === "ACTIVE";
    try {
      const response = await (cardIsActive
        ? API.postLockCard(account.id, card.cardNumberMask, payload)
        : API.postUnlockCard(account.id, card.cardNumberMask, payload));
      if (response.data.status === "SUCCESS") {
        const cardsCopy = JSON.parse(JSON.stringify(cards));
        const { card: tempCard } = findCard(cardsCopy, i);
        tempCard.status = cardIsActive ? "INACTIVE" : "ACTIVE";
        setContracts("card", cardsCopy);
        setModal(
          <OperationResult
            titleText={`${text(cardIsActive ? "cards.modal.titleText.blocked" : "cards.modal.titleText.unblocked")}!`}
            buttonText={text("buttons.complete")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      } else {
        setModal(
          <OperationResult
            result="failure"
            text={`${serverErrorMessages({response}, language) || text("cards.modal.titleText.unSuccessOperationTitle")}`}
            buttonText={text("buttons.close")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      }
    } catch (err) {
      setModal(
        <OperationResult
          result="failure"
          text={serverErrorMessages(err, language)}
          buttonText={text("buttons.close")}
          clickCompleteHandler={() => setModal(null)}
        />
      );
    }
  };

  const updateCardTitle = i => async current => {
    let title = current;
    await new Promise(resolve => {
      setModal(
        <ChangeTitle
          value={title}
          clickContinueHandler={value => {
            title = value;
            setModal(null);
            resolve();
          }}
        />
      );
    });
    try {
      const { card, account } = findCard(cards, i);
      await API.setProfileAttributes([
        {
          key: "own_name_" + account.providerId + "-" + account.id + "-" + card.id,
          value: title
        }
      ]);
      readContracts(account.providerId);
      setModal(
        <OperationResult titleText={text("cards.modal.titleText.success")} buttonText={text("buttons.complete")} clickCompleteHandler={() => setModal(null)} />
      );
    } catch (err) {
      setModal(
        <OperationResult
          result="failure"
          text={serverErrorMessages(err, language)}
          buttonText={text("buttons.close")}
          clickCompleteHandler={() => setModal(null)}
        />
      );
    }
  };

  return (
    <div className={classes.cards}>
      <Button className={classes.addCard} clickHandler={() => history.push(`/home/cards/order`)}>
        <Icon name="plus" />
        &nbsp;&nbsp;{text("buttons.orderNewCard")}
      </Button>
      <Carousel
        forwardRef={ref}
        className={classes.carousel}
        slideHeight={210}
        mode="single"
        initialSlide={card ? index : count}
        changeHandler={i => history.push(`/home/cards/${i < count ? findCardByIndex(cards, i).card.id : "new"}`)}
      >
        {cards.map(account =>
          account.cardsList.map(card => (
            <CardTile
                providerId={account.providerId}
                contractId={account.id}
                cardId={card.id}
              className={classes.cardTile}
              amount={account.balance}
              currency={account.mainAccountCurrency}
              number={card.cardNumberMask}
              fullPan={getAttribute(card.attributes, 'cardPan')}
              owner={card.ownerName}
              month={card.expiryMonth}
              year={card.expiryYear}
              paymentSystem={card.bankCardType}
              status={card.status}
              creditLimit={[null, undefined].includes(account.creditLimitFormatted) ? "Не встановлений" : account.creditLimitFormatted}
              ownFunds={account.ownFunds}
              isActive={card.id === cardId}
              clickCopyHandler={() => {
                navigator.clipboard.writeText(account.mainAccountNumber);
                notify.show(text("card.details.iban.success"), "custom", 1000, {
                  background: "#7ABD43",
                  text: "#FFFFFF"
                });
              }}
              clickCreditHandler={account.isCreditLimitChangeRequestAllowed ? () => history.push(`/home/cards/${card.id}/limits`) : false}
              clickSetLimitHandler={() => setModal(<CardLimit card={card} account={account} />)}
              clickBlockHandler={() => updateCardStatus(card.id)}
                clickChangePinHandler={() => {changePin(account.id, card.id)}}
              hasOpenButton
              onlyOperations
              key={card.id}
              showCreditInfo={account.isCreditLimitChangeRequestAllowed === "true"}
            />
          ))
        )}

        <SuggestionTile
          className={classes.suggestionTile}
          iconName="suggestionsNewCard"
          title={text("suggestionTile.card.title")}
          description={text("suggestionTile.card.description")}
          buttonText={`${text("buttons.orderNewCard")}`}
          clickButtonHandler={() => history.push(`/home/cards/order`)}
        />
      </Carousel>
      {card && (
        <Card
          card={card}
          account={account}
          clickOuterToInnerTransactionHandler={() =>
            history.push(`/home/transactions/outer-to-inner?receiver=${genContractKey(account.providerId, account.id, card.id)}`)
          }
          clickInnerToInnerTransactionHandler={() =>
            history.push(`/home/transactions/outer-to-inner?sender=${genContractKey(account.providerId, account.id, card.id)}`)
          }
          clickInnerToOuterTransactionHandler={() =>
            history.push(`/home/transactions/inner-to-outer?sender=${genContractKey(account.providerId, account.id, card.id)}`)
          }
          clickChangeLimitHandler={() => setModal(<CardLimit card={card} account={account} />)}
          clickStatusHandler={() => updateCardStatus(card.id)}
          clickTitleHandler={updateCardTitle(card.id)}
          clickCreditHandler={() => history.push(`/home/cards/${card.id}/limits`)}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ contracts }) => ({ cards: contracts.cards });

const mapDispatchToProps = dispatch => ({
  setContracts: (providerId, data) => dispatch(actions.setContracts(providerId, data)),
  readContracts: providerId => dispatch(actions.readContracts(providerId))
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(Cards));
