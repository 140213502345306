import React from "react";
import classes from "./Requisites.module.scss";
import { languageContext, modalContext } from "../../../context/index";
import * as API from "../../../api/index";
import {Title, Button, Spinner} from "../../../components/index";
import {notify} from "react-notify-toast";


const Requisites = ({providerId, contractId, cardId}) => {
  const { text } = React.useContext(languageContext);
  const { setModal } = React.useContext(modalContext);

  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState();

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await API.getContractRequisites(providerId, contractId, cardId);
      setData(response.data.requisites);
      setLoading(false);
    })();
  }, [providerId, contractId, cardId]);

  return (
    <div className={classes.dialog}>
      <Title className={classes.title} text={text("requisites.modal.title")} />
        <div className={classes.text}>
          {loading ? <Spinner diameter={50} /> : <>{data}</>}
        </div>
        <div className={classes.row}>
          <Button className={classes.buttonCancel} clickHandler={()=>{setModal(null)}}>
            {text("requisites.modal.cancel")}
          </Button>
            {loading
                ? <>&nbsp;</>
                : <Button
                      className={classes.button}
                      clickHandler={async ()=>{
                          await navigator.clipboard.writeText(data);
                          notify.show(text("requisites.modal.success"), "custom", 1000, {
                              background: "#7ABD43",
                              text: "#FFFFFF"
                          });
                          setModal(null)}
                        }
                  >
                    {text("requisites.modal.confirm")}
                  </Button>
            }
        </div>
    </div>
  );
};

export default Requisites;
