import * as actionTypes from "../actions/actionTypes";
import updateState from "../../helpers/updateState";

const initialState = {
  cards: [],
  accounts: [],
  credits: [],
  deposits: []
};

const setContracts = (state, { contractType, data }) => {
  return updateState(state, {
    [contractType + "s"]: data
  });
};

const removeContracts = () => {
  return {
    cards: [],
    accounts: [],
    credits: [],
    deposits: []
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CONTRACTS:
      return setContracts(state, action);
    case actionTypes.REMOVE_CONTRACTS:
      return removeContracts();
    default:
      return state;
  }
};

export default reducer;
