import React, { useContext, useState, useEffect } from "react";
import classes from "./PasswordSaving.module.scss";
import { languageContext, authContext, modalContext } from "../../../context/index";
import { LanguageSwitch, Title, Error, Input, Button, OperationResult } from "../../../components/index";
import * as API from "../../../api/index";
import { passwordSavingSchema, validationError, fieldErrorMessage } from "../../../validation/index";
import { useTitle } from "../../../hooks/index";
import serverErrorMessages from "../../../helpers/serverErrorMessages";

const PasswordSaving = ({ history }) => {
  const { language, setLanguage, text } = useContext(languageContext);
  const { temporaryPasswordRecoveryAuthHeader, setTemporaryPasswordRecoveryAuthHeader } = useContext(authContext);
  const { setModal } = useContext(modalContext);

  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const globalErrorMessage = fieldErrorMessage("global", error);

  const submit = async () => {
    clearTimeout(PasswordSaving.timeout);
    setError(null);
    const error = validationError(passwordSavingSchema(language, password), {
      code,
      password,
      confirmedPassword
    });
    if (error) {
      setError(error);
      return;
    }
    setIsFetching(true);
    try {
      await API.postPasswordSave(temporaryPasswordRecoveryAuthHeader, {
        otp: code,
        newPassword: password,
        confirmPassword: confirmedPassword
      });
      setTemporaryPasswordRecoveryAuthHeader(null);
      history.push("/auth/login");
      setModal(
        <OperationResult
          titleText={`${text("settings.modal.titleText")}!`}
          buttonText={text("buttons.complete")}
          clickCompleteHandler={() => setModal(null)}
        />
      );
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
      setModal(
        <OperationResult
          result='failure'
          titleText={`${serverErrorMessages(err, language)}!`}
          buttonText={text("buttons.complete")}
          clickCompleteHandler={() => setModal(null)}
        />
      );
    }
    setIsFetching(false);

    history.push("/auth/login");
  };

  useEffect(() => {
    if (error) {
      PasswordSaving.timeout = setTimeout(() => {
        setError(null);
      }, 6000);
    }
  }, [error]);

  useEffect(() => {
    if (!temporaryPasswordRecoveryAuthHeader) {
      history.push("/auth/password-recovery");
    }
  }, [history, temporaryPasswordRecoveryAuthHeader]);

  useEffect(() => {
    return () => {
      clearTimeout(PasswordSaving.timeout);
    };
  }, []);

  useTitle(text("passwordSaving.title"));

  return (
    <div className={classes.wrapper}>
      <LanguageSwitch className={classes.languageSwitch} currentLanguage={language}
                      changeHandler={value => setLanguage(value)}/>
      <Title className={classes.title} text={text("passwordSaving.title")}/>
      {globalErrorMessage && <Error className={classes.error} message={globalErrorMessage}/>}
      <Input
        className={classes.input}
        errorClassName={classes.error}
        labelText={text("passwordSaving.codeSMS")}
        value={code}
        changeHandler={value => setCode(value)}
        errorMessage={fieldErrorMessage("code", error)}
      />
      <Input
        className={classes.input}
        errorClassName={classes.error}
        labelText={text("passwordSaving.newPassword")}
        type="password"
        maxLength={21}
        value={password}
        changeHandler={value => setPassword(value)}
        errorMessage={fieldErrorMessage("password", error)}
      />
      <Input
        className={classes.input}
        errorClassName={classes.error}
        labelText={text("passwordSaving.confirmPassword")}
        type="password"
        maxLength={21}
        value={confirmedPassword}
        changeHandler={value => setConfirmedPassword(value)}
        errorMessage={fieldErrorMessage("confirmedPassword", error)}
      />
      <div className={classes.text}>{text("passwordSaving.passwordInformation")}</div>
      <Button className={classes.button} clickHandler={submit} isDisabled={isFetching}>
        {text("passwordSaving.buttonSave")}
      </Button>
    </div>
  );
};

export default PasswordSaving;
