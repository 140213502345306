import React from "react";
import classes from "./DepositOrderNew.module.scss";
import { Button, Checkbox, ConfirmationCode, Error, Input, Label, OperationResult, Select, Title } from "../../../../../components";
import { fieldErrorMessage, validationError } from "../../../../../validation";
import { authContext, languageContext, modalContext, otpContext } from "../../../../../context";
import * as actions from "../../../../../store/actions";
import { connect } from "react-redux";
import { accountOptions, cardOptions } from "../../../../../store/getters";
import cn from "../../../../../helpers/cn";
import localeAmount from "../../../../../helpers/localeAmount";
import serverErrorMessages from "../../../../../helpers/serverErrorMessages";
import * as API from "../../../../../api";
import { makeContractSchema } from "../../../../../validation/contracts";
import { usePrevious } from "../../../../../hooks";

const DepositOrderNew = ({ product, cards, accounts, onCancel, history, payout, readContracts }) => {
  const [error, setError] = React.useState(null);
  const [confirm, setConfirm] = React.useState(false);
  const [confirm2, setConfirm2] = React.useState(false);
  const [confirm3, setConfirm3] = React.useState(false);

  const { setOtp } = React.useContext(otpContext);
  const { modal, setModal } = React.useContext(modalContext);
  const { language, text } = React.useContext(languageContext);
  const { user } = React.useContext(authContext);
  const [isPrefaced, setIsPrefaced] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [commission, setCommission] = React.useState(false);
  const [extAuthRequired, setExtAuthRequired] = React.useState(false);
  const [otpCredentialsChallenge, setOtpCredentialsChallenge] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [autoprolongation, setAutoprolongation] = React.useState(true);
  const globalErrorMessage = fieldErrorMessage("global", error);
  const name = "".concat(text("depositOrderNew.title"), ' "', product.name, '"');
  const [isShowCommission, setShowCommission] = React.useState("false");
  const [fee, setFee] = React.useState(0);

  const contractOptions = React.useMemo(() => [...cardOptions(cards), ...accountOptions(accounts, text)].filter(x => x.currency === product.currency), [
    cards,
    accounts,
    product.currency,
    text
  ]);

  const [srcContract, _setSrcContract] = React.useState({});

  const setSrcContract = React.useCallback(
    value => {
      _setSrcContract(contractOptions.find(contract => contract.value === value) || {});
    },
    [contractOptions]
  );

  React.useEffect(() => {
    setEmail(user.email);
  }, [user.email]);

  const submitExecute = async () => {
    let challenge;
    if (extAuthRequired === true) {
      try {
        setIsFetching(true);
        const { data } = await API.getAuthExtended();
        challenge = data;
        setIsFetching(false);
      } catch (err) {
        setError({
          field: "global",
          message: serverErrorMessages(err, language)
        });
        setIsFetching(false);
        return;
      }
    }

    if (challenge) {
      try {
        setIsFetching(true);
        const otpCredentials = {
          otp: undefined,
          challenge
        };
        await new Promise(resolve => {
          setOtp(
            <ConfirmationCode
              clickContinueHandler={code => {
                otpCredentials.otp = code;
                setOtp(null);
                resolve();
              }}
            />
          );
        });

        setIsFetching(true);
        await API.postAuthLoginOtp(otpCredentials);
      } catch (err) {
        setError({
          field: "global",
          message: serverErrorMessages(err, language)
        });
        setIsFetching(false);
        return;
      }
    }
    setIsFetching(true);
    try {
      const payload = {
        providerId: "deposit",
        typeId: product.typeId,
        contractCurrency: product.currency,
        currency: product.currency,
        termId: product.termId,
        srcContractRef: {
          contractId: srcContract?.contractId,
          providerId: srcContract?.providerId
        },
        amount: product.requestedAmount,
        cardId: srcContract?.cardId,
        email,
        autoprolongation
      };
      const { data } = await API.makeContractExecute(payload);
      if (data.status === "FAIL") {
        setError({
          field: "global",
          message: serverErrorMessages(
            {
              response: {
                data
              }
            },
            language
          )
        });
        setIsFetching(false);
        return;
      }
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
      setIsFetching(false);
      return;
    }
    readContracts("deposit");
    setModal(
      <OperationResult
        titleText={`${text("depositOrderNew.modal.titleText")}!`}
        text={text("depositOrderNew.modal.text")}
        buttonText="Ok"
        // buttonText={text("buttons.complete")}
        clickCompleteHandler={() => setModal(null)}
      />
    );
  };

  const previousModal = usePrevious(modal);

  React.useEffect(() => {
    if (previousModal && !modal) {
      history.push("/home/deposits");
    }
  }, [previousModal, modal, history]);

  const submitPreface = async () => {
    clearTimeout(DepositOrderNew.timeout);
    setError(null);
    const error = validationError(makeContractSchema(language), {
      email,
      givingAccount: srcContract?.value
    });
    if (error) {
      setError(error);
      return;
    }
    setIsFetching(true);
    try {
      const payload = {
        providerId: "deposit",
        typeId: product.typeId,
        contractCurrency: product.currency,
        currency: product.currency,
        termId: product.termId,
        srcContractRef: {
          contractId: srcContract?.contractId,
          providerId: srcContract?.providerId
        },
        amount: product.requestedAmount,
        cardId: srcContract?.cardId,
        email,
        autoprolongation
      };
      const { data } = await API.makeContractPreface(payload);
      if (data.status === "FAIL" && data.errorDescriptionUk !== undefined) {
        setError({
          field: "global",
          message: language === "ua" ? data.errorDescriptionUk : language === "en" ? data.errorDescriptionEn : data.errorDescriptionRu
        });
      } else {
        setCommission(`${localeAmount(data.operationConditions?.commission, language)} ${data.currency}`);
        setExtAuthRequired(data.operationConditions?.extAuthRequired || false);
        setShowCommission(data.isShowCommission);
        setIsPrefaced(true);
        setFee(data.operationConditions?.commission);
      }
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
    }
    setIsFetching(false);
  };

  const toStringTerm = ({ type, value }, locale = undefined) =>
    type === "DAY" && value === 95
      ? text("depositOrderConditions.termTypeD95", locale)
      : `${value} ${text("depositOrderConditions.termType" + type.slice(0, 1), locale)}`;

  return (
    <div className={classes.depositOrder}>
      <div className={classes.header}>
        <Title className={classes.title} text={name} />
      </div>
      {!isPrefaced && (
        <div className={classes.content}>
          <Input style={{ marginBottom: 20 }} labelText={text("depositOrderNew.labels.currency")} value={product.currency} isDisabled />

          <Input style={{ marginBottom: 20 }} labelText={text("depositOrderNew.labels.term")} value={toStringTerm(product.term)} isDisabled />

          <Input
            style={{ marginBottom: 20 }}
            labelText={text("depositOrderNew.labels.interestRate")}
            value={"".concat(product.interestRate || "", " %")}
            isDisabled
          />

          <Input style={{ marginBottom: 20 }} labelText={text("depositOrderNew.labels.interestPayment")} value={payout[product.repaymentTermType]} isDisabled />

          <div className={cn(classes.checkbox, classes.margin)}>
            <Label>{text("depositOrderNew.labels.prolongation")}</Label>
            <Checkbox type={1} value={product.autoProlongationAllowed} />
          </div>

          <div className={cn(classes.checkbox, classes.margin)}>
            <Label>{text("depositOrderNew.labels.refill")}</Label>
            <Checkbox type={1} value={product.refillAllowed} />
          </div>

          <div className={cn(classes.checkbox, classes.margin)}>
            <Label>{text("depositOrderNew.labels.capitalization")}</Label>
            <Checkbox type={1} value={product.capitalization} />
          </div>

          <Label>{text("depositOrderNew.labels.account")}</Label>
          <Select
            errorClassName={classes.error}
            style={{ marginBottom: 20 }}
            options={contractOptions}
            value={srcContract?.value}
            changeHandler={setSrcContract}
            placeholder={text("depositOrderNew.placeholder")}
            errorMessage={fieldErrorMessage("givingAccount", error)}
            isDisabled={isPrefaced || isFetching}
          />
          <Input
            style={{ marginBottom: 20 }}
            // className={classes.input}
            errorClassName={classes.error}
            labelText={text("depositOrderNew.labels.email")}
            value={email}
            changeHandler={setEmail}
            errorMessage={fieldErrorMessage("email", error)}
          />
          {product.autoProlongationAllowed && (
            <div className={classes.checkbox}>
              <Label>{text("depositOrderNew.labels.isProlongation")}</Label>
              <Checkbox type={2} value={autoprolongation} changeHandler={() => setAutoprolongation(!autoprolongation)} />
            </div>
          )}
          <Input
            style={{ width: 250, marginBottom: 20 }}
            labelText={text("depositOrderNew.labels.amount")}
            value={localeAmount(product.requestedAmount)}
            units={product.currency}
            isDisabled
          />
          {commission && (
            <div className={cn(classes.commisionText, classes.margin)}>
              {text("depositOrderNew.labels.commission")}: {commission}
            </div>
          )}
        </div>
      )}
      {isPrefaced && (
        <div className={classes.content_table}>
          <>
            <h2>{text("depositOrderNew.table.title")}</h2>
            <h3>{text("depositOrderNew.table.subTitle")}</h3>
            <table>
              <tbody>
                <tr>
                  <td>{text("depositOrderNew.table.row1")}</td>
                  <td>{product.name}</td>
                </tr>
                <tr>
                  <td>{text("depositOrderNew.table.row2")}</td>
                  <td>
                    {localeAmount(product.requestedAmount)} {product.currency}
                  </td>
                </tr>
                <tr>
                  <td>{text("depositOrderNew.table.row3")}</td>
                  <td>{toStringTerm(product.term, "ua")}</td>
                </tr>
                <tr>
                  <td>{text("depositOrderNew.table.row4")}</td>
                  <td>{"".concat(product.interestRate || "", " %")}</td>
                </tr>
                <tr>
                  <td>{text("depositOrderNew.table.row5")}</td>
                  <td>{text(product.capitalization === true ? "labels.yes" : "labels.no")}</td>
                </tr>
                <tr>
                  <td>{text("depositOrderNew.table.row6")}</td>
                  <td>{text(product.preTermCloseEnabled === "true" ? "labels.allowed" : "labels.notAllowed")}</td>
                </tr>
                <tr>
                  <td>{text("depositOrderNew.table.row7")}</td>
                  {parseFloat(product.penalty) === 0 && <td>{text("labels.notAllowed")}</td>}
                  {parseFloat(product.penalty) > 0 && <td>{"".concat(product.penalty || "", " %")}</td>}
                </tr>
                <tr>
                  <td>{text("depositOrderNew.table.row8")}</td>
                  <td>{text(product.autoProlongationAllowed === true ? "labels.allowed" : "labels.notAllowed")}</td>
                </tr>
                <tr>
                  <td>{text("depositOrderNew.table.row9")}</td>
                  <td>{text(product.earlyWithdrawlAllowed === true ? "labels.allowed" : "labels.notAllowed")}</td>
                </tr>
                <tr>
                  <td>{text("depositOrderNew.table.row10")}</td>
                  <td>{text(product.refillAllowed === true ? "labels.allowed" : "labels.notAllowed")}</td>
                </tr>
                <tr>
                  <td>{text("depositOrderNew.table.row11")}</td>
                  <td>{text("paymentTermTypes.".concat(product.regularInterestPaymentTermType))}</td>
                </tr>
                <tr>
                  <td>{text("depositOrderNew.table.row12")}</td>
                  {parseInt(product.minReplenishmentAmount, 10) === 0 && <td>{text("labels.notAllowed")}</td>}
                  {parseInt(product.minReplenishmentAmount, 10) !== 0 && (
                    <td>
                      {localeAmount(product.minReplenishmentAmount)} {product.currency}
                    </td>
                  )}
                </tr>
                <tr>
                  <td>{text("depositOrderNew.table.row13")}</td>
                  <td>{srcContract?.accountNumber}</td>
                </tr>
                <tr>
                  <td>{text("depositOrderNew.table.row14")}</td>
                  <td>{email}</td>
                </tr>
                {isShowCommission === "true" && (
                    <tr>
                      <td colSpan={2}>
                        <b>Увага! За переказ кредитних коштів буде нараховано комісію {localeAmount(fee)} грн.</b>
                      </td>
                    </tr>
                )}
              </tbody>
            </table>
          </>
        </div>
      )}
      {!isPrefaced && (
          <div className={classes.content_table}>
            <div className={classes.checkbox}>
              <Label>{text("depositOrderNew.labels.confirm3")}</Label>
            <Checkbox type={2} value={confirm2} changeHandler={() => setConfirm2(!confirm2)} />
          </div>
          <div className={classes.checkbox}>
            <Label>{text("depositOrderNew.labels.confirm4")}</Label>
            <Checkbox type={2} value={confirm3} changeHandler={() => setConfirm3(!confirm3)} />
          </div>
        </div>
      )}
      {isPrefaced && (
        <div className={classes.content_table}>
          <br />
          <div className={classes.checkbox}>
            <label>
              {text("depositOrderNew.labels.confirm1")}
              &nbsp;
              <a href="https://ap-bank.com/documents/download/1084" target="_blank" rel="noreferrer noopener">
                {text("depositOrderNew.labels.confirm1_1")}
              </a>
              &nbsp;
              <a href="https://ap-bank.com/documents/download/1033" target="_blank" rel="noreferrer noopener">
                {text("depositOrderNew.labels.confirm2")}
              </a>
              &nbsp;
              {text("depositOrderNew.labels.confirm2_1")}
            </label>
            <Checkbox type={2} value={confirm} changeHandler={() => setConfirm(!confirm)} />
          </div>
        </div>
      )}
      <div className={classes.content}>{globalErrorMessage && <Error className={classes.error} message={globalErrorMessage} />}</div>
      <div className={classes.content}>
        <div className={classes.buttons}>
          <Button className={cn(classes.button, classes.buttonBack)} clickHandler={() => onCancel()} isDisabled={isFetching}>
            {text("buttons.cancel")}
          </Button>
          {isPrefaced && (
            <Button className={cn(classes.button, classes.buttonSubmit)} clickHandler={submitExecute} isDisabled={!confirm || isFetching}>
              {text("depositOrderNew.buttons.open")} &rarr;
            </Button>
          )}
          {!isPrefaced && (
            <Button className={cn(classes.button, classes.buttonSubmit)} clickHandler={submitPreface} isDisabled={!(confirm2 && confirm3) || isFetching}>
              {text("buttons.continue")} &rarr;
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ contracts }) => ({ cards: contracts.cards, accounts: contracts.accounts });
const mapDispatchToProps = dispatch => ({
  readContracts: contractType => dispatch(actions.readContracts(contractType))
});

export default connect(mapStateToProps, mapDispatchToProps)(DepositOrderNew);
