import React, { useContext, useState } from "react";
import classes from "./AccountTile.module.scss";
import PropTypes from "prop-types";
import { languageContext } from "../../../context/index";
import cn from "../../../helpers/cn";
import localeAmount from "../../../helpers/localeAmount";
import all from "../../../helpers/all";
import { Icon } from "../../index";

const AccountTile = ({
  className,
  style,
  height,
  number,
  amount,
  currency,
  clickHistoryHandler,
  clickFillHandler,
  clickTransactionHandler,
  clickPayHandler,
  hasOpenButton
}) => {
  const { text } = useContext(languageContext);

  const options = [
    {
      iconName: "historyOption",
      label: text("accountTile.options.history"),
      clickHandler: clickHistoryHandler
    },
    {
      iconName: "fillAccount",
      label: text("accountTile.options.fillAccount"),
      clickHandler: clickFillHandler
    },
    {
      iconName: "withdrawAccount",
      label: text("accountTile.options.withdrawAccount"),
      clickHandler: clickTransactionHandler
    },
    {
      iconName: "payOption",
      label: text("accountTile.options.pay"),
      clickHandler: clickPayHandler
    }
  ];

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={cn(classes.accountTile, className)} style={style}>
      <div className={classes.top} style={{ height: height / 2 }}>
        <div className={classes.iconWrapper}>
          <Icon name="accountTile" />
        </div>
        <div className={classes.description}>
          <div className={classes.name} title={number}>
            {number}
          </div>
        </div>
        {hasOpenButton && (
          <div className={classes.openIconWrapper} onClick={() => setIsOpen(!isOpen)}>
            <Icon style={{ ...(isOpen ? { transform: "rotate(180deg)" } : {}) }} name="selectArrow" />
          </div>
        )}
      </div>
      {!isOpen ? (
        <div className={classes.bottom} style={{ height: height / 2 }}>
          <div className={classes.row}>
            <div className={classes.amount}>
              {localeAmount(amount)} <span className={classes.currency}>{currency}</span>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.options}>
          {options.map((option, i) => (
            <div id="option" className={classes.option} onClick={() => all(option.clickHandler, () => setIsOpen(false))} key={i}>
              {option.iconName && <Icon name={option.iconName} />}
              {option.label && <div className={classes.label}>{option.label}</div>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

AccountTile.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  height: PropTypes.number,
  number: PropTypes.string,
  amount: PropTypes.number,
  currency: PropTypes.string,
  clickHistoryHandler: PropTypes.func,
  clickFillHandler: PropTypes.func,
  clickTransactionHandler: PropTypes.func,
  clickPayHandler: PropTypes.func,
  hasOpenButton: PropTypes.bool
};

AccountTile.defaultProps = {
  className: "",
  style: {},
  height: 130,
  number: "",
  amount: "0",
  currency: "UAH",
  clickHistoryHandler: null,
  clickFillHandler: null,
  clickTransactionHandler: null,
  clickPayHandler: null,
  hasOpenButton: true
};

export default AccountTile;
