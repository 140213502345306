import React from "react";
import classes from "./OperationResult.module.scss";
import PropTypes from "prop-types";
import { Icon, Title, Button } from "../../index";

const OperationResult = ({ result, titleText, text, buttonText, clickCompleteHandler }) => {
  return (
    <div className={classes.operationResult}>
      <Icon name={result} />
      {titleText && <Title className={classes.title} text={titleText} />}
      {text && <div className={classes.text}>{text}</div>}
      <Button className={classes.button} clickHandler={clickCompleteHandler}>
        {buttonText}
      </Button>
    </div>
  );
};

OperationResult.propTypes = {
  result: PropTypes.oneOf(["success", "failure"]),
  titleText: PropTypes.string,
  text: PropTypes.string,
  buttonText: PropTypes.string,
  clickCompleteHandler: PropTypes.func
};

OperationResult.defaultProps = {
  result: "success",
  titleText: "",
  text: "",
  buttonText: "",
  clickCompleteHandler: null
};

export default OperationResult;
