import React, { useState, useEffect, useContext } from "react";
import classes from "./ChangeCategoryModal.module.scss";
import * as API from "../../../../../api/index";
import { Title, Button, Radio, Spinner } from "../../../../../components/index";
import { languageContext, modalContext } from "../../../../../context/index";

const ChangeCategoryModal = ({ operationId, categoryId, categoryType }) => {
  const { language, text } = useContext(languageContext);
  const { setModal } = useContext(modalContext);

  const iconURL = "/bank/category-icon/svg/";

  const [categories, setCategories] = useState([]);
  const [value, setValue] = useState(categoryId);
  const [isContent, setIsContent] = useState(false);

  useEffect(() => {
    API.getFinancialCategories().then(({ data }) => {
      if (data) {
        setIsContent(true);
      }
      setCategories(data.filter(item => item.categoryType === categoryType));
    });
  }, [categoryType]);

  const translation = categoryLocale => {
    const { locale, translation } = categoryLocale;
    return locale === language || (locale === "uk" && language === "ua") ? (
      <div className={classes.categoryName} key={locale}>
        <span>{translation}</span>
      </div>
    ) : null;
  };

  const radioButtonChangeHandler = e => {
    // console.log(e.target.value, 'radio-value');
    setValue(e.target.value);
  };

  const saveChangesCategoryHandler = () => {
    API.postChangeCategoryForOperation(operationId, value).then(result => console.log(result, "post-changes"));
    setModal(null);
  };

  return (
    <div className={classes.changeCategoriesWrapper}>
      <Title style={{ marginBottom: "25px" }} text={text("financialAssistant.category.changeTitle")} />
      {!isContent && <Spinner className={classes.spinner} diameter={50} />}
      {isContent && (
        <>
          <span className={classes.subTitle}>{text("financialAssistant.category.assignCategory")}</span>
          <div className={classes.categoriesWrapper} value={value} onChange={radioButtonChangeHandler}>
            {categories.map(item => (
              <div className={classes.categories} key={item.categoryId}>
                <div className={classes.imageAndNameBlock}>
                  <img className={classes.categoryImage} src={`${iconURL}/${item.categoryIcon}.svg`} alt="icon" />
                  {item.categoryLocalization.map(categoryLocale => translation(categoryLocale))}
                </div>
                <Radio className={classes.radio} name="changeCategory" value={item.categoryId} defaultChecked={item.categoryId === categoryId} />
              </div>
            ))}
          </div>
          <div className={classes.buttons}>
            <Button className={classes.cancelButton} clickHandler={() => setModal(null)}>
              {text("buttons.cancel")}
            </Button>
            <Button className={classes.saveChanesButton} clickHandler={saveChangesCategoryHandler}>
              {text("buttons.saveChanges")}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
export default ChangeCategoryModal;
