import React from "react";
import classes from "./SuggestionTile.module.scss";
import PropTypes from "prop-types";
import cn from "../../../helpers/cn";
import { Icon, Button } from "../../index";

const SuggestionTile = ({ className, style, iconName, title, description, buttonText, clickButtonHandler }) => {
  return (
    <div className={cn(classes.suggestionTile, className)} style={style}>
      <div className={classes.iconWrapper}>
        <Icon name={iconName} />
      </div>
      <div className={classes.content}>
        {title && <div className={classes.title}>{title}</div>}
        {description && <div className={classes.description}>{description}</div>}
        <Button className={classes.button} clickHandler={clickButtonHandler}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

SuggestionTile.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  iconName: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  clickButtonHandler: PropTypes.func
};

SuggestionTile.defaultProps = {
  className: "",
  style: {},
  iconName: null,
  title: "",
  description: "",
  buttonText: "",
  clickButtonHandler: null
};

export default SuggestionTile;
