import React from "react";
import classes from "./AccountOrder.module.scss";
import { Button, Checkbox, ConfirmationCode, Error, Input, Label, OperationResult, Title } from "../../components";
import { fieldErrorMessage, validationError } from "../../validation";
import { authContext, languageContext, modalContext, otpContext } from "../../context";
import * as actions from "../../store/actions";
import { connect } from "react-redux";
import cn from "../../helpers/cn";
import serverErrorMessages from "../../helpers/serverErrorMessages";
import * as API from "../../api";
import { makeContractSchema } from "../../validation/contracts";
import { usePrevious } from "../../hooks";
import { useParams } from "react-router-dom";

const AccountOrder = ({ history, readContracts }) => {
  const { currency } = useParams();
  const [error, setError] = React.useState(null);
  const [confirm, setConfirm] = React.useState(false);
  const [confirm2, setConfirm2] = React.useState(false);
  const [confirm3, setConfirm3] = React.useState(false);

  const { setOtp } = React.useContext(otpContext);
  const { modal, setModal } = React.useContext(modalContext);
  const { language, text } = React.useContext(languageContext);
  const { user } = React.useContext(authContext);
  const [isPrefaced, setIsPrefaced] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [otpCredentialsChallenge, setOtpCredentialsChallenge] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const globalErrorMessage = fieldErrorMessage("global", error);

  React.useEffect(() => {
    setEmail(user.email);
  }, [user.email]);

  const submitExecute = async () => {
    if (otpCredentialsChallenge) {
      try {
        setIsFetching(true);
        const otpCredentials = {
          otp: undefined,
          challenge: otpCredentialsChallenge
        };
        await new Promise(resolve => {
          setOtp(
            <ConfirmationCode
              clickContinueHandler={code => {
                otpCredentials.otp = code;
                setOtp(null);
                resolve();
              }}
            />
          );
        });

        setIsFetching(true);
        await API.postAuthLoginOtp(otpCredentials);
      } catch (err) {
        setError({
          field: "global",
          message: serverErrorMessages(err, language)
        });
        setIsFetching(false);
        return;
      }
    }
    setIsFetching(true);
    try {
      const payload = {
        email,
        providerId: "account",
        contractCurrency: currency
      };
      const { data } = await API.makeContractExecute(payload);
      if (data.status === "FAIL") {
        setError({
          field: "global",
          message: serverErrorMessages(
            {
              response: {
                data
              }
            },
            language
          )
        });
        setIsFetching(false);
        return;
      }
    } catch (err) {
      if (err?.response?.data?.errorMessageKey === "specific_integration_error_account_in_same_currency_already_created") {
        await setModal(
          <OperationResult
            result="failure"
            titleText={text("exchangeTransaction.alreadyCreated")}
            buttonText={text("buttons.close")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
        readContracts("account");
        history.push("/home/profile");
      }
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
      setIsFetching(false);
      return;
    }
    readContracts("account");
    setModal(
      <OperationResult
        titleText={`${text("accountOrder.modal.titleText")}!`}
        text={text("accountOrder.modal.text")}
        buttonText="Ok"
        // buttonText={text("buttons.complete")}
        clickCompleteHandler={() => setModal(null)}
      />
    );
  };

  const previousModal = usePrevious(modal);

  React.useEffect(() => {
    if (previousModal && !modal) {
      history.push("/home/transactions/exchange");
    }
  }, [previousModal, modal, history]);

  const submitPreface = async () => {
    clearTimeout(AccountOrder.timeout);
    setError(null);
    const error = validationError(makeContractSchema(language), { email });
    if (error) {
      setError(error);
      return;
    }
    setIsFetching(true);
    try {
      const payload = {
        email,
        providerId: "account",
        contractCurrency: currency
      };
      const { data } = await API.makeContractPreface(payload);
      if (data.status === "FAIL" && data.errorDescriptionUk !== undefined) {
        setError({
          field: "global",
          message: language === "ua" ? data.errorDescriptionUk : language === "en" ? data.errorDescriptionEn : data.errorDescriptionRu
        });
      } else {
        const { extAuthRequired } = data.operationConditions;
        if (extAuthRequired === true) {
          try {
            setIsFetching(true);

            const { data } = await API.getAuthExtended();
            setOtpCredentialsChallenge(data);
            setIsFetching(false);
          } catch (err) {
            setError({
              field: "global",
              message: serverErrorMessages(err, language)
            });
            setIsFetching(false);
            return;
          }
        }
        setIsPrefaced(true);
      }
    } catch (err) {
      if (err?.response?.data?.errorMessageKey === "specific_integration_error_account_in_same_currency_already_created") {
        await setModal(
          <OperationResult
            result="failure"
            titleText={text("exchangeTransaction.alreadyCreated")}
            buttonText={text("buttons.close")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
        readContracts("account");
        history.push("/home/profile");
      }
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
    }
    setIsFetching(false);
  };

  return (
    <div className={classes.accountOrder}>
      <div className={classes.header}>
        <Title className={classes.title} text={`${text("accountOrder.title")} ${currency}`} />
      </div>

      <div className={classes.content}>
        <Input
          style={{ marginBottom: 20 }}
          // className={classes.input}
          errorClassName={classes.error}
          labelText={text("accountOrder.labels.email")}
          value={email}
          changeHandler={setEmail}
          isDisabled={isPrefaced}
          errorMessage={fieldErrorMessage("email", error)}
        />
        <div className={classes.checkbox}>
          <Label>{text("accountOrder.labels.confirm3")}</Label>
          <Checkbox type={2} value={confirm2} changeHandler={() => setConfirm2(!confirm2)} isDisabled={isPrefaced} />
        </div>
        <div className={classes.checkbox}>
          <Label>{text("accountOrder.labels.confirm4")}</Label>
          <Checkbox type={2} value={confirm3} changeHandler={() => setConfirm3(!confirm3)} isDisabled={isPrefaced} />
        </div>
        <div className={classes.checkbox}>
          <Label>
            <span>
              {text("accountOrder.labels.confirm1")}
              &nbsp;
              <a href="https://ap-bank.com/documents/download/1084" target="_blank" rel="noreferrer noopener">
                {text("accountOrder.labels.confirm1_1")}
              </a>
              ,&nbsp;
              <a href="https://ap-bank.com/documents/download/1594" target="_blank" rel="noreferrer noopener">
                {text("accountOrder.labels.confirm1_2")}
              </a>
              ,&nbsp;
              <a href="https://ap-bank.com/documents/download/1033" target="_blank" rel="noreferrer noopener">
                {text("accountOrder.labels.confirm1_3")}
              </a>
              &nbsp;
              {text("accountOrder.labels.confirm1_4")}
              &nbsp;
              <a href="https://ap-bank.com/documents/download/1437" target="_blank" rel="noreferrer noopener">
                {text("accountOrder.labels.confirm1_5")}
              </a>
            </span>
          </Label>
          <Checkbox type={2} value={confirm} changeHandler={() => setConfirm(!confirm)} isDisabled={isPrefaced} />
        </div>
      </div>

      <div className={classes.content}>{globalErrorMessage && <Error className={classes.error} message={globalErrorMessage} />}</div>
      <div className={classes.content}>
        <div className={classes.buttons}>
          <Button className={cn(classes.button, classes.buttonBack)} clickHandler={history.goBack} isDisabled={isFetching}>
            {text("buttons.cancel")}
          </Button>
          {isPrefaced && (
            <Button
              className={cn(classes.button, classes.buttonSubmit)}
              clickHandler={submitExecute}
              isDisabled={!(confirm && confirm2 && confirm3) || isFetching}
            >
              {text("accountOrder.buttons.open")} &rarr;
            </Button>
          )}
          {!isPrefaced && (
            <Button
              className={cn(classes.button, classes.buttonSubmit)}
              clickHandler={submitPreface}
              isDisabled={!(confirm && confirm2 && confirm3) || isFetching}
            >
              {text("buttons.continue")} &rarr;
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
  readContracts: contractType => dispatch(actions.readContracts(contractType))
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountOrder);
