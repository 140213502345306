import React from "react";

export default React.createContext({
  templates: [],
  readTemplates: () => {},
  createTemplate: () => {},
  updateTemplate: () => {},
  deleteTemplates: () => {},
  deleteTemplate: () => {},
});
