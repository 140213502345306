import localeAmount from "../../helpers/localeAmount";
import cardNumber from "../../helpers/cardNumber";
import { genContractKey } from "../../helpers/contract";

export const cardOptions = (accounts, options = {}) =>
  [].concat(
    ...accounts.map(account =>
      account.cardsList
        .filter(card => options.all || card.status === "ACTIVE")
        .map(card => ({
          value: genContractKey(account.providerId, account.id, card.id),
          label: `${cardNumber(card.cardNumberMask)} "${getAttribute(card.attributes, "productTitle")}" (${localeAmount(account.balance)} ${
            account.mainAccountCurrency
          })`,
          // @deprecated
          type: account.providerId,
          // ???
          accountNumber: account.mainAccountNumber,
          currency: account.mainAccountCurrency,
          providerId: account.providerId,
          contractId: account.id,
          cardId: card.id
        }))
    )
  );

export const uahCardOptions = accounts => [...cardOptions(accounts)].filter(account => account.currency === "UAH");

export const accountOptions = (accounts, text) =>
  accounts.map(account => ({
    value: genContractKey(account.providerId, account.id),
    label: `${account.mainAccountNumber} "${account.productTitle === "Поточний рахунок" ? text("account.title") : account.productTitle}" (${localeAmount(
      account.balance
    )} ${account.mainAccountCurrency})`,
    // @deprecated
    type: account.providerId,
    // ???
    accountNumber: account.mainAccountNumber,
    currency: account.mainAccountCurrency,
    providerId: account.providerId,
    contractId: account.id
  }));

export const uahAccountOptions = (accounts, text) => accountOptions(accounts, text).filter(x => x.currency === "UAH");

export const creditOptions = (credits, text) =>
  credits.map(credit => ({
    value: genContractKey(credit.providerId, credit.id),
    label: `${text("outerToInnerTransaction.options.credit")} "${credit.productTitle}" (${localeAmount(credit.balance)} ${credit.mainAccountCurrency})`,
    // @deprecated
    type: credit.providerId,
    currency: credit.mainAccountCurrency,
    providerId: credit.providerId,
    contractId: credit.id
  }));

export const depositSenderOptions = (deposits, text) =>
  deposits
    .filter(x => !!x.earlyWithdrawlAllowed)
    .map(deposit => ({
      value: genContractKey(deposit.providerId, deposit.id),
      label: `${text("outerToInnerTransaction.options.deposit")} "${deposit.productTitle}" (${localeAmount(deposit.balance)} ${deposit.mainAccountCurrency})`,
      // @deprecated
      type: deposit.providerId,
      currency: deposit.mainAccountCurrency,
      providerId: deposit.providerId,
      contractId: deposit.id
    }));

export const depositReceiverOptions = (deposits, text) =>
  deposits
    .filter(x => !!x.refillAllowed)
    .map(deposit => ({
      value: genContractKey(deposit.providerId, deposit.id),
      label: `${text("outerToInnerTransaction.options.deposit")} "${deposit.productTitle}" (${localeAmount(deposit.balance)} ${deposit.mainAccountCurrency})`,
      // @deprecated
      type: deposit.providerId,
      currency: deposit.mainAccountCurrency,
      providerId: deposit.providerId,
      contractId: deposit.id
    }));

export const cardLimitsData = card => {
  let dailyWithdrawalAmount = "";
  let dailyWithdrawalQuantity = "";
  try {
    const dailyWithdrawal = card.limits.find(limit => limit.type === "ATM");
    dailyWithdrawalAmount = dailyWithdrawal.amount;
    dailyWithdrawalQuantity = dailyWithdrawal.quantity;
  } catch (err) {}

  let dailyPurchaseAmount = "";
  let dailyPurchaseQuantity = "";
  try {
    const dailyPurchase = card.limits.find(limit => limit.type === "POS");
    dailyPurchaseAmount = +dailyPurchase.amount;
    dailyPurchaseQuantity = dailyPurchase.quantity;
  } catch (err) {}

  return {
    dailyWithdrawalAmount,
    dailyWithdrawalQuantity,
    dailyPurchaseAmount,
    dailyPurchaseQuantity
  };
};

export const findCard = (accounts, cardId) => {
  let account = null;
  let card = null;
  let index = 0;
  let count = 0;
  accounts.forEach(tempAccount =>
    tempAccount.cardsList.forEach(tempCard => {
      if (tempCard.id === cardId) {
        account = tempAccount;
        card = tempCard;
        index = count;
      }
      count += 1;
    })
  );
  return { account, card, index, count };
};

export const getAttribute = (attributes, name) => (attributes.find(attribute => attribute.key === name) || {}).value;
