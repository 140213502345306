import axios from "../axios";

export const postAutopaymentsCreatePreface = (type, payload) =>
    axios.post(`/autopayments/${type}/create/preface`, payload);

export const postAutopaymentsCreateExecute = (type, payload) =>
    axios.post(`/autopayments/${type}/create/execute`, payload);

export const getAutopayments = () => axios.get('/autopayments');

export const postAutopaymentsClosePreface = (type, payload) =>
    axios.post(`autopayments/${type}/close/preface`, payload);

export const postAutopaymentsCloseExecute = (type, payload) =>
    axios.post(`/autopayments/${type}/close/execute`, payload);

export const postAutopaymentsDeactivatePreface = (type, payload) =>
    axios.post(`/autopayments/${type}/deactivate/preface`, payload);

export const postAutopaymentsDeactivateExecute = (type, payload) =>
    axios.post(`/autopayments/${type}/deactivate/execute`, payload);

export const postAutopaymentsActivatePreface = (type, payload) =>
    axios.post(`/autopayments/${type}/activate/preface`, payload);

export const postAutopaymentsActivateExecute = (type, payload) =>
    axios.post(`/autopayments/${type}/activate/execute`, payload);

export const postAutopaymentsUpdatePreface = (type, payload) =>
    axios.post(`/autopayments/${type}/update/preface`, payload);

export const postAutopaymentsUpdateExecute = (type, payload) =>
    axios.post(`/autopayments/${type}/update/execute`, payload);


