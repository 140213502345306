import React from "react";
import classes from "./InnerToCustomTransaction.module.scss";
import { connect } from "react-redux";
import {
  Button,
  ConfirmationCode,
  Divider,
  Error,
  Input,
  Label,
  OperationResult,
  RegularPayment,
  Select,
  TemplateSettings,
  Textarea,
  Title,
  TransactionSuccess,
  Triangle
} from "../../../../components/index";
import {authContext, languageContext, modalContext, templatesContext} from "../../../../context/index";
import isCurrency from "validator/lib/isCurrency";
import isLength from "validator/lib/isLength";
import matches from "validator/lib/matches";
import getParamFromUrl from "../../../../helpers/getParamFromUrl";
import { useError } from "../../../../hooks/index";
import { fieldErrorMessage, innerToCustomTransactionSchema, innerToBudgetTransactionSchema, validationError } from "../../../../validation/index";
import * as API from "../../../../api/index";
import serverErrorMessages from "../../../../helpers/serverErrorMessages";
import localeAmount from "../../../../helpers/localeAmount";
import amountForRequest from "../../../../helpers/amountForRequest";
import { uahAccountOptions, uahCardOptions } from "../../../../store/getters/index";
import * as actions from "../../../../store/actions/index";
import Tooltip from "react-tooltip-lite";
import { findContractByKey } from "../../../../helpers/contract";
import { defIban, defIbanPast, defIbanPreChange, defIbanValidator, defIbanValue } from "../../../../helpers/iban";

const InnerToCustomTransaction = ({ history, location, cards, accounts, readContract }) => {
  const { language, text } = React.useContext(languageContext);
  const { setModal } = React.useContext(modalContext);
  const { createTemplate } = React.useContext(templatesContext);
  const contractOptions = React.useMemo(() => [...uahCardOptions(cards), ...uahAccountOptions(accounts, text)], [cards, accounts, text]);
  const [givingContract, setGivingContract] = React.useState(() => {
    return (
      (findContractByKey(contractOptions, getParamFromUrl("sender")) ? getParamFromUrl("sender") : null) ||
      (findContractByKey(contractOptions, getParamFromUrl("srcContract")) ? getParamFromUrl("srcContract") : null)
    );
  });
  const [name, setName] = React.useState(() => getParamFromUrl("name") || getParamFromUrl("receiverName") || "");
  const [code, setCode] = React.useState(() => getParamFromUrl("code") || "");
  const [taxNumber, setTaxNumber] = React.useState(() => getParamFromUrl("tax") || "");
  const [gettingAccount, setGettingAccount] = React.useState(() => defIban(getParamFromUrl("receiver") || ""));
  const [purpose, setPurpose] = React.useState(() => (getParamFromUrl("purpose") || getParamFromUrl("description") || "Переказ коштів "));
  const [amount, setAmount] = React.useState(() => getParamFromUrl("amount") || "");
  const [isFetching, setIsFetching] = React.useState(false);
  const [commission, setCommission] = React.useState("");
  const [isPrefaced, setIsPrefaced] = React.useState(false);
  const [error, setError] = useError(null);
  const [bankName, setBankName] = React.useState("");

  const [paymentTypes, setPaymentTypes] = React.useState([{value: '', label: '', description: ''}]);
  const [paymentTypeCode, setPaymentTypeCode] = React.useState("");
  const [additionalPaymentInfo, setAdditionalPaymentInfo] = React.useState("");
  const [actualPayerName, setActualPayerName] = React.useState("");
  const [actualPayerTaxIdOrPassport, setActualPayerTaxIdOrPassport] = React.useState("");

  const [bankId, setBankId] = React.useState(gettingAccount.substring(4, 10));
  const isBudget = bankId === "899998";
  const {user: {taxId}} = React.useContext(authContext);
  const [taxChanged, setTaxChanged] = React.useState(false);
  React.useEffect(() => {
    if (!taxChanged && taxNumber !== getParamFromUrl("tax")) {
      setTaxChanged(true);
    }
  }, [taxNumber, taxChanged]);
  const [isMyTax, setMyTax] = React.useState(taxId === getParamFromUrl("tax"));
  React.useEffect(() => {
    setMyTax(taxId === taxNumber)
  }, [taxNumber, taxId]);
    React.useEffect(() => {
        if (taxChanged && !isBudget) {
            setPurpose(isMyTax ? 'Переказ між власними рахунками' : 'Переказ коштів ')
        }
    }, [isMyTax, taxChanged, isBudget]);
  React.useEffect(() => {
    if (gettingAccount.length >= 10) {
      setBankId(gettingAccount.substring(4, 10));
    } else {
      setBankId("");
    }
  }, [gettingAccount]);
  React.useEffect(() => {
    if (bankId) {
      API
          .requestForCustomTransaction(bankId)
          .then(res => {
            setBankName(res.data?.bankName);
          });
    } else {
      setBankName("");
    }
    setPaymentTypeCode("");
    setActualPayerName("");
    setActualPayerTaxIdOrPassport("");
    setAdditionalPaymentInfo("");
  }, [bankId]);

  React.useEffect(() => {
    API.getPaymentTypes().then((response) => {
      setPaymentTypes([
          {
            value: '',
            label: '',
            description: '',
          },
        ...(response.data?.resource || []).map(({code, description}) => ({
          value: code,
          description,
          label: `${code} ${description}`,
        })),
      ]);
    })
  }, []);

  React.useEffect(() => {
    if (isBudget) {
      setPurpose(`${paymentTypeCode} ${additionalPaymentInfo} ${actualPayerTaxIdOrPassport}`.trim());
    }
  }, [paymentTypeCode, additionalPaymentInfo, actualPayerTaxIdOrPassport, isBudget]);

  const globalErrorMessage = fieldErrorMessage("global", error);

  React.useEffect(() => {
    if (gettingAccount < 29) {
      setIsFetching(false);
    }
  }, [gettingAccount]);

  const addTemplate = React.useCallback(
    async payload => {
      try {
        setModal(null);
        await createTemplate(payload);
        setModal(
          <OperationResult
            titleText={`${text("templates.modals.createSuccess.titleText")}!`}
            text={text("templates.modals.createSuccess.text")}
            buttonText={text("buttons.complete")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      } catch (err) {
        setModal(
          <OperationResult
            result="failure"
            text={serverErrorMessages(err, language)}
            buttonText={text("buttons.close")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      }
    },
    [setModal, text, createTemplate, language]
  );

  const initialSubmit = async () => {
    setError(null);
    const validationSchema = isBudget ? innerToBudgetTransactionSchema(language) : innerToCustomTransactionSchema(language);
    const error = validationError(validationSchema, {
      givingContract,
      gettingAccount: gettingAccount.toUpperCase(),
      name,
      code: gettingAccount.substring(4, 10),
      taxNumber,
      purpose: (purpose || '').trim(),
      amount,
      actualPayerName,
      actualPayerTaxIdOrPassport,
      paymentTypeCode,
      additionalPaymentInfo,
    });
    if (error) {
      setError(error);
      return;
    }
    setIsFetching(true);

    try {
      const srcContract = findContractByKey(contractOptions, givingContract);
      const { data } = await API.postInnerToCustomTransactionPreface({
        srcContractRef: {
          contractId: srcContract.contractId,
          providerId: srcContract.providerId
        },
        cardId: srcContract.cardId,
        destination: {
          accountNumber: gettingAccount.toUpperCase(),
          bankId: gettingAccount.substring(4, 10),
          name,
          taxId: taxNumber,
          currencyId: "UAH"
        },
        amount: Math.round(amountForRequest(amount)),
        currency: "UAH",
        purpose: (purpose || '').trim(),
        actualPayerName,
        actualPayerTaxIdOrPassport,
        paymentTypeCode,
        additionalPaymentInfo,
      });

      if (data.status === "FAIL" && data.errorDescriptionUk !== undefined) {
        console.log(language);
        setError({
          field: "global",
          message: language === "ua" ? data.errorDescriptionUk : language === "en" ? data.errorDescriptionEn : data.errorDescriptionRu
        });
      } else {
        setCommission(`${localeAmount(data.operationConditions.commission)} ${data.currency}`);
        setIsPrefaced(true);
      }
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
    }

    setIsFetching(false);
  };

  const submit = async () => {
    const srcContract = findContractByKey(contractOptions, givingContract);

    const payload = {
      otpCredentials: {
        otp: undefined,
        challenge: undefined
      },
      srcContractRef: {
        contractId: srcContract.contractId,
        providerId: srcContract.providerId
      },
      cardId: srcContract.cardId,
      destination: {
        accountNumber: gettingAccount.toUpperCase(),
        bankId: gettingAccount.substring(4, 10),
        name,
        taxId: taxNumber,
        currencyId: "UAH"
      },
      amount: Math.round(amountForRequest(amount)),
      currency: "UAH",
      purpose: (purpose || '').trim(),
      actualPayerName,
      actualPayerTaxIdOrPassport,
      paymentTypeCode,
      additionalPaymentInfo,
    };

    try {
      setIsFetching(true);
      const { data } = await API.getAuthExtended();
      payload.otpCredentials.challenge = data;
      setIsFetching(false);
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
      setIsFetching(false);
      return;
    }

    await new Promise(resolve => {
      setModal(
        <ConfirmationCode
          clickContinueHandler={code => {
            payload.otpCredentials.otp = code;
            setModal(null);
            resolve();
          }}
        />
      );
    });

    try {
      setIsFetching(true);
      const { data } = await API.postInnerToCustomTransactionExecute(payload);
      if (data.status === "FAIL") {
        setError({
          field: "global",
          message: serverErrorMessages(
            {
              response: {
                data
              }
            },
            language
          )
        });
        setIsFetching(false);
        cancel();
        return;
      }
      try {
        await readContract(srcContract.providerId, srcContract.contractId);
      } catch (error) {
        console.log("Unable to update the contract", error);
      }
      setModal(
        <TransactionSuccess
          clickCancelHandler={() => setModal(null)}
          clickAddHandler={() =>
            setModal(
              <TemplateSettings
                isNew
                initialType="SEP_TRANSFER"
                initialGivingContract={givingContract}
                initialGettingAccount={gettingAccount}
                initialReceiverName={name}
                initialReceiverCode={code}
                initialTaxNumber={taxNumber}
                initialPurpose={purpose}
                initialAmount={amount}
                clickCancelHandler={() => setModal(null)}
                clickSaveHandler={addTemplate}
              />
            )
          }
          clickRegularHandler={() =>
            setModal(
              <RegularPayment
                initialType="SEP_TRANSFER"
                initialGivingContract={givingContract}
                initialGettingAccount={gettingAccount}
                initialReceiverName={name}
                initialReceiverCode={code}
                initialTaxNumber={taxNumber}
                initialPurpose={purpose}
                initialAmount={amount}
                clickCancelHandler={() => setModal(null)}
              />
            )
          }
        />
      );
      history.push("/home/transactions");
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
      setIsFetching(false);
    }
  };

  const cancel = () => {
    setGivingContract(null);
    setName("");
    setCode("");
    setTaxNumber("");
    setGettingAccount(defIbanValue);
    setPurpose("");
    setAmount("");
    setCommission("");
    setIsPrefaced(false);
    setPaymentTypeCode("");
    setActualPayerName("");
    setActualPayerTaxIdOrPassport("");
    setAdditionalPaymentInfo("");
  };

  // const BeneficiaryNumberValidator = gettingAccount => {
  //   const CapitalLetters = gettingAccount.length === 1 ? matches(gettingAccount.substring(0, 1), /U|u/) : matches(gettingAccount.substring(0, 2), /UA|Ua|ua/);
  //   const Digital = gettingAccount.length > 2 ? isInt(gettingAccount.substring(2, 29)) : CapitalLetters;
  //
  //   return CapitalLetters && Digital && isLength(gettingAccount, { max: 29 });
  // };

  return (
    <div className={classes.wrapper}>
      <Title text={text("innerToCustomTransaction.title")} />
      <div className={classes.content}>
        <Label>{text("innerToCustomTransaction.fromAccount")}</Label>
        <Select
          errorClassName={classes.error}
          style={{ zIndex: 1, marginBottom: 20 }}
          options={contractOptions}
          value={givingContract}
          changeHandler={value => setGivingContract(value)}
          placeholder={text("innerToCustomTransaction.select.placeholder")}
          errorMessage={fieldErrorMessage("givingContract", error)}
          isDisabled={isFetching || isPrefaced}
        />
        <Divider />
        <Triangle style={{ margin: "auto" }} />

        <Label>
          {text("innerToCustomTransaction.beneficiaryNumber")}
          <>&nbsp;</>
          <Tooltip
            color="black"
            background="lightgrey"
            content={
              <span>
                {" "}
                {text("innerToCustomTransaction.beneficiaryNumberTip")}
                <br />
                {text("innerToCustomTransaction.beneficiaryNumberTip2")}
                <br />
                {text("innerToCustomTransaction.beneficiaryNumberTip3")}
              </span>
            }
          >
            <div className={classes.icon}>?</div>
          </Tooltip>
        </Label>

        <Input
          className={classes.ibanInput}
          errorClassName={classes.error}
          style={{ marginBottom: 20 }}
          value={gettingAccount}
          changeHandler={x => setGettingAccount(x)}
          validator={defIbanValidator}
          preChange={defIbanPreChange}
          errorMessage={fieldErrorMessage("gettingAccount", error)}
          isDisabled={isFetching || isPrefaced}
          onPaste={defIbanPast(setGettingAccount)}
        />

        <p>{bankName}</p>

        <Label>{text("innerToCustomTransaction.recipientName")}</Label>
        <Input
          errorClassName={classes.error}
          style={{ marginBottom: 20 }}
          value={name}
          changeHandler={value => setName(value)}
          placeholder={text("innerToCustomTransaction.receiverPlaceholder")}
          maxLength={38}
          errorMessage={fieldErrorMessage("name", error)}
          isDisabled={isFetching || isPrefaced}
        />

        <Label>
          {text("innerToCustomTransaction.taxNumber")}
          <>&nbsp;</>
          <Tooltip
            color="black"
            background="lightgrey"
            content={
              <span>
                {" "}
                {text("innerToCustomTransaction.taxNumberTip")}
                <br />
                {text("innerToCustomTransaction.taxNumberTip2")}
                <br />
                {text("innerToCustomTransaction.taxNumberTip3")}
              </span>
            }
          >
            <div className={classes.icon}>?</div>
          </Tooltip>
        </Label>
        <Input
          errorClassName={classes.error}
          style={{ marginBottom: 20 }}
          value={taxNumber}
          changeHandler={value => setTaxNumber(value)}
          validator={value => matches(value, /^([А-ЯҐЄІЇ]{0,2}\d{0,6}|\d{0,10})?$/)}
          errorMessage={fieldErrorMessage("taxNumber", error)}
          isDisabled={isFetching || isPrefaced}
        />

        {isBudget && (
          <>
            <Label>
              {text("innerToCustomTransaction.actualPayerName")}
            </Label>
            <Input
                errorClassName={classes.error}
                style={{ marginBottom: 20 }}
                value={actualPayerName}
                changeHandler={value => setActualPayerName(value)}
                errorMessage={fieldErrorMessage("actualPayerName", error)}
                isDisabled={isFetching || isPrefaced}
                placeholder={text("innerToCustomTransaction.actualPayerPlaceholder")}
                maxLength={100}
                validator={value => matches(value, /^[A-ZА-ЯҐЄІЇ.-][A-ZА-ЯҐЄІЇ. -]*$/i)}
            />

            <Label>
              {text("innerToCustomTransaction.actualPayerTaxIdOrPassport")}
            </Label>
            <Input
                errorClassName={classes.error}
                style={{ marginBottom: 20 }}
                value={actualPayerTaxIdOrPassport}
                changeHandler={value => setActualPayerTaxIdOrPassport(value)}
                validator={value => matches(value, /^([А-ЯҐЄІЇ]{0,2}\d{0,6}|\d{0,10})?$/)}
                errorMessage={fieldErrorMessage("actualPayerTaxIdOrPassport", error)}
                isDisabled={isFetching || isPrefaced}
                placeholder={text("innerToCustomTransaction.actualPayerPlaceholder")}
            />

            <Label>
              {text("innerToCustomTransaction.paymentTypeCode")}
            </Label>
            <Select
                style={{ marginBottom: 20 }}
                options={paymentTypes}
                value={paymentTypeCode}
                changeHandler={(value) => setPaymentTypeCode(value)}
                errorMessage={fieldErrorMessage("paymentTypeCode", error)}
                // errorMessage="{fieldErrorMessage(paymentTypeCode, error)}"
                isDisabled={isFetching || isPrefaced}
            />

            <Label>
              {text("innerToCustomTransaction.additionalPaymentInfo")}
            </Label>
            <Input
                errorClassName={classes.error}
                style={{ marginBottom: 20 }}
                value={additionalPaymentInfo}
                changeHandler={value => setAdditionalPaymentInfo(value)}
                errorMessage={fieldErrorMessage("additionalPaymentInfo", error)}
                isDisabled={isFetching || isPrefaced}
                maxLength={140}
                validator={(value) => matches(value, /^[^ ].*$/)}
            />
          </>
        )}

        <Label>{text("innerToCustomTransaction.paymentPurpose")}</Label>
        <Textarea
          errorClassName={classes.error}
          style={{ marginBottom: 20 }}
          value={purpose}
          changeHandler={value => setPurpose((value))}
          onBlur={value => setPurpose((value || '').trim())}
          // warnHolder={text("innerToCustomTransaction.paymentPurposeWarnHolder")}
          placeholder={text("innerToCustomTransaction.paymentPurposeWarnHolder")}
          maxLength={160}
          errorMessage={fieldErrorMessage("purpose", error)}
          isDisabled={isFetching || isPrefaced || isBudget}
        />

        <Label>{text("innerToCustomTransaction.transferAmount")}</Label>
        <div className={classes.row} style={{ marginBottom: 30 }}>
          <div>
            <Input
              style={{ width: 250 }}
              name="amount"
              value={amount}
              changeHandler={value => setAmount(value)}
              units="UAH"
              commaIsReplacedWithDot
              validator={value =>
                isCurrency(value, {
                  digits_after_decimal: [0, 1, 2]
                }) &&
                (isCurrency(value, { digits_after_decimal: [0] })
                  ? isLength(value, { min: 0, max: 14 })
                  : isCurrency(value, { digits_after_decimal: [1] })
                  ? isLength(value, { min: 0, max: 16 })
                  : isLength(value, { min: 0, max: 17 }))
              }
              errorMessage={fieldErrorMessage("amount", error)}
              isDisabled={isFetching || isPrefaced}
            />
          </div>
          {commission && (
            <div className={classes.commisionText}>
              {text("innerToCustomTransaction.commission")}: {commission}
            </div>
          )}
        </div>
        <div className={classes.conditionText}>
          {text("innerToCustomTransaction.pressTransfer")}{" "}
          <a className={classes.link} href="https://ap-bank.com/documents/download/1084" target="_blank" rel="noopener noreferrer">
            {text("outerToInnerTransaction.conditions")}
          </a>
        </div>
        {globalErrorMessage && <Error className={classes.globalError} message={globalErrorMessage} />}
        <div className={classes.row}>
          <Button
            style={{
              width: 200,
              height: 48,
              color: "#241F5A",
              textDecoration: "underline",
              fontWeight: "bold"
            }}
            clickHandler={!isPrefaced ? history.goBack : cancel}
          >
            {text(!isPrefaced ? "buttons.back" : "buttons.cancel")}
          </Button>
          <Button
            style={{
              width: 200,
              height: 48,
              color: "white",
              backgroundColor: "#241F5A",
              borderColor: "#241F5A",
              fontWeight: "bold"
            }}
            clickHandler={!isPrefaced ? initialSubmit : submit}
            isDisabled={isFetching}
          >
            {text(!isPrefaced ? "buttons.continue" : "innerToCustomTransaction.buttonConfirm")} &rarr;
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ contracts }) => ({ cards: contracts.cards, accounts: contracts.accounts });
const mapDispatchToProps = dispatch => ({
  readContract: (providerId, contractId) => dispatch(actions.readContract(providerId, contractId))
});

export default connect(mapStateToProps, mapDispatchToProps)(InnerToCustomTransaction);
