import React, { useContext, useState, useMemo } from "react";
import classes from "./RegularPaymentTile.module.scss";
import PropTypes from "prop-types";
import { languageContext } from "../../../context/index";
import cn from "../../../helpers/cn";
import all from "../../../helpers/all";
import { Icon, Button } from "../../index";
import localeAmount from "../../../helpers/localeAmount";

const RegularPaymentTile = ({
  className,
  style,
  title,
  amount,
  currency,
  status,
  isValid,
  clickSettingsHandler,
  clickGoToHistoryHandler,
  clickDeleteHandler,
  clickDetailedPaymentHandler,
  clickActivatePaymentHandler,
  clickDeactivatePaymentHandler
}) => {
  const { text } = useContext(languageContext);

  const [isOpen, setIsOpen] = useState(false);

  const options = useMemo(
    () => [
      {
        iconName: "settingIcon",
        label: text("regularPayments.edit"),
        clickHandler: clickSettingsHandler
      },
      {
        iconName: "timeIcon",
        label: text("regularPayments.history"),
        clickHandler: clickGoToHistoryHandler
      },
      {
        iconName: "deleteNew",
        // iconName: "deleteOption",
        label: text("templateTile.options.delete"),
        clickHandler: clickDeleteHandler
      }
    ],
    [text, clickSettingsHandler, clickGoToHistoryHandler, clickDeleteHandler]
  );

  return (
    <div className={cn(classes.templateTile, className)} style={style}>
      <div className={classes.top}>
        <div className={classes.iconWrapper}>
          {status === "ACTIVE" ? (
            <Icon className={classes.regularPaymentIcon} name="regularPaymentActive" />
          ) : (
            <Icon className={classes.regularPaymentIcon} name="regularPayment" />
          )}
        </div>
        <div>
          <div className={classes.title}>{title}</div>
          {/* <div className={classes.description} title={description}>{description}</div> */}
        </div>
        <div className={classes.openIconWrapper} onClick={() => setIsOpen(!isOpen)}>
          <Icon style={{ ...(isOpen ? { transform: "rotate(180deg)" } : {}) }} name="selectArrow" />
        </div>
      </div>
      {!isOpen ? (
        <div className={classes.bottom}>
          <div className={classes.amount}>
            {localeAmount(amount)} {!!amount && <span className={classes.currency}>{currency}</span>}
          </div>
          <Button className={classes.button} clickHandler={clickDetailedPaymentHandler}>
            {text("regularPayments.detailed")}
          </Button>
        </div>
      ) : (
        <div className={classes.options}>
          {options.map((option, i) => (
            <div id="option" className={classes.option} onClick={() => all(option.clickHandler, () => setIsOpen(false))} key={i}>
              {option.iconName && <Icon name={option.iconName} style={{ width: "25px", height: "25px" }} />}
              {option.label && <div className={classes.label}>{option.label}</div>}
            </div>
          ))}
          {status === "INACTIVE" && (
            <div className={classes.option} onClick={() => all(isValid ? clickActivatePaymentHandler : clickSettingsHandler, () => setIsOpen(false))}>
              {<Icon name="activeNew" style={{ width: "25px", height: "25px" }} />}
              {<div className={classes.label}>{text("regularPayments.active")}</div>}
            </div>
          )}
          {status === "ACTIVE" && (
            <div className={classes.option} onClick={() => all(clickDeactivatePaymentHandler, () => setIsOpen(false))}>
              {<Icon name="activeNew" style={{ width: "25px", height: "25px" }} />}
              {<div className={classes.label}>{text("regularPayments.deactive")}</div>}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

RegularPaymentTile.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  amount: PropTypes.string,
  currency: PropTypes.string,
  buttonText: PropTypes.string,
  clickSettingsHandler: PropTypes.func,
  clickGoToHistoryHandler: PropTypes.func,
  clickDeleteHandler: PropTypes.func,
  clickDetailedPaymentHandler: PropTypes.func,
  clickActivatePaymentHandler: PropTypes.func,
  clickDeactivatePaymentHandler: PropTypes.func
};

RegularPaymentTile.defaultProps = {
  className: "",
  style: {},
  name: "",
  amount: "",
  currency: "",
  buttonText: "Сплатити",
  clickSettingsHandler: () => {},
  clickGoToHistoryHandler: () => {},
  clickDeleteHandler: () => {},
  clickDetailedPaymentHandler: () => {},
  clickActivatePaymentHandler: () => {},
  clickDeactivatePaymentHandler: () => {}
};

export default RegularPaymentTile;
