import React from "react";
import classes from "./QuestionModal.module.scss";
import PropTypes from "prop-types";
import { Title, Button } from "../../index";

const QuestionModal = ({ titleText, text, buttonText, cancelText, clickCompleteHandler, clickCancelHandler }) => {
  return (
    <div className={classes.operationResult}>
      {titleText && <Title className={classes.title} text={titleText} />}
      {text && <div className={classes.text}>{text}</div>}
      <div className={classes.buttons}>
        <Button className={classes.buttonCancel} clickHandler={clickCancelHandler}>
          {cancelText}
        </Button>
        <Button className={classes.button} clickHandler={clickCompleteHandler}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

QuestionModal.propTypes = {
  titleText: PropTypes.string,
  text: PropTypes.string,
  buttonText: PropTypes.string,
  cancelText: PropTypes.string,
  clickCompleteHandler: PropTypes.func,
  clickCancelHandler: PropTypes.func
};

QuestionModal.defaultProps = {
  titleText: "",
  text: "",
  buttonText: "",
  cancelText: "",
  clickCompleteHandler: null,
  clickCancelHandler: null
};

export default QuestionModal;
