import React from "react";
import classes from "./Checkbox.module.scss";
import PropTypes from "prop-types";
import cn from "../../../helpers/cn";

const Checkbox = ({ className, style, type, value, changeHandler, isDisabled }) => {
  const handleClick = !isDisabled ? () => changeHandler(!value) : null;

  return (
    <>
      {type === 1 && (
        <div className={cn(classes.checkbox, isDisabled ? classes.disabled : "", className)} style={style} onClick={handleClick}>
          {value && <div className={classes.checkmark} />}
        </div>
      )}
      {type === 2 && (
        <div
          className={cn(classes.slider, isDisabled ? classes.disabled : "", !!value ? classes.sliderChecked : classes.sliderUnchecked, className)}
          style={style}
          onClick={handleClick}
        >
          <div className={cn(classes.checkmark, !!value ? classes.checkmarkChecked : classes.checkmarkUnchecked)} />
        </div>
      )}
    </>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.number,
  value: PropTypes.bool,
  changeHandler: PropTypes.func,
  isDisabled: PropTypes.bool
};

Checkbox.defaultProps = {
  className: "",
  style: {},
  type: 1,
  value: false,
  changeHandler: () => {},
  isDisabled: false
};

export default Checkbox;
