import React from "react";
import classes from "./Error.module.scss";
import PropTypes from "prop-types";
import cn from "../../../helpers/cn";

const Error = ({ className, style, message }) => {
  return (
    <div className={cn(classes.wrapper, className)} style={style}>
      <div className={classes.icon}>!</div>
      <div className={classes.text}>{message}</div>
    </div>
  );
};

Error.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  message: PropTypes.string
};

Error.defaultProps = {
  className: "",
  style: {},
  message: ""
};

export default Error;
