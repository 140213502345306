export const asc = require("../../assets/icons/asc.svg").default;
export const dsc = require("../../assets/icons/dsc.svg").default;
export const uns = require("../../assets/icons/uns.svg").default;
export const sort = require("../../assets/icons/sort.svg").default;
export const eyeOpened = require("../../assets/icons/eye-opened.svg").default;
export const eyeClosed = require("../../assets/icons/eye-closed.svg").default;
export const phone = require("../../assets/icons/phone.svg").default;
export const pointer = require("../../assets/icons/pointer.svg").default;
export const exchange = require("../../assets/icons/exchange.svg").default;
export const arrow = require("../../assets/icons/arrow.png").default;
export const cards = require("../../assets/icons/cards-link.svg").default;
export const cardsActive = require("../../assets/icons/cards-links-active.svg").default;
export const accounts = require("../../assets/icons/accounts-link.svg").default;
export const accountsActive = require("../../assets/icons/accounts-link-active.svg").default;
export const credits = require("../../assets/icons/credits-icon.svg").default;
export const creditsActive = require("../../assets/icons/credits-icon-active.svg").default;
export const deposits = require("../../assets/icons/deposits-link.svg").default;
export const depositsActive = require("../../assets/icons/deposits-link-active.svg").default;
export const logout = require("../../assets/icons/logout.png").default;
export const profile = require("../../assets/icons/profile-link.svg").default;
export const profileActive = require("../../assets/icons/profile-link-active.svg").default;
export const transactions = require("../../assets/icons/transactions-link.svg").default;
export const transactionsActive = require("../../assets/icons/transactions-link-active.svg").default;
export const payments = require("../../assets/icons/payments-link.svg").default;
export const paymentsActive = require("../../assets/icons/payments-link-active.svg").default;
export const templates = require("../../assets/icons/templates-link.svg").default;
export const templatesActive = require("../../assets/icons/templates-link-active.svg").default;
export const history = require("../../assets/icons/history-link.svg").default;
export const historyActive = require("../../assets/icons/history-link-active.svg").default;
export const settings = require("../../assets/icons/settings-link.svg").default;
export const settingsActive = require("../../assets/icons/settings-link-active.svg").default;
export const exit = require("../../assets/icons/exit-link.svg").default;
export const success = require("../../assets/icons/success.svg").default;
export const failure = require("../../assets/icons/failure.svg").default;
export const close = require("../../assets/icons/close.svg").default;
export const facebook = require("../../assets/icons/facebook.png").default;
export const youtube = require("../../assets/icons/youtube.png").default;
export const linkedin = require("../../assets/icons/linkedin.png").default;
export const logoColored = require("../../assets/icons/logo-colored.png").default;
export const logoGrayscale = require("../../assets/icons/logo-greyscale.png").default;
export const exchangeCurrency = require("../../assets/icons/exchange.png").default;
export const refresh = require("../../assets/icons/refresh.svg").default;
export const selectArrow = require("../../assets/icons/select-arrow.svg").default;
export const markerPurple = require("../../assets/icons/marker-purple.svg").default;
export const markerGreen = require("../../assets/icons/marker-green.svg").default;
export const transactionInnerToInner = require("../../assets/icons/transaction-inner-to-inner.svg").default;
export const transactionInnerToOuter = require("../../assets/icons/transaction-inner-to-outer.svg").default;
export const transactionOuterToInner = require("../../assets/icons/transaction-outer-to-inner.svg").default;
export const transactionOuterToOuter = require("../../assets/icons/transaction-outer-to-outer.svg").default;
export const transactionInnerToCustom = require("../../assets/icons/transaction-inner-to-custom.png").default;
export const transactionPayments = require("../../assets/icons/transaction-payments.svg").default;
export const transactionExchange = require("../../assets/icons/ic_exchange.svg").default;
export const templateSettings = require("../../assets/icons/template-settings.svg").default;
export const magnifyingGlass = require("../../assets/icons/magnifying-glass.png").default;
// payments
export const paymentsInternet = require("../../assets/icons/payments-internet.svg").default;
export const paymentsCredits = require("../../assets/icons/payments-credits.svg").default;
export const paymentsTelephony = require("../../assets/icons/payments-telephony.svg").default;

export const paymentsTelecommunications = require("../../assets/icons/payments-telecommunications.svg").default;
export const paymentsUtilities = require("../../assets/icons/payments-utilities.svg").default;

export const paymentManual = require("../../assets/icons/payments-manual.png").default;
export const paymentsMobile = require("../../assets/icons/payments-mobile.svg").default;
// export const paymentsGames = require("../../assets/icons/payments-games.svg").default;
export const paymentsGoods = require("../../assets/icons/payments-goods.svg").default;

export const paymentsInsurance = require("../../assets/icons/payments-insurance.svg").default;
export const paymentsPeriodicals = require("../../assets/icons/payments-periodicals.svg").default;
export const paymentsTransport = require("../../assets/icons/payments-transport.svg").default;

export const paymentsSecurity = require("../../assets/icons/payments-security.svg").default;
export const paymentsTickets = require("../../assets/icons/payments-tickets.svg").default;
export const paymentsCharity = require("../../assets/icons/payments-сharity.svg").default;
export const paymentsBudget2 = require("../../assets/icons/payments-budget2.svg").default;
export const paymentsBudget = require("../../assets/icons/payments-budget.svg").default;
export const defaultBillerIcon = require("../../assets/icons/default.png").default;

export const paymentsFinancialServices = require("../../assets/icons/payments-financial-services.svg").default;
export const paymentsEducation = require("../../assets/icons/payments-education.svg").default;
export const paymentsDistribution = require("../../assets/icons/payments-distribution.svg").default;

export const income = require("../../assets/icons/income.png").default;
export const outcome = require("../../assets/icons/outcome.png").default;
export const authorization = require("../../assets/icons/authorization.png").default;
export const service = require("../../assets/icons/service.png").default;
export const plus = require("../../assets/icons/plus.png").default;
export const suggestionsEnvelope = require("../../assets/icons/suggestions-envelope.svg").default;
export const suggestionsPhone = require("../../assets/icons/suggestions-phone.svg").default;
export const suggestionsNewCard = require("../../assets/icons/suggestions-new-card.svg").default;
export const suggestionsDeposit = require("../../assets/icons/suggestions-deposit.svg").default;
export const suggestionsCredit = require("../../assets/icons/suggestions-credit.svg").default;
export const copy = require("../../assets/icons/copy.svg").default;
export const masterCard = require("../../assets/icons/master-card.png").default;
export const visa = require("../../assets/icons/visa.jpg").default;
export const accountTile = require("../../assets/icons/account-tile.svg").default;
export const depositTile = require("../../assets/icons/deposit-tile.svg").default;
export const creditTile = require("../../assets/icons/credit-tile.svg").default;
export const historyOption = require("../../assets/icons/history-option.svg").default;
export const fillAccount = require("../../assets/icons/fill-account-option.svg").default;
export const withdrawAccount = require("../../assets/icons/withdraw-account-option.svg").default;
export const payOption = require("../../assets/icons/pay-option.svg").default;
export const carouselArrow = require("../../assets/icons/carousel-arrow.png").default;
export const fillCard = require("../../assets/icons/fill-card.svg").default;
export const transferCard = require("../../assets/icons/transfer-card.svg").default;
export const transactionCard = require("../../assets/icons/transaction-card.svg").default;
export const limitsCard = require("../../assets/icons/limits-card.svg").default;
export const calendar = require("../../assets/icons/calendar.png").default;
export const clean = require("../../assets/icons/clean.svg").default;
export const locationDescription = require("../../assets/icons/location-description.svg").default;
export const cardBgGreen = require("../../assets/icons/card-bg-green.jpg").default;
export const cardBgYellow = require("../../assets/icons/card-bg-yellow.jpg").default;
export const padlock = require("../../assets/icons/padlock.png").default;
export const padlockUnlocked = require("../../assets/icons/padlock-unlock.png").default;
export const transferOption = require("../../assets/icons/transfer-option.svg").default;
export const changeLimitOption = require("../../assets/icons/change-limit-option.svg").default;

export const avatarka = require("../../assets/icons/avatarka.svg").default;
export const pencel = require("../../assets/icons/pencel.svg").default;
export const exportArrow = require("../../assets/icons/export-arrow.svg").default;
export const iconPDF = require("../../assets/icons/iconPDF.svg").default;

export const doneIcon = require("../../assets/icons/doneIcon.svg").default;
export const rejectIcon = require("../../assets/icons/rejectedIcon.svg").default;
export const checkboxEnabled = require("../../assets/icons/checkbox-enabled.svg").default;
export const checkboxDisabled = require("../../assets/icons/checkbox-disabled.svg").default;
//history
export const successSmallIcon = require("../../assets/icons/success-small-icon.svg").default;
export const warningSmallIcon = require("../../assets/icons/warning-small-icon.svg").default;
// financial-assistant
export const finance = require("../../assets/icons/finance.svg").default;
export const financeActive = require("../../assets/icons/finance-active.svg").default;
export const calendarIcon = require("../../assets/icons/calendarIcon.png").default;
export const arrowBack = require("../../assets/icons/arrow-back.svg").default;
export const pointsIcon = require("../../assets/icons/pointsIcon.png").default;
export const pickerLeftArrow = require("../../assets/icons/date-range-left-arrow.png").default;
export const pickerRightArrow = require("../../assets/icons/date-range-right-arrow.png").default;
// regular payment
export const regularPaymentSuccess = require("../../assets/icons/regular-payment-success.svg").default;
export const regularPayment = require("../../assets/icons/regular-payment.svg").default;
export const regularPaymentActive = require("../../assets/icons/regular-payment-active.svg").default;
export const settingIcon = require("../../assets/icons/setting-icon.svg").default;
export const timeIcon = require("../../assets/icons/time-icon.svg").default;
export const activeNew = require("../../assets/icons/active-new.svg").default;
export const deleteNew = require("../../assets/icons/delete-new.svg").default;

export const news = require("../../assets/icons/news-link-inactive.svg").default;
export const newsActive = require("../../assets/icons/news-link-active.svg").default;

export const cardCredit = require("../../assets/icons/card-credit.svg").default;
export const changePin = require("../../assets/icons/change-pin.png").default;
