import React, { useContext, useState, useRef, useEffect } from "react";
import classes from "./Credits.module.scss";
import { languageContext, modalContext } from "../../../context/index";
import { withRouter, Route } from "react-router-dom";
import { connect } from "react-redux";
import { Carousel, CreditTile, OperationResult } from "../../../components";
import Credit from "./Credit/Credit.jsx";
import { useTitle } from "../../../hooks/index";
import * as actions from "../../../store/actions/index";
import ChangeTitle from "../../../components/modals/ChangeTitle/ChangeTitle";
import * as API from "../../../api";
import serverErrorMessages from "../../../helpers/serverErrorMessages";
import { genContractKey } from "../../../helpers/contract";

const Credits = ({ history, location, credits, readContracts }) => {
  const { language, text } = useContext(languageContext);
  const { setModal } = useContext(modalContext);

  const creditId = location.pathname.split("/")[3];

  const initialCreditIndex = useRef(0);

  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    readContracts("credit");
  }, [readContracts]);

  useEffect(() => {
    if (creditId) {
      const creditIndex = credits.findIndex(credit => credit.id === creditId);
      if (creditIndex > 0) {
        initialCreditIndex.current = creditIndex;
      } else {
        history.push(`/home/credits/${credits[0].id}`);
      }
      setIsShown(true);
    } else {
      setIsShown(false);
      history.push(`/home/credits/${credits[0].id}`);
      initialCreditIndex.current = 0;
      setTimeout(() => {
        setIsShown(true);
      }, 10);
    }
  }, [credits, creditId, history]);

  useTitle(text("credits.title"));

  const updateCreditTitle = i => current => async () => {
    let title = current;
    await new Promise(resolve => {
      setModal(
        <ChangeTitle
          value={title}
          clickContinueHandler={value => {
            title = value;
            setModal(null);
            resolve();
          }}
        />
      );
    });
    try {
      const credit = credits[i];
      await API.setProfileAttributes([
        {
          key: "own_name_" + genContractKey(credit.providerId, credit.id),
          value: title
        }
      ]);
      readContracts(credit.providerId);
      setModal(
        <OperationResult
          titleText={text("credits.modal.titleText.success")}
          buttonText={text("buttons.complete")}
          clickCompleteHandler={() => setModal(null)}
        />
      );
    } catch (err) {
      setModal(
        <OperationResult
          result="failure"
          text={serverErrorMessages(err, language)}
          buttonText={text("buttons.close")}
          clickCompleteHandler={() => setModal(null)}
        />
      );
    }
  };

  return (
    <div className={classes.credits}>
      {isShown && (
        <>
          <Carousel
            className={classes.carousel}
            slideHeight={210}
            mode="single"
            initialSlide={initialCreditIndex.current}
            changeHandler={i => history.push(`/home/credits/${credits[i].id}`)}
          >
            {credits.map((credit, i) => (
              <CreditTile
                className={classes.creditTile}
                height={208}
                name={credit.productTitle}
                totalAmount={credit.dealAmount}
                currency={credit.mainAccountCurrency}
                amountToPay={credit.totalDept}
                date={credit.endDate}
                hasOpenButton={false}
                key={i}
              />
            ))}
          </Carousel>
          {credits.map((credit, i) => (
            <Route
              exact
              path={`/home/credits/${credit.id}`}
              render={() => (
                <Credit
                  credit={credit}
                  clickOuterToInnerTransactionHandler={() =>
                    history.push(`/home/transactions/outer-to-inner?receiver=${genContractKey(credit.providerId, credit.id)}`)
                  }
                  clickTitleHandler={updateCreditTitle(i)}
                />
              )}
              key={i}
            />
          ))}
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ contracts }) => ({ credits: contracts.credits });

const mapDispatchToProps = dispatch => ({
  readContracts: contractType => dispatch(actions.readContracts(contractType))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Credits));
