import React from "react";
import Label from "../../../../components/inputs/Label/Label.jsx";
import Input from "../../../../components/inputs/Input/Input.jsx";
import Button from "../../../../components/Button/Button.jsx";
import classes from "./styles.module.scss";
import { fieldErrorMessage } from "../../../../validation";
import { Error } from "../../../../components";

const Init = ({onSubmit, error, parametersConf, billerParams, setBillerParams}) => {
  const globalErrorMessage = fieldErrorMessage("global", error);

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        {parametersConf.map(parameter => (
            <div key={parameter.name}>
                <Label>{parameter.title}</Label>
                <Input
                  style={{ marginBottom: 20, width: 498 }}
                  value={billerParams[parameter.name]}
                  name={parameter.name}
                  changeHandler={value => setBillerParams(parameter.name, value)}
                />
            </div>
        ))}

        {globalErrorMessage && (
          <Error
            className={classes.globalError}
            message={globalErrorMessage}
          />
        )}

        <div className={classes.next}>
            <Button
                style={{
                    width: 200,
                    height: 48,
                    color: "white",
                    backgroundColor: "#241F5A",
                    borderColor: "#241F5A",
                    fontWeight: "bold"
                }}
                clickHandler={onSubmit}
            >
              Продовжити &rarr;
            </Button>
        </div>
      </div>
    </div>
  );
};

export default Init;
