import React from "react";
import classes from "./Profile.module.scss";
import { connect } from "react-redux";
import { ToggleWrapper } from "../../../hocs/index";
import CardLimit from "../Cards/CardLimit/CardLimit.jsx";
import {
  Carousel,
  TemplateTile,
  TemplateSettings,
  CardTile,
  AccountTile,
  CreditTile,
  DepositTile,
  SuggestionTile,
  OperationResult
} from "../../../components/index";
import { languageContext, modalContext, templatesContext } from "../../../context/index";
import { useTitle } from "../../../hooks/index";
import localeAmount from "../../../helpers/localeAmount";
import cardNumber from "../../../helpers/cardNumber";
import serverErrorMessages from "../../../helpers/serverErrorMessages";
import * as actions from "../../../store/actions/index";
import { notify } from "react-notify-toast";
import { useUpdateCardStatus } from "../../../hooks/useUpdateCardStatus";
import { genContractKey } from "../../../helpers/contract";
import { getAttribute } from "../../../store/getters/contracts";
import cn from "../../../helpers/cn";
import {useChangePin} from "../../../hooks/useChangePin";

const Profile = ({ history, cards, accounts, credits, deposits, setContracts }) => {
  const { language, text } = React.useContext(languageContext);
  const { templates, updateTemplate, deleteTemplate } = React.useContext(templatesContext);
  const { setModal } = React.useContext(modalContext);

  const updateCardStatus = useUpdateCardStatus(cards, setContracts);
  const changePin = useChangePin();

  const contractOptions = React.useMemo(() => {
    const cardOptions = cards
      .filter(account => account.mainAccountCurrency === "UAH")
      .map(account =>
        account.cardsList.map(card => ({
          value: account.id,
          label: `${cardNumber(card.cardNumberMask)} "${account.productTitle}" (${localeAmount(account.balance)} ${account.mainAccountCurrency})`,
          type: account.providerId
        }))
      );
    const accountOptions = accounts
      .filter(account => account.mainAccountCurrency === "UAH")
      .map(account => ({
        value: account.id,
        label: `${account.mainAccountNumber} "${account.productTitle}" (${localeAmount(account.balance)} ${account.mainAccountCurrency})`,
        type: account.providerId
      }));
    return [...cardOptions, ...accountOptions];
  }, [cards, accounts]);

  const renewTemplate = React.useCallback(
    async (id, payload) => {
      try {
        setModal(null);
        await updateTemplate(id, payload);
        setModal(
          <OperationResult
            titleText={`${text("templates.modals.updateSuccess.titleText")}!`}
            text={text("templates.modals.updateSuccess.text")}
            buttonText={text("buttons.complete")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      } catch (err) {
        setModal(
          <OperationResult
            result="failure"
            text={serverErrorMessages(err, language)}
            buttonText={text("buttons.close")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      }
    },
    [setModal, text, updateTemplate, language]
  );

  const removeTemplate = React.useCallback(
    async id => {
      try {
        setModal(null);
        await deleteTemplate(id);
        setModal(
          <OperationResult
            titleText={`${text("templates.modals.deleteSuccess.titleText")}!`}
            text={text("templates.modals.deleteSuccess.text")}
            buttonText={text("buttons.complete")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      } catch (err) {
        setModal(
          <OperationResult
            result="failure"
            text={serverErrorMessages(err, language)}
            buttonText={text("buttons.close")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      }
    },
    [setModal, text, deleteTemplate, language]
  );

  useTitle(text("profile.title"));

  return (
    <div className={classes.profile}>
      {!!templates.length && (
        <>
          <ToggleWrapper className={classes.toggleWrapper} titleText={text("profile.templates")}>
            <div className={classes.carouselWrapper}>
              <Carousel className={classes.carousel}>
                {templates.map((x, i) => (
                  <TemplateTile
                    iconName={x.iconName}
                    iconColor={x.iconColor}
                    name={x.name}
                    description={x.description}
                    amount={x.amount}
                    currency={x.currency}
                    buttonText={text("buttons.pay")}
                    clickPayHandler={() => history.push(x.url)}
                    clickSettingsHandler={() =>
                      setModal(
                        <TemplateSettings
                          contractOptions={contractOptions}
                          initialType={x.type}
                          initialName={x.name}
                          initialGivingContract={x.givingContract}
                          initialGettingContract={x.gettingContract}
                          initialGettingAccount={x.gettingAccount}
                          initialReceiverName={x.receiverName}
                          initialReceiverCode={x.receiverCode}
                          initialTaxNumber={x.taxNumber}
                          initialPurpose={x.purpose}
                          initialAmount={x.amount}
                          initialDescription={x.description}
                          initialIconName={x.iconName}
                          initialIconColor={x.iconColor}
                          clickCancelHandler={() => setModal(null)}
                          clickSaveHandler={payload => renewTemplate(x.id, payload)}
                          clickDeleteHandler={() => removeTemplate(x.id)}
                        />
                      )
                    }
                    clickDeleteHandler={() => removeTemplate(x.id)}
                    key={i}
                  />
                ))}
              </Carousel>
            </div>
          </ToggleWrapper>
          <div className={classes.divider} />
        </>
      )}
      {!!cards.length && (
        <ToggleWrapper className={classes.toggleWrapper} titleText={text("profile.card")}>
          <div className={classes.carouselWrapper}>
            <Carousel className={cn(classes.carousel, classes.up)} slideHeight={180}>
              {cards.map(account =>
                account.cardsList.map(card => (
                  <CardTile
                      providerId={account.providerId}
                      contractId={account.id}
                      cardId={card.id}
                    name={account.productTitle}
                    amount={account.balance}
                    currency={account.mainAccountCurrency}
                    number={card.cardNumberMask}
                    fullPan={getAttribute(card.attributes, 'cardPan')}
                    owner={card.ownerName}
                    month={card.expiryMonth}
                    year={card.expiryYear}
                    paymentSystem={card.bankCardType}
                    status={card.status}
                    ownFunds={account.ownFunds}
                    creditLimit={[null, undefined].includes(account.creditLimitFormatted) ? "Не встановлений" : account.creditLimitFormatted}
                    showCreditInfo={account.isCreditLimitChangeRequestAllowed === "true"}
                    clickCopyHandler={() => {
                      navigator.clipboard.writeText(account.mainAccountNumber);
                      notify.show(text("card.details.iban.success"), "custom", 1000, {
                        background: "#7ABD43",
                        text: "#FFFFFF"
                      });
                    }}
                    clickHistoryHandler={() => history.push(`/home/cards/${card.id}`)}
                    clickFillHandler={() =>
                      history.push(`/home/transactions/outer-to-inner?receiver=${genContractKey(account.providerId, account.id, card.id)}`)
                    }
                    clickInnerTransferHandler={() =>
                      history.push(`/home/transactions/outer-to-inner?sender=${genContractKey(account.providerId, account.id, card.id)}`)
                    }
                    clickOuterTransferHandler={() =>
                      history.push(`/home/transactions/inner-to-outer?sender=${genContractKey(account.providerId, account.id, card.id)}`)
                    }
                    clickSetLimitHandler={() => setModal(<CardLimit card={card} account={account} />)}
                    clickBlockHandler={() => updateCardStatus(card.id)}
                    // clickCreditHandler={() => history.push(`/home/cards/${card.id}/limits`)}
                    clickChangePinHandler={() => {changePin(account.id, card.id)}}
                    hasOpenButton
                    key={card.id}
                  />
                ))
              )}
            </Carousel>
          </div>
        </ToggleWrapper>
      )}
      {!!accounts.length && (
        <ToggleWrapper className={classes.toggleWrapper} titleText={text("profile.accounts")}>
          <div className={classes.carouselWrapper}>
            <Carousel className={classes.carousel}>
              {accounts.map((account, i) => (
                <AccountTile
                  number={account.mainAccountNumber}
                  amount={account.balance}
                  currency={account.mainAccountCurrency}
                  clickHistoryHandler={() => history.push(`/home/accounts/${account.id}`)}
                  clickFillHandler={() => history.push(`/home/transactions/outer-to-inner?receiver=${genContractKey(account.providerId, account.id)}`)}
                  clickTransactionHandler={() => history.push(`/home/transactions/outer-to-inner?sender=${genContractKey(account.providerId, account.id)}`)}
                  clickPayHandler={() => history.push(`/home/transactions/inner-to-custom?sender=${genContractKey(account.providerId, account.id)}`)}
                  key={i}
                />
              ))}
            </Carousel>
          </div>
        </ToggleWrapper>
      )}
      {!!credits.length && (
        <ToggleWrapper className={classes.toggleWrapper} titleText={text("profile.credits")}>
          <div className={classes.carouselWrapper}>
            <Carousel className={classes.carousel}>
              {credits.map((credit, i) => (
                <CreditTile
                  name={credit.productTitle}
                  totalAmount={credit.dealAmount}
                  currency={credit.mainAccountCurrency}
                  amountToPay={credit.totalDept}
                  date={credit.endDate}
                  clickHistoryHandler={() => history.push(`/home/credits/${credit.id}`)}
                  clickPayHandler={() => history.push(`/home/transactions/outer-to-inner?receiver=${genContractKey(credit.providerId, credit.id)}`)}
                  key={i}
                />
              ))}
            </Carousel>
          </div>
        </ToggleWrapper>
      )}
      {!!deposits.length && (
        <ToggleWrapper className={classes.toggleWrapper} titleText={text("profile.deposits")}>
          <div className={classes.carouselWrapper}>
            <Carousel className={classes.carousel}>
              {deposits.map((deposit, i) => (
                <DepositTile
                  name={deposit.productTitle}
                  totalAmount={deposit.balance}
                  currency={deposit.mainAccountCurrency}
                  interestAmount={deposit.accruedInterest}
                  interestRate={deposit.currentInterestRate}
                  clickHistoryHandler={() => history.push(`/home/deposits/${deposit.id}`)}
                  clickFillHandler={
                    deposit.refillAllowed
                      ? () => history.push(`/home/transactions/outer-to-inner?receiver=${genContractKey(deposit.providerId, deposit.id)}`)
                      : null
                  }
                  clickWithdrawHandler={
                    deposit.earlyWithdrawlAllowed
                      ? () => history.push(`/home/transactions/outer-to-inner?sender=${genContractKey(deposit.providerId, deposit.id)}`)
                      : null
                  }
                  key={i}
                />
              ))}
            </Carousel>
          </div>
        </ToggleWrapper>
      )}
      <SuggestionTile
        style={{ marginBottom: 30 }}
        iconName="suggestionsEnvelope"
        title={text("suggestionTile.card.title")}
        description={text("suggestionTile.card.description")}
        buttonText={`${text("buttons.orderNewCard")}`}
        clickButtonHandler={() => history.push(`/home/cards/order`)}
      />
      <SuggestionTile
        style={{ marginButtom: 30 }}
        iconName="suggestionsPhone"
        title={text("suggestionTile.app.title")}
        description={text("suggestionTile.app.description")}
        buttonText={text("buttons.download")}
        clickButtonHandler={() => window.open("https://play.google.com/store/search?q=ap%20bank&c=apps&hl=uk", "_blank")}
      />
    </div>
  );
};

const mapStateToProps = ({ contracts }) => ({ cards: contracts.cards, accounts: contracts.accounts, credits: contracts.credits, deposits: contracts.deposits });

const mapDispatchToProps = dispatch => ({
  setContracts: (providerId, data) => dispatch(actions.setContracts(providerId, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
