import { useState, useEffect } from "react";

export const useScrollButton = () => {
  const [scrollButtonIsShown, setScrollButtonIsShown] = useState(false);

  useEffect(() => {
    const toggleScrollButton = () => setScrollButtonIsShown(document.documentElement.scrollTop > 25 ? true : false);
    window.addEventListener("scroll", toggleScrollButton);
    return () => {
      window.removeEventListener("scroll", toggleScrollButton);
    };
  }, []);

  return scrollButtonIsShown;
};
