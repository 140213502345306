import React, { useContext } from "react";
import * as API from "../api";
import { ConfirmationCode, OperationResult } from "../components";
import serverErrorMessages from "../helpers/serverErrorMessages";
import { languageContext, modalContext } from "../context";
import QuestionModal from "../components/modals/QuestionModal/QuestionModal";
import PinCodeModal from "../components/modals/PinCodeModal/PinCodeModal";

const preface = { change_card_settings_operation_type: 'change_pin' };
const execute = {
    ...preface,
    otpCredentials: undefined,
    pinCode: undefined,
};

export const useChangePin = () => {
  const { setModal } = useContext(modalContext);
  const { language, text } = useContext(languageContext);

  return React.useCallback( async (contractId, cardId) => {
    await new Promise((resolve) => {
      setModal(
          <QuestionModal
              titleText={text("cards.modal.pin.question")}
              buttonText="Ok"
              cancelText={text("buttons.cancel")}
              clickCancelHandler={() => setModal(null)}
              clickCompleteHandler={() => {
                setModal(null);
                resolve();
              }}
          />
      )
    });

    await new Promise((resolve) => {
      setModal(
          <PinCodeModal
              titleText={text("dailyOperations.card.changePin")}
              text={text("cards.modal.pin.action")}
              clickCompleteHandler={(pinCode) => {
                setModal(null);
                execute.pinCode = pinCode;
                resolve();
              }}
          />
      )
    });

    const { data } = await API.setCardSettingsPreface(contractId, cardId, preface);
    const { extAuthRequired } = data.operationConditions;

    if (extAuthRequired === true) {
      execute.otpCredentials = {
        otp: undefined,
        challenge: undefined
      };

      try {
        const { data } = await API.getAuthExtended();
        execute.otpCredentials.challenge = data;
      } catch (err) {
        setModal(
            <OperationResult
                result="failure"
                text={serverErrorMessages(err, language)}
                buttonText={text("buttons.close")}
                clickCompleteHandler={() => setModal(null)}
            />
        );
        return;
      }

      await new Promise(resolve => {
        setModal(
            <ConfirmationCode
                clickContinueHandler={code => {
                  execute.otpCredentials.otp = code;
                  setModal(null);
                  resolve();
                }}
            />
        );
      });
    }

    try {
      const response = await API.setCardSettingsExecute(contractId, cardId, execute);

      if (response?.data?.status === "SUCCESS") {
        setModal(
          <OperationResult
            titleText={text("cards.modal.pin.success")}
            buttonText={text("buttons.complete")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      } else {
        setModal(
          <OperationResult
            result="failure"
            text={`${text("cards.modal.pin.failure")}`}
            buttonText={text("buttons.close")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      }
    } catch (err) {
      setModal(
        <OperationResult
          result="failure"
          text={serverErrorMessages(err, language)}
          buttonText={text("buttons.close")}
          clickCompleteHandler={() => setModal(null)}
        />
      );
    }
  }, [language, setModal, text]);
};
