import React from 'react';
import { languageContext } from "../../../../context/index";
import DepositOrderNew from './DepositOrderNew/DepositOrderNew';
import DepositOrderConditions from "./DepositOrderConditions/DepositOrderConditions";

const DepositOrder = props => {
  const { text } = React.useContext(languageContext);
  const [product, setProduct] = React.useState(null);
  const payout = {
    'INSTANT': `${text('depositOrderConditions.tableLable.INSTANT')}`,
    'MONTH': `${text('depositOrderConditions.tableLable.MONTH')}`
  }
  return product
    ? <DepositOrderNew {...props} onCancel={setProduct} product={product} payout={payout} />
    : <DepositOrderConditions {...props} onSelect={setProduct} payout={payout} />
};

export default DepositOrder;
