import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { languageContext } from "../../../context/index";
import TransactionOptions from "./TransactionOptions/TransactionOptions.jsx";
import ExchangeTransaction from "./ExchangeTransaction/ExchangeTransaction";
import InnerToInnerTransaction from "./InnerToInnerTransaction/InnerToInnerTransaction.jsx";
import InnerToOuterTransaction from "./InnerToOuterTransaction/InnerToOuterTransaction.jsx";
import OuterToInnerTransaction from "./OuterToInnerTransaction/OuterToInnerTransaction.jsx";
import OuterToOuterTransaction from "./OuterToOuterTransaction/OuterToOuterTransaction.jsx";
import InnerToCustomTransaction from "./InnerToCustomTransaction/InnerToCustomTransaction.jsx";
import { useTitle } from "../../../hooks/index";

const Transactions = () => {
  const { language, text } = useContext(languageContext);

  useTitle(text("transactions.title"), [language]);

  return (
    <Switch>
      <Route exact path="/home/transactions" component={TransactionOptions} />
      <Route path="/home/transactions/inner-to-inner" component={InnerToInnerTransaction} />
      <Route path="/home/transactions/inner-to-outer" component={InnerToOuterTransaction} />
      <Route path="/home/transactions/outer-to-inner" component={OuterToInnerTransaction} />
      <Route path="/home/transactions/outer-to-outer" component={OuterToOuterTransaction} />
      <Route path="/home/transactions/inner-to-custom" component={InnerToCustomTransaction} />
      <Route path="/home/transactions/exchange" component={ExchangeTransaction} />
      <Redirect to="/home/transactions" />
    </Switch>
  );
};

export default Transactions;
