import React, { useContext, useState, useEffect } from "react";
import classes from "./Contacts.module.scss";
import * as API from "../../../api";
import { languageContext } from "../../../context/index";
import { Title, Select, Map, LocationDescription, Spinner, Error } from "../../../components/index";
import { useTitle } from "../../../hooks/index";
import serverErrorMessages from "../../../helpers/serverErrorMessages";
import markerGreen from "../../../assets/icons/marker-green.svg";
import markerPurple from "../../../assets/icons/marker-purple.svg";

const Contacts = () => {
  const { text, language } = useContext(languageContext);

  const [type, setType] = useState("OFFICE");
  const [locations, setLocations] = useState([]);
  const [isShown, setIsShown] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const types = [
    {
      value: "OFFICE",
      iconName: "markerGreen",
      label: text("contacts.select.options.departments"),
      icon: markerGreen
    },
    {
      value: "ATM",
      iconName: "markerPurple",
      label: text("contacts.select.options.atms"),
      icon: markerPurple
    }
  ];

  const filteredLocations = type ? locations.filter(location => location.type === type) : locations;

  const modifiedLocations = () => {
    const modifiedLocations = {};
    filteredLocations.forEach(location => {
      if (location.city in modifiedLocations) {
        modifiedLocations[location.city] = [...modifiedLocations[location.city], location];
      } else {
        modifiedLocations[location.city] = [location];
      }
    });
    return modifiedLocations;
  };

  useEffect(() => {
    (async () => {
      setIsShown(false);
      setErrorMessage(null);
      const params = {
        locale: language
      };
      try {
        const { data } = await API.getLocations(params);
        setLocations(data.locations);
      } catch (err) {
        setErrorMessage(serverErrorMessages(err, language));
      }
      setIsShown(true);
    })();
  }, [language, text]);

  useTitle(text("contacts.title"));

  return (
    <div className={classes.wrapper}>
      <Title text={text("contacts.title")} />
      <Select
        className={classes.select}
        options={types}
        value={type}
        changeHandler={value => setType(value)}
        placeholder={text("contacts.select.placeholder")}
      />
      <Map
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&language=${
          language === "ua" ? "uk" : language
        }&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: "100%" }} />}
        containerElement={<div style={{ height: "400px" }} />}
        mapElement={<div style={{ height: "100%" }} />}
        locations={filteredLocations}
        markerIcon={types.find(x => x.value === type).icon}
      />
      {isShown ? (
        Object.keys(modifiedLocations()).map((city, i) => (
          <LocationDescription className={classes.locationDescription} locations={modifiedLocations()[city]} key={i} />
        ))
      ) : (
        <Spinner className={classes.spinner} diameter={50} />
      )}
      {errorMessage && <Error className={classes.error} message={errorMessage} />}
    </div>
  );
};

export default Contacts;
