import axios from "../axios";

export const getPaymentsCategories = () => axios.get("/biller/categories/all");

export const getBillersByCategory = params => axios.get("/biller/all", {params});

export const getBillerBy = (billerId, params) => axios.get(`/biller/portmone/${billerId}`, {params});

export const postBillPayment = payload => axios.post("/biller/billPayment", payload);

export const postBillPaymentPreface = payload => axios.post("/operation/billPayment/preface", payload);

export const postBillPaymentExecute = payload => axios.post("operation/billPayment/execute", payload);

