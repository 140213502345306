import React, { useState, useContext, useMemo } from "react";
import classes from "./SimpleForm.module.scss";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Label from "../../../../../components/inputs/Label/Label.jsx";
import Select from "../../../../../components/inputs/Select/Select.jsx";
import Input from "../../../../../components/inputs/Input/Input.jsx";
import Divider from "../../../../../components/shapes/Divider/Divider.jsx";
import Triangle from "../../../../../components/shapes/Triangle/Triangle.jsx";
import Button from "../../../../../components/Button/Button.jsx";
import { languageContext, modalContext } from "../../../../../context/index";
import { uahCardOptions, uahAccountOptions } from "../../../../../store/getters/index";
import localeAmount from "../../../../../helpers/localeAmount";
import { ConfirmationCode, Error } from "../../../../../components/index";
import * as API from "../../../../../api/index";
import { validationError, fieldErrorMessage, billersFormSchema } from "../../../../../validation/index";
import { useError } from "../../../../../hooks/index";
import serverErrorMessages from "../../../../../helpers/serverErrorMessages";
import { OperationResult } from "../../../../../components/index";
import isCurrency from "validator/lib/isCurrency";
import isLength from "validator/lib/isLength";
import PaymentsContext from "../../PaymentsContext";
import { urlCategories } from "../../PaymentsUtils";

const initialValues = {
  parameter01: undefined,
  parameter02: undefined,
  parameter03: undefined,
  parameter04: undefined,
  parameter05: undefined
};

const SimpleForm = ({ cards, accounts }) => {
  const { push, goBack } = useHistory();
  const { biller } = React.useContext(PaymentsContext);
  const { language, text } = useContext(languageContext);
  const { setModal } = useContext(modalContext);
  const srcContractAll = useMemo(() => [...uahCardOptions(cards), ...uahAccountOptions(accounts, text)], [cards, accounts, text]);
  const [isFetching, setIsFetching] = useState(false);
  const [amount, setAmount] = useState("");
  const [commission, setCommission] = useState("");
  const [isPrefaced, setIsPrefaced] = useState(false);
  const [error, setError] = useError(null);
  const [billSections, setBillSections] = useState([]);
  const [billDetails, setBillDetails] = useState([]);
  const [billerParams, setBillerParams] = React.useState(initialValues);
  const [extendedAuthRequired, setExtendedAuthRequired] = useState(null);

  const globalErrorMessage = fieldErrorMessage("global", error);

  const [srcContract, _setSrcContract] = React.useState();
  const setSrcContract = React.useCallback(value => _setSrcContract(srcContractAll.find(contract => contract.value === value) || {}), [srcContractAll]);

  const submitPaymentPreface = async () => {
    setError(null);
    const error = validationError(billersFormSchema(language), {
      givingAccount: srcContract?.value,
      amount
    });
    if (error) {
      setError(error);
      return;
    }
    setIsFetching(true);
    try {
      const payload = {
        destination: {
          providerId: "portmone",
          effective_biller_id: biller.id,
          billerId: biller.id,
          billerAccount: billerParams?.parameter01,
          categoryId: biller.categoryId
        },
        ...billerParams,
        amount: Math.round(amount * 100),
        srcContractRef: {
          providerId: srcContract?.providerId,
          contractId: srcContract?.contractId
        },
        currency: "UAH",
        cardId: srcContract?.cardId
      };

      const { data } = await API.postBillPaymentPreface(payload);
      console.log(data, "data");
      setExtendedAuthRequired(data.operationConditions.extAuthRequired);
      setBillDetails(data.destination.billDetails);
      setBillSections(data.destination.billSections);
      setIsPrefaced(true);
      setCommission(`${localeAmount(data.operationConditions.commission, language)} ${data.currency}`);
      setIsPrefaced(true);
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
    }
    setIsFetching(false);
  };

  const cancel = () => {
    setSrcContract(null);
    setBillerParams(initialValues);
    setAmount("");
  };
  const submitExtended = async () => {
    const otpCredentials = {
      otp: undefined,
      challenge: undefined
    };
    if (extendedAuthRequired === true) {
      try {
        setIsFetching(true);
        const { data } = await API.getAuthExtended();
        // console.log(data, 'data-extended')
        otpCredentials.challenge = data;
        setIsFetching(false);
      } catch (err) {
        setError({
          field: "global",
          message: serverErrorMessages(err, language)
        });
        setIsFetching(false);
        return;
      }

      await new Promise(resolve => {
        setModal(
          <ConfirmationCode
            clickContinueHandler={code => {
              otpCredentials.otp = code;
              setModal(null);
              resolve();
            }}
          />
        );
      });
    }
    try {
      setIsFetching(true);
      const payload = {
        destination: {
          providerId: "portmone",
          effective_biller_id: biller.id,
          billerId: biller.id,
          billSections,
          billerAccount: billerParams.parameter01,
          categoryId: biller.categoryId,
          billDetails
        },
        otpCredentials,
        ...billerParams,
        amount: Math.round(amount * 100),
        srcContractRef: {
          providerId: srcContract?.providerId,
          contractId: srcContract?.contractId
        },
        currency: "UAH",
        cardId: srcContract?.cardId
      };
      const { data } = await API.postBillPaymentExecute(payload);
      // console.log(data, 'data-execute')

      if (data.status === "SUCCESS") {
        setModal(<OperationResult titleText={text("payments.success")} buttonText="Закрити" clickCompleteHandler={() => setModal(null)} />);
      } else if (data.status === "WAIT_FOR_PROCESSING") {
        setModal(<OperationResult result="failure" titleText={text("payments.processing")} buttonText="Закрити" clickCompleteHandler={() => setModal(null)} />);
      } else {
        setModal(<OperationResult result="failure" titleText={text("payments.failure")} buttonText="Закрити" clickCompleteHandler={() => setModal(null)} />);
      }
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
      cancel();
      setIsFetching(false);
    }
    push(urlCategories);
  };

  const amountValidator = value => {
    const length = isLength(value, { min: 1, max: 16 });
    const currency = isCurrency(value, {
      digits_after_decimal: [0, 1, 2]
    });
    return length && currency;
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <Label>З рахунку / картки</Label>
        <Select
          errorClassName={classes.error}
          style={{ zIndex: 1, marginBottom: 20, width: 498 }}
          options={srcContractAll}
          value={srcContract?.value}
          changeHandler={setSrcContract}
          placeholder={text("outerToInnerTransaction.placeholder")}
          errorMessage={fieldErrorMessage("givingAccount", error)}
        />
        <Divider />
        <Triangle style={{ margin: "auto" }} />

        {biller.parametersConf.map(parameter => (
          <div key={parameter.name}>
            <Label>{parameter.title}</Label>
            <Input
              errorClassName={classes.error}
              style={{ marginBottom: 20, width: 498 }}
              name={parameter.name}
              value={billerParams[parameter.name]}
              changeHandler={value => setBillerParams({ ...billerParams, [parameter.name]: value })}
              errorMessage={fieldErrorMessage("personalAccount", error)}
            />
          </div>
        ))}

        <div className={classes.amountAndCommissionBlock}>
          <div className={classes.amountBlock}>
            <Label>Сума переказу</Label>
            <Input
              errorClassName={classes.errorAmount}
              style={{ width: 220 }}
              value={amount}
              changeHandler={value => setAmount(value)}
              units="грн."
              validator={value => amountValidator(value)}
              errorMessage={fieldErrorMessage("amount", error)}
            />
          </div>
          {commission && (
            <div className={classes.text}>
              {text("innerToInnerTransaction.commission")}: {commission}
            </div>
          )}
        </div>

        {globalErrorMessage && <Error className={classes.globalError} message={globalErrorMessage} />}

        <div className={classes.next}>
          <Button
            style={{
              width: 200,
              height: 48,
              color: "#241F5A",
              textDecoration: "underline",
              fontWeight: "bold"
            }}
            clickHandler={() => goBack()}
          >
            Відмінити
          </Button>
          <Button
            style={{
              width: 200,
              height: 48,
              color: "white",
              backgroundColor: "#241F5A",
              borderColor: "#241F5A",
              fontWeight: "bold"
            }}
            clickHandler={!isPrefaced ? submitPaymentPreface : submitExtended}
            isDisabled={isFetching}
          >
            Продовжити &rarr;
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ contracts }) => ({ cards: contracts.cards, accounts: contracts.accounts });

export default connect(mapStateToProps)(SimpleForm);
