import React, { useState } from "react";
import classes from "./Carousel.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PropTypes from "prop-types";
import cn from "../../helpers/cn";
import all from "../../helpers/all";
import { Icon } from "../index";

const Carousel = ({ className, style, slideHeight, children, mode, initialSlide, changeHandler, forwardRef }) => {
  const config = {
    single: {
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      variableWidth: true
    },
    pair: {
      slidesToShow: 2,
      slidesToScroll: 2,
      centerMode: false,
      variableWidth: false
    }
  }[mode];

  const [currentSlideIndex, setCurrentSlideIndex] = useState(initialSlide);

  const PrevArrow = ({ className, onClick }) => (
    <div className={cn(className, classes.arrow, classes.arrowPrev)} style={currentSlideIndex === 0 ? { display: "none" } : null} onClick={onClick}>
      <Icon className={cn(classes.arrowIcon, classes.arrowIconPrev)} name="carouselArrow" />
    </div>
  );
  const NextArrow = ({ className, onClick }) => (
    <div
      className={cn(className, classes.arrow, classes.arrowNext)}
      style={currentSlideIndex === React.Children.count(children) - 1 ? { display: "none" } : null}
      onClick={onClick}
    >
      <Icon className={cn(classes.arrowIcon, classes.arrowIconNext)} name="carouselArrow" />
    </div>
  );

  return (
    <Slider
      ref={forwardRef}
      className={cn(classes.slider, className)}
      style={{ ...style, height: slideHeight + 20 }}
      dots={false}
      infinite={false}
      slidesToShow={config.slidesToShow}
      slidesToScroll={config.slidesToScroll}
      centerMode={config.centerMode}
      variableWidth={config.variableWidth}
      prevArrow={<PrevArrow />}
      nextArrow={<NextArrow />}
      initialSlide={initialSlide}
      afterChange={i => all(() => setCurrentSlideIndex(i), () => changeHandler(i))}
    >
      {React.Children.map(children, (child, i) => (
        <div className={cn(classes.slideWrapper, mode === "single" && i !== currentSlideIndex ? classes.slideWrapperTransparent : null)}>{child}</div>
      ))}
    </Slider>
  );
};

Carousel.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  slideHeight: PropTypes.number,
  children: PropTypes.node,
  mode: PropTypes.oneOf(["single", "pair"]),
  initialSlide: PropTypes.number,
  changeHandler: PropTypes.func
};

Carousel.defaultProps = {
  className: "",
  style: {},
  slideHeight: 130,
  children: null,
  mode: "pair",
  initialSlide: 0,
  changeHandler: () => {}
};

export default Carousel;
