import React from "react";
import classes from "./Label.module.scss";
import PropTypes from "prop-types";
import cn from "../../../helpers/cn";

const Label = ({ className, style, children }) => (
  <div className={cn(classes.wrapper, className)} style={style}>
    {children}
  </div>
);

Label.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node
};

Label.defaultProps = {
  className: "",
  style: {},
  children: ""
};

export default Label;
