import React, { useContext, useEffect, useState } from "react";
import classes from "./ApproveStep.module.scss";
import { Button, ConfirmationCode, OperationResult, Title } from "../../../index";
import * as API from "../../../../api/index";
import { languageContext, modalContext } from "../../../../context/index";
import moment from "moment";
import localeAmount from "../../../../helpers/localeAmount";

const ApproveStep = ({ isEdit, clickCancelHandler, contractPayload, prefaceData, srcProvider, destProvider, bankName, statusCreatedRegularPayment }) => {
  const { text } = useContext(languageContext);
  const { setModal } = useContext(modalContext);
  const [isFetching, setIsFetching] = useState(false);
  const [srcLabel, setSrcLabel] = useState("");
  const [destLabel, setDestLabel] = useState("");
  useEffect(() => {
    if (srcProvider) {
      setSrcLabel(srcProvider.label);
    }
    if (destProvider) {
      setDestLabel(destProvider.label);
    }
  }, [srcProvider, destProvider]);
  const types = {
    CONTRACT_TO_CONTRACT: "contractToContract",
    INTRABANK_TRANSFER: "intrabankTransfer",
    SEP_TRANSFER: "sepTransfer"
  };
  const instantPeriods = {
    DAY: text("templateSettings.labels.day"),
    WEEK: text("templateSettings.labels.week"),
    MONTH: text("templateSettings.labels.month")
  };
  const periods = {
    DAY: text("templateSettings.labels.everyDay"),
    WEEK: text("templateSettings.labels.everyWeek"),
    MONTH: text("templateSettings.labels.everyMonth"),
    INSTANT: `${text("templateSettings.labels.every")} ${prefaceData.intervalValue} ${instantPeriods[prefaceData.intervalOfPayment]}`
  };

  const submitApproveExtended = async () => {
    setIsFetching(true);

    const otpCredentials = {
      otp: undefined,
      challenge: undefined
    };

    if (prefaceData.operationConditions.extAuthRequired === true) {
      try {
        setIsFetching(true);
        const { data } = await API.getAuthExtended();
        otpCredentials.challenge = data;
        setIsFetching(false);
      } catch (err) {
        setIsFetching(false);
        return;
      }
      await new Promise(resolve => {
        setModal(
          <ConfirmationCode
            clickContinueHandler={code => {
              otpCredentials.otp = code;
              setModal(null);
              resolve();
            }}
          />
        );
      });
    }
    let payload;
    if (prefaceData.typeOfAutopayment === "CONTRACT_TO_CONTRACT") {
      payload = {
        typeOfAutopayment: prefaceData.typeOfAutopayment,
        title: prefaceData.title,
        amountOfPayment: prefaceData.amountOfPayment,
        extId: prefaceData.extId,
        externalOperationId: prefaceData.externalOperationId,
        amountOfPaymentCalculationRule: prefaceData.amountOfPaymentCalculationRule,
        startDateOfPayment: prefaceData.startDateOfPayment,
        finalDateOfPayment: prefaceData.finalDateOfPayment,
        interval: prefaceData.interval,
        intervalOfPayment: prefaceData.intervalOfPayment,
        intervalValue: prefaceData.intervalValue,
        operation: {
          cardId: prefaceData.cardId,
          dstCardId: prefaceData.dstCardId,
          currency: prefaceData.operation.currency,
          destContractRef: {
            providerId: prefaceData.operation.destContractRef.providerId,
            contractId: prefaceData.operation.destContractRef.contractId
          },
          srcContractRef: {
            providerId: prefaceData.operation.srcContractRef.providerId,
            contractId: prefaceData.operation.srcContractRef.contractId
          }
        },
        otpCredentials
      };
    } else if (prefaceData.typeOfAutopayment === "INTRABANK_TRANSFER") {
      payload = {
        typeOfAutopayment: prefaceData.typeOfAutopayment,
        title: prefaceData.title,
        amountOfPayment: prefaceData.amountOfPayment,
        extId: prefaceData.extId,
        externalOperationId: prefaceData.externalOperationId,
        amountOfPaymentCalculationRule: prefaceData.amountOfPaymentCalculationRule,
        startDateOfPayment: prefaceData.startDateOfPayment,
        finalDateOfPayment: prefaceData.finalDateOfPayment,
        interval: prefaceData.interval,
        intervalOfPayment: prefaceData.intervalOfPayment,
        intervalValue: prefaceData.intervalValue,
        operation: {
          cardId: prefaceData.cardId,
          currency: prefaceData.operation.currency,
          destination: {
            identifier: prefaceData.operation.destination.identifier,
            identifierType: prefaceData.operation.destination.identifierType
          },
          srcContractRef: {
            providerId: prefaceData.operation.srcContractRef.providerId,
            contractId: prefaceData.operation.srcContractRef.contractId
          }
        },
        otpCredentials
      };
    } else if (prefaceData.typeOfAutopayment === "SEP_TRANSFER") {
      payload = {
        typeOfAutopayment: prefaceData.typeOfAutopayment,
        title: prefaceData.title,
        amountOfPayment: prefaceData.amountOfPayment,
        extId: prefaceData.extId,
        externalOperationId: prefaceData.externalOperationId,
        amountOfPaymentCalculationRule: prefaceData.amountOfPaymentCalculationRule,
        startDateOfPayment: prefaceData.startDateOfPayment,
        finalDateOfPayment: prefaceData.finalDateOfPayment,
        interval: prefaceData.interval,
        intervalOfPayment: prefaceData.intervalOfPayment,
        intervalValue: prefaceData.intervalValue,
        operation: {
          cardId: prefaceData.cardId,
          currency: prefaceData.operation.currency,
          destination: {
            accountNumber: prefaceData.operation.destination.accountNumber,
            bankId: prefaceData.operation.destination.bankId,
            name: prefaceData.operation.destination.name,
            taxId: prefaceData.operation.destination.taxId
          },
          purpose: prefaceData.operation.purpose,
          srcContractRef: {
            providerId: prefaceData.operation.srcContractRef.providerId,
            contractId: prefaceData.operation.srcContractRef.contractId
          }
        },
        otpCredentials
      };
    }
    if (isEdit) {
      API.postAutopaymentsUpdateExecute(types[prefaceData.typeOfAutopayment], payload)
        .then(response => {
          console.log(response.data.status, "result");
          if (response.data.status === "SUCCESS") {
            setModal(
              <OperationResult
                titleText={text("regularPayments.update.textSuccess")}
                buttonText={text("buttonCancel")}
                clickCompleteHandler={() => setModal(null)}
              />
            );
            statusCreatedRegularPayment(response.data.status);
          } else {
            setModal(
              <OperationResult
                result="failure"
                titleText={text("regularPayments.update.textFail")}
                buttonText={text("buttonCancel")}
                clickCompleteHandler={() => setModal(null)}
              />
            );
          }
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.status, contractPayload, "error");
          }
        });
    } else {
      API.postAutopaymentsCreateExecute(types[prefaceData.typeOfAutopayment], payload)
        .then(response => {
          console.log(response.data.status, "result");
          if (response.data.status === "SUCCESS") {
            setModal(
              <OperationResult
                titleText={text("templateSettings.textCreatedSuccess")}
                buttonText={text("buttonCancel")}
                clickCompleteHandler={() => setModal(null)}
              />
            );
            statusCreatedRegularPayment(response.data.status);
          } else {
            setModal(
              <OperationResult
                result="failure"
                titleText={text("templateSettings.textCreatedFail")}
                buttonText={text("buttonCancel")}
                clickCompleteHandler={() => setModal(null)}
              />
            );
          }
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.status, contractPayload, "error");
          }
        });
    }
  };

  return (
    <>
      <Title text={text("templateSettings.regularPaymentTitle")} />

      <div className={classes.wrapper}>
        <div className={classes.row}>
          <span className={classes.name}>{text("templateSettings.labels.namePaymentApprove")}</span>
          <span className={classes.value}>{prefaceData.title}</span>
        </div>
        <div className={classes.row}>
          <span className={classes.name}>{text("templateSettings.labels.fromContract")}</span>
          <span className={classes.value}>{srcLabel}</span>
        </div>
        {prefaceData.typeOfAutopayment !== "SEP_TRANSFER" && (
          <div className={classes.row}>
            <span className={classes.name}>{text("templateSettings.labels.toContract")}</span>
            {prefaceData.typeOfAutopayment === "CONTRACT_TO_CONTRACT" && <span className={classes.value}>{destLabel}</span>}
            {prefaceData.typeOfAutopayment === "INTRABANK_TRANSFER" && <span className={classes.value}>{prefaceData.operation.destination.identifier}</span>}
          </div>
        )}

        {prefaceData.typeOfAutopayment === "SEP_TRANSFER" && (
          <>
            <div className={classes.row}>
              <span className={classes.name}>{text("templateSettings.labels.toContractForSep")}</span>
              <span className={classes.value}>{prefaceData.operation.destination.accountNumber}</span>
            </div>
            <div className={classes.row}>
              <span className={classes.name}>{text("innerToCustomTransaction.recipientName")}</span>
              <span className={classes.value}>{prefaceData.operation.destination.name}</span>
            </div>
            <div className={classes.row}>
              <span className={classes.name}>{text("innerToCustomTransaction.mfo")}</span>
              <span className={classes.value}>{bankName}</span>
            </div>
            <div className={classes.row}>
              <span className={classes.name}>{text("templateSettings.labels.taxNumber")}</span>
              <span className={classes.value}>{prefaceData.operation.destination.taxId}</span>
            </div>
            <div className={classes.row}>
              <span className={classes.name}>{text("templateSettings.labels.purpose")}</span>
              <span className={classes.value}>{prefaceData.operation.purpose}</span>
            </div>
          </>
        )}

        <div className={classes.row}>
          <span className={classes.name}>{text("templateSettings.transferAmount")}</span>
          <span className={classes.value}>
            {localeAmount(prefaceData.amountOfPayment)} {prefaceData.operation.currency}
          </span>
        </div>
        {/* <div className={classes.row}>
                    <span className={classes.name}>{text("templateSettings.labels.commission")}</span>
                    <span className={classes.value}>{localeAmount(prefaceData.operationConditions.commission)} {prefaceData.operation.currency}</span>
                </div> */}
        <div className={classes.row} style={{ borderTop: "1px solid #f2f2f2", paddingTop: "15px" }}>
          <span className={classes.name}>{text("templateSettings.labels.regularPaymentScheduleApprove")}</span>
          <span className={classes.value}>{prefaceData.intervalValue === 1 ? periods[prefaceData.intervalOfPayment] : periods["INSTANT"]}</span>
        </div>
        <div className={classes.row}>
          <span className={classes.name}>{text("templateSettings.labels.startDate")}</span>
          <span className={classes.value}>{moment(prefaceData.startDateOfPayment).format("DD.MM.YYYY")}</span>
        </div>
        <div className={classes.row}>
          <span className={classes.name}>{text("templateSettings.labels.finishDate")}</span>
          <span className={classes.value}>{moment(prefaceData.finalDateOfPayment).format("DD.MM.YYYY")}</span>
        </div>
        <div className={classes.rowLink}>
          <span className={classes.linkText}>
            {text("templateSettings.text")}
            <a className={classes.link} target="_blank" rel="noreferrer nofollow noopener" href="https://ap-bank.com/documents/download/1084">
              {text("templateSettings.linkText")}
            </a>
          </span>
        </div>
        <div className={classes.buttonBlock}>
          <Button className={classes.buttonCancel} clickHandler={clickCancelHandler}>
            {text("templateSettings.buttonCancel")}
          </Button>
          <Button className={classes.buttonContinue} clickHandler={submitApproveExtended} isDisabled={isFetching}>
            {text("templateSettings.buttonContinue")}
          </Button>
        </div>
      </div>
    </>
  );
};
export default ApproveStep;
