import React from "react";
import classes from "./Title.module.scss";
import PropTypes from "prop-types";
import cn from "../../helpers/cn";

const Title = ({ className, style, text, ...props }) => {
  return (
    <div className={cn(classes.wrapper, className, props.onClick && classes.link)} style={style} {...props}>
      {text}
    </div>
  );
};

Title.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  text: PropTypes.string
};

Title.defaultProps = {
  className: "",
  style: {},
  text: ""
};

export default Title;
