import localization from "../localization/index";

export const feedbackSchema = language => {
  const { required } = localization[language].errors;

  return {
    phone: [
      {
        validator: "length",
        args: {
          min: 13,
          max: 13
        },
        errorMessage: required
      }
    ]
  };
};
