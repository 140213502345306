const defIbanValue = "UA";
const defIbanValidator = value => RegExp(`^${defIbanValue}[0-9]{0,27}$`).test((value || "").toUpperCase());
const defIban = value => (defIbanValidator(value || "") ? (value || "").toUpperCase() : defIbanValue);
const defIbanPreChange = value => (RegExp(`^${defIbanValue}`).test(value) ? value : defIbanValue);
const defIbanPast = callback => event => {
  const value = (event.clipboardData.getData("text") || '').replaceAll(/[^UA0-9]+/ig, '');
  if (defIbanValidator(value)) {
    event.preventDefault();
    callback(defIban(value));
  }
};

export { defIbanValue, defIban, defIbanValidator, defIbanPreChange, defIbanPast };
