import React, { useState } from "react";
import classes from "./OuterToInnerTransaction.module.scss";
import { connect } from "react-redux";
import {
  Button,
  ConfirmationCode,
  Divider,
  Error,
  Input,
  Label,
  OperationResult,
  RegularPayment,
  Select,
  TemplateSettings,
  Title,
  TransactionSuccess,
  Triangle
} from "../../../../components/index";
import { languageContext, modalContext, templatesContext } from "../../../../context/index";
import isCurrency from "validator/lib/isCurrency";
import getParamFromUrl from "../../../../helpers/getParamFromUrl";
import { useError } from "../../../../hooks/index";
import { fieldErrorMessage, innerToInnerTransactionSchema, outerToInnerTransactionSchema, validationError } from "../../../../validation/index";
import * as API from "../../../../api/index";
import serverErrorMessages from "../../../../helpers/serverErrorMessages";
import localeAmount from "../../../../helpers/localeAmount";
import numberWithSpaces from "../../../../helpers/numberWithSpaces";
import amountForRequest from "../../../../helpers/amountForRequest";
import { accountOptions, cardOptions, creditOptions, depositReceiverOptions, depositSenderOptions, uahCardOptions } from "../../../../store/getters/index";
import * as actions from "../../../../store/actions/index";
import Tooltip from "react-tooltip-lite";

const OuterToInnerTransaction = ({ history, location, cards, accounts, credits, deposits, readContract }) => {
  const { language, text } = React.useContext(languageContext);
  const { setModal } = React.useContext(modalContext);
  const { createTemplate } = React.useContext(templatesContext);

  const [type, setType] = React.useState("innerToInner");

  const sender = getParamFromUrl("sender") || location.state?.srcContract || "";
  const receiver = getParamFromUrl("receiver") || location.state?.dstContract || "";

  const srcContractOptions = React.useMemo(() => [...cardOptions(cards), ...accountOptions(accounts, text), ...depositSenderOptions(deposits, text)], [
    accounts,
    cards,
    deposits,
    text
  ]);
  const dstContractOptions = React.useMemo(
    () => [...cardOptions(cards), ...accountOptions(accounts, text), ...depositReceiverOptions(deposits, text), ...creditOptions(credits, text)],
    [accounts, cards, credits, deposits, text]
  );
  const srcContractAll = React.useMemo(
    () => [...cardOptions(cards), ...accountOptions(accounts, text), ...depositSenderOptions(deposits, text), ...creditOptions(credits, text)],
    [accounts, cards, credits, deposits, text]
  );
  const dstContractAll = React.useMemo(
    () => [...cardOptions(cards), ...accountOptions(accounts, text), ...depositReceiverOptions(deposits, text), ...creditOptions(credits, text)],
    [accounts, cards, credits, deposits, text]
  );

  const [srcContract, _setSrcContract] = React.useState({});
  const [dstContract, _setDstContract] = React.useState({});

  const setSrcContract = React.useCallback(
    value => {
      _setSrcContract(srcContractAll.find(contract => contract.value === value) || {});
    },
    [srcContractAll]
  );
  const setDstContract = React.useCallback(
    value => {
      _setDstContract(dstContractAll.find(contract => contract.value === value) || {});
    },
    [dstContractAll]
  );

  React.useEffect(() => {
    setSrcContract(sender);
    setDstContract(receiver);
  }, [receiver, sender, setDstContract, setSrcContract]);

  const srcContractList = React.useMemo(() => {
    if (dstContract?.value) {
      return srcContractOptions
        .filter(contract => contract?.value !== dstContract?.value)
        .filter(contract => contract?.currency === dstContract?.currency)
        .filter(contract => (dstContract?.providerId === "card" ? ["account", "deposit", "credit", "card"].includes(contract?.providerId) : true))
        .filter(contract => (dstContract?.providerId === "credit" ? ["account", "card"].includes(contract?.providerId) : true))
        .filter(contract => (dstContract?.providerId === "deposit" ? ["account", "card"].includes(contract?.providerId) : true))
        .filter(contract => (dstContract?.providerId === "account" ? ["account", "deposit", "card"].includes(contract?.providerId) : true));
    }
    return srcContractOptions;
  }, [dstContract.currency, dstContract.providerId, dstContract.value, srcContractOptions]);

  const dstContractList = React.useMemo(() => {
    if (type === "outerToInner") {
      return uahCardOptions(cards);
    } else {
      if (srcContract?.value) {
        return dstContractOptions
          .filter(contract => contract?.value !== srcContract?.value)
          .filter(contract => contract?.currency === srcContract?.currency)
          .filter(contract => (srcContract?.providerId === "deposit" ? ["account", "card"].includes(contract?.providerId) : true))
          .filter(contract => (srcContract?.providerId === "credit" ? contract?.providerId === "card" : true));
      }
    }
    return dstContractOptions;
  }, [cards, dstContractOptions, srcContract.currency, srcContract.providerId, srcContract.value, type]);

  const cancel = React.useCallback(() => {
    setSrcContract(null);
    setDstContract(null);
    setGivingAccount("");
    setDate("");
    setCvv("");
    setAmount("");
    setCommission("");
    setIsPrefaced(false);
  }, [setDstContract, setSrcContract]);

  const [givingAccount, setGivingAccount] = React.useState("");
  const [date, setDate] = React.useState("");
  const [cvv, setCvv] = React.useState("");

  const [amount, setAmount] = React.useState(() => {
    return numberWithSpaces(getParamFromUrl("amount")) || (location.state && numberWithSpaces(localeAmount(location.state.amount))) || "";
  });
  const [isFetching, setIsFetching] = React.useState(false);
  const [isPrefaced, setIsPrefaced] = React.useState(false);
  const [commission, setCommission] = React.useState("");
  const [enrolledOperationId, setEnrolledOperationId] = React.useState(null);
  const [otpCredentialsChallenge, setOtpCredentialsChallenge] = React.useState(undefined);
  const [error, setError] = useError(null);
  const [step, setStep] = React.useState(null);

  // --------------------useState for requests - 3d-secure and function for 3D-secure-------------------
  const [id, setId] = React.useState(false);
  const [ascUrl, setAscUrl] = React.useState(null);
  const [monUrl, setMonUrl] = useState(null);
  const [termUrl, setTermUrl] = React.useState(null);
  // const refForm = React.useRef(null);
  const refIframe = React.useRef(null);
  const timer = React.useRef(null);

  const checkFrame = React.useCallback(
    async value => {
      if (refIframe.current?.contentDocument?.location.href === value && refIframe.current.contentDocument.getElementById("param")) {
        clearInterval(timer.current);
        const paRes = refIframe.current.contentDocument.getElementById("param");
        const payload = { enrolledOperationId: id, paRes: paRes.textContent };

        try {
          let { data } = await API.postOuterToInnerTransactionCommit(payload);

          if (data.status === "CREATED") {
            setAscUrl(data.challengeUrl);
            setStep(data.nextStep);
          }

          if (data.status === "CREATED" && data.nextStep === "lookup") {
            const payload2 = {
              enrolledOperationId: data.id,
              otp: undefined
            };

            await new Promise(resolve => {
              setModal(
                <ConfirmationCode
                  clickContinueHandler={code => {
                    payload2.otp = code;
                    setModal(null);
                    resolve();
                  }}
                />
              );
            });

            data = (await API.postOuterToInnerTransactionCommit(payload2)).data;
          }

          if (data.status === "FAIL") {
            setError({ field: "global", message: serverErrorMessages({ response: { data } }, language) });
            setAscUrl(null);
            setStep(null);
          }

          if (data.status === "SUCCESS") {
            setModal(
              <OperationResult
                titleText={text("transactionSuccess.success")}
                buttonText={text("buttons.complete")}
                clickCompleteHandler={() => setModal(null)}
              />
            );
            history.push("/home/transactions");
          }
        } catch (err) {
          setError({
            field: "global",
            message: serverErrorMessages(err, language)
          });
        }
      }
    },
    [history, id, language, setError, setModal, text]
  );

  React.useEffect(() => () => clearInterval(timer.current), []);
  React.useEffect(() => {
    if (termUrl) {
      clearInterval(timer.current);
      timer.current = setInterval(() => checkFrame(termUrl), 200);
    }
  }, [checkFrame, termUrl]);

  // -----------------------------------------------------------------------------------

  const globalErrorMessage = fieldErrorMessage("global", error);

  const typeOptions = React.useMemo(
    () => [
      { value: "innerToInner", label: text("outerToInnerTransaction.options.innerToInner") },
      { value: "outerToInner", label: text("outerToInnerTransaction.options.outerToInner") }
    ],
    [text]
  );

  React.useEffect(() => {
    if (type === "outerToInner") {
      setSrcContract(null);
      if (dstContract?.currency !== "UAH" || dstContract?.providerId !== "card") {
        setDstContract(null);
      }
    } else {
      setGivingAccount("");
      setDate("");
      setCvv("");
    }
  }, [dstContract.currency, dstContract.providerId, setDstContract, setSrcContract, type]);

  const addTemplate = React.useCallback(
    async payload => {
      try {
        setModal(null);
        await createTemplate(payload);
        setModal(
          <OperationResult
            titleText={`${text("templates.modals.createSuccess.titleText")}!`}
            text={text("templates.modals.createSuccess.text")}
            buttonText={text("buttons.complete")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      } catch (err) {
        setModal(
          <OperationResult
            result="failure"
            text={serverErrorMessages(err, language)}
            buttonText={text("buttons.close")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      }
    },
    [setModal, text, createTemplate, language]
  );

  const initialSubmitInnerToInner = async () => {
    setError(null);
    const error = validationError(innerToInnerTransactionSchema(language), {
      givingContract: srcContract?.value,
      gettingContract: dstContract?.value,
      amount
    });
    if (error) {
      setError(error);
      return;
    }
    setIsFetching(true);
    try {
      const { data } = await API.postInnerToInnerTransactionPreface({
        srcContractRef: {
          contractId: srcContract?.contractId,
          providerId: srcContract?.providerId
        },
        cardId: srcContract?.cardId,
        destContractRef: {
          contractId: dstContract?.contractId,
          providerId: dstContract?.providerId
        },
        dstCardId: dstContract?.cardId,
        amount: Math.round(amountForRequest(amount)),
        currency: dstContract?.currency || srcContract?.currency
      });
      setCommission(`${localeAmount(data.operationConditions.commission)} ${data.currency}`);
      const { extAuthRequired } = data.operationConditions;
      if (extAuthRequired === true) {
        try {
          setIsFetching(true);

          const { data } = await API.getAuthExtended();
          setOtpCredentialsChallenge(data);
          setIsFetching(false);
        } catch (err) {
          setError({
            field: "global",
            message: serverErrorMessages(err, language)
          });
          setIsFetching(false);
          return;
        }
      }
      setIsPrefaced(true);
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
    }

    setIsFetching(false);
  };

  const submitInnerToInner = async () => {
    try {
      setIsFetching(true);
      const otpCredentials = {
        otp: undefined,
        challenge: otpCredentialsChallenge
      };
      await new Promise(resolve => {
        setModal(
          <ConfirmationCode
            clickContinueHandler={code => {
              otpCredentials.otp = code;
              setModal(null);
              resolve();
            }}
          />
        );
      });

      setIsFetching(true);
      await API.postAuthLoginOtp(otpCredentials);
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
      setIsFetching(false);
      return;
    }
    // ---------------------------------------------------------------------------------------------------------
    try {
      setIsFetching(true);
      const { data } = await API.postInnerToInnerTransactionExecute({
        srcContractRef: {
          contractId: srcContract?.contractId,
          providerId: srcContract?.providerId
        },
        cardId: srcContract?.cardId,
        destContractRef: {
          contractId: dstContract?.contractId,
          providerId: dstContract?.providerId
        },
        dstCardId: dstContract?.cardId,
        amount: Math.round(amountForRequest(amount)),
        currency: dstContract?.currency || srcContract?.currency
      });

      if (data.status === "FAIL") {
        setError({
          field: "global",
          message: serverErrorMessages(
            {
              response: {
                data
              }
            },
            language
          )
        });
        setIsFetching(false);
        cancel();
        return;
      }
      try {
        await Promise.all([readContract(srcContract?.providerId, srcContract?.contractId), readContract(dstContract?.providerId, dstContract?.contractId)]);
      } catch (error) {
        console.log("Unable to update the contract", error);
      }
      setModal(
        <TransactionSuccess
          clickCancelHandler={() => setModal(null)}
          clickAddHandler={() =>
            setModal(
              <TemplateSettings
                isNew
                initialType="CONTRACT_TO_CONTRACT"
                initialGivingContract={srcContract?.value}
                initialGettingContract={dstContract?.value}
                initialAmount={amount}
                clickCancelHandler={() => setModal(null)}
                clickSaveHandler={addTemplate}
              />
            )
          }
          clickRegularHandler={() =>
            setModal(
              <RegularPayment
                initialType="CONTRACT_TO_CONTRACT"
                initialGivingContract={srcContract?.value}
                initialGettingContract={dstContract?.value}
                initialAmount={amount}
                clickCancelHandler={() => setModal(null)}
              />
            )
          }
        />
      );
      history.push("/home/transactions");
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
      setIsFetching(false);
    }
  };

  const initialSubmitOuterToInner = async () => {
    setError(null);
    const modifiedGivingAccount = givingAccount.split(/\s|_/).join("");
    const modifiedDate = date.split(/\/|_/).join("");
    const modifiedCvv = cvv.split(/_/).join("");
    const error = validationError(outerToInnerTransactionSchema(language), {
      givingAccount: modifiedGivingAccount,
      date: modifiedDate,
      cvv: modifiedCvv,
      gettingContract: dstContract?.value,
      amount
    });
    if (error) {
      setError(error);
      return;
    }

    //  payload for postOuterToInnerTransactionEnroll
    const payload = {
      srcCard: {
        cardNumber: modifiedGivingAccount,
        secureCode: cvv,
        expMonth: date.split("/")[0],
        expYear: date.split("/")[1]
      },
      destCard: {
        contractReference: {
          providerId: dstContract?.providerId,
          contractId: dstContract?.contractId
        },
        cardId: dstContract?.cardId
      },
      amount: Math.round(amount * 100),
      currency: "UAH",
      customCallbackUrl: `${window.location.origin}/bank/secure3d/callback-url.jsp`
    };

    setIsFetching(true);
    try {
      const { data } = await API.postOuterToInnerTransactionEnroll(payload);

      setId(data.id);
      setTermUrl(`${window.location.origin}/bank/secure3d/callback-url.jsp`);
      setMonUrl(data.monitorUrl);
      setAscUrl(data.secure3DRequest.acsUrl);
      setStep(data.nextStep);

      setEnrolledOperationId(data.id);
      setCommission(`${localeAmount(data.operationConditions.commission)} ${data.currency}`);
      setIsPrefaced(true);
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
      setIsFetching(false);
      cancel();
    }
  };

  const submitOuterToInner = async () => {
    const payload = {
      enrolledOperationId,
      otp: undefined
      // challenge: undefined
    };

    await new Promise(resolve => {
      setModal(
        <ConfirmationCode
          clickContinueHandler={code => {
            payload.otp = code;
            setModal(null);
            resolve();
          }}
        />
      );
    });

    try {
      setIsFetching(true);
      const { data } = await API.postOuterToInnerTransactionCommit(payload);
      if (data.status === "FAIL") {
        setError({
          field: "global",
          message: serverErrorMessages(
            {
              response: {
                data
              }
            },
            language
          )
        });
        setIsFetching(false);
        cancel();
        return;
      }
      try {
        await readContract(dstContract?.providerId, dstContract?.contractId);
      } catch (error) {
        console.log("Unable to update the contract", error);
      }
      //setIsFetching(false);
      setModal(
        <OperationResult titleText={text("transactionSuccess.success")} buttonText={text("buttons.complete")} clickCompleteHandler={() => setModal(null)} />
      );
      history.push("/home/transactions");
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Title text={text("outerToInnerTransaction.title")} />
      <div className={classes.content}>
        <div className={classes.subtitle}>{text("outerToInnerTransaction.sender")}:</div>
        <Select
          style={{ marginBottom: 20 }}
          labelText={text("outerToInnerTransaction.labels.type")}
          options={typeOptions}
          value={type}
          changeHandler={value => setType(value)}
          isDisabled={
            (srcContract?.value === sender && ["deposit", "credit"].includes(srcContract?.providerId)) ||
            (dstContract?.value === receiver && ["deposit", "credit"].includes(dstContract?.providerId)) ||
            isPrefaced
          }
        />
        <Divider style={{ marginBottom: 10 }} />
        {type === "innerToInner" && (
          <Select
            errorClassName={classes.error}
            style={{ marginBottom: 20 }}
            labelText={text("outerToInnerTransaction.labels.fromMyAccount")}
            options={srcContractList}
            value={srcContract?.value}
            changeHandler={setSrcContract}
            placeholder={text("innerToInnerTransaction.select.placeholder")}
            errorMessage={fieldErrorMessage("givingContract", error)}
            isDisabled={(srcContract?.value === sender && ["deposit", "credit"].includes(srcContract?.providerId)) || isPrefaced}
          />
        )}
        {type === "outerToInner" && (
          <>
            <Input
              style={{ marginBottom: 20 }}
              labelText={text("outerToInnerTransaction.cardNumber")}
              value={givingAccount}
              changeHandler={value => setGivingAccount(value)}
              placeholder="**** **** **** ****"
              mask="9999 9999 9999 9999"
              errorMessage={fieldErrorMessage("givingAccount", error)}
              isDisabled={isPrefaced}
            />
            <div className={classes.row} style={{ marginBottom: 20 }}>
              <div style={{ width: 158 }}>
                <Label>{text("outerToInnerTransaction.date")}</Label>
                <Input
                  errorStyle={{ marginTop: 20 }}
                  value={date}
                  changeHandler={value => setDate(value)}
                  placeholder="MM/YY"
                  mask="99/99"
                  errorMessage={fieldErrorMessage("date", error)}
                  isDisabled={isPrefaced}
                />
              </div>
              <div style={{ width: 158 }}>
                <Input
                  type="password"
                  errorStyle={{ marginTop: 20 }}
                  labelText={
                    <>
                      CVV2/CVC2&nbsp;
                      <Tooltip
                        className={classes.target}
                        color="black"
                        background="lightgrey"
                        content={
                          <span>
                            {text("innerToOuterTransaction.tooltipCVV_1")}
                            <br />
                            {text("innerToOuterTransaction.tooltipCVV_2")}
                            <br />
                            {text("innerToOuterTransaction.tooltipCVV_3")}
                          </span>
                        }
                      >
                        <div className={classes.icon}>?</div>
                      </Tooltip>
                    </>
                  }
                  value={cvv}
                  changeHandler={value => setCvv(value)}
                  placeholder="***"
                  maxLength={3}
                  errorMessage={fieldErrorMessage("cvv", error)}
                  isDisabled={isPrefaced}
                />
              </div>
            </div>
          </>
        )}
        <Divider />
        <Triangle style={{ margin: "auto" }} />
        <div className={classes.subtitle}>{text("outerToInnerTransaction.recipient")}:</div>
        <Label>{text("outerToInnerTransaction.labels.toAccount")}</Label>
        {/*Переказ На власний рахунок чи картку */}
        <Select
          style={{ marginBottom: 20 }}
          options={dstContractList}
          value={dstContract?.value}
          changeHandler={setDstContract}
          placeholder={text("outerToInnerTransaction.select.placeholder")}
          errorMessage={fieldErrorMessage("gettingContract", error)}
          isDisabled={(dstContract?.value === receiver && ["deposit", "credit"].includes(dstContract?.providerId)) || isPrefaced}
        />
        <Divider style={{ marginBottom: 10 }} />
        <Label>{text("outerToInnerTransaction.transferAmount")}</Label>
        <div className={classes.row} style={{ marginBottom: 20 }}>
          <div>
            <Input
              style={{ width: 250 }}
              errorStyle={{ marginTop: 20 }}
              name="amount"
              value={amount}
              changeHandler={value => setAmount(value)}
              units={dstContract?.currency || srcContract?.currency}
              commaIsReplacedWithDot
              validator={value =>
                isCurrency(value, {
                  digits_after_decimal: [0, 1, 2]
                })
              }
              errorMessage={fieldErrorMessage("amount", error)}
              isDisabled={isPrefaced}
            />
          </div>
          {commission && (
            <div className={classes.commisionText}>
              {text("outerToInnerTransaction.commission")}: {commission}
            </div>
          )}
        </div>

        {step === "3d2" && (
          <div className={classes.row} style={{ marginBottom: 20, display: "none" }}>
            <iframe title="monitor" src={monUrl} />
            <iframe title="callback" src={ascUrl} ref={refIframe} />
          </div>
        )}
        {step === "challenge" && (
          <div className={classes.row} style={{ marginBottom: 20 }}>
            <iframe title="challenge" src={ascUrl} ref={refIframe} height="500" width="100%" style={{ border: "none" }} />
          </div>
        )}

        <div className={classes.text}>
          {text("outerToInnerTransaction.pressTransfer")}{" "}
          <a
            className={classes.link}
            href={type === "innerToInner" ? "https://ap-bank.com/documents/download/1084" : "https://pay4-api-v2.payforce.net.ua/public/public_offer.pdf"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {text("outerToInnerTransaction.conditions")}
          </a>
        </div>
        {globalErrorMessage && <Error className={classes.globalError} message={globalErrorMessage} />}
        <div className={classes.row}>
          <Button
            style={{
              width: 200,
              height: 48,
              color: "#241F5A",
              textDecoration: "underline",
              fontWeight: "bold"
            }}
            clickHandler={!isPrefaced ? history.goBack : cancel}
          >
            {text(!isPrefaced ? "buttons.back" : "buttons.cancel")}
          </Button>
          <Button
            style={{
              width: 200,
              height: 48,
              color: "white",
              backgroundColor: "#241F5A",
              borderColor: "#241F5A",
              fontWeight: "bold"
            }}
            clickHandler={
              type === "innerToInner"
                ? !isPrefaced
                  ? initialSubmitInnerToInner
                  : submitInnerToInner
                : !isPrefaced
                ? initialSubmitOuterToInner
                : submitOuterToInner
            }
            isDisabled={isFetching}
          >
            {text(!isPrefaced ? "buttons.continue" : "outerToInnerTransaction.buttonConfirm")} &rarr;
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  readContract: (providerId, contractId) => dispatch(actions.readContract(providerId, contractId))
});

export default connect(({ contracts }) => contracts, mapDispatchToProps)(OuterToInnerTransaction);
