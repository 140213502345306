import React, { useContext, useState } from "react";
import classes from "./HistoryFilter.module.scss";
import PropTypes from "prop-types";
import moment from "moment";
import { languageContext } from "../../../context/index";
import { Select, DateRange } from "../../index";
import dateRanges from "../../../data/dateRanges";
import * as icons from "../../../components/icons/icons";

const HistoryFilter = ({ dates, types, type, changeDatesHandler, changeTypeHandler, typeIsShown, handlerPDFClick }) => {
  const { language, text } = useContext(languageContext);
  const [selectPDF, setSelectPDF] = useState(false);

  const PDFClickHandler = () => {
    handlerPDFClick();
    setSelectPDF(false);
  }
  return (
    <div className={classes.filterBlock}>
      <div className={classes.historyFilter}>
        <div>
          <Select
            labelText={text("historyFilter.showBy")}
            options={dateRanges(language)}
            value={dates}
            changeHandler={value => changeDatesHandler(value)}
            placeholder={text("historyFilter.placeholder")}
          />
        </div>
        <div>
          <DateRange
            labelText={text("historyFilter.selectDate")}
            value={[moment(dates[0]).format("YYYY-MM-DD"), moment(dates[1]).format("YYYY-MM-DD")]}
            changeHandler={value => changeDatesHandler([moment(value[0]).format("YYYYMMDD"), moment(value[1]).format("YYYYMMDD")])}
          />
        </div>
        {typeIsShown && (
          <div>
            <Select
              labelText={text("historyFilter.byType")}
              options={[{ value: "All", label: text("historyFilter.typeOptions.all") }, ...types]}
              value={type}
              changeHandler={value => changeTypeHandler(value)}
            />
          </div>
        )}
      </div>
      {!typeIsShown && ( <div className={classes.blockPDF}>
        <div className={classes.arrowPDF}>
          <button className={classes.ractangleCopy} onClick={() => setSelectPDF(!selectPDF)}>
            <img src={icons.exportArrow} alt="exportArrow" />
          </button>
        </div>
        {selectPDF && <div className={classes.selectPDF}  onClick={PDFClickHandler}>
          <img src={icons.iconPDF} alt="iconPDF" />
          в PDF</div>}
      </div>)}
    </div>
  );
};

HistoryFilter.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string),
  types: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  type: PropTypes.string,
  changeDatesHandler: PropTypes.func,
  changeTypeHandler: PropTypes.func,
  typeIsShown: PropTypes.bool
};
HistoryFilter.defaultProps = {
  dates: [null, null],
  types: [],
  type: "All",
  changeDatesHandler: () => { },
  changeTypeHandler: () => { },
  typeIsShown: true
};

export default HistoryFilter;
