import React from "react";
import PropTypes from "prop-types";
import * as icons from "./icons";

const Icon = ({ className, style, name, clickHandler, title }) => {
  return (
      <img
          className={className}
          style={{...style, objectFit: "contain"}}
          src={icons[name]}
          alt=""
          onClick={clickHandler}
          title={title}
      />
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  name: PropTypes.string,
  clickHandler: PropTypes.func,
  title: PropTypes.string,
};

Icon.defaultProps = {
  className: "",
  style: {},
  name: null,
  clickHandler: null,
  title: null,
};

export default Icon;
