import React, { useContext, useMemo, useState } from "react";
import classes from "./DetailedRegularPayment.module.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Title } from "../../index";
import { languageContext } from "../../../context/index";
import moment from "moment";
import localeAmount from "../../../helpers/localeAmount";
import { accountOptions, cardOptions, creditOptions, depositReceiverOptions, depositSenderOptions } from "../../../store/getters/index";
import { genContractKey } from "../../../helpers/contract";

const DetailedRegularPayment = ({ regularPayment, cards, accounts, credits, deposits, isOperation }) => {
  const [autopayment] = useState(isOperation ? regularPayment : regularPayment.autopayment);

  const { text } = useContext(languageContext);
  const statuses = {
    ACTIVE: text("regularPayments.detailedModals.active"),
    INACTIVE: text("regularPayments.detailedModals.deactive")
  };
  const activeStatuses = {
    SUCCESS: text("regularPayments.paymentHistory.success"),
    FAIL: text("regularPayments.paymentHistory.fail")
  };
  const instantPeriods = {
    DAY: text("templateSettings.labels.day"),
    WEEK: text("templateSettings.labels.week"),
    MONTH: text("templateSettings.labels.month")
  };
  const periods = {
    DAY: text("templateSettings.labels.everyDay"),
    WEEK: text("templateSettings.labels.everyWeek"),
    MONTH: text("templateSettings.labels.everyMonth"),
    INSTANT: `${text("templateSettings.labels.every")} ${autopayment.intervalValue} ${instantPeriods[autopayment.intervalOfPayment]}`
  };
  const contractOptions = useMemo(
    () => [
      ...cardOptions(cards, { all: true }),
      ...accountOptions(accounts, text),
      ...creditOptions(credits, text),
      ...depositSenderOptions(deposits, text),
      ...depositReceiverOptions(deposits, text)
    ],
    [cards, accounts, credits, deposits, text]
  );

  const srcProvider = contractOptions.find(
    item =>
      item.value ===
      genContractKey(
        isOperation ? autopayment.srcContractRef.providerId : autopayment.operation.srcContractRef.providerId,
        isOperation ? autopayment.srcContractRef.contractId : autopayment.operation.srcContractRef.contractId,
        autopayment.cardId
      )
  );

  const destProvider =
    (autopayment.typeOfAutopayment === "CONTRACT_TO_CONTRACT" || autopayment.type === "CONTRACT_TO_CONTRACT") &&
    (autopayment.destContractRef || autopayment.operation.destContractRef) &&
    contractOptions.find(
      item =>
        item.value ===
        genContractKey(
          isOperation ? autopayment.destContractRef.providerId : autopayment.operation.destContractRef.providerId,
          isOperation ? autopayment.destContractRef.contractId : autopayment.operation.destContractRef.contractId,
          autopayment.dstCardId
        )
    );

  return (
    <>
      <Title text={isOperation ? text("regularPayments.detailedModals.titleOperation") : text("regularPayments.detailedModals.title")} />

      <div className={classes.wrapper}>
        {!isOperation && (
          <div className={classes.row}>
            <span className={classes.name}>{text("templateSettings.labels.namePaymentApprove")}</span>
            <span className={classes.value}>{autopayment.title}</span>
          </div>
        )}
        <div className={classes.row}>
          <span className={classes.name}>{text("templateSettings.labels.fromContract")}</span>
          <span className={classes.value}>{srcProvider?.label}</span>
        </div>
        {(isOperation ? autopayment.type !== "SEP_TRANSFER" : autopayment.typeOfAutopayment !== "SEP_TRANSFER") && (
          <div className={classes.row}>
            {/* {(autopayment.typeOfAutopayment !== 'SEP_TRANSFER' || autopayment.type !== 'SEP_TRANSFER') && */}
            <span className={classes.name}>{text("templateSettings.labels.toContract")}</span>
            {/* } */}
            {(autopayment.typeOfAutopayment === "CONTRACT_TO_CONTRACT" || autopayment.type === "CONTRACT_TO_CONTRACT") && (
              <span className={classes.value}>{destProvider?.label}</span>
            )}
            {(autopayment.typeOfAutopayment === "INTRABANK_TRANSFER" || autopayment.type === "INTRABANK_TRANSFER") && (
              <span className={classes.value}>{isOperation ? autopayment.destination.identifier : autopayment.operation.destination.identifier}</span>
            )}
          </div>
        )}

        {(autopayment.typeOfAutopayment === "SEP_TRANSFER" || autopayment.type === "SEP_TRANSFER") && (
          <>
            <div className={classes.row}>
              <span className={classes.name}>{text("templateSettings.labels.toContractForSep")}</span>
              <span className={classes.value}>{isOperation ? autopayment.destination.accountNumber : autopayment.operation.destination.accountNumber}</span>
            </div>
            <div className={classes.row}>
              <span className={classes.name}>{text("innerToCustomTransaction.recipientName")}</span>
              <span className={classes.value}>{isOperation ? autopayment.destination.name : autopayment.operation.destination.name}</span>
            </div>
            {/* <div className={classes.row}>
                                <span className={classes.name}>{text("innerToCustomTransaction.mfo")}</span>
                                <span className={classes.value}>{bankName}</span>
                            </div> */}
            <div className={classes.row}>
              <span className={classes.name}>{text("templateSettings.labels.taxNumber")}</span>
              <span className={classes.value}>{isOperation ? autopayment.destination.taxId : autopayment.operation.destination.taxId}</span>
            </div>
            <div className={classes.row}>
              <span className={classes.name}>{text("templateSettings.labels.purpose")}</span>
              <span className={classes.value}>{isOperation ? autopayment.purpose : autopayment.operation.purpose}</span>
            </div>
          </>
        )}

        <div className={classes.row}>
          <span className={classes.name}>{text("templateSettings.transferAmount")}</span>
          <span className={classes.value}>
            {isOperation ? localeAmount(autopayment.amount) : localeAmount(autopayment.amountOfPayment)}{" "}
            {isOperation ? autopayment.currency : autopayment.operation.currency}
          </span>
        </div>
        {!isOperation && (
          <div className={classes.row} style={{ borderTop: "1px solid #f2f2f2", paddingTop: "15px" }}>
            <span className={classes.name}>{text("templateSettings.labels.regularPaymentScheduleApprove")}</span>
            <span className={classes.value}>{autopayment.intervalValue === 1 ? periods[autopayment.intervalOfPayment] : periods["INSTANT"]}</span>
          </div>
        )}
        <div className={classes.row}>
          <span className={classes.name}>{text("templateSettings.labels.startDate")}</span>
          <span className={classes.value}>{moment(autopayment.startDateOfPayment).format("DD.MM.YYYY")}</span>
        </div>
        <div className={classes.row}>
          <span className={classes.name}>{text("templateSettings.labels.finishDate")}</span>
          <span className={classes.value}>{moment(autopayment.finalDateOfPayment).format("DD.MM.YYYY")}</span>
        </div>
        <div className={classes.row}>
          <span className={classes.name}>{text("regularPayments.detailedModals.status")}</span>
          <span className={classes.value}>{isOperation ? activeStatuses[autopayment.status] : statuses[autopayment.autopaymentStatus]}</span>
        </div>
      </div>
    </>
  );
};
DetailedRegularPayment.propTypes = {
  regularPayment: PropTypes.object,
  cards: PropTypes.array,
  accounts: PropTypes.array,
  credits: PropTypes.array,
  deposits: PropTypes.array
};

const mapStateToProps = ({ contracts }) => ({
  cards: contracts.cards,
  accounts: contracts.accounts,
  credits: contracts.credits,
  deposits: contracts.deposits
});

export default connect(mapStateToProps)(DetailedRegularPayment);
