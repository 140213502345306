import React from "react";
import classes from "./Auth.module.scss";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
// import LoginType from "./LoginType/LoginType";
import Login from "./Login/Login";
import Signup from "./Signup/Signup.jsx";
import PasswordRecovery from "./PasswordRecovery/PasswordRecovery";
import PasswordSaving from "./PasswordSaving/PasswordSaving";

const Auth = () => {
  return (
    <div className={classes.wrapper}>
      <Switch>
        {/* <Route exact path="/auth/login-type" component={LoginType} /> */}
        <Route exact path="/auth/login" component={Login} />
        <Route exact path="/auth/signup" component={Signup} />
        <Route exact path="/auth/password-recovery" component={PasswordRecovery} />
        <Route exact path="/auth/password-saving" component={PasswordSaving} />
        <Redirect to="/home" />
      </Switch>
    </div>
  );
};

export default withRouter(Auth);
