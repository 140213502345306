import React from "react";
import PropTypes from "prop-types";

const Radio = ({ name, value, className, defaultChecked, changed }) => {
  return <input type="radio" className={className} name={name} value={value} defaultChecked={defaultChecked} onChange={changed} />;
};

Radio.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.any,
  defaultChecked: PropTypes.bool,
  changed: PropTypes.func
};

export default Radio;
