import React, { useContext, useState, useCallback } from "react";
import classes from "./Deposit.module.scss";
import PropTypes from "prop-types";
import { languageContext, modalContext } from "../../../../context";
import {
  Title,
  Button,
  Icon,
  NavigationTabs,
  ContractHistory,
  ProductDetails,
  Checkbox,
  OperationResult,
  ConfirmationCode
} from "../../../../components";
import * as API from "../../../../api/index";
import localeAmount from "../../../../helpers/localeAmount";
import { DEPOSIT_ORDER_AVAILABLE } from "../../../../constants";
import { downloadPDFFile } from "../../../../helpers/downloadPDFFile";
import serverErrorMessages from "../../../../helpers/serverErrorMessages";

const Deposit = ({ deposit, clickFillHandler, clickWithdrawHandler, clickOrderHandler, clickTitleHandler, readContracts }) => {
  const { setModal } = useContext(modalContext);
  const { language, text } = useContext(languageContext);

  const [activeTab, setActiveTab] = useState("history");

  const tabs = [
    {
      value: "history",
      label: text("card.tabs.history")
    },
    {
      value: "details",
      label: text("card.tabs.details")
    }
  ];
  const booleanToText = bool => text(bool ? "deposit.details.yes" : "deposit.details.no");

  const fetchDepositHistoryHandler = useCallback(params => API.getDepositHistory(deposit.id, params), [deposit]);
  const fetchDepositPDFHandler = useCallback(params =>
    API.getFormatPDFByProvider(deposit.providerId, deposit.id, params).then(result => {
      const { data } = result;
      downloadPDFFile(data)
    }), [deposit]);

  const setDepositSettings = async (value) => {
    try {
      const { data } = await API.setDepositSettingsPreface(deposit.id, { depositProlongationChangeAction: value ? 'activate' : 'deactivate' });
      const { extAuthRequired } = data.operationConditions;
      const otpCredentials = {
        otp: undefined,
        challenge: undefined
      };
      if (extAuthRequired === true) {
        const { data } = await API.getAuthExtended();
        otpCredentials.challenge = data;
        await new Promise(resolve => {
          setModal(
            <ConfirmationCode
              clickContinueHandler={code => {
                otpCredentials.otp = code;
                setModal(null);
                resolve();
              }}
            />
          );
        });
      }
      const response = await API.setDepositSettingsExecute(deposit.id, { depositProlongationChangeAction: value ? 'activate' : 'deactivate', otpCredentials });
      if (response.data.status === 'FAIL') {
        setModal(
          <OperationResult
            result="failure"
            text={serverErrorMessages({ response }, language)}
            buttonText={text("buttons.close")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      } else {
        readContracts('deposit');
        setModal(
          <OperationResult
            titleText={text("deposits.modal.changeProlongation.success")}
            buttonText={text("buttons.complete")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      }
    } catch (err) {
      setModal(
        <OperationResult
          result="failure"
          text={serverErrorMessages(err, language)}
          buttonText={text("buttons.close")}
          clickCompleteHandler={() => setModal(null)}
        />
      );
    }
  };

  return (
    <>
      <div className={classes.top}>
        <div className={classes.header}>
          {DEPOSIT_ORDER_AVAILABLE ? (
            <Button className={classes.button} clickHandler={clickOrderHandler}>
              <div className={classes.plus}>+</div>
              &nbsp;&nbsp;{text("deposit.orderButton")}
            </Button>
          ) : (
              <div>&nbsp;</div>
            )}
          <Title
            text={deposit.productTitle}
            onClick={clickTitleHandler && clickTitleHandler(deposit.productTitle || text("deposit.title"))}
          />
        </div>
        <div className={classes.buttons}>
          {deposit.refillAllowed && (
            <Button className={classes.button} clickHandler={clickFillHandler}>
              <Icon name="fillCard" />
              &nbsp;&nbsp;{text("deposit.rechargeButton")}
            </Button>
          )}
          {deposit.earlyWithdrawlAllowed && (
            <Button className={classes.button} clickHandler={clickWithdrawHandler}>
              <Icon name="transactionCard" />
              &nbsp;&nbsp;{text("deposit.transactionButton")}
            </Button>
          )}
        </div>
      </div>
      <div className={classes.bottom}>
        <NavigationTabs className={classes.navigationTabs} tabs={tabs} activeTab={activeTab} changeTabHandler={value => setActiveTab(value)} />
        {activeTab === "history" && <ContractHistory fetchHandler={fetchDepositHistoryHandler}
          fetchPDFHandler={fetchDepositPDFHandler} />}
        {activeTab === "details" && (
          <ProductDetails
            classname={classes.productDetails}
            rows={[
              { label: `${text("deposit.details.name")}:`, value: deposit.productTitle },
              { label: `${text("deposit.details.number")}:`, value: deposit.legalNumber },
              { label: `${text("deposit.details.openingDate")}:`, value: deposit.startDate },
              {
                label: `${text("deposit.details.time")}:`, value: `${deposit.term.value} ${deposit.term.value === 1 ? text('deposit.details.termTypeDay') :
              /*deposit.term.type*/ text('deposit.details.termTypeDays')}`
              },
              { label: `${text("deposit.details.rate")}, %:`, value: deposit.currentInterestRate },
              { label: `${text("deposit.details.monthlyPayment")}, %:`, value: booleanToText(deposit.regularInterestPayment) },
              { label: `${text("deposit.details.capitalization")}, %:`, value: booleanToText(deposit.capitalization) },
              { label: `${text("deposit.details.rechargeable")}:`, value: booleanToText(deposit.refillAllowed) },
              {
                label: `${text("deposit.details.minReplenishmentAmount")}:`,
                value:
                  deposit.minRefillAmount || deposit.minRefillAmount === 0
                    ? `${localeAmount(deposit.minRefillAmount)} ${deposit.mainAccountCurrency}`
                    : "-"
              },
              {
                label: `${text("deposit.details.maxReplenishmentAmount")}:`,
                value:
                  deposit.maxRefillAmount || deposit.maxRefillAmount === 0
                    ? `${localeAmount(deposit.maxRefillAmount)} ${deposit.mainAccountCurrency}`
                    : "-"
              },
              { label: `${text("deposit.details.withdrawals")}:`, value: booleanToText(deposit.earlyWithdrawlAllowed) },
              { label: `${text("deposit.details.endDate")}:`, value: deposit.endDate },
              {
                label: `${text("deposit.details.automaticProlongation")}:`, value: (
                  <Checkbox
                    type={2}
                    value={deposit.autoProlongationEnabled}
                    isDisabled={(deposit.autoprolongationEnabledByProduct || 'false') === 'false'}
                    changeHandler={setDepositSettings}
                  />
                )
              }
            ]}
          />
        )}
      </div>
    </>
  );
};

Deposit.propTypes = {
  deposit: PropTypes.object,
  clickFillHandler: PropTypes.func,
  clickWithdrawHandler: PropTypes.func,
  clickOrderHandler: PropTypes.func,
  clickTitleHandler: PropTypes.func,
  readContracts: PropTypes.func,
};

Deposit.defaultProps = {
  deposit: {},
  clickFillHandler: () => { },
  clickWithdrawHandler: () => { },
  clickOrderHandler: () => { },
  clickTitleHandler: null,
  readContracts: () => { },
};

export default React.memo(Deposit);
