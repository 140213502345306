import localization from "../localization/index";

export const signupCardSchema = (language, password) => {
  const { required, emailErrorMessage, passwordErrorMessage, confirmedPasswordErrorMessage, wrong_card } = localization[language].errors;

  return {
    phone: [
      {
        validator: "length",
        args: {
          min: 13,
          max: 13
        },
        errorMessage: required
      }
    ],
    identifier: [
      {
        validator: "required",
        errorMessage: required
      },
      {
        validator: "length",
        args: {
          min: 16,
          max: 16
        },
        errorMessage: wrong_card
      },
      {
        validator: "card",
        errorMessage: wrong_card
      }
    ],
    name: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    surname: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    email: [
      {
        validator: "required",
        errorMessage: required
      },
      {
        validator: "email",
        errorMessage: emailErrorMessage
      }
    ],
    password: [
      {
        validator: "required",
        errorMessage: required
      },
      {
        validator: "password",
        errorMessage: passwordErrorMessage
      }
    ],
    confirmedPassword: [
      {
        validator: "required",
        errorMessage: required
      },
      {
        validator: "equals",
        args: password,
        errorMessage: confirmedPasswordErrorMessage
      }
    ]
  };
};

export const signupIbanSchema = (language, password) => {
  const { required, emailErrorMessage, passwordErrorMessage, confirmedPasswordErrorMessage, wrong_iban } = localization[language].errors;

  return {
    phone: [
      {
        validator: "length",
        args: {
          min: 13,
          max: 13
        },
        errorMessage: required
      }
    ],
    identifier: [
      {
        validator: "required",
        errorMessage: required
      },
      {
        validator: "length",
        args: {
          min: 29,
          max: 29
        },
        errorMessage: wrong_iban
      },
      {
        validator: "iban",
        errorMessage: wrong_iban
      }
    ],
    name: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    surname: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    email: [
      {
        validator: "required",
        errorMessage: required
      },
      {
        validator: "email",
        errorMessage: emailErrorMessage
      }
    ],
    password: [
      {
        validator: "required",
        errorMessage: required
      },
      {
        validator: "password",
        errorMessage: passwordErrorMessage
      }
    ],
    confirmedPassword: [
      {
        validator: "required",
        errorMessage: required
      },
      {
        validator: "equals",
        args: password,
        errorMessage: confirmedPasswordErrorMessage
      }
    ]
  };
};

export const loginSchema = language => {
  const { required } = localization[language].errors;

  return {
    phone: [
      {
        validator: "length",
        args: {
          min: 13,
          max: 13
        },
        errorMessage: required
      }
    ],
    password: [
      {
        validator: "required",
        errorMessage: required
      }
    ]
  };
};

export const passwordRecoveryCardSchema = language => {
  const { required, wrong_card } = localization[language].errors;

  return {
    phone: [
      {
        validator: "length",
        args: {
          min: 13,
          max: 13
        },
        errorMessage: required
      }
    ],
    account: [
      {
        validator: "required",
        errorMessage: required
      },
      {
        validator: "length",
        args: {
          min: 16,
          max: 16
        },
        errorMessage: wrong_card
      },
      {
        validator: "card",
        errorMessage: wrong_card
      }
    ]
  };
};

export const passwordRecoveryIbanSchema = language => {
  const { required, wrong_iban } = localization[language].errors;

  return {
    phone: [
      {
        validator: "length",
        args: {
          min: 13,
          max: 13
        },
        errorMessage: required
      }
    ],
    account: [
      {
        validator: "required",
        errorMessage: required
      },
      {
        validator: "length",
        args: {
          min: 29,
          max: 29
        },
        errorMessage: wrong_iban
      },
      {
        validator: "iban",
        errorMessage: wrong_iban
      }
    ]
  };
};

export const passwordSavingSchema = (language, password) => {
  const { required, passwordErrorMessage, confirmedPasswordErrorMessage } = localization[language].errors;

  return {
    code: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    password: [
      {
        validator: "required",
        errorMessage: required
      },
      {
        validator: "password",
        errorMessage: passwordErrorMessage
      }
    ],
    confirmedPassword: [
      {
        validator: "required",
        errorMessage: required
      },
      {
        validator: "equals",
        args: password,
        errorMessage: confirmedPasswordErrorMessage
      }
    ]
  };
};

export const passwordChangeSchema = (language, password) => {
  const { required, passwordErrorMessage, confirmedPasswordErrorMessage } = localization[language].errors;

  return {
    password: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    newPassword: [
      {
        validator: "required",
        errorMessage: required
      },
      {
        validator: "password",
        errorMessage: passwordErrorMessage
      }
    ],
    newConfirmedPassword: [
      {
        validator: "required",
        errorMessage: required
      },
      {
        validator: "equals",
        args: password,
        errorMessage: confirmedPasswordErrorMessage
      }
    ]
  };
};
