import React, { useContext, useState, useRef, useEffect } from "react";
import classes from "./InnerToOuterTransaction.module.scss";
import { connect } from "react-redux";
import { Title, Error, Label, Select, Input, Divider, Triangle, Button, ConfirmationCode, OperationResult } from "../../../../components/index";
import { languageContext, modalContext } from "../../../../context/index";
import isCurrency from "validator/lib/isCurrency";
import getParamFromUrl from "../../../../helpers/getParamFromUrl";
import { useError } from "../../../../hooks/index";
import { innerToOuterTransactionSchema, validationError, fieldErrorMessage } from "../../../../validation/index";
import * as API from "../../../../api/index";
import cn from "../../../../helpers/cn";
import serverErrorMessages from "../../../../helpers/serverErrorMessages";
import localeAmount from "../../../../helpers/localeAmount";
import { uahCardOptions } from "../../../../store/getters/index";
import * as actions from "../../../../store/actions/index";
import Tooltip from "react-tooltip-lite";
import { findContractByKey } from "../../../../helpers/contract";

const InnerToOuterTransaction = ({ history, cards, readContract }) => {
  const { language, text } = useContext(languageContext);
  const { setModal } = useContext(modalContext);

  const [isPrefaced, setIsPrefaced] = useState(false);
  const contractOptions = React.useMemo(() => [...uahCardOptions(cards)], [cards]);
  const [givingAccount, setGivingAccount] = useState(() => {
    const sender = getParamFromUrl("sender");
    return sender && uahCardOptions(cards).find(x => x.value === sender) ? sender : null;
  });
  const [cvv, setCvv] = useState("");
  const [gettingAccount, setGettingAccount] = useState("");
  const [amount, setAmount] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [commission, setCommission] = useState("");
  const [enrolledOperationId, setEnrolledOperationId] = useState(null);
  const [error, setError] = useError(null);
  const [step, setStep] = React.useState(null);

  // --------------------useState for requests - 3d-secure and function for 3D-secure-------------------
  const [id, setId] = useState(false);
  const [ascUrl, setAscUrl] = useState(null);
  const [monUrl, setMonUrl] = useState(null);
  const [termUrl, setTermUrl] = useState(null);
  const refIframe = useRef(null);
  const timer = useRef(null);

  const checkFrame = React.useCallback(
    async value => {
      if (refIframe.current?.contentDocument?.location?.href === value && refIframe.current.contentDocument.getElementById("param")) {
        clearInterval(timer.current);
        const paRes = refIframe.current.contentDocument.getElementById("param");
        const payload = { enrolledOperationId: id, paRes: paRes.textContent };

        try {
          let { data } = await API.postInnerToOuterTransactionCommit(payload);

          if (data.status === "CREATED") {
            setAscUrl(data.challengeUrl);
            setStep(data.nextStep);
          }

          if (data.status === "CREATED" && data.nextStep === "lookup") {
            const payload2 = {
              enrolledOperationId: data.id,
              otp: undefined
            };

            await new Promise(resolve => {
              setModal(
                <ConfirmationCode
                  clickContinueHandler={code => {
                    payload2.otp = code;
                    setModal(null);
                    resolve();
                  }}
                />
              );
            });

            data = (await API.postInnerToOuterTransactionCommit(payload2)).data;
          }

          if (data.status === "FAIL") {
            setError({ field: "global", message: serverErrorMessages({ response: { data } }, language) });
            setAscUrl(null);
            setStep(null);
          }

          if (data.status === "SUCCESS") {
            setModal(
              <OperationResult
                titleText={text("transactionSuccess.success")}
                buttonText={text("buttons.complete")}
                clickCompleteHandler={() => setModal(null)}
              />
            );
            history.push("/home/transactions");
          }
        } catch (err) {
          setError({
            field: "global",
            message: serverErrorMessages(err, language)
          });
        }
      }
    },
    [history, id, language, setError, setModal, text]
  );

  useEffect(() => () => clearInterval(timer.current), []);
  useEffect(() => {
    if (termUrl) {
      clearInterval(timer.current);
      timer.current = setInterval(() => checkFrame(termUrl), 200);
    }
  }, [checkFrame, termUrl]);
  // -----------------------------------------------------------------------------------

  const globalErrorMessage = fieldErrorMessage("global", error);

  const initialSubmit = async () => {
    const contract = findContractByKey(contractOptions, givingAccount);
    setError(null);
    const modifiedCvv = cvv.split(/_/).join("");
    const modifiedGettingAccount = gettingAccount.split(/\s|_/).join("");
    const error = validationError(innerToOuterTransactionSchema(language), {
      givingAccount,
      cvv: modifiedCvv,
      gettingAccount: modifiedGettingAccount,
      amount
    });
    if (error) {
      setError(error);
      return;
    }
    const payload = {
      srcCard: {
        contractReference: {
          providerId: contract.providerId,
          contractId: contract.contractId
        },
        cardId: contract.cardId,
        secureCode: cvv
      },
      currency: "UAH",
      destCard: {
        cardNumber: modifiedGettingAccount
      },
      amount: Math.round(amount * 100),
      customCallbackUrl: `${window.location.origin}/bank/secure3d/callback-url.jsp`
    };
    setIsFetching(true);
    try {
      const { data } = await API.postInnerToOuterTransactionEnroll(payload);
      // -----------------------3d-secure---------------------------------------
      setId(data.id);
      setTermUrl(`${window.location.origin}/bank/secure3d/callback-url.jsp`);
      setMonUrl(data.monitorUrl);
      setAscUrl(data.secure3DRequest.acsUrl);
      setStep(data.nextStep);

      // -----------------------------------------------------------------------
      setEnrolledOperationId(data.id);
      setCommission(`${localeAmount(data.operationConditions.commission)} ${data.currency}`);
      setIsPrefaced(true);
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
      setIsFetching(false);
      cancel();
    }
  };

  const cancel = () => {
    setGivingAccount(null);
    setCvv("");
    setGettingAccount("");
    setAmount("");
    setCommission("");
    setEnrolledOperationId(null);
    setIsPrefaced(false);
  };

  const submit = async () => {
    const contract = findContractByKey(contractOptions, givingAccount);
    const payload = {
      enrolledOperationId,
      otp: undefined
    };

    await new Promise(resolve => {
      setModal(
        <ConfirmationCode
          clickContinueHandler={code => {
            payload.otp = code;
            setModal(null);
            resolve();
          }}
        />
      );
    });

    try {
      setIsFetching(true);
      const { data } = await API.postInnerToOuterTransactionCommit(payload);

      if (data.status === "FAIL") {
        setError({
          field: "global",
          message: serverErrorMessages(
            {
              // wrong_parameters: "Невірно заповнено дані. Будь ласка, перевірте заповнення",
              response: {
                data
              }
            },
            language
          )
        });
        setIsFetching(false);
        cancel();
        return;
      }
      try {
        await readContract(contract.providerId, contract.contractId);
      } catch (error) {
        console.log("Unable to update the contract", error);
      }
      setIsFetching(false);
      setModal(
        <OperationResult titleText={text("transactionSuccess.success")} buttonText={text("buttons.complete")} clickCompleteHandler={() => setModal(null)} />
      );
      history.push("/home/transactions");
    } catch (err) {
      // console.log(err, 'errr')
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
      setIsFetching(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Title text={text("innerToOuterTransaction.title")} />
      <div className={classes.content}>
        <div className={classes.subtitle}>{text("innerToOuterTransaction.sender")}:</div>
        <Label>{text("innerToOuterTransaction.cardNumber")}</Label>
        <Select
          errorClassName={classes.error}
          style={{ zIndex: 1, marginBottom: 20 }}
          options={contractOptions}
          value={givingAccount}
          changeHandler={value => setGivingAccount(value)}
          placeholder={text("innerToOuterTransaction.select.placeholder")}
          errorMessage={fieldErrorMessage("givingAccount", error)}
          isDisabled={isPrefaced}
        />
        <div className={cn(classes.row, classes.rowRight)}>
          <div>
            <Input
              type="password"
              id="cvv"
              errorClassName={classes.error}
              style={{ marginBottom: 20, width: 200 }}
              labelText={
                <>
                  CVV2/CVC2&nbsp;
                  <Tooltip
                    className={classes.target}
                    color="black"
                    background="lightgrey"
                    content={
                      <span>
                        {text("innerToOuterTransaction.tooltipCVV_1")}
                        <br />
                        {text("innerToOuterTransaction.tooltipCVV_2")}
                        <br />
                        {text("innerToOuterTransaction.tooltipCVV_3")}
                      </span>
                    }
                  >
                    <div className={classes.icon}>?</div>
                  </Tooltip>
                  {/* <div style={{Width:'150px', height:'100px',backgroundColor:'lightGrey',padding:'5px',position:'relative',zIndex:'5'}}>
                    {text("innerToOuterTransaction.tooltipCVV")}
                    </div> */}
                </>
              }
              value={cvv}
              changeHandler={value => setCvv(value)}
              placeholder={"***"}
              maxLength={3}
              errorMessage={fieldErrorMessage("cvv", error)}
              isDisabled={isPrefaced}
            />
          </div>
        </div>
        <Divider />
        <Triangle style={{ margin: "auto" }} />
        <div className={classes.subtitle}>{text("innerToOuterTransaction.recipient")}:</div>
        <Label>{text("innerToOuterTransaction.cardNumber")}</Label>
        <Input
          errorClassName={classes.error}
          style={{ marginBottom: 20 }}
          value={gettingAccount}
          changeHandler={value => setGettingAccount(value)}
          placeholder="**** **** **** ****"
          mask="9999 9999 9999 9999"
          errorMessage={fieldErrorMessage("gettingAccount", error)}
          isDisabled={isPrefaced}
        />
        <Divider style={{ marginBottom: 10 }} />
        <Label>{text("innerToOuterTransaction.transferAmount")}</Label>
        <div className={classes.row}>
          <div>
            <Input
              style={{ width: 250 }}
              errorStyle={{ marginTop: 20 }}
              value={amount}
              changeHandler={value => setAmount(value)}
              units="UAH"
              commaIsReplacedWithDot
              validator={value =>
                isCurrency(value, {
                  digits_after_decimal: [0, 1, 2]
                })
              }
              errorMessage={fieldErrorMessage("amount", error)}
              isDisabled={isPrefaced}
            />
          </div>
          {commission && (
            <div className={classes.commisionText}>
              {text("innerToOuterTransaction.commission")}: {commission}
            </div>
          )}
        </div>

        {step === "3d2" && (
          <div className={classes.row} style={{ marginBottom: 20, display: "none" }}>
            <iframe title="monitor" src={monUrl} />
            <iframe title="callback" src={ascUrl} ref={refIframe} />
          </div>
        )}
        {step === "challenge" && (
          <div className={classes.row} style={{ marginBottom: 20 }}>
            <iframe title="challenge" src={ascUrl} ref={refIframe} height="500" width="100%" style={{ border: "none" }} />
          </div>
        )}

        <div className={classes.text}>
          {text("innerToOuterTransaction.pressTransfer")}{" "}
          <a className={classes.link} href="https://pay4-api-v2.payforce.net.ua/public/public_offer.pdf" target="_blank" rel="noopener noreferrer">
            {text("innerToOuterTransaction.conditions")}
          </a>
        </div>
        {globalErrorMessage && <Error className={classes.globalError} message={globalErrorMessage} />}
        <div className={classes.buttons}>
          <Button
            style={{
              width: 200,
              height: 48,
              color: "#241F5A",
              textDecoration: "underline",
              fontWeight: "bold"
            }}
            clickHandler={!isPrefaced ? history.goBack : cancel}
          >
            {text(!isPrefaced ? "buttons.back" : "buttons.cancel")}
          </Button>
          <Button
            style={{
              width: 200,
              height: 48,
              color: "white",
              backgroundColor: "#241F5A",
              borderColor: "#241F5A",
              fontWeight: "bold"
            }}
            clickHandler={!isPrefaced ? initialSubmit : submit}
            isDisabled={isFetching}
          >
            {text(!isPrefaced ? "buttons.continue" : "innerToOuterTransaction.buttonConfirm")} &rarr;
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ contracts }) => ({ cards: contracts.cards });
const mapDispatchToProps = dispatch => ({
  readContract: (providerId, contractId) => dispatch(actions.readContract(providerId, contractId))
});

export default connect(mapStateToProps, mapDispatchToProps)(InnerToOuterTransaction);
