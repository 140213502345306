import React from "react";
import classes from "./NavigationLink.module.scss";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import insertConditionally from "../../../helpers/insertConditionally";

const NavigationLink = ({ style, path, icon, iconActive, textColorIsChanging, text, clickHandler }) => {
  return (
    <div className={classes.wrapper} style={style} onClick={clickHandler}>
      {path ? (
        <NavLink
          to={path}
          style={{ backgroundImage: `url('${icon}')` }}
          activeStyle={{ backgroundImage: `url('${iconActive}')`, ...insertConditionally(textColorIsChanging, { color: "#7ABD43" }) }}
        >
          {text}
        </NavLink>
      ) : (
        <div style={{ backgroundImage: `url('${icon}')` }}>{text}</div>
      )}
    </div>
  );
};

NavigationLink.propTypes = {
  style: PropTypes.object,
  path: PropTypes.string,
  icon: PropTypes.string,
  iconActive: PropTypes.string,
  text: PropTypes.string,
  textColorIsChanging: PropTypes.bool,
  clickHandler: PropTypes.func
};

NavigationLink.defaultProps = {
  style: {},
  path: null,
  icon: "",
  iconActive: "",
  text: "",
  textColorIsChanging: false,
  clickHandler: null
};

export default NavigationLink;
