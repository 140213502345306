import React, { useContext } from "react";
import classes from "./TransactionSuccess.module.scss";
import PropTypes from "prop-types";
import { Icon, Button } from "../../index";
import { languageContext } from "../../../context/index";

const TransactionSuccess = ({ clickCancelHandler, clickAddHandler, clickRegularHandler }) => {
  const { text } = useContext(languageContext);

  return (
    <div className={classes.wrapper}>
      <Icon name="success" />
      <div className={classes.text}>
        {text("transactionSuccess.gratitude")}!
        <br />
        {text("transactionSuccess.success")}.
      </div>
      <div className={classes.buttons}>
        <Button className={classes.button_ok} clickHandler={clickCancelHandler}>
          Ok
        </Button>
        {clickAddHandler && (
          <Button className={classes.button_ext} clickHandler={clickAddHandler}>
            {text("buttons.addToTemplates")}
          </Button>
        )}
      </div>
      <div className={classes.buttons}>
        {clickRegularHandler && (
          <Button className={classes.button_full} clickHandler={clickRegularHandler}>
            {text("buttons.createRegular")}
          </Button>
        )}
      </div>
    </div>
  );
};

TransactionSuccess.propTypes = {
  clickCancelHandler: PropTypes.func,
  clickAddHandler: PropTypes.func,
  clickRegularHandler: PropTypes.func
};

TransactionSuccess.defaultProps = {
  clickCancelHandler: () => {},
  clickAddHandler: () => {},
  clickRegularHandler: () => {}
};

export default TransactionSuccess;
