const ua = {
  buttonCancel: "Закрити",
  // CONTAINERS
  home: {
    placeholders: {
      accounts: "У вас немає рахунків",
      credits: "У вас немає кредитів",
      deposits: "У вас немає депозитів"
    }
  },
  financialAssistant: {
    startPeriod: "Початок періоду:",
    finishPeriod: "Кінець періоду:",
    defaultText: "Немає даних для відображення за вказані дати",
    totalCostsPeriod: "Всього витрат у періоді:",
    costsByCategory: "Витрати по категоріям:",
    totalIncomePeriod: "Всього доходів у періоді:",
    incomeByCategory: "Доходи по категоріям:",
    category: {
      backToStatistic: "Назад до статистики",
      changeTitle: "Зміна категорії",
      assignCategory: "Присвоїти категорію вручну:"
    },
    detailedInfoAvailableError: "Детальна інформація щодо операцій доступна з: ",
    noDetailedInfoError: "Детальна інформація щодо операцій відсутня"
  },
  regularPayments: {
    placeholder: "Пошук регулярних платежів",
    addPaymentButton: "Додати новий платіж",
    title: "Мої регулярні платежі",
    noPayments: "Регулярні платежі відсутні. Створити можна на основі вже виконаних операцій в меню “Історія операцій”",
    noPaymentsBySearch: "Регулярні платежі по даному пошуку відсутні",
    edit: "Редагувати платіж",
    history: "Історія",
    detailed: "Деталі платежу",
    active: "Активувати",
    deactive: "Деактивувати",
    createRegularPaymentTitle: "Створення регулярного платежу",
    editRegularPaymentTitle: "Редагування регулярного платежу",
    paymentHistory: {
      title: "Історія регулярних платежів",
      period: "Період",
      sendReceipt: "Відправити квитанцію",
      detailsOperation: "Деталі операції",
      success: "Успішно",
      fail: "Неуспішно",
      noPaymentsHistory: "Історія операцій по регулярним платежам відсутня"
    },
    deactivate: {
      title: "Деактивація регулярного платежу",
      text: "Ви дійсно бажаєте деактивувати регулярний платіж?",
      buttonDeactivate: "Деактивувати",
      textSuccess: "Ваш регулярний платіж деактивовано!",
      textFail: "Регулярний платіж не деактивовано."
    },
    activate: {
      title: "Активація регулярного платежу",
      text: "Ви дійсно бажаєте активувати регулярний платіж?",
      buttonActivate: "Активувати",
      textSuccess: "Ваш регулярний платіж активовано!",
      textFail: "Регулярний платіж не активовано."
    },
    deleteModals: {
      title: "Видалення регулярного платежу",
      text: "Увага! Регулярний платіж буде видалено без можливості відновлення.",
      buttonDelete: "Видалити",
      textDeletedSuccess: "Ваш регулярний платіж видалено!",
      textDeletedFail: "Регулярний платіж не видалено."
    },
    detailedModals: {
      title: "Деталі регулярного платежу",
      titleOperation: "Деталі операції",
      active: "Активний",
      deactive: "Деактивований",
      status: "Статус"
    },
    update: {
      textSuccess: "Ваш регулярний платіж оновлено і збережено!",
      textFail: "Регулярний платіж не оновлено."
    }
  },
  feedback: {
    title: {
      initial: "Зворотній зв'язок",
      final: "Запит надіслано!"
    },
    text: "Замовити дзвінок"
  },
  contacts: {
    title: "Відділення та банкомати",
    select: {
      placeholder: "Тип відділення",
      options: {
        atms: "Банкомати",
        departments: "Відділення"
      }
    }
  },
  cards: {
    title: "Картки",
    createNewCardText: "Відкрити нову картку",
    modal: {
      pin: {
        question: 'Ви впевнені, що хочете змінити ПІН-код?',
        success: 'Ваш ПІН-код було змінено',
        failure: 'Сталася помилка при зміні ПІН-коду. Спробуйте будь-ласка пізніше',
        action: 'Введіть новий ПІН-код',
      },
      titleText: {
        success: "Назву змінено",
        blocked: "Картку заблоковано",
        unblocked: "Картку розблоковано",
        changeLimits: "Ліміти змінено",
        changeLimitsWithNotification: "Вказані Вами ліміти перевищують максимально можливі. Будуть встановлені максимально дозволені значення",
        unSuccessOperationTitle: "Неуспішно. Будь ласка, спробуйте пізніше або зв'яжіться з нами за тел. 0 800 300 170"
      }
    }
  },
  card: {
    title: "Картка",
    rechargeButton: "Поповнити картку",
    transferButton: "Між своїми рахунками",
    transactionButton: "Переказ на інші рахунки",
    fuckingButton: "Змінити ліміти по операціям",
    limitsButton: "Змінити ліміти по операціям",
    creditButton: "Заявка на встановлення/зміну кредитного ліміту",
    blockButton: "Заблокувати картку",
    unblockButton: "Розблокувати картку",
    changePinButton: "Змінити ПІН-код",
    tabs: {
      history: "Історія операцій",
      details: "Детальна інформація"
    },
    details: {
      number: "Номер договору",
      validTo: "Картка дійсна до",
      status: "Статус картки",
      active: "Активна",
      inactive: "Неактивна",
      currentBalance: "Поточний залишок",
      creditLimit: "Кредитний ліміт",
      debt: "Заборгованість",
      availableFunds: "Доступні кредитні кошти",
      interestRate: "Відсоток на залишок",
      accountNumber: "№ Рахунку",
      cvvCode: "CVV - код",
      cardType: "Тип картки",
      iban: {
        copy: 'Скопіювати номер рахунку',
        success: 'Номер рахунку скопійовано',
      },
      pan: {
        copy: 'Cкопіювати номер картки',
        success: 'Номер картки скопійовано',
      },
      cvv: {
        copy: 'Cкопіювати CVV код',
        success: 'CVV код скопійовано',
      },
      limit: {
        balance: "Доступний залишок",
        ownFunds: "Власні кошти",
        currentInterestRateFormatted: "Відсоток на залишок, %",
        notUsedCreditAmountFormatted: "Доступний Кредитний ліміт",
        creditLimitFormatted: "Встановлений Кредитний ліміт",
        usedCreditAmountFormatted: "Використано кредитних коштів",
        loanDealInterestRate: "Діюча відсоткова ставка за кредитом",
        endGracePeriodDate: "Дата закінчення пільгового періоду",
        nextPaymentAmount: "Обов'язковий мінімальний платіж (ОМП)",
        mandatoryMonthlyPaymentDeadlineDate: "Гранична дата сплати ОМП",
        legalNumber: "Номер договору",
        expiry: "Картка дійсна до",
        status: "Статус картки",
        iban: "Рахунок",
        cardTypeFullName: "Тип картки",
        perMonth: "на місяць",
        creditLimit: "Кредитний ліміт"
      }
    }
  },
  cardCredit: {
    title: "Заявка на встановлення/зміну кредитного ліміту",
    limit: "Встановлений Кредитний ліміт",
    options: {
      type1: "Встановити/підвищити кредитний ліміт на картку",
      type2: "Знизити кредитний ліміт"
    },
    amount: "Бажаний Кредитний ліміт",
    type: "Тип змін",
    min: "Мінімальна сума",
    max: "Максимальна сума",
    zero: "Для скасування кредитного ліміту",
    submit: "Замовити",
    modal: {
      titleText: "Дякуємо",
      text: "Вашу заявку відправлено"
    }
  },
  cardOrder: {
    title: "Замовлення нової картки",
    labels: {
      cardType: "Тип картки",
      paymentSystem: "Платіжна система",
      card: "Картка",
      currency: "Валюта"
    },
    options: {
      cardType1: "Картка Мрія",
      currency1: "Гривня"
    },
    modal: {
      titleText: "Дякуємо",
      text: "Вашу заявку відправлено"
    }
  },
  accounts: {
    title: "Рахунки",
    modal: {
      titleText: {
        success: "Назву змінено",
        unSuccessOperationTitle: "Неуспішно. Будь ласка, спробуйте пізніше або зв'яжіться з нами за тел. 0 800 300 170"
      }
    }
  },
  account: {
    title: "Поточний рахунок",
    createNewAccountText: "Відкрити новий рахунок",
    tabs: {
      history: "Історія операцій",
      details: "Детальна інформація"
    },
    details: {
      name: "Назва рахунку",
      contractNumber: "Номер договору",
      accountNumber: "Номер рахунку",
      interestRate: "Відсоток на залишок",
      openingDate: "Дата відкриття",
      time: "Термін без врахування пролонгації",
      rechargeable: "Можливість поповнення",
      balance: "Залишок",
      replenishmentAmount: "Макс. сума поповнення",
      withdrawals: "Дострокове зняття коштів",
      endDate: "Дата закінчення"
    }
  },
  requisites: {
    details: {
      params: "Скопіювати реквізити для поповнення",
      button: "Реквізити",
    },
    modal: {
      title: "Реквізити поповнення рахунку",
      confirm: "Скопіювати",
      cancel: "Відмінити",
      success: "Реквізити скопійовано",
    },
  },
  credits: {
    title: "Кредити",
    modal: {
      titleText: {
        success: "Назву змінено",
        unSuccessOperationTitle: "Неуспішно. Будь ласка, спробуйте пізніше або зв'яжіться з нами за тел. 0 800 300 170"
      }
    }
  },
  credit: {
    closeCreditButton: "Погасити кредит",
    tabs: {
      history: "Історія операцій",
      details: "Детальна інформація"
    },
    details: {
      name: "Назва кредиту",
      number: "Номер",
      rate: "Ставка",
      monthlyPayment: "Щомісячна комісія",
      debt: "Загальна сума заборгованості по кредиту",
      term: "Термін дії кредиту",
      minRepaymentAmount: "Мінімальна сума до погашення",
      date: "Дата щомісячного планового погашення"
    }
  },
  deposits: {
    title: "Депозити",
    modal: {
      changeProlongation: {
        success: "Операція виконана успішно",
        unSuccessOperationTitle: "Неуспішно. Будь ласка, спробуйте пізніше або зв'яжіться з нами за тел. 0 800 300 170"
      },
      titleText: {
        success: "Назву змінено",
        unSuccessOperationTitle: "Неуспішно. Будь ласка, спробуйте пізніше або зв'яжіться з нами за тел. 0 800 300 170"
      }
    }
  },
  deposit: {
    rechargeButton: "Поповнити депозит",
    transactionButton: "Зняти депозит",
    orderButton: "Відкрити новий депозит",
    tabs: {
      history: "Історія операцій",
      details: "Детальна інформація"
    },
    details: {
      name: "Назва депозиту",
      number: "Номер",
      openingDate: "Дата відкриття",
      time: "Строк без врахування пролонгації",
      termTypeDay: "д.",
      termTypeDays: "д.",
      rate: "Ставка",
      monthlyPayment: "Щомісячна виплата",
      capitalization: "Капіталізація",
      rechargeable: "Можливість поповнення",
      minReplenishmentAmount: "Мін. сума поповнення",
      maxReplenishmentAmount: "Макс. сума поповнення",
      withdrawals: "Дострокове зняття коштів",
      endDate: "Дата закінчення",
      automaticProlongation: "Автоматична пролонгація",
      yes: "Так",
      no: "Немає"
    }
  },
  depositOrderConditions: {
    title: "Підбір депозиту",
    labels: {
      currency: "Валюта",
      amount: "Сума вкладу",
      term: "Термін депозиту"
    },
    term: "міс.",
    termTypeM: "міс.",
    termTypeD: "д.",
    termTypeD95: "З місяці (95 днів)",
    noDepositByThisParams: "Неможливо підібрати депозит по заданим параметрам",
    tableLable: {
      annualRate: "Річна ставка",
      replenishment: "Поповнення",
      autoProlongation: "Автопролонгація",
      partialWithdrawal: "Часткове зняття",
      payment: "Виплата",
      INSTANT: "В кінці строку",
      MONTH: "Щомісяця",
      amountIncome: "Сума доходу"
    },
    buttons: {
      approve: "Підібрати"
    }
  },
  depositOrderNew: {
    title: "Депозит",
    labels: {
      currency: "Валюта",
      amount: "Сума депозиту",
      term: "Термін",
      interestRate: "Розмір % ставки",
      prolongation: "Автопролонгація",
      refill: "Поповнення",
      capitalization: "Капіталізація %",
      interestPayment: "Сплата %",
      account: "Рахунок для поповнення/виплат",
      confirm1: "Підтверджую, що ознайомленний та погоджуюсь",
      confirm1_1: "з Публічною пропозицією,",
      confirm2: "Довідкою Фонду",
      confirm2_1: "та умовами договору",
      licence: "Свідоцтво учасника ФГВФО №106 від 06.11.2012",
      commission: "Комісія",
      email: "Імейл, на який буде надіслано депозитний договір та довідку ФГВФО",
      isProlongation: "Пролонгувати депозит",
      confirm3: "Підтверджую, що паспортні дані, надані до Банку, не змінились.",
      confirm4: "Підтверджую, що дані, надані до Банку щодо мого статусу приватного підприємця/відсутності такого статусу, не змінились."
    },
    table: {
      title: "Основні умови договору банківського вкладу, що буде відкрито",
      subTitle: "Перевірте, будь ласка, дані:",
      row1: "Вид вкладу",
      row2: "Розмір вкладу, валюта",
      row3: "Строк зберігання коштів",
      row4: "Нарахування Банком процентів на Вклад здійснюється з розрахунку відсотків річних",
      row5: "Капіталізація відсотків",
      row6: "Дострокове припинення вкладу",
      row7: "Штрафна процентна ставка при достроковому поверненні",
      row8: "Можливість автоматичного продовження строку зберігання коштів",
      row9: "Часткове знаття коштів з вкладного (депозитного) рахунку",
      row10: "Можливість поповнення вкладу",
      row11: "Виплата процентів за Вкладом здійснюється",
      row12: "Мінімальна сума поповнення",
      row12_1: "Сума максимального поповнення",
      row13: "Номер рахунку для зарахування процентів та повернення суми Вкладу",
      row14: "Імейл, на який буде надіслано депозитний договір та довідку ФГВФО"
    },
    placeholder: "Виберіть рахунок",
    buttons: {
      approve: "Оформити",
      open: "Відкрити депозит"
    },
    modal: {
      titleText: "Дякуємо!",
      text: "Ваш депозит успішно оформлений"
    }
  },
  profile: {
    title: "Мій кабінет",
    templates: "Мої шаблони",
    card: "Картки",
    accounts: "Рахунки",
    credits: "Кредити",
    deposits: "Депозити"
  },
  transactions: {
    title: "Перекази"
  },
  transactionOptions: {
    innerToInner: "Всередині АП Банку",
    innerToOuter: "На картку іншого банку",
    outerToInner: "На свій рахунок",
    outerToOuter: "З картки на картку інших банків",
    innerToCustom: "За реквізитами",
    exchange: "Обмін валют",
    payments: "Платежі",
    title: "Перекази"
  },
  exchangeTransaction: {
    transaction_with_commission: 'З врахуванням комісії',
    transaction_without_commission: 'За вирахуванням комісії',
    noUAH: "Для виконання операції відсутній рахунок у необхідній валюті",
    alreadyCreated: "Рахунок вже відкритий",
    title: "Обмін валюти",
    sender: "Списати з рахунку / картки",
    recipient: "Зарахувати на рахунок / картку",
    select: {
      placeholder: "Виберіть рахунок"
    },
    debitAmount: "Сума списання",
    creditAmount: "Сума зарахування",
    buttonUpdate: "Оновити курс",
    buttonCancel: "Відмінити",
    buttonConfirm: "Обміняти",
    labels: {
      fromMyAccount: "З рахунку чи картки",
      toAccount: "На рахунок чи картку"
    },
    rate: "Курс обміну",
    ttl: "Час дії курсу",
    placeholder: "Виберіть рахунок",
    openAccount: "Відкрити рахунок в",
    failureModal: "Вибачте, купівля валюти онлайн обмежена вимогами НБУ, скористайтесь касами банку"
  },
  accountOrder: {
    title: "Відкрити рахунок в",
    labels: {
      email: "Електронна адреса, на яку буде надіслано договір про відкриття рахунку",
      confirm1: "Підтверджую, що ознайомлений(а) та погоджуюсь з",
      confirm1_1: "Публічною пропозицією",
      confirm1_2: "Тарифами та ставками",
      confirm1_3: "Довідкою Фонду",
      confirm1_4: "та умовами",
      confirm1_5: "договору",
      confirm2: "Довідкою Фонду",
      confirm2_1: "та умовами договору",
      confirm3: "Підтверджую, що паспортні дані, надані до Банку, не змінились.",
      confirm4: "Підтверджую, що дані, надані до Банку щодо мого статусу приватного підприємця/відсутності такого статусу, не змінились."
    },
    buttons: {
      open: "Відкрити рахунок"
    },
    modal: {
      titleText: "Дякуємо!",
      text: "Ваш рахунок успішно оформлений"
    }
  },
  innerToInnerTransaction: {
    title: "Переказ всередині АП Банку",
    fromMyAccount: "З рахунку чи картки",
    toMyAccount: "На рахунок чи картку",
    transferAmount: "Сума переказу",
    commission: "Комісія",
    buttonCancel: "Відмінити",
    buttonConfirm: "Переказати",
    select: {
      placeholder: "Виберіть рахунок"
    },
    pressTransfer: "Натиснувши «Переказати», я приймаю",
    conditions: "умови публічної пропозиції"
  },
  innerToOuterTransaction: {
    title: "Переказ на картку інших банків",
    sender: "Відправник",
    tooltipCVV_1: "Введіть CVV/CVC код карти відправника. Код",
    tooltipCVV_2: "знаходиться на паперовій стрічці на зворотній",
    tooltipCVV_3: "стороні карти та складається з 3 цифр.",
    cardNumber: "Номер картки",
    recipient: "Отримувач",
    transferAmount: "Сума переказу",
    commission: "Комісія",
    pressTransfer: "Натиснувши «Переказати», я приймаю",
    conditions: "умови публічної пропозиції",
    buttonCancel: "Відмінити",
    buttonConfirm: "Переказати",
    select: {
      placeholder: "Виберіть картку"
    }
  },
  outerToInnerTransaction: {
    title: "Переказ на мій рахунок",
    sender: "Відправник",
    cardNumber: "Номер картки",
    date: "Дата",
    recipient: "Отримувач",
    select: {
      placeholder: "Виберіть рахунок"
    },
    transferAmount: "Сума переказу",
    commission: "Комісія",
    pressTransfer: "Натиснувши «Переказати», я приймаю",
    conditions: "умови публічної пропозиції",
    buttonCancel: "Відмінити",
    buttonConfirm: "Переказати",
    options: {
      innerToInner: "З власного рахунку",
      outerToInner: "З картки іншого банку",
      credit: "Кредит",
      deposit: "Депозит"
    },
    labels: {
      type: "Тип",
      fromMyAccount: "З рахунку чи картки",
      toAccount: "На рахунок чи картку"
    },
    placeholder: "Виберіть рахунок"
  },
  outerToOuterTransaction: {
    title: "Переказ з картки на картку інших банків",
    sender: "Відправник",
    cardNumber: "Номер картки",
    date: "Дата",
    recipient: "Отримувач",
    select: {
      placeholder: "Виберіть рахунок"
    },
    transferAmount: "Сума переказу",
    commission: "Комісія",
    pressTransfer: "Натиснувши «Переказати», я приймаю",
    conditions: "умови публічної пропозиції",
    buttonCancel: "Відмінити",
    buttonConfirm: "Переказати"
  },
  innerToCustomTransaction: {
    title: "Переказ за реквізитами",
    fromAccount: "З рахунку чи картки",
    recipientName: "Найменування отримувача",
    receiverPlaceholder: "ПІБ або найменування отримувача",
    mfo: "Найменування банку",
    mfoPlaceholder: "Введіть номер",
    taxNumber: "ІПН / ЄДРПОУ",
    beneficiaryNumber: "Рахунок отримувача",
    beneficiaryNumberTip: "Для України IBAN складається з 29 літерно-цифрових символів: ",
    beneficiaryNumberTip2: "код України «UA» — 2 літери; контрольний розряд — 2 цифри;",
    beneficiaryNumberTip3: "код банку — 6 цифр; рахунок —  від 5 до 19",
    taxNumberTip: "Допустимі значення поля: код ЄДРПОУ / ІПН /",
    taxNumberTip2: "номер паспорта ID-картки / серія (великі літери кирилицею)",
    taxNumberTip3: "та номер паспорта, пробіли не допускаються",
    paymentPurpose: "Призначення платежу",
    paymentPurposePlaceholder: "Введіть текст",
    paymentPurposeWarnHolder: "Будь ласка, детально вкажіть мету операції",
    transferAmount: "Сума переказу",
    select: {
      placeholder: "Виберіть рахунок"
    },
    commission: "Комісія",
    buttonCancel: "Відмінити",
    buttonConfirm: "Переказати",
    pressTransfer: "Натиснувши «Переказати», я приймаю",
    conditions: "умови публічної пропозиції",
    paymentTypeCode: "Код виду сплати",
    additionalPaymentInfo: "Додаткова інформація про платіж",
    actualPayerName: "ПІБ фактичного платинка",
    actualPayerTaxIdOrPassport: "ІПН/паспорт фактичного платника",
    actualPayerPlaceholder: "Заповніть у разі сплати за третю особу",
  },
  templates: {
    placeholder: "Пошук шаблонів",
    addTemplateButton: "Додати новий шаблон",
    title: "Мої шаблони",
    noTemplates: "Шаблони відсутні",
    modals: {
      createSuccess: {
        titleText: "Дякуємо",
        text: "Ваш шаблон успішно створено"
      },
      updateSuccess: {
        titleText: "Дякуємо",
        text: "Ваш шаблон успішно оновлено"
      },
      deleteSuccess: {
        titleText: "Дякуємо",
        text: "Ваш шаблон успішно видалено"
      }
    }
    // communityServicesTitle: "Комунальні послуги",
    // other: "Інші",
  },
  history: {
    title: "Історія операцій",
    tabs: {
      financial: "Фінансові",
      service: "Сервісні",
      authorization: "Авторизаційні"
    },
    receipt: {
      sendReceipt: "Відправити квитанцію",
      downloadReceipt: "Завантажити квитанцію",
      sendReceiptSuccess: "Вашу квитанцію відправлено на вказаний E-mail",
      sendReceiptFail: "Вашу квитанцію не було відправлено на вказаний E-mail"
    },
    buttons: {
      saveTemplate: "Зберегти шаблон",
      createRegularPayment: "Створити регулярний платіж",
      repeatOperation: "Повторити операцію"
    }
  },
  payments: {
    title: "Послуги та платежі",
    placeholder: "Введіть назву компанії",
    inputLable: "Я хочу сплатити:",
    success: "Ваша операція виконана успішно!",
    failure: "Ваша операція не виконана",
    processing: "Ваша операція знаходиться в процесі обробки"
  },
  settings: {
    title: "Налаштування",
    avatar: {
      tooltip_add: "Додати",
      tooltip_change: "Змінити",
      save: "Зберегти",
      delete: "Видалити"
    },
    personalData: {
      dataTitle: "Особисті дані",
      labelText: "ПІБ"
    },
    contacts: {
      dataTitle: "Контактні дані",
      phoneNumber: "Номер телефону",
      emailAddress: "Електронна адреса",
      loginForEntry: "Логін для входу"
    },
    password: {
      dataTitle: "Пароль",
      currentPassword: "Поточний пароль",
      newPassword: "Новий пароль",
      confirmationPassword: "Підтвердження нового пароля",
      passwordInformation: "Мінімум 6 символів (латиниця, хоча б одна мала і велика буква та одна цифра"
    },
    modal: {
      titleText: "Пароль змінено",
      text: "Вас буде перенаправлено на сторінку логіну"
    }
  },
  signup: {
    title: "Реєстрація нового користувача",
    phoneNumber: "Номер телефону",
    typeOfId: "Тип ідентифікатора",
    select: {
      cardNumber: "Номер картки",
      dealNumber: "Номер договору"
    },
    bankAccount: "Номер картки або IBAN",
    inputIdentificator: "Ідентифікатор",
    name: "Ім'я",
    lastName: "Прізвище",
    email: "Електронна адреса",
    password: "Ваш пароль",
    confirmPassword: "Повторіть пароль",
    passwordInformation: "Мінімум 6 символів (латиниця, хоча б одна мала і велика буква та одна цифра",
    familiar: "Підтверджую, що ознайомлений та погоджуюсь з",
    familiarNext: "умовами Угоди з банком",
    familiar2: "та",
    familiarNext2: "Довідкою Фонду",
    buttonBack: "Назад",
    buttonRegistration: "Зареєструватись"
  },
  login: {
    title: "Вхід в систему",
    loginInput: "Логін",
    passwordInput: "Пароль",
    passwordRecovery: "Я забув пароль",
    buttonConfirm: "Увійти",
    buttonRegistration: "Реєстрація",
    error: {
      loginError: "Логін не існує",
      errorPassword: "Невірний логін або пароль"
    }
  },
  passwordRecovery: {
    title: "Відновлення паролю",
    phoneNumber: "Введіть номер Вашого телефону",
    cardNumber: "Номер картки",
    bankAccount: "Номер картки або IBAN",
    email: "E-mail",
    button: "Відновити пароль"
  },
  passwordSaving: {
    title: "Відновлення паролю",
    codeSMS: "Введіть код з СМС",
    newPassword: "Новий пароль",
    confirmPassword: "Підтвердження паролю",
    passwordInformation: "Мінімум 6 символів (латиниця, хоча б одна мала і велика буква та одна цифра",
    buttonSave: "Зберегти пароль"
  },
  //COMPONENTS
  header: {
    phoneDescription: "безкоштовно по Україні",
    feedback: "Зателефонуйте мені",
    department: "Відділення та банкомати",
    exchange: "Конвертер валют"
  },
  navigationHorizontal: {
    cards: "Картки",
    accounts: "Рахунки",
    credits: "Кредити",
    deposits: "Депозити"
  },
  navigationVertical: {
    myCabinet: "Мій кабінет",
    transactions: "Перекази",
    payments: "Послуги та платежі",
    templates: "Мої шаблони",
    history: "Історія операцій",
    financialAssistant: "Фінансовий помічник",
    settings: "Налаштування",
    exit: "Вийти з кабінету",
    regularPayment: "Регулярний платіж",
    news: "Новини банку"
  },
  news: {
    title: "Новини банку"
  },
  footer: {
    licence: "Ліцензія НБУ № 241 від 16.02.2016",
    depositSecurity: "Ваш депозит захищено",
    fond: "Фондом гарантування вкладів фізичних осіб",
    securityText: "Всі права захищені.",
    bankName: "АТ «АГРОПРОСПЕРІС БАНК»"
  },
  exchange: {
    buy: "Купівля",
    sell: "Продаж",
    bank: "Курси в касах банку",
    central: "Курси НБУ",
    online: "Курси онлайн обміну",
    button: "Обмін валют"
  },
  converter: {
    title: "Конвертер валют",
    labels: {
      give: "Віддаю",
      get: "Отримую",
      currency: "Валюта"
    },
    buttons: {
      calculate: "Розрахувати"
    }
  },
  cardLimit: {
    titles: {
      changeLimits: "Зміна лімітів на картці",
      confirmation: "Підтвердіть внесені зміни до лімітів картки"
    },
    subtitles: {
      dailyWithdrawal: "Зняття готівки за добу",
      dailyPurchase: "Покупки за добу",
      generalLimit: "Загальний ліміт"
    },
    labels: {
      amount: "Сума",
      quantity: "Кількість транзакцій",
      code: "Код підтвердження"
    },
    confirmation: {
      text: "Внесені зміни вступлять у дію через декілька хвилин. Будь ласка, підтвердіть внесені зміни, щоб їх задіяти.",
      warningText: "Зверніть увагу! Суму ліміту і кількість операцій необхідно встановлювати з урахуванням неуспішних операцій, проведених за поточну добу."
    }
  },
  accountTile: {
    name: "Поточний рахунок",
    options: {
      history: "Історія",
      fillAccount: "Поповнити рахунок",
      withdrawAccount: "Переказ з рахунку",
      pay: "Сплатити"
    }
  },
  creditTile: {
    loanAmount: "Сума кредиту",
    remains: "Залишилось виплатити",
    before: "до",
    options: {
      history: "Історія",
      pay: "Погасити"
    }
  },
  depositTile: {
    depositAmount: "Сума вкладу",
    interestAmount: "Сума відсотків",
    interestRate: "Ставка",
    options: {
      history: "Історія",
      fill: "Поповнити",
      withdraw: "Зняти суму"
    }
  },
  templateTile: {
    options: {
      edit: "Редагувати шаблон",
      delete: "Видалити"
    }
  },
  templateSettings: {
    title: {
      createTemplate: "Створення шаблону",
      editTemplate: "Редагування шаблону"
    },
    text: "Натиснувши «Продовжити», я приймаю",
    linkText: "умови публічної пропозиції",
    textCreatedSuccess: "Регулярний платіж успішно створено",
    textCreatedFail: "Регулярний платіж не було створено",
    labels: {
      type: "Тип шаблону",
      typePayment: "Тип регулярного платежу",
      namePayment: "Назва регулярного платежу",
      fromContract: "З рахунку / картки",
      toContract: "На рахунок / картку",
      toContractForSep: "На рахунок",
      purpose: "Призначення платежу",
      taxNumber: "ІПН / ЄДРПОУ",
      regularPaymentSchedule: "Графік регулярного платежу",
      namePaymentApprove: "Назва платежу",
      regularPaymentScheduleApprove: "Графік платежу",
      startDate: "Дата першого платежу",
      finishDate: "Період дії до",
      everyDay: "Щоденно",
      everyWeek: "Щотижня",
      everyMonth: "Щомісяця",
      freePeriod: "Довільний період",
      every: "кожний",
      day: "День",
      week: "Тиждень",
      month: "Місяць",
      commission: "Комісія"
    },
    options: {
      types: {
        innerToInner: "Між власними рахунками / картками",
        bankInner: "У межах Банку",
        innerToCustom: "За реквізитами",
        mobile: "Мобільний зв'язок та телефонія"
      }
    },
    select: {
      placeholder: "Виберіть рахунок"
    },
    templateName: "Назва шаблону",
    transferAmount: "Сума переказу",
    noCommission: "Без комісії",
    minimizeAdditionalSettings: "Згорнути",
    expandAdditionalSettings: "Розгорнути",
    additionalSettings: " додаткові налаштування",
    regularPayment: "Регулярний платіж",
    expanded: {
      description: "Опис",
      textareaPlaceholder: "Введіть текст",
      iconsColor: "Колір іконки",
      templateIcon: "Іконка шаблону"
    },
    buttonCancel: "Відмінити",
    buttonSave: "Зберегти шаблон",
    buttonDelete: "Видалити шаблон",
    buttonCreateRegularPayment: "Створити платіж",
    buttonEditRegularPayment: "Зберегти платіж",
    buttonContinue: "Продовжити"
  },
  historyFilter: {
    showBy: "Показати за",
    placeholder: "Вибраний період",
    selectDate: "Вибрати дати",
    byType: "За типом операцій та категорій",
    typeOptions: {
      all: "Всі",
      server: {
        EXCHANGE_CURRENCY: "Обмін валют",
        SEP_TRANSFER: "За реквізитами",
        BILLER_PAYMENT: "Послуги та платежі",
        CONTRACT_TO_CONTRACT: "Між власними рахунками",
        INTRABANK_TRANSFER: "У межах банку",
        OUTER_CARD_TO_OUTER_CARD: "З картки на картку",
        CARD_TO_CONTRACT: "З картки іншого банку",
        UNLOCK_CARD: "Розблокування картки",
        LOCK_CARD: "Блокування картки",
        CHANGE_LIMITS: "Зміна лімітів",
        LOGIN: "Авторизація",
        MAKE_CONTRACT: "Відкриття депозиту",
        CARD_TO_CARD: "На картку іншого банку",
        CUSTOM: "Інше",
      }
    }
  },
  dateRange: {
    months: ["Січень", "Лютий", "Березень", "Квітень", "Травень", "Червень", "Липень", "Серпень", "Вересень", "Жовтень", "Листопад", "Грудень"],
    start: "Початок періоду",
    end: "Кінець періоду",
    buttonClean: "Очистити",
    buttonCancel: "Відмінити",
    buttonConfirm: "Застосувати"
  },
  dailyOperations: {
    success: "Успішно",
    failure: "Неуспішно",
    WAIT_FOR_PROCESSING: "В процесі обробки",
    noDescription: "Без опису",
    authorization: "Авторизація",
    labels: {
      creationDate: "Дата створення",
      executionDate: "Дата виконання",
      sender: "Відправник",
      senderAccount: "Рахунок відправника",
      fullName: "ПІБ",
      receiver: "Отримувач",
      receiverAccount: "Рахунок отримувача",
      name: "Найменування отримувача",
      status: "Статус платежу / переказу",
      amount: "Cумa платежу / переказу (без комісії)",
      commission: "Комісія",
      finalAmount: "Загальна сумa платежу / переказу"
    },
    serviceAutoprolongationOptions: {
      deactivate: "Відключення пролонгації",
      activate: "Підключення пролонгації"
    },
    autopayment: {
      autopaymentActivate: "Активація регулярного платежу",
      autopaymentClose: "Видалення регулярного платежу",
      autopaymentCreate: "Створення регулярного платежу",
      autopaymentDeactivate: "Деактивація регулярного платежу",
      autopaymentEdit: "Редагування регулярного платежу"
    },
    changeLimits: "Зміна лімітів",
    card: {
      changePin: "Зміна ПІН-коду",
      lockCard: "Блокування картки",
      unLockCard: "Розблокування картки"
    },
    changeCreditLimitSetRequestOperation: "Заявка на встановлення кредитного ліміту",
    changeCreditLimitUpRequestOperation: "Заявка на підвищення кредитного ліміту",
    changeCreditLimitDownRequestOperation: "Заявка на зниження кредитного ліміту",
    changeCreditLimitCancelRequestOperation: "Заявка на скасування кредитного ліміту"
  },
  confirmationCode: {
    title: "Введіть код підтвердження"
  },
  changeTitle: {
    title: "Введіть назву продукту"
  },
  select: {
    noOptions: "Опції відсутні"
  },
  transactionSuccess: {
    gratitude: "Дякуємо",
    success: "Транзакція виконана успішно",
    question: "Хотите добавить реквизиты транзакции к шаблонам"
  },
  suggestionTile: {
    card: {
      title: "Картка Мрія",
      description: "отримуйте до 7% річних на залишок і безкоштовно платіть в торговельній мережі та Інтернет"
    },
    app: {
      title: "Онлайн-банкінг AP Bank",
      description: "здійснюйте банківські операції у режимі 24/7 – швидко, зручно, надійно!"
    }
  },
  // MICS
  labels: {
    phone: "Номер телефону (без +380)",
    yes: "Так",
    no: "Ні",
    allowed: "Передбачено",
    notAllowed: "Не передбачено"
  },
  paymentTermTypes: {
    INSTANT: "В кінці строку",
    MONTH: "Щомісяця"
  },
  buttons: {
    confirm: "Підтвердити",
    close: "Закрити",
    showMore: "Показати ще",
    back: "Назад",
    continue: "Продовжити",
    complete: "Завершити",
    changePassword: "Змінити пароль",
    cancel: "Відмінити",
    saveChanges: "Зберегти зміни",
    pay: "Сплатити",
    addToTemplates: "Додати до шаблонів",
    orderNewCard: "Замовити картку",
    download: "Завантажити додаток",
    submitApplication: "Відправити заявку",
    createRegular: "Створити регулярний платіж"
  },
  errors: {
    purposeNeedDetails: "Просимо деталізувати призначення платежу",
    required: "Обов'язкове поле",
    wrong_iban: "Некоректний IBAN",
    wrong_card: "Некоректний номер картки",
    taxNumMin: "Невірна довжина коду ЄДРПОУ / ІПН",
    taxNumRules:
      "Допустимі значення поля: код ЄДРПОУ / ІПН / номер паспорта ID-картки / серія (великі літери кирилицею) та номер паспорта, пробіли не допускаються",
    innRules:
      "Допустимі значення поля: ІПН / номер паспорта ID-картки / серія (великі літери кирилицею) та номер паспорта, пробіли не допускаються",
    taxNumEmpty: "У разі відсутності ІПН вводиться серія (за наявності) та номер паспорта фізичної особи, яка відмовилась від ІПН, пробіли не допускаються",
    emailErrorMessage: "Введіть правильну електронну адресу",
    passwordErrorMessage: "Пароль не відповідає вказаному формату",
    confirmedPasswordErrorMessage: "Паролі повинні бути однакові",
    noOperations: "Операції відсутні",
    server: {
      default: "Неуспішно. Будь ласка, спробуйте пізніше або зв'яжіться з нами по тел. 0 800 300 170",
      period_is_large: "Вибраний період занадто довгий. Будь ласка, виберіть коротший період",
      biller_not_found: "Компанію отримувача не знайдено",
      invalid_change_password_parameters:
        "Помилка встановлення паролю. Будь ласка, переконайтесь, що пароль: не менш за 6 символів, має мінімум 1 цифру та одну літеру у верхньому регістрі",
      insufficient_funds: "Недостатньо коштів на рахунку для виконання цієї операції",
      client_already_exists: "Логін не унікальний. Будь ласка, введіть інше значення",
      client_not_found: "Користувача не знайдено",
      client_profile_not_found: "Користувача не знайдено",
      contragent_not_found: "Контрагента-отримувача не знайдено",
      contract_not_found: "Рахунок/договір не знайдено",
      deny_operation: "Операцію заборонено правилами банку",
      extended_authentication_required: "Необхідно виконати автентифікацію з використанням одноразового паролю",
      password_attempts_exceeded:
        "Кількість спроб вводу коду активації вичерпано. Для завершення реєстрації виконайте вхід в систему, ввівши Логін та Пароль, який ви створили під час проходження реєстрації",
      session_expired: "Поточна сесія застаріла або була закрита",
      user_already_binded_to_identifier: "Ваш профіль вже зв'язано з обліковим записом у банку",
      wrong_authentication_level: "Недостатній рівень доступу поточної сесії для цієї операції. Будь ласка, спробуйте увійти по логіну та паролю",
      wrong_credentials: "Помилкові дані автентифікації",
      wrong_login_and_password_credentials: "Вказано некоректні дані",
      wrong_otp_credentials: "Невірний одноразовий пароль",
      wrong_otp_credentials_attempt_count_exceed: "Невірний одноразовий пароль",
      wrong_parameters: "Невірно заповнено дані. Будь ласка, перевірте заповнення",
      max_refill_amount_violated: "Перевищено дозволену суму поповнення для цього депозиту",
      max_withdrawal_amount_violated: "Перевищено дозволену суму списання для цього депозиту",
      min_refill_amount_violated: "Занадто мала сума поповнення для цього депозиту",
      refill_not_allowed: "Поповнення цього депозиту заборонене",
      withdrawal_not_allowed: "Дострокове списання з цього депозиту заборонене",
      invalid_data: "Некоректні параметри переказу",
      invalid_password: "Пароль некоректний",
      invalid_user_registration_parameters: "Невірні дані реєстрації: перевірте відповідність правилам для паролю та логіну",
      code_attempts_exceeded: "Перевищено кількість спроб введення коду",
      limit_violation_min: "Сума операції не може бути меншою за ",
      unsupported_limit_value: "Недопустиме значення. \nМінімальне значення суми ліміту - 0.01, \nкількості операцій - 1",
      limit_violation_max: "Сума операції не може бути більшою за ",
      limit_violation: "Операцію заборонено правилами Банку",
      io_integration_error: "Неуспішно. Будь ласка, спробуйте пізніше або зв'яжіться з нами по тел. 0 800 300 170",
      unknown_integration_error: "Неуспішно. Будь ласка, спробуйте пізніше або зв'яжіться з нами по тел. 0 800 300 170",
      wrong_bankid_or_account_number: "Невірні реквізити отримувача",
      invalid_user_registration_parameters_login_does_not_match_security_rules: "Логін має містити мін. 6 символів (великі/малі літери, латиниця, цифри)",
      invalid_user_registration_parameters_password_does_not_match_security_rules: "Пароль не відповідає політиці",
      suretyrecords_error: "Невірний одноразовий пароль",
      account_temporary_locked: 'Доступ тимчасово заблокований, для розблокування – скористайтесь функцією "Відновити пароль"',
      wrong_biller_account: "Неможливо здійснити платіж за вказанними реквізитами",
      wrong_credentials_registration_are_denied: "Помилка реєстрації. Перевірте дані або зверніться до Банку 0 800 300 170",
      wrong_credentials_reset_password_are_denied: "Помилка автентифікації. Перевірте дані або зверніться до Банку 0 800 300 170",
      wrong_credentials_credentials_already_in_use: "Помилка реєстрації. Перевірте дані або зверніться до Банку 0 800 300 170",
      invalid_email: "Некоректний формат електронної адреси",
      wrong_iban: "Некоректний IBAN",
      wrong_card: "Некоректний номер картки",
      iban_is_invalid: "Некоректний IBAN",
      specific_integration_error_operation_cannot_be_performed: "На жаль, виконання даної операції неможливо. Зверніться до банку за телефоном 0800 300 170",
    }
  },
  dateRanges: {
    today: "Сьогодні",
    yesterday: "Вчора",
    week: "Тиждень",
    month: "Місяць",
    year: "Рік",
    all: "Весь період"
  },
  notifications: {
    copiedToBuffer: "Скопійовано в буфер обміну"
  },
  support: {
    efse_eu4b: "Реалізовано за сприяння та фінансування Європейського Союзу"
  }
};

export default ua;
