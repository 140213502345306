import moment from "moment";
import localization from "../localization/index";

const dateRanges = (language = "ua") => {
  // const { today, yesterday, week, month, year, all } = localization[language].dateRanges;
  const { today, yesterday, week, month } = localization[language].dateRanges;

  return [
    {
      value: [moment().format("YYYYMMDD"), moment().format("YYYYMMDD")],
      label: today
    },
    {
      value: [
        moment()
          .subtract(1, "days")
          .format("YYYYMMDD"),
        moment()
          .subtract(1, "days")
          .format("YYYYMMDD")
      ],
      label: yesterday
    },
    {
      value: [
        moment()
          .subtract(6, "days")
          .format("YYYYMMDD"),
        moment().format("YYYYMMDD")
      ],
      label: week
    },
    {
      value: [
        moment()
          .subtract(1, "months")
          .format("YYYYMMDD"),
        moment().format("YYYYMMDD")
      ],
      label: month
    }
    // {
    //   value: [
    //     moment()
    //       .subtract(1, "years")
    //       .format("YYYYMMDD"),
    //     moment().format("YYYYMMDD")
    //   ],
    //   label: year
    // },
    // {
    //   value: [
    //     moment()
    //       .subtract(10, "years")
    //       .format("YYYYMMDD"),
    //     moment().format("YYYYMMDD")
    //   ],
    //   label: all
    // }
  ];
};

export default dateRanges;
