import localization from "../localization/index";

export const cardOrderSchema = language => {
  const { required } = localization[language].errors;

  return {
    cardType: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    paymentSystem: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    card: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    currency: [
      {
        validator: "required",
        errorMessage: required
      }
    ]
  };
};

export const cardCreditSchema = language => {
  const { required } = localization[language].errors;

  return {
    amount: [
      {
        validator: "required",
        errorMessage: required
      }
    ]
  };
};

export const makeContractSchema = language => {
  const { required, emailErrorMessage } = localization[language].errors;

  return {
    givingAccount: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    email: [
      {
        validator: "required",
        errorMessage: required
      },
      {
        validator: "email",
        errorMessage: emailErrorMessage
      }
    ]
  };
};

export const makeAccountSchema = language => {
  //const {} = localization[language].errors;

  return {};
};
