import { useState, useEffect } from "react";

export const useError = initialError => {
  const [error, setError] = useState(initialError);

  const _setError = error => {
    if (error) {
      clearTimeout(useError.timeout);
      setError(error);
      useError.timeout = setTimeout(() => {
        setError(null);
      }, 6000);
    } else {
      clearTimeout(useError.timeout);
      setError(null);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(useError.timeout);
    };
  }, []);

  return [error, _setError];
};
