import localization from "../localization/index";

export const innerBankTransactionSchema = language => {
  const { required } = localization[language].errors;

  return {
    givingAccount: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    gettingAccount: [
      {
        validator: "length",
        args: {
          min: 1,
          max: 29
        },
        errorMessage: required
      }
    ],
    amount: [
      {
        validator: "required",
        errorMessage: required
      }
    ]
  };
};

export const innerToOuterTransactionSchema = language => {
  const { required } = localization[language].errors;

  return {
    givingAccount: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    cvv: [
      {
        validator: "length",
        args: {
          min: 3,
          max: 3
        },
        errorMessage: required
      }
    ],
    gettingAccount: [
      {
        validator: "length",
        args: {
          min: 16,
          max: 16
        },
        errorMessage: required
      }
    ],
    amount: [
      {
        validator: "required",
        errorMessage: required
      }
    ]
  };
};

export const innerToInnerTransactionSchema = language => {
  const { required } = localization[language].errors;

  return {
    givingContract: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    gettingContract: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    gettingContractCardOrIban: [
      {
        validator: "cardOrIban",
        errorMessage: required
      }
    ],
    amount: [
      {
        validator: "required",
        errorMessage: required
      }
    ]
  };
};

export const outerToInnerTransactionSchema = language => {
  const { required } = localization[language].errors;

  return {
    givingAccount: [
      {
        validator: "length",
        args: {
          min: 16,
          max: 16
        },
        errorMessage: required
      }
    ],
    date: [
      {
        validator: "length",
        args: {
          min: 4,
          max: 4
        },
        errorMessage: required
      }
    ],
    cvv: [
      {
        validator: "length",
        args: {
          min: 3,
          max: 3
        },
        errorMessage: required
      }
    ],
    gettingContract: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    amount: [
      {
        validator: "required",
        errorMessage: required
      }
    ]
  };
};

export const outerToOuterTransactionSchema = language => {
  const { required } = localization[language].errors;

  return {
    givingAccount: [
      {
        validator: "length",
        args: {
          min: 16,
          max: 16
        },
        errorMessage: required
      }
    ],
    date: [
      {
        validator: "length",
        args: {
          min: 4,
          max: 4
        },
        errorMessage: required
      }
    ],
    cvv: [
      {
        validator: "length",
        args: {
          min: 3,
          max: 3
        },
        errorMessage: required
      }
    ],
    gettingAccount: [
      {
        validator: "length",
        args: {
          min: 16,
          max: 16
        },
        errorMessage: required
      }
    ],
    amount: [
      {
        validator: "required",
        errorMessage: required
      }
    ]
  };
};

export const innerToCustomTransactionSchema = language => {
  const { required, taxNumEmpty, taxNumMin, taxNumRules, purposeNeedDetails } = localization[language].errors;

  return {
    givingContract: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    name: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    code: [
      {
        validator: "length",
        args: {
          min: 6,
          max: 6
        },
        errorMessage: required
      }
    ],
    taxNumber: [
      {
        validator: "taxNumEmpty",
        errorMessage: taxNumEmpty
      },
      {
        validator: "taxNumMin",
        errorMessage: taxNumMin
      },
      {
        validator: "taxNumRules",
        errorMessage: taxNumRules
      }
    ],
    gettingAccount: [
      {
        validator: "ibanSep",
        errorMessage: required
      }
    ],
    gettingAccountSep: [
      {
        validator: "ibanSep",
        errorMessage: required
      }
    ],
    purpose: [
      {
        validator: "required",
        errorMessage: required
      },
      // {
      //   validator: "purposeEmpty",
      //   errorMessage: required
      // },
      {
        validator: "purposeNeedDetails",
        errorMessage: purposeNeedDetails
      }
    ],
    amount: [
      {
        validator: "required",
        errorMessage: required
      }
    ]
  };
};

export const innerToBudgetTransactionSchema = (language) => {
  const { required, innRules } = localization[language].errors;

  return {
    ...innerToCustomTransactionSchema(language),
    actualPayerName: [
      {
        validator: "actualPayerName",
        errorMessage: required
      }
    ],
    actualPayerTaxIdOrPassport: [
      {
        validator: "actualPayerTaxIdOrPassport",
        errorMessage: required
      },
      {
        validator: "innRules",
        errorMessage: innRules
      }
    ],
    additionalPaymentInfo: [
      {
        validator: "required",
        errorMessage: required
      },
    ],
    purpose: [

    ],
  };
};

export const exchangeTransactionSchema = language => {
  const { required } = localization[language].errors;

  return {
    givingContract: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    gettingContract: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    gettingContractCardOrIban: [
      {
        validator: "cardOrIban",
        errorMessage: required
      }
    ],
    debitAmount: [
      {
        validator: "required",
        errorMessage: required
      }
    ],
    creditAmount: [
      {
        validator: "required",
        errorMessage: required
      }
    ]
  };
};
