import React, { useState, useCallback } from "react";
import LanguageContext from "./languageContext";
import localization from "../../localization/index";

const LanguageState = ({ children }) => {
  const [language, _setLanguage] = useState("ua");

  const setLanguage = useCallback(lang => {
    localStorage.language = lang;
    _setLanguage(lang);
  }, []);

  const text = useCallback(
    (path = "", locale = language) => {
      const fields = path.split(".");
      try {
        let value = localization[locale];
        fields.forEach(field => {
          value = value[field];
        });
        return value.toString();
      } catch (err) {
        return "";
      }
    },
    [language]
  );

  return <LanguageContext.Provider value={{ language, setLanguage, text }}>{children}</LanguageContext.Provider>;
};

export default LanguageState;
