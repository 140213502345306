import axios from "../axios";

export const postInnerBankTransactionPreface = payload => axios.post("operation/intrabankTransfer/preface", payload);
export const postInnerBankTransactionExecute = (authHeader, payload) =>
  axios.post("operation/intrabankTransfer/execute", payload, {
    headers: {
      Authorization: authHeader
    }
  });

export const postInnerToOuterTransactionEnroll = payload =>
  axios.post("operation/cardToCard/enroll", payload, { baseURL: process.env.REACT_APP_API2_URL || "" });
export const postInnerToOuterTransactionCommit = payload =>
  axios.post("operation/cardToCard/commit", payload, { baseURL: process.env.REACT_APP_API2_URL || "" });

export const postInnerToInnerTransactionPreface = payload => axios.post("operation/contractToContract/preface", payload);
export const postInnerToInnerTransactionExecute = payload => axios.post("operation/contractToContract/execute", payload);

export const postOuterToInnerTransactionEnroll = payload =>
  axios.post("operation/cardToContract/enroll", payload, { baseURL: process.env.REACT_APP_API2_URL || "" });
export const postOuterToInnerTransactionCommit = payload =>
  axios.post("operation/cardToContract/commit", payload, { baseURL: process.env.REACT_APP_API2_URL || "" });

export const postOuterToOuterTransactionEnroll = payload =>
  axios.post("operation/outerCardToOuterCard/enroll", payload, { baseURL: process.env.REACT_APP_API2_URL || "" });
export const postOuterToOuterTransactionCommit = payload =>
  axios.post("operation/outerCardToOuterCard/commit", payload, { baseURL: process.env.REACT_APP_API2_URL || "" });

export const postInnerToCustomTransactionPreface = payload => axios.post("operation/sepTransfer/preface", payload);
export const postInnerToCustomTransactionExecute = payload => axios.post("operation/sepTransfer/execute", payload);

export const postExchangeTransactionPreface = payload => axios.post("operation/exchange/currency/preface", payload);
export const postExchangeTransactionExecute = payload => axios.post("operation/exchange/currency/execute", payload);

export const requestForCustomTransaction = data => axios.get(`mfo/${data}`);

export const getPaymentTypes = () => axios.get("/config/resources/paymentTypes");
