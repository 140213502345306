import React, { useState } from "react";
import classes from "./Textarea.module.scss";
import PropTypes from "prop-types";
import { Error } from "../../index";
import cn from "../../../helpers/cn";

const Textarea = ({ errorClassName, style, errorStyle, value, changeHandler, placeholder, maxLength, errorMessage, isDisabled, warnHolder, onBlur }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      <div className={cn(classes.wrapper, isFocused ? classes.wrapperFocused : "", errorMessage ? classes.error : "")} style={style}>
        <textarea
          value={value}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={(event) => {
            if (onBlur) {
              onBlur(event.target.value);
            }
            setIsFocused(false);
          }}
          onChange={e => changeHandler(e.target.value)}
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={isDisabled}
        />
        {maxLength !== Infinity && (
          <div className={classes.indicator}>
            <div>{warnHolder}</div>
            <div>
              {value.length}/{maxLength}
            </div>
          </div>
        )}
        {isDisabled && <div className={classes.plug} />}
      </div>
      {errorMessage && <Error className={errorClassName} style={errorStyle} message={errorMessage} />}
    </>
  );
};

Textarea.propTypes = {
  errorClassName: PropTypes.string,
  style: PropTypes.object,
  errorStyle: PropTypes.object,
  value: PropTypes.string,
  changeHandler: PropTypes.func,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  errorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  onBlur: PropTypes.func,
};

Textarea.defaultProps = {
  errorClassName: "",
  style: {},
  errorStyle: {},
  value: "",
  changeHandler: () => {},
  placeholder: null,
  maxLength: Infinity,
  errorMessage: "",
  isDisabled: false,
  onBlur: () => {},
};

export default Textarea;
