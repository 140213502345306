import React from "react";
import classes from "./ExchangeTransaction.module.scss";
import { connect } from "react-redux";
import { Button, ConfirmationCode, Divider, Error, Input, Label, Select, Title, TransactionSuccess, Triangle } from "../../../../components/index";
import { languageContext, modalContext } from "../../../../context/index";
import isCurrency from "validator/lib/isCurrency";
import { useError } from "../../../../hooks/index";
import { fieldErrorMessage, validationError } from "../../../../validation/index";
import * as API from "../../../../api/index";
import { currenciesCalculate } from "../../../../api/index";
import serverErrorMessages from "../../../../helpers/serverErrorMessages";
import amountForRequest from "../../../../helpers/amountForRequest";
import { accountOptions, cardOptions } from "../../../../store/getters/index";
import * as actions from "../../../../store/actions/index";
import { exchangeTransactionSchema } from "../../../../validation/transactions";
import debounce from "../../../../helpers/debounce";

const efse = require("../../../../assets/images/efse.jpg").default;
const eu4b = require("../../../../assets/images/eu4b.png").default;

const time = ms => {
  if (ms === false) {
    return;
  }
  const temp = parseInt(String(ms / 1000));
  const min = String(parseInt(String(temp / 60))).padStart(1, "0");
  const sec = String(parseInt(String(temp % 60))).padStart(2, "0");
  return `${min}:${sec}`;
};

const ExchangeTransaction = ({ history, location, cards, accounts, credits, deposits, readContract }) => {
  const { language, text } = React.useContext(languageContext);
  const { setModal } = React.useContext(modalContext);
  const [extAuthRequired, setExtAuthRequired] = React.useState(false);

  const srcContractAll = React.useMemo(() => [...cardOptions(cards), ...accountOptions(accounts, text)], [accounts, cards, text]);
  const dstContractAll = React.useMemo(() => [...cardOptions(cards), ...accountOptions(accounts, text)], [accounts, cards, text]);

  const getFirst = React.useCallback((list, type, currency) => list.find(item => item.providerId === type && item.currency === currency), []);
  const getFirstAccount = React.useCallback((list, currency) => getFirst(list, "account", currency) || {}, [getFirst]);
  const getFirstCard = React.useCallback((list, currency) => getFirst(list, "card", currency) || getFirstAccount(list, currency) || {}, [
    getFirst,
    getFirstAccount
  ]);

  const [srcContract, _setSrcContract] = React.useState(() => getFirstAccount(srcContractAll, "USD"));
  const [dstContract, _setDstContract] = React.useState(() => getFirstCard(dstContractAll, "UAH"));

  const currencies = React.useMemo(() => [...new Set([...srcContractAll, ...dstContractAll].map(item => item.currency))], [dstContractAll, srcContractAll]);

  const newCurrencies = React.useMemo(
    () =>
      ["USD", "EUR"]
        .map(currency =>
          !currencies.includes(currency)
            ? {
                value: currency,
                label: `${text("exchangeTransaction.openAccount")} ${currency}`,
                type: "open"
              }
            : false
        )
        .filter(Boolean),
    [currencies, text]
  );

  const setSrcContract = React.useCallback(
    value => {
      if (["EUR", "USD", "UAH"].includes(value)) {
        history.push(`/home/accounts/order/${value}`);
        return;
      }
      _setSrcContract(oldValue => {
        const temp = srcContractAll.find(contract => contract.value === value) || {};
        if (oldValue?.currency !== temp.currency) {
          _setDstContract(dst => {
            if (oldValue.currency === "UAH") {
              return getFirstCard(dstContractAll, "UAH");
            } else if (temp?.currency === "UAH") {
              return getFirstAccount(dstContractAll, "USD");
            } else {
              return dst;
            }
          });
        }
        return temp;
      });
    },
    [history, srcContractAll, getFirstCard, dstContractAll, getFirstAccount]
  );
  const setDstContract = React.useCallback(
    value => {
      if (["EUR", "USD"].includes(value)) {
        history.push(`/home/accounts/order/${value}`);
      }
      _setDstContract(dstContractAll.find(contract => contract.value === value) || {});
    },
    [dstContractAll, history]
  );

  const srcContractOptions = React.useMemo(() => [...cardOptions(cards), ...accountOptions(accounts, text), ...newCurrencies], [
    accounts,
    cards,
    newCurrencies,
    text
  ]);
  const dstContractOptions = React.useMemo(() => [...cardOptions(cards), ...accountOptions(accounts, text), ...newCurrencies], [
    accounts,
    cards,
    newCurrencies,
    text
  ]);

  const srcContractList = React.useMemo(() => srcContractOptions, [srcContractOptions]);
  const dstContractList = React.useMemo(
    () =>
      srcContract?.value
        ? dstContractOptions.filter(
            contract =>
              (srcContract?.currency === "UAH" && ["USD", "EUR"].includes(contract?.currency)) ||
              (["EUR", "USD"].includes(srcContract?.currency) && contract?.currency === "UAH") ||
              contract?.type === "open"
          )
        : dstContractOptions,
    [dstContractOptions, srcContract.currency, srcContract.value]
  );

  const cancel = React.useCallback(() => {
    setSrcContract(null);
    setDstContract(null);
    setDebitAmount("");
    setCreditAmount("");
    setIsPrefaced(false);
  }, [setDstContract, setSrcContract]);

  const [debitAmount, setDebitAmount] = React.useState("");
  const [creditAmount, setCreditAmount] = React.useState("");
  const [isFetching, setIsFetching] = React.useState(false);
  const [isPrefaced, setIsPrefaced] = React.useState(false);
  const [calcData, setCalcData] = React.useState({});
  const [error, setError] = useError(null);
  const [ticker, setTicker] = React.useState(false);
  const timer = React.useRef();

  React.useEffect(() => () => window.clearInterval(timer.current), [timer]);

  const update = React.useCallback(async ({srcContract, dstContract, dstCurrency, srcCurrency, _amount}) => {
    setCalcData(null);
    // if (srcCurrency !== "UAH") {
    //   setCreditAmount("");
    // }
    // if (srcCurrency === "UAH") {
    //   setDebitAmount("");
    // }
    // window.clearInterval(timer.current);
    // if (dstCurrency && srcCurrency && _amount) {
    const amount = Math.round(amountForRequest(_amount));
    let response;
    try {
      response = await currenciesCalculate({
        srcContractRef: {
          contractId: srcContract?.contractId,
          providerId: srcContract?.providerId
        },
        cardId: srcContract?.cardId,

        dstContractRef: {
          contractId: dstContract?.contractId,
          providerId: dstContract?.providerId
        },
        dstCardId: dstContract?.cardId,

        debitAmount: srcCurrency !== "UAH" ? amount : undefined,
        creditAmount: srcCurrency === "UAH" ? amount : undefined,
        sellCurrency: srcCurrency,
        buyCurrency: dstCurrency
      });
    } catch (err) {
      if (amount) {
        setError({
          field: "global",
          message: serverErrorMessages(err, language)
        });
      }

      setTicker(false);
      setCalcData(null);
      if (srcCurrency !== "UAH") {
        setCreditAmount("");
      }
      if (srcCurrency === "UAH") {
        setDebitAmount("");
      }
      window.clearInterval(timer.current);
    }
    if ((response?.data || {})[srcCurrency !== "UAH" ? "debitAmount" : "creditAmount"] === amount) {
      if ((response?.data || {})[srcCurrency !== "UAH" ? "creditAmount" : "debitAmount"] >= 0) {
        const expire = new Date().getTime() + parseInt(response?.data?.calculationTtlMillis, 10);
        setCalcData({ ...response?.data, expire });
        window.clearInterval(timer.current);
        timer.current = window.setInterval(() => {
          const temp = expire - new Date().getTime();
          if (temp <= 0) {
            window.clearInterval(timer.current);
            setTicker(0);
          } else {
            setTicker(temp);
          }
        }, 500);
        if (srcCurrency !== "UAH") {
          setCreditAmount((response?.data?.creditAmount / 100).toFixed(2));
        }
        if (srcCurrency === "UAH") {
          setDebitAmount((response?.data?.debitAmount / 100).toFixed(2));
        }
      } else {
        setTicker(false);
        setCalcData(null);
        if (srcCurrency !== "UAH") {
          setCreditAmount("");
        }
        if (srcCurrency === "UAH") {
          setDebitAmount("");
        }
        window.clearInterval(timer.current);
      }
    }
    // }
  }, []);

  const updateDebounce = React.useCallback((...props) => debounce(update, 500)(...props), [update]);

  React.useEffect(() => {
    if (srcContract?.currency !== "UAH") {
      updateDebounce({
        dstContract,
        srcContract,
        dstCurrency: dstContract?.currency,
        srcCurrency: srcContract?.currency,
        _amount: debitAmount
    });
    }
  }, [
    srcContract?.providerId, srcContract?.contractId, srcContract?.cardId,
    dstContract?.providerId, dstContract?.contractId, dstContract?.cardId,
    dstContract?.currency,
    srcContract?.currency,
    debitAmount,
    updateDebounce
  ]);

  React.useEffect(() => {
    if (srcContract?.currency === "UAH") {
      updateDebounce({
        srcContract,
        dstContract,
        dstCurrency: dstContract?.currency,
        srcCurrency: srcContract?.currency,
        _amount: creditAmount
    });
    }
  }, [
    srcContract?.providerId, srcContract?.contractId, srcContract?.cardId,
    dstContract?.providerId, dstContract?.contractId, dstContract?.cardId,
    dstContract?.currency,
    srcContract?.currency,
    updateDebounce,
    creditAmount
  ]);

  React.useEffect(() => {
    setTicker(false);
    setCalcData(null);
    setDebitAmount("");
    setCreditAmount("");
    window.clearInterval(timer.current);
    // updateDebounce(dstContract?.currency, srcContract?.currency, null);
  }, [srcContract, dstContract]);

  const globalErrorMessage = fieldErrorMessage("global", error);

  const initialExchange = async () => {
    setError(null);
    const error = validationError(exchangeTransactionSchema(language), {
      givingContract: srcContract?.value,
      gettingContract: dstContract?.value,
      debitAmount,
      creditAmount
    });
    if (error) {
      setError(error);
      return;
    }
    setIsFetching(true);
    try {
      const { data } = await API.postExchangeTransactionPreface({
        srcContractRef: {
          contractId: srcContract?.contractId,
          providerId: srcContract?.providerId
        },
        cardId: srcContract?.cardId,

        dstContractRef: {
          contractId: dstContract?.contractId,
          providerId: dstContract?.providerId
        },
        dstCardId: dstContract?.cardId,

        debitAmount: Math.round(amountForRequest(debitAmount)),
        creditAmount: Math.round(amountForRequest(creditAmount)),
        encrypted: calcData.encrypted,

        sellCurrency: srcContract?.currency,
        buyCurrency: dstContract?.currency,

        rate: calcData.rate
      });
      window.clearInterval(timer.current);
      // const { extAuthRequired } = data.operationConditions;
      setExtAuthRequired(data.operationConditions.extAuthRequired);
      // if (extAuthRequired === true) {
      //   try {
      //     setIsFetching(true);
      //
      //     const { data } = await API.getAuthExtended();
      //     setOtpCredentialsChallenge(data);
      //     setIsFetching(false);
      //   } catch (err) {
      //     setError({
      //       field: "global",
      //       message: serverErrorMessages(err, language)
      //     });
      //     setIsFetching(false);
      //     return;
      //   }
      // }
      setIsPrefaced(true);
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
    }

    setIsFetching(false);
  };

  const submitExchange = async () => {
    try {
      setIsFetching(true);
      if (extAuthRequired === true) {
        const { data } = await API.getAuthExtended();
        const otpCredentials = {
          otp: undefined,
          challenge: data
        };
        setIsFetching(false);
        await new Promise(resolve => {
          setModal(
            <ConfirmationCode
              clickContinueHandler={code => {
                otpCredentials.otp = code;
                setModal(null);
                resolve();
              }}
            />
          );
        });
        setIsFetching(true);
        await API.postAuthLoginOtp(otpCredentials);
      }
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
      setIsFetching(false);
      return;
    }
    // ---------------------------------------------------------------------------------------------------------
    try {
      setIsFetching(true);
      const { data } = await API.postExchangeTransactionExecute({
        srcContractRef: {
          contractId: srcContract?.contractId,
          providerId: srcContract?.providerId
        },
        cardId: srcContract?.cardId,

        dstContractRef: {
          contractId: dstContract?.contractId,
          providerId: dstContract?.providerId
        },
        dstCardId: dstContract?.cardId,

        debitAmount: Math.round(amountForRequest(debitAmount)),
        creditAmount: Math.round(amountForRequest(creditAmount)),
        encrypted: calcData.encrypted,

        sellCurrency: srcContract?.currency,
        buyCurrency: dstContract?.currency,

        rate: calcData.rate
      });

      if (data.status === "FAIL") {
        setError({
          field: "global",
          message: serverErrorMessages(
            {
              response: {
                data
              }
            },
            language
          )
        });
        setIsFetching(false);
        cancel();
        return;
      }
      try {
        await Promise.all([readContract(srcContract?.providerId, srcContract?.contractId), readContract(dstContract?.providerId, dstContract?.contractId)]);
      } catch (error) {
        console.log("Unable to update the contract", error);
      }
      setModal(<TransactionSuccess clickCancelHandler={() => setModal(null)} clickAddHandler={false} clickRegularHandler={false} />);
      history.push("/home/transactions");
    } catch (err) {
      setError({
        field: "global",
        message: serverErrorMessages(err, language)
      });
      setIsFetching(false);
    }
  };

  const fee = React.useMemo(() => {
    let fee = parseInt(calcData?.commission);
    if (isNaN(fee)) {
      fee = 0;
    }
    return fee;
  }, [calcData?.commission]);

  const creditAmountFee = React.useMemo(() => {
        return [null, undefined, ""].includes(creditAmount) ? "" : (((creditAmount * 100) - (srcContract?.currency !== "UAH" ? fee : 0)) / 100)
      }, [fee, creditAmount, srcContract?.currency]);

  const debitAmountFee = React.useMemo(() => {
    return [null, undefined, ""].includes(debitAmount) ? "" : (((debitAmount * 100) + (srcContract?.currency === "UAH" ? fee : 0)) / 100)
  }, [fee, debitAmount, srcContract?.currency]);

  return (
    <div className={classes.wrapper}>
      <Title text={text("exchangeTransaction.title")} />
      <div className={classes.content}>
        <div className={classes.subtitle}>{text("exchangeTransaction.sender")}:</div>
        <Select
          errorClassName={classes.error}
          style={{ marginBottom: 20 }}
          labelText={text("exchangeTransaction.labels.fromMyAccount")}
          options={srcContractList}
          value={srcContract?.value}
          changeHandler={setSrcContract}
          placeholder={text("exchangeTransaction.select.placeholder")}
          errorMessage={fieldErrorMessage("givingContract", error)}
          isDisabled={isPrefaced}
        />

        <Label>{text("exchangeTransaction.debitAmount")}</Label>
        <div className={classes.row} style={{ marginBottom: 20 }}>
          <div>
            <Input
              style={{ width: 250, border: srcContract?.currency === "UAH" && "none" }}
              errorStyle={{ marginTop: 20 }}
              name="debitAmount"
              value={debitAmountFee}
              changeHandler={value => setDebitAmount(value)}
              units={srcContract?.currency}
              commaIsReplacedWithDot
              validator={value =>
                isCurrency(value, {
                  digits_after_decimal: [0, 1, 2]
                })
              }
              errorMessage={fieldErrorMessage("debitAmount", error)}
              isDisabled={isPrefaced || srcContract?.currency === "UAH"}
            />
          </div>
          {(srcContract?.currency === "UAH" && calcData?.commission) && (
              <div className={classes.feeBlock}>
                <div className={classes.feeText}>{text("exchangeTransaction.transaction_with_commission")}:</div>
                <div className={classes.feeAmount}>{(calcData?.commission / 100).toFixed(2)} UAH</div>
              </div>
          )}
        </div>

        <Divider />
        <Triangle style={{ margin: "auto" }} />

        <div className={classes.subtitle}>{text("exchangeTransaction.recipient")}:</div>
        <Label>{text("exchangeTransaction.labels.toAccount")}</Label>
        <Select
          style={{ marginBottom: 20 }}
          options={dstContractList}
          value={dstContract?.value}
          changeHandler={setDstContract}
          placeholder={text("exchangeTransaction.select.placeholder")}
          errorMessage={fieldErrorMessage("gettingContract", error)}
          isDisabled={isPrefaced}
        />

        <Label>{text("exchangeTransaction.creditAmount")}</Label>
        <div className={classes.row} style={{ marginBottom: 20 }}>
          <div>
            <Input
              style={{ width: 250, border: srcContract?.currency !== "UAH" && "none" }}
              errorStyle={{ marginTop: 20 }}
              name="creditAmount"
              value={creditAmountFee}
              changeHandler={value => setCreditAmount(value)}
              units={dstContract?.currency}
              commaIsReplacedWithDot
              validator={value =>
                isCurrency(value, {
                  digits_after_decimal: [0, 1, 2]
                })
              }
              errorMessage={fieldErrorMessage("creditAmount", error)}
              isDisabled={isPrefaced || srcContract?.currency !== "UAH"}
            />
          </div>
          {(srcContract?.currency !== "UAH" && calcData?.commission) && (
              <div className={classes.feeBlock}>
                <div className={classes.feeText}>{text("exchangeTransaction.transaction_without_commission")}:</div>
                <div className={classes.feeAmount}>{(calcData?.commission / 100).toFixed(2)} UAH</div>
              </div>
          )}
        </div>

        <Divider style={{marginBottom: 10}}/>

        {calcData?.rate && (
            <>
            <div className={classes.commisionText}>
              {text("exchangeTransaction.rate")}: 1 {calcData?.sellCurrency === "UAH" ? calcData?.buyCurrency : calcData?.sellCurrency} = {calcData?.rate}{" "}
              {calcData?.sellCurrency === "UAH" ? calcData?.sellCurrency : calcData?.buyCurrency}
            </div>
            {!isPrefaced && ticker !== false && (
              <div className={classes.commisionText}>
                {text("exchangeTransaction.ttl")}: {time(ticker)}
              </div>
            )}
          </>
        )}

        <div className={classes.text}>
          {text("exchangeTransaction.pressTransfer")}{" "}
          <a className={classes.link} href="https://ap-bank.com/documents/download/1084" target="_blank" rel="noopener noreferrer">
            {text("exchangeTransaction.conditions")}
          </a>
        </div>
        {globalErrorMessage && <Error className={classes.globalError} message={globalErrorMessage} />}
        <div className={classes.row}>
          <Button
            style={{
              width: 200,
              height: 48,
              color: "#241F5A",
              textDecoration: "underline",
              fontWeight: "bold"
            }}
            clickHandler={!isPrefaced ? history.goBack : cancel}
            isDisabled={isFetching}
          >
            {text(!isPrefaced ? "buttons.back" : "buttons.cancel")}
          </Button>
          {ticker === 0 && (
            <Button
              style={{
                width: 200,
                height: 48,
                color: "white",
                backgroundColor: "#241F5A",
                borderColor: "#241F5A",
                fontWeight: "bold"
              }}
              clickHandler={() => {
                updateDebounce({
                  srcContract,
                  dstContract,
                  dstCurrency: dstContract?.currency,
                  srcCurrency: srcContract?.currency,
                  _amount: srcContract?.currency !== "UAH" ? debitAmount : creditAmount
              })
              }}
            >
              {text("exchangeTransaction.buttonUpdate")}
            </Button>
          )}
          {(ticker > 0 || ticker === false) && (
            <Button
              style={{
                width: 200,
                height: 48,
                color: "white",
                backgroundColor: "#241F5A",
                borderColor: "#241F5A",
                fontWeight: "bold"
              }}
              clickHandler={!isPrefaced ? initialExchange : submitExchange}
              isDisabled={isFetching}
            >
              {text(!isPrefaced ? "buttons.continue" : "exchangeTransaction.buttonConfirm")} &rarr;
            </Button>
          )}
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.support}>
          <div className={classes["support-text"]}>{text("support.efse_eu4b")}</div>
          <div>
            <img src={efse} className={classes["support-logo"]} alt="efse" />
            <img src={eu4b} className={classes["support-logo"]} alt="eu4b" />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  readContract: (providerId, contractId) => dispatch(actions.readContract(providerId, contractId))
});

export default connect(({ contracts }) => contracts, mapDispatchToProps)(ExchangeTransaction);
