import React, { useContext, useState } from "react";
import classes from "./DepositTile.module.scss";
import PropTypes from "prop-types";
import { languageContext } from "../../../context/index";
import cn from "../../../helpers/cn";
import all from "../../../helpers/all";
import localeAmount from "../../../helpers/localeAmount";
import { Icon } from "../../index";

const DepositTile = ({
  className,
  style,
  height,
  name,
  totalAmount,
  currency,
  interestAmount,
  interestRate,
  clickHistoryHandler,
  clickFillHandler,
  clickWithdrawHandler,
  hasOpenButton
}) => {
  const { text } = useContext(languageContext);

  const options = [
    {
      iconName: "historyOption",
      label: text("depositTile.options.history"),
      clickHandler: clickHistoryHandler
    },
    ...(clickFillHandler
      ? [
          {
            iconName: "fillAccount",
            label: text("depositTile.options.fill"),
            clickHandler: clickFillHandler
          }
        ]
      : []),
    ...(clickWithdrawHandler
      ? [
          {
            iconName: "withdrawAccount",
            label: text("depositTile.options.withdraw"),
            clickHandler: clickWithdrawHandler
          }
        ]
      : [])
  ];

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={cn(classes.depositTile, className)} style={style}>
      <div className={classes.top} style={{ height: height / 2 }}>
        <div className={classes.iconWrapper}>
          <Icon name="depositTile" />
        </div>
        <div className={classes.description}>
          <div className={classes.name}>{name}</div>
          <div className={cn(classes.text, classes.textTop)}>
            {text("depositTile.depositAmount")}: {localeAmount(totalAmount)} {currency}
          </div>
        </div>
        {hasOpenButton && (
          <div className={classes.openIconWrapper} onClick={() => setIsOpen(!isOpen)}>
            <Icon style={{ ...(isOpen ? { transform: "rotate(180deg)" } : {}) }} name="selectArrow" />
          </div>
        )}
      </div>
      {!isOpen ? (
        <div className={classes.bottom} style={{ height: height / 2 }}>
          <div className={classes.row}>
            <div className={classes.text}>{text("depositTile.interestAmount")}:</div>
            <div className={classes.text}>{text("depositTile.interestRate")}:</div>
          </div>
          <div className={classes.row}>
            <div className={cn(classes.amount, classes.amountLeft)}>
              {localeAmount(interestAmount)} <span className={classes.currency}>{currency}</span>
            </div>
            <div className={cn(classes.amount, classes.amountRight)}>{Number(interestRate)}%</div>
          </div>
        </div>
      ) : (
        <div className={classes.options}>
          {options.map((option, i) => (
            <div id="option" className={classes.option} onClick={() => all(option.clickHandler, () => setIsOpen(false))} key={i}>
              {option.iconName && <Icon name={option.iconName} />}
              {option.label && <div className={classes.label}>{option.label}</div>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

DepositTile.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  height: PropTypes.number,
  name: PropTypes.string,
  totalAmount: PropTypes.number,
  currency: PropTypes.string,
  interestAmount: PropTypes.number,
  interestRate: PropTypes.number,
  clickHistoryHandler: PropTypes.func,
  clickFillHandler: PropTypes.func,
  clickWithdrawHandler: PropTypes.func,
  hasOpenButton: PropTypes.bool
};

DepositTile.defaultProps = {
  className: "",
  style: {},
  height: 130,
  name: "Депозит",
  totalAmount: null,
  currency: "грн",
  interestAmount: null,
  interestRate: null,
  clickHistoryHandler: null,
  clickFillHandler: null,
  clickWithdrawHandler: null,
  hasOpenButton: true
};

export default DepositTile;
