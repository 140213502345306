import React, { useContext } from "react";
import classes from "./DailyOperations.module.scss";
import PropTypes from "prop-types";
import { languageContext } from "../../context/index";
import cn from "../../helpers/cn";
import localeAmount from "../../helpers/localeAmount";
import { Icon } from "..";
import FinancialOperation from "./FinancialOperation/FinancialOperation.jsx";

const DailyOperations = ({ className, style, type, date, rows }) => {
  const { text } = useContext(languageContext);

  const statuses = {
    SUCCESS: text("dailyOperations.success"),
    FAIL: text("dailyOperations.failure"),
    WAIT_FOR_PROCESSING: text("dailyOperations.WAIT_FOR_PROCESSING")
  };

  const ContractFinancialRow = (row, i) => (
    <FinancialOperation
      isContractOperation
      time={row.time}
      description={row.description || text("dailyOperations.noDescription")}
      amount={row.amount}
      localeAmount={localeAmount(row.amount)}
      currency={row.currency}
      detailsRows={[
        {
          label: `${text("dailyOperations.labels.creationDate")}:`,
          value: row.creationDate
        },
        {
          label: `${text("dailyOperations.labels.executionDate")}:`,
          value: row.executionDate
        },
        {
          label: `${text("dailyOperations.labels.senderAccount")}:`,
          value: row.senderAccount
        },
        {
          label: `${text("dailyOperations.labels.sender")}:`,
          value: row.sender
        },
        {
          label: `${text("dailyOperations.labels.receiverAccount")}:`,
          value: row.receiverAccount
        },
        {
          label: `${text("dailyOperations.labels.receiver")}:`,
          value: row.receiver
        }
      ]}
      key={i}
    />
  );

  const FinancialRow = (row, i) => (
    <FinancialOperation
      row={row}
      id={row.id}
      time={row.time}
      description={row.description || text("dailyOperations.noDescription")}
      amount={row.amount}
      localeAmount={localeAmount(row.amount)}
      status={row.status}
      operationType={row.operationType}
      currency={row.currency}
      detailsRows={[
        {
          label: `${text("dailyOperations.labels.creationDate")}:`,
          value: row.creationDate
        },
        {
          label: `${text("dailyOperations.labels.executionDate")}:`,
          value: row.executionDate
        },
        // {
        //   label: `${text("dailyOperations.labels.senderAccount")}:`,
        //   value: row.senderAccount
        // },
        {
          label: `${text("dailyOperations.labels.sender")}:`,
          value: row.sender
        },
        ...(row.operationType === "INTRABANK_TRANSFER" ? [{ label: `${text("dailyOperations.labels.fullName")}:`, value: row.senderName }] : []),
        // {
        //   label: `${text("dailyOperations.labels.receiverAccount")}:`,
        //   value: row.receiverAccount
        // },
        {
          label: `${text("dailyOperations.labels.receiver")}:`,
          value: row.receiver
        },
        ...(row.operationType === "INTRABANK_TRANSFER" ? [{ label: `${text("dailyOperations.labels.fullName")}:`, value: row.receiverName }] : []),
        ...(row.operationType === "SEP_TRANSFER" ? [{ label: `${text("dailyOperations.labels.name")}:`, value: row.receiverName }] : []),
        {
          label: `${text("dailyOperations.labels.status")}:`,
          value: statuses[row.status]
        },
        {
          label: `${text("dailyOperations.labels.amount")}:`,
          value: `${localeAmount(row.amount)} ${row.currency}`
        },
        {
          label: `${text("dailyOperations.labels.commission")}:`,
          value: `${localeAmount(row.commission)} ${row.operationType === "EXCHANGE_CURRENCY" ? "UAH" : row.currency}`
        },
        {
          label: `${text("dailyOperations.labels.finalAmount")}:`,
          value: `${localeAmount(row.amount + (row.currency === "UAH" ? row.commission : 0))} ${row.currency}`
        }
      ]}
      key={i}
    />
  );

  const serviceOperations = {
    deactivate: `${text("dailyOperations.serviceAutoprolongationOptions.deactivate")}`,
    activate: `${text("dailyOperations.serviceAutoprolongationOptions.activate")}`,
    AUTOPAYMENT_ACTIVATE_OPERATION: `${text("dailyOperations.autopayment.autopaymentActivate")}`,
    AUTOPAYMENT_CLOSE_OPERATION: `${text("dailyOperations.autopayment.autopaymentClose")}`,
    AUTOPAYMENT_CREATE_OPERATION: `${text("dailyOperations.autopayment.autopaymentCreate")}`,
    AUTOPAYMENT_DEACTIVATE_OPERATION: `${text("dailyOperations.autopayment.autopaymentDeactivate")}`,
    AUTOPAYMENT_EDIT_OPERATION: `${text("dailyOperations.autopayment.autopaymentEdit")}`,
    CHANGE_LIMITS: `${text("dailyOperations.changeLimits")}`,
    LOCK_CARD: `${text("dailyOperations.card.lockCard")}`,
    UNLOCK_CARD: `${text("dailyOperations.card.unLockCard")}`,
    "change_pin": text("dailyOperations.card.changePin"),
    changeCreditLimitSetRequestOperation: text("dailyOperations.changeCreditLimitSetRequestOperation"),
    changeCreditLimitUpRequestOperation: text("dailyOperations.changeCreditLimitUpRequestOperation"),
    changeCreditLimitDownRequestOperation: text("dailyOperations.changeCreditLimitDownRequestOperation"),
    changeCreditLimitCancelRequestOperation: text("dailyOperations.changeCreditLimitCancelRequestOperation"),
  };

  const ServiceRow = (row, i) => (
    <div className={classes.row} key={i}>
      <div className={classes.time}>{row.time}</div>
      <Icon className={classes.icon} name="service" />
      {row.status === "SUCCESS" ? (
        <Icon name="successSmallIcon" className={classes.smallIcon} />
      ) : (
        <Icon name="warningSmallIcon" className={classes.smallIcon} />
      )}
      <div className={classes.description}>{serviceOperations[row.description] || text("dailyOperations.noDescription")}</div>
    </div>
  );

  const AuthorizationRow = (row, i) => (
    <div className={classes.row} key={i}>
      <div className={classes.time}>{row.time}</div>
      <Icon className={classes.icon} name="authorization" />
      <div className={classes.description}>
        {row.description ? text("dailyOperations.authorization") + ". IP: " + row.description : text("dailyOperations.noDescription")}
      </div>
      <div className={cn(classes.status, row.status === "SUCCESS" ? classes.amountPositive : classes.amountNegative)}>{statuses[row.status]}</div>
    </div>
  );

  const Row = {
    contractFinancial: ContractFinancialRow,
    financial: FinancialRow,
    service: ServiceRow,
    authorization: AuthorizationRow
  }[type];

  return (
    <div className={cn(classes.dailyOperations, className)} style={style}>
      <div className={classes.date}>{date}</div>
      {rows.map((row, i) => Row(row, i))}
    </div>
  );
};

DailyOperations.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.oneOf(["contractFinancial", "financial", "service", "authorization"]),
  date: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.string,
      iconName: PropTypes.string,
      description: PropTypes.string,
      amount: PropTypes.number,
      currency: PropTypes.string,
      status: PropTypes.string
    })
  )
};

DailyOperations.defaultProps = {
  className: "",
  style: {},
  type: "contractFinancial",
  date: "",
  rows: []
};

export default DailyOperations;
