import React from "react";
import classes from "./DepositOrderConditions.module.scss";
import { languageContext } from "../../../../../context/index";
import { Button, Error, Input, Label, Select, Spinner, Title } from "../../../../../components";
import { fieldErrorMessage } from "../../../../../validation/index";
import cn from "../../../../../helpers/cn";
import * as API from "../../../../../api/index";
import Icon from "../../../../../components/icons/Icon";
import isCurrency from "validator/lib/isCurrency";
import localeAmount from "../../../../../helpers/localeAmount";
import numberWithSpaces from "../../../../../helpers/numberWithSpaces";
import amountForRequest from "../../../../../helpers/amountForRequest";

const DepositOrderConditions = ({ history, onSelect, payout }) => {
  const { language, text } = React.useContext(languageContext);

  const [term, setTerm] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [currency, setCurrency] = React.useState("");

  const [error, setError] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [isSpinner, setIsSpinner] = React.useState(false);
  const [noDepositMessage, setNoDepositMessage] = React.useState(false);

  const [terms, setTerms] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [currencies, setCurrencies] = React.useState([]);

  const getDeposits = React.useCallback(params => {
    (async function() {
      setIsSpinner(true);
      setNoDepositMessage(false);
      const response = await API.getContractConditions("deposit", params);
      setStatus(response.status);
      setProducts((response && response.data && response.data.contractConditions) || []);
    })();
  }, []);

  React.useEffect(() => {
    (async function() {
      setIsSpinner(true);
      setNoDepositMessage(false);

      const params = await API.getContractConditions("deposit");
      setStatus(params.status);
      if (params && params.data) {
        const { defaultUiSettings, variantsOfSelectors } = params.data;

        if (defaultUiSettings) {
          setTerm(JSON.stringify({ termType: defaultUiSettings.termType, term: defaultUiSettings.term }));
          setAmount(numberWithSpaces(parseInt(defaultUiSettings.amount, 10) / 100));
          setCurrency(defaultUiSettings.currencyId);
          getDeposits(defaultUiSettings);
        }
        if (variantsOfSelectors) {
          const { variantsOfTerms, variantsOfCurrencies } = variantsOfSelectors;
          if (variantsOfCurrencies) {
            setCurrencies(variantsOfCurrencies.map(item => ({ value: item, label: item })));
          }
          if (variantsOfTerms) {
            setTerms(
              variantsOfTerms.map(({ type, value }) => ({
                value: JSON.stringify({ termType: type, term: value }),
                label: `${value} ${text("depositOrderConditions.termType" + type.slice(0, 1))}`
              }))
            );
          }
        }
      }
    })();
  }, [getDeposits, language, text]);

  const globalErrorMessage = fieldErrorMessage("global", error);

  React.useEffect(() => {
    if (error) {
      DepositOrderConditions.timeout = setTimeout(() => {
        setError(null);
      }, 6000);
    }
  }, [error]);

  React.useEffect(() => {
    return () => {
      clearTimeout(DepositOrderConditions.timeout);
    };
  }, []);

  React.useEffect(() => {
    if (products.length !== 0 && status === 200) {
      setIsSpinner(false);
    } else if (products.length === 0 && status === 200) {
      setIsSpinner(false);
      setNoDepositMessage(true);
    }
  }, [products, status]);
  return (
    <div className={classes.depositOrder}>
      <div className={classes.header}>
        <Title className={classes.title} text={text("depositOrderConditions.title")} />
      </div>
      <div className={classes.content}>{globalErrorMessage && <Error className={classes.error} message={globalErrorMessage} />}</div>
      <div className={classes.content}>
        <div>
          <Select
            className={classes.select}
            errorClassName={classes.error}
            labelText={text("depositOrderConditions.labels.currency")}
            options={currencies}
            value={currency}
            changeHandler={value => setCurrency(value)}
            errorMessage={fieldErrorMessage("currency", error)}
          />
        </div>
        <div>
          <Label>{text("depositOrderConditions.labels.amount")}</Label>
          <Input
            style={{ width: 250 }}
            name="amount"
            value={amount}
            changeHandler={value => setAmount(value)}
            units={currency}
            commaIsReplacedWithDot
            validator={value =>
              isCurrency(value, {
                digits_after_decimal: [0, 1, 2]
              })
            }
            errorMessage={fieldErrorMessage("amount", error)}
          />
        </div>
        <div>
          <Select
            className={classes.select}
            errorClassName={classes.error}
            labelText={text("depositOrderConditions.labels.term")}
            options={terms}
            value={term}
            changeHandler={value => setTerm(value)}
            errorMessage={fieldErrorMessage("term", error)}
          />
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.buttons}>
          <Button className={cn(classes.button, classes.buttonBack)} clickHandler={() => history.goBack()}>
            {text("buttons.cancel")}
          </Button>
          <Button
            className={cn(classes.button, classes.buttonSubmit)}
            clickHandler={() =>
              getDeposits({
                amount: amountForRequest(amount),
                currencyId: currency,
                ...JSON.parse(term)
              })
            }
          >
            {text("depositOrderConditions.buttons.approve")} &rarr;
          </Button>
        </div>
      </div>
      <div className={classes.products}>
        {isSpinner && <Spinner className={classes.spinner} diameter={50} />}
        {noDepositMessage && <div className={classes.warning}>{text("depositOrderConditions.noDepositByThisParams")}</div>}
        {!isSpinner &&
          products.length !== 0 &&
          products.map(product => (
            <div className={classes.product} onClick={() => onSelect(Object.assign({ amount }, product))} key={"".concat(product.productId, product.minAmount)}>
              <div className={classes.product_header}>
                <div className={classes.product_header_wrapper}>
                  <Icon name="depositTile" className={classes.product_header_icon} />
                  <div className={classes.product_header_name}>{product.name}</div>
                </div>
                <div className={classes.product_header_percent}>
                  <div>{text("depositOrderConditions.tableLable.annualRate")}:</div>
                  <div>{product.interestRate}%</div>
                </div>
              </div>
              <div className={classes.product_footer}>
                <div>
                  <div>{text("depositOrderConditions.tableLable.replenishment")}</div>
                  {product.refillAllowed ? <Icon name="doneIcon" /> : <Icon name="rejectIcon" />}
                </div>
                <div>
                  <div>{text("depositOrderConditions.tableLable.autoProlongation")}</div>
                  {product.autoProlongationAllowed ? <Icon name="doneIcon" /> : <Icon name="rejectIcon" />}
                </div>
                <div>
                  <div>{text("depositOrderConditions.tableLable.partialWithdrawal")}</div>
                  {product.earlyWithdrawlAllowed ? <Icon name="doneIcon" /> : <Icon name="rejectIcon" />}
                </div>
                <div>
                  <div>{text("depositOrderConditions.tableLable.payment")} %</div>
                  <div className={classes.termType}>{payout[product.repaymentTermType]}</div>
                </div>
                <div>
                  <div>{text("depositOrderConditions.tableLable.amountIncome")}:</div>
                  {/* change accruedAmount to repayAmount without field renaming - AGSUPP-207*/}
                  <div>
                    {localeAmount(product.repayAmount)} {product.currency}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DepositOrderConditions;
