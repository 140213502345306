import React, { useEffect, useState, useContext } from "react";
import classes from "./Receipt.module.scss";
import PropTypes from "prop-types";
import { Input, Button, } from "../../index";
import * as icons from "../../icons/icons";
import * as API from "../../../api/index";
import { downloadPDFFile } from "../../../helpers/downloadPDFFile";
import isEmail from "validator/lib/isEmail";
import { languageContext, modalContext } from "../../../context/index";
import { OperationResult } from "../../../components/index";


const Receipt = ({ operationId }) => {
    const { text } = useContext(languageContext);
    const { setModal } = useContext(modalContext);

    const [emailValue, setEmailValue] = useState('');
    const [isFetching, setIsFetching] = useState(true);
    const [isEmailSend,setIsEmailSend] = useState(false)

    useEffect(
        () => {
            if (isEmail(emailValue)) {
                setIsFetching(false)
            } else {
                setIsFetching(true)
            }
        }, [emailValue])

    const fetchReceiptInPDF = () => {
        API.getReceiptInPDF(operationId)
            .then(result => {
                const { data } = result;
                downloadPDFFile(data)
            })
    }

    const fetchSendReceiptToEmail = async () => {
        setIsFetching(true);
        setIsEmailSend(true);

        const payload = {
            type: "EMAIL",
            address: emailValue
        }
        await API.postSendReceiptToEmail(operationId, payload)
            .then(
                response => {
                    // console.log(response.status, 'result');
                    if (response.status === 204) {
                        setModal(
                            <OperationResult
                                titleText={text("history.receipt.sendReceiptSuccess")}
                                buttonText={text("buttonCancel")}
                                clickCompleteHandler={() => setModal(null)}
                            />
                        );
                    } else {
                        setModal(
                            <OperationResult
                                result="failure"
                                titleText={text("history.receipt.sendReceiptFail")}
                                buttonText={text("buttonCancel")}
                                clickCompleteHandler={() => setModal(null)}
                            />
                        );
                    }
                }
            )
            .catch(
                error => {
                    // console.log(error.response.status, 'result-catch');
                    if (error.response.status) {
                        setModal(
                            <OperationResult
                                result="failure"
                                titleText={text("history.receipt.sendReceiptFail")}
                                buttonText={text("buttonCancel")}
                                clickCompleteHandler={() => setModal(null)}
                            />
                        );
                    }
                }
            );
        setEmailValue('');
        setIsFetching(true);
    }

    return (
        <div className={classes.recieptWrapper}>
            <Input
                className={classes.input}
                placeholder="mymail@mail.com"
                value={emailValue}
                changeHandler={value => setEmailValue(value)}
                isDisabled={isEmailSend}
            />
            <Button
                className={classes.button}
                clickHandler={fetchSendReceiptToEmail}
                isDisabled={isFetching}
            >
                {text("history.receipt.sendReceipt")}
            </Button>
            <Button
                className={classes.buttonPDF}
                clickHandler={fetchReceiptInPDF}
            >
                <img src={icons.iconPDF} className={classes.iconPDF} alt="iconPDF" />
                <span>
                    {text("history.receipt.downloadReceipt")}
                </span>
            </Button>
        </div>
    )
}

Receipt.propTypes = {
    operationId: PropTypes.string,
}

export default Receipt