import React from 'react';
import * as API from "../../../api";
import PaymentsContext from "./PaymentsContext";
import { icons } from "./PaymentsUtils";

const PaymentsState = ({ children }) => {
  const [title, setTitle] = React.useState(null);
  const [biller, setBiller] = React.useState(null);
  const [billers, setBillers] = React.useState(null);
  const [categories, setCategories] = React.useState(null);
  
  const getCategories = React.useCallback(async () => {
    const result = await API.getPaymentsCategories();
    setCategories(
      result?.data?.billerCategoryList
        ?.filter(category => (category.isHidden || 'false') === 'false')
        .map(category => ({ ...category, iconName: icons[category.id] }))
    )
  }, []);
  
  const getBillers = React.useCallback(async ({ categoryId, search }) => {
    setBillers(null);
    const result = await API.getBillersByCategory({ limit: 10000, offset: 0, categoryId, searchQuery: search });
    setBillers(result.data?.billers?.filter(biller => (biller.isHidden || 'false') === 'false'));
  }, []);
  
  const getBiller = React.useCallback(async ({ billerId }) => {
    setBiller(null);
    const result = await API.getBillerBy(billerId);
    setBiller(result.data);
  }, []);
  
  return (
    <PaymentsContext.Provider
      value={{
        title,
        setTitle,
        categories,
        billers,
        getCategories,
        getBillers,
        biller,
        getBiller,
      }}
    >
      {children}
    </PaymentsContext.Provider>
  );
};

export default PaymentsState;
