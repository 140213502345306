import localization from "../localization/index";
import localeAmount from "./localeAmount";

const serverErrorMessage = (serverError, language = "ua") => {
  const serverErrors = localization[language].errors.server;
  const defaultErrorKey = "default";
  let errorKey = serverError?.response?.data?.errorMessageKey;
  let description = serverError?.response?.data?.errorDescription;
  let additional = "";

  if (errorKey === "limit_violation") {
    additional = localeAmount((description || "").replace(/^.*?(\d+)$/, "$1"));
    if (description.startsWith("minimum limit")) {
      errorKey = "limit_violation_min";
    }
    if (description.startsWith("maximum limit")) {
      errorKey = "limit_violation_max";
    }
  }

  return (serverErrors[errorKey]
      || (errorKey === "portmone" && description)
      || (errorKey === "exception_to_show" && description)
      || serverErrors[defaultErrorKey]) + additional;
};

export default serverErrorMessage;
