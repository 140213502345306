import React from "react";
import { Title } from "../../../components";
import { languageContext } from "../../../context";
import classes from "./News.module.scss";
import { useTitle } from "../../../hooks";
import { rssFetch } from "../../../api";
import { parseString } from "xml2js";
import { convertHtmlDate, convertHTMLEntity } from "./util";
import cn from "../../../helpers/cn";

const News = () => {
  const SIZE = 10;
  const [page, setPage] = React.useState(0);
  const { language, text } = React.useContext(languageContext);
  const [loading, setLoading] = React.useState(true);
  const [all, setAll] = React.useState([]);
  const [list, setList] = React.useState([]);

  useTitle(text("news.title"));

  React.useEffect(() => {
    setLoading(true);
    rssFetch(language)
      .then(
        ({ data }) =>
          new Promise((resolve, reject) => {
            parseString(data, (error, result) => {
              if (error) {
                reject(error);
              } else {
                resolve(result);
              }
            });
          })
      )
      .then(data => {
        setAll(data.rss.channel[0].item);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [language]);

  React.useEffect(() => {
    setList(all.slice(page * SIZE, page * SIZE + SIZE));
  }, [page, all]);

  return (
    <div className={classes.wrapper}>
      <Title text={text("news.title")} />

      {loading && <div>loading</div>}
      {!loading && (
        <div className={classes.list}>
          {list.map(item => {
            return (
              <div key={item.guid[0]} className={classes.item}>
                <a href={item.link[0]} target="_blank" rel="noopener nofollow noreferrer" className={classes.link}>
                  <div>{item.title[0]}</div>
                </a>
                <div className={classes.body} dangerouslySetInnerHTML={{ __html: convertHTMLEntity(item.description[0].replace(/<.*?>/g, "")) }} />
                <div className={classes.date}>{convertHtmlDate(item.pubDate[0])}</div>
              </div>
            );
          })}
        </div>
      )}
      <div className={classes.pages}>
        {""
          .padStart(Math.ceil(all.length / SIZE), "0")
          .split("")
          .map((_, index) => {
            return (
              <span className={cn(classes.page, page === index && classes.active)} onClick={() => setPage(index)}>
                {String(1 + index).padStart(2, "0")}
              </span>
            );
          })}
      </div>
    </div>
  );
};

export default News;
