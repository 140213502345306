import React, { useContext, useState, useMemo, useCallback } from "react";
import classes from "./Templates.module.scss";
import { languageContext, modalContext, templatesContext } from "../../../context/index";
import { Title, Button, Icon, Input, TemplateTile, TemplateSettings, OperationResult } from "../../../components/index";
import { useTitle } from "../../../hooks/index";
import serverErrorMessages from "../../../helpers/serverErrorMessages";

const Templates = ({ history }) => {
  const { setModal } = useContext(modalContext);
  const { language, text } = useContext(languageContext);
  const { templates, createTemplate, updateTemplate, deleteTemplate } = useContext(templatesContext);

  const [filter, setFilter] = useState("");

  const filteredTemplates = useMemo(() => {
    return filter
      ? templates.filter(template => {
          const modifiedFilter = filter.toLowerCase();
          const { name = "", description = "" } = template;
          const modifiedName = name.toLowerCase();
          const modifiedDescription = description.toLowerCase();
          return modifiedName.includes(modifiedFilter) || modifiedDescription.includes(modifiedFilter);
        })
      : templates;
  }, [filter, templates]);

  const addTemplate = useCallback(
    async payload => {
      try {
        setModal(null);
        await createTemplate(payload);
        setModal(
          <OperationResult
            titleText={`${text("templates.modals.createSuccess.titleText")}!`}
            text={text("templates.modals.createSuccess.text")}
            buttonText={text("buttons.complete")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      } catch (err) {
        setModal(
          <OperationResult
            result="failure"
            text={serverErrorMessages(err, language)}
            buttonText={text("buttons.close")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      }
    },
    [setModal, text, createTemplate, language]
  );

  const renewTemplate = useCallback(
    async (id, payload) => {
      try {
        setModal(null);
        await updateTemplate(id, payload);
        setModal(
          <OperationResult
            titleText={`${text("templates.modals.updateSuccess.titleText")}!`}
            text={text("templates.modals.updateSuccess.text")}
            buttonText={text("buttons.complete")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      } catch (err) {
        setModal(
          <OperationResult
            result="failure"
            text={serverErrorMessages(err, language)}
            buttonText={text("buttons.close")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      }
    },
    [setModal, text, updateTemplate, language]
  );

  const removeTemplate = useCallback(
    async id => {
      try {
        setModal(null);
        await deleteTemplate(id);
        setModal(
          <OperationResult
            titleText={`${text("templates.modals.deleteSuccess.titleText")}!`}
            text={text("templates.modals.deleteSuccess.text")}
            buttonText={text("buttons.complete")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      } catch (err) {
        setModal(
          <OperationResult
            result="failure"
            text={serverErrorMessages(err, language)}
            buttonText={text("buttons.close")}
            clickCompleteHandler={() => setModal(null)}
          />
        );
      }
    },
    [setModal, text, deleteTemplate, language]
  );

  useTitle(text("templates.title"));

  return (
    <div className={classes.wrapper}>
      <div className={classes.row}>
        <Input style={{ width: 420 }} value={filter} changeHandler={value => setFilter(value)} placeholder={text("templates.placeholder")} isSearch />
        <Button
          className={classes.addTemplateButton}
          clickHandler={() => setModal(<TemplateSettings isNew clickCancelHandler={() => setModal(null)} clickSaveHandler={addTemplate} />)}
        >
          <Icon name="plus" />
          &nbsp;&nbsp;{text("templates.addTemplateButton")}
        </Button>
      </div>
      <div className={classes.content}>
        <div className={classes.categories}>
          {!!filteredTemplates.length ? (
            <div className={classes.category}>
              <Title className={classes.title} text={text("templates.title")} />
              <div className={classes.templates}>
                {filteredTemplates.map((x, i) => (
                  <div className={classes.templateTileWrapper} key={i}>
                    <TemplateTile
                      iconName={x.iconName}
                      iconColor={x.iconColor}
                      name={x.name}
                      description={x.description}
                      amount={x.amount}
                      currency={x.currency}
                      buttonText={text("buttons.pay")}
                      clickPayHandler={() => history.push(x.url)}
                      clickSettingsHandler={() =>
                        setModal(
                          <TemplateSettings
                            initialType={x.type}
                            initialName={x.name}
                            initialGivingContract={x.givingContract}
                            initialGettingContract={x.gettingContract}
                            initialGettingAccount={x.gettingAccount}
                            initialReceiverName={x.receiverName}
                            initialReceiverCode={x.receiverCode}
                            initialTaxNumber={x.taxNumber}
                            initialPurpose={x.purpose}
                            initialAmount={x.amount}
                            initialDescription={x.description}
                            initialIconName={x.iconName}
                            initialIconColor={x.iconColor}
                            clickCancelHandler={() => setModal(null)}
                            clickSaveHandler={payload => renewTemplate(x.id, payload)}
                            clickDeleteHandler={() => removeTemplate(x.id)}
                          />
                        )
                      }
                      clickDeleteHandler={() => removeTemplate(x.id)}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className={classes.warning}>{text("templates.noTemplates")}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Templates;
