import axios from "../axios";

export const getCardHistory = (cardId, params) =>
  axios.get(`contract/card/${cardId}/history/all/between`, {
    params
  });

export const getAccountHistory = (accountId, params) =>
  axios.get(`contract/account/${accountId}/history/all/between`, {
    params
  });

export const getCreditHistory = (creditId, params) =>
  axios.get(`contract/credit/${creditId}/history/all/between`, {
    params
  });

export const getDepositHistory = (depositId, params) =>
  axios.get(`contract/deposit/${depositId}/history/all/between`, {
    params
  });

export const getFinancialHistory = params =>
  axios.get("profile/history/financial/all/between", {
    params
  });

export const getServiceHistory = params =>
  axios.get("profile/history/common/all/between", {
    params
  });

export const getAuthorizationHistory = params =>
  axios.get("profile/history/authentification/all/between", {
    params
  });

export const getFormatPDFByProvider = (providerId, id, params) =>
  axios.get(`contract/${providerId}/${id}/history/report/pdf/all/between`,
    { params })

export const getReceiptInPDF = (operationId) => axios.get(`/operation/${operationId}/receipt/pdf`)

export const postSendReceiptToEmail = (operationId, payload) => axios.post(`operation/${operationId}/receipt/pdf/send`, payload)
